import { createStyles, rem } from '@mantine/core'

export default createStyles((theme, _params, getRef) => {
  const height = rem(36)

  return {
    figure: {
      height,
    },

    imageWrapper: {
      height: '100%',
    },

    placeholder: {
      ['svg']: {
        width: rem(24),
        height: rem(24),
        verticalAlign: 'top',
      },
    },

    image: {
      height: `${height} !important`,
    },
  }
})
