import React, { useCallback } from 'react'
import { ActionIcon, Group, ThemeIcon } from '@mantine/core'
import { Icon } from '@/components/Elements'
import { ENTRIES_VIEW_MODE } from '@/const/layout'

interface IProps {
  value: ENTRIES_VIEW_MODE
  onChange: (value: ENTRIES_VIEW_MODE) => void
  options?: ENTRIES_VIEW_MODE[]
}

export const EntriesViewModeControl = ({ value, onChange, options }: IProps) => {
  const modes = Object.values(ENTRIES_VIEW_MODE)

  options = options || modes

  const getIcon = useCallback((name: ENTRIES_VIEW_MODE) => {
    return name === ENTRIES_VIEW_MODE.GRID ? 'grid-3x3' : 'list-ul'
  }, [])

  const onClick = (mode: ENTRIES_VIEW_MODE) => {
    if (mode !== value) {
      onChange(mode)
    }
  }

  return (
    <>
      <Group>
        {options.map((item) => (
          <ActionIcon
            variant={item === value ? 'outline' : 'default'}
            color={'primary'}
            key={item}
            onClick={() => onClick(item)}
          >
            <Icon name={getIcon(item)} />
          </ActionIcon>
        ))}
      </Group>
    </>
  )
}
