import React from 'react'
import { Link } from 'react-router-dom'
import { SimpleGrid } from '@mantine/core'
import { GeneralProjectCard } from '@/features/project/components/Projects/Cards'
import { Project } from '@/features/project/types'
import { PATH } from '@/routes/const'
import useStyles from './Style.styles'

interface IProps {
  items: Project[]
  editProps?: any
  removeProps?: any
}

export const GridView: React.FC<IProps> = ({ items, ...props }) => {
  const { classes } = useStyles()

  return (
    <>
      <SimpleGrid
        cols={4}
        breakpoints={[
          { maxWidth: 'lg', cols: 3 },
          { maxWidth: 'md', cols: 2 },
          { maxWidth: 'xs', cols: 1 },
        ]}
      >
        {items.map((item, index) => (
          <Link key={index} to={`/${PATH.PROJECTS}/${item?.id}`} className={classes.link}>
            <GeneralProjectCard
              data={item}
              actionBarProps={{ config: { menu: true } }}
              {...props}
            />
          </Link>
        ))}
      </SimpleGrid>
    </>
  )
}
