import * as React from 'react'
import { Outlet } from 'react-router-dom'
import { BasicLayout, BasicLayoutPage } from '@/components/Layouts'
import { useDocumentBodyClassNameEffect } from '@/hooks'
import useStyles from './AuthLayout.styles'

export const AuthLayout = () => {
  const { classes } = useStyles()

  useDocumentBodyClassNameEffect('bg-hero')

  return (
    <BasicLayout>
      <BasicLayoutPage>
        <div className={classes.wrapper}>
          <div className={classes.block}>
            <Outlet />
          </div>
        </div>
      </BasicLayoutPage>
    </BasicLayout>
  )
}
