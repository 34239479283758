import { createStyles } from '@mantine/core'

// eslint-disable-next-line
interface StylesParams {}

export default createStyles((theme, props: StylesParams) => {
  return {
    body: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },

    title: {
      display: 'flex',
      minWidth: 0,
    },
  }
})
