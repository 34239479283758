import { createStyles, rem } from '@mantine/core'

// eslint-disable-next-line
interface StylesParams {}

export default createStyles((theme, props: StylesParams) => {
  return {
    header: {
      display: 'flex',
      gap: theme.spacing.xs,
      minHeight: rem(28), // action bar size
    },

    topBar: {
      display: 'flex',
      flexGrow: 1,
      minWidth: 0,
    },

    actionBar: {},

    content: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
  }
})
