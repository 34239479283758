import { createStyles } from '@mantine/core'
import { GLAZING_TEMPLATE_DEFAULT_COLOR } from '@/features/glazing/GlazingTemplate/consts/glazing-template'

export interface StylesParams {
  color: string | null
}

export default createStyles((theme, _params: StylesParams) => {
  const { color } = _params

  return {
    root: {
      width: theme.spacing.xl,
      height: theme.spacing.xl,
      backgroundColor: color || GLAZING_TEMPLATE_DEFAULT_COLOR,
    },
  }
})
