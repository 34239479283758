import React from 'react'
import { Outlet } from 'react-router-dom'
import { GeneralProjectPanel } from '@/features/project/components/Project/Layouts'
import { useProjectData } from '@/features/project/hooks'
import { usePopup } from '@/hooks'
import { HeaderBurgerMenu } from './HeaderBurgerMenu'
import { Navbar } from './Navbar'

export const ProjectFacadesPanel = () => {
  const navbarDrawer = usePopup()

  const { project } = useProjectData({})

  return (
    <GeneralProjectPanel
      title={project?.name}
      navbar={
        project && (
          <Navbar
            drawerVisible={navbarDrawer.visible}
            onDrawerClose={navbarDrawer.close}
            project={project}
          />
        )
      }
      headerLeftSection={<HeaderBurgerMenu onDrawerOpen={navbarDrawer.open} />}
    >
      <Outlet />
    </GeneralProjectPanel>
  )
}
