import _ from 'lodash'
import { FEEDBACK_TYPE } from '@/features/feedback/consts/feedback'
import i18n from '@/i18n'

type OptionType = {
  label: string
  value: FEEDBACK_TYPE
}

const { QUESTION, FEEDBACK, FEATURE_REQUEST } = FEEDBACK_TYPE

export const getTypes = (): OptionType[] => {
  return [
    {
      label: i18n.t('question'),
      value: QUESTION,
    },
    {
      label: i18n.t('feedback'),
      value: FEEDBACK,
    },
    {
      label: i18n.t('feature_request'),
      value: FEATURE_REQUEST,
    },
  ]
}

export const getType = (value: FEEDBACK_TYPE): OptionType | any => {
  const options = getTypes()
  return _.find(options, { value })
}
