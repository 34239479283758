import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Input } from '@mantine/core'
import { GeneralFacadeSystemCard } from '@/features/facade/FacadeSystem/components/FacadeSystems/Cards'
import { FacadeSystem } from '@/features/facade/FacadeSystem/types'
import { FormUtils } from '@/utils'
import { Button } from './Button'
import { Footer } from './Footer'
import useStyles from './Style.styles'

export interface FacadeSystemPickerProps {
  value: string
  onChange: (value: string) => void
  options: FacadeSystem[]
  fieldRef?: any
  translateParams?: object
  meta?: any
  label?: any
  errorProps?: any
}

export const FacadeSystemPicker = ({
  options = [],
  value,
  onChange,
  meta,
  fieldRef,
  translateParams,
  ...props
}: FacadeSystemPickerProps) => {
  const { t } = useTranslation()

  const { classes, cx } = useStyles()

  const { error } = meta

  const showError = Boolean(error)

  const getErrorMessage = () => {
    return FormUtils.getErrorMessage(error, {
      t,
      translateParams: translateParams,
    })
  }

  const isChecked = useCallback(
    (id: string) => {
      return value === id
    },
    [value]
  )

  const onPick = (id: string) => {
    if (!isChecked(id)) {
      onChange(id)
    }
  }

  return (
    <>
      <Input.Wrapper
        {...props}
        error={showError ? getErrorMessage() : null}
        inputWrapperOrder={['label', 'error', 'input', 'description']}
      >
        {options.length ? (
          <Grid>
            {options.map((item, index) => (
              <Grid.Col key={index} xs={6} sm={4} md={4} lg={3} xl={2}>
                <GeneralFacadeSystemCard
                  data={item}
                  footer={<Footer checked={isChecked(item.id)} />}
                  component={Button}
                  classNames={{
                    root: cx(classes.root, { [`${classes.active}`]: isChecked(item.id) }),
                  }}
                  componentProps={{
                    onClick: () => onPick(item.id),
                  }}
                />
              </Grid.Col>
            ))}
          </Grid>
        ) : (
          t('no_options')
        )}
      </Input.Wrapper>
    </>
  )
}
