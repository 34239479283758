import React from 'react'
import { useTranslation } from 'react-i18next'
import { Image } from '@mantine/core'
import { FallProtection } from '@/features/facade/FallProtection/types'
import useStyles from './MediaBlock.styles'

interface IProps {
  data: FallProtection
}

export const MediaBlock = ({ data }: IProps) => {
  const { t } = useTranslation()

  const { picture } = data

  const { classes } = useStyles()

  return (
    <Image alt={'protection'} src={picture} classNames={classes} withPlaceholder fit={'contain'} />
  )
}
