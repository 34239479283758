import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Loader } from '@mantine/core'
import { Alert, OverlayBlockLoading } from '@/components/Elements'

interface IProps {
  isLoading: boolean
  isFetching: boolean
  isSuccess: boolean
  isError: boolean
  error: any
  children: React.ReactNode
  loaderProps?: {
    center?: boolean
    className?: string
  }
  overlayLoadingProps?: any
}

export const QueryDataShell = ({
  isLoading,
  isFetching,
  isSuccess,
  isError,
  error,
  children,
  loaderProps,
  overlayLoadingProps,
}: IProps) => {
  const { t } = useTranslation()

  loaderProps = {
    ...loaderProps,
    center: true,
  }

  if (isLoading)
    return (
      <div
        className={classNames({
          'text-center': loaderProps.center,
          [`${loaderProps.className}`]: loaderProps.className,
        })}
      >
        <Loader />
      </div>
    )

  const renderErrorMessage = () => {
    let message = null
    if (typeof error === 'string') {
      message = error
    }
    if (error?.message) {
      message = error.message
    }
    if (error?.data?.message) {
      message = error?.data?.message
    }

    return message || t('error')
  }

  if (isError) {
    return (
      <div>
        <Alert type={'error'}>{renderErrorMessage()}</Alert>
      </div>
    )
  }

  return (
    <React.Fragment>
      {isSuccess ? (
        <OverlayBlockLoading
          zIndex={100}
          isLoading={isFetching}
          overlayOpacity={0.3}
          {...overlayLoadingProps}
        >
          {children}
        </OverlayBlockLoading>
      ) : null}
    </React.Fragment>
  )
}
