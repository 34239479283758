import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { LegalShell } from '@/features/legal/components/Layouts'
import { PATH_LEGAL } from '@/routes/const'
import { ImprintDisclaimer, LicenceAgreement, PrivacyPolicy, Terms } from '../panel'

export const PanelRoot = () => {
  return (
    <Routes>
      <Route element={<LegalShell />}>
        <Route path={PATH_LEGAL.TERMS} element={<Terms />} />
        <Route path={PATH_LEGAL.LICENCE_AGREEMENT} element={<LicenceAgreement />} />
        <Route path={PATH_LEGAL.PRIVACY_POLICY} element={<PrivacyPolicy />} />
        <Route path={PATH_LEGAL.IMPRINT_DISCLAIMER} element={<ImprintDisclaimer />} />
      </Route>

      <Route path={'*'} element={<Navigate to={PATH_LEGAL.TERMS} replace={true} />} />
    </Routes>
  )
}
