import { GENDER } from '@/const/features'
import { ModelTypes } from '@/types'
import { ItemDTO } from '@/types'
import { CustomerProfile } from './Customer'
import { UserAddress } from './UserAddress'

export enum ROLE {
  CUSTOMER = 'customer',
}

export type BaseUserType = {
  id: string
  avatar: ItemDTO<ModelTypes.AvatarType> | null
  name: string
  last_name: string
  email: string
  email_verified_at: string | null
  gender: GENDER
  phone: string
  role: ROLE
  companyLogo: ItemDTO<ModelTypes.AvatarType> | null
}

export interface UserModel extends BaseUserType {
  customerProfile?: ItemDTO<CustomerProfile>
  userAddress?: ItemDTO<UserAddress>
}
