import React from 'react'
import { useTranslation } from 'react-i18next'
import { AvatarManagerControl } from '@/components/Controls'
import { useAvatarManager } from '@/features/profile/hooks'
import { useUser } from '@/features/user/hooks'

export const AvatarManager = () => {
  const { t } = useTranslation()

  const { user } = useUser()

  const { uploading, onUpload, removing, onRemove } = useAvatarManager()

  return (
    <AvatarManagerControl
      image={user?.avatar?.data?.full}
      onUpload={onUpload}
      onRemove={onRemove}
      uploading={uploading}
      removing={removing}
    />
  )
}
