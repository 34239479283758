import React from 'react'
import { GeneralCard } from '@/components/Elements'

interface IProps {
  title: string
  children: React.ReactNode
}

export const AuthCard = ({ title, children }: IProps) => {
  return (
    <GeneralCard title={title} titleProps={{ order: 1 }}>
      {children}
    </GeneralCard>
  )
}
