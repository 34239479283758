import { createStyles, rem } from '@mantine/core'

// eslint-disable-next-line
interface StylesParams {}

export default createStyles((theme, props: StylesParams) => {
  const variant = 'light'
  const colors = theme.fn.variant({ variant, color: 'gray' })

  const lineColor = '#45CDC7'

  return {
    root: {
      width: '100%',
      height: '100%',
      backgroundColor: colors.background,
      borderRadius: theme.radius.sm,
      padding: theme.spacing.md,
    },

    item: {},

    lineBlock: {
      display: 'flex',
      alignItems: 'center',
      height: rem(18),
    },

    line: {
      backgroundColor: lineColor,
      width: rem(54),
      height: rem(2),
    },

    solid: {},

    dotted: {
      background: `repeating-linear-gradient(90deg, ${lineColor} 0 ${rem(2)}, transparent 0 ${rem(
        4
      )})`,
    },

    dashedDotted: {
      background: `repeating-linear-gradient(90deg, ${lineColor} 0 ${rem(10)}, transparent 0 ${rem(
        12
      )}, ${lineColor} 0 ${rem(14)}, transparent 0 ${rem(16)})`,
    },
  }
})
