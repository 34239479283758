import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { EmailVerificationContainer } from '@/features/auth/components/EmailVerification'

export const EmailVerification = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'email_verification'} />

      <EmailVerificationContainer />
    </>
  )
}
