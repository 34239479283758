import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { Box, Flex, Loader, ScrollArea, Space } from '@mantine/core'
import { NavLink } from '@/features/panel/components/Layouts'
import { GeneralActionMenu } from '@/features/project-facade/components/ProjectFacadeActionControls'
import { projectFacadeHelper } from '@/features/project-facade/helpers'
import { ProjectFacade } from '@/features/project-facade/types'

interface IProps {
  projectId: string
  facades?: ProjectFacade[]
  onRemove: (data: ProjectFacade) => void
  lastRef: any
  isFetchingNextPage?: boolean
}

export const Content = ({
  projectId,
  facades = [],
  onRemove,
  lastRef,
  isFetchingNextPage = false,
}: IProps) => {
  const { t } = useTranslation()

  const location = useLocation()

  const locationPathname = location.pathname

  const getFacadePaths = useCallback(
    (id: string) => {
      return projectFacadeHelper.routes.getProjectFacadePathConfig(projectId, id)
    },
    [projectId]
  )

  const menu = useMemo(() => {
    return facades.map((item) => ({
      label: item.name,
      to: getFacadePaths(item.id).view,
      facade: item,
    }))
  }, [facades])

  return (
    <>
      <ScrollArea>
        <Space h={'xs'} />

        <Box px={'xs'}>
          {menu.map(({ label, to, facade }, index) => (
            <NavLink
              active={locationPathname === to}
              key={index}
              label={label}
              variant={'default'}
              component={Link}
              to={to}
              rightSection={
                <GeneralActionMenu
                  data={facade}
                  menuProps={{ withinPortal: true }}
                  removeProps={{ onRemove }}
                  editProps={{
                    component: Link,
                    to: getFacadePaths(facade.id).edit,
                  }}
                />
              }
              elementRef={index === menu.length - 1 ? lastRef : null}
            />
          ))}

          {isFetchingNextPage && (
            <Flex justify={'center'} align={'center'}>
              <Loader size={'sm'} />
            </Flex>
          )}
        </Box>

        <Space h={'xs'} />
      </ScrollArea>
    </>
  )
}
