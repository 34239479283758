import React from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { ProjectFacadeDataEntryPanel } from '@/features/project-facade/components/ProjectFacadeDataEntry/Layouts'
import { useProjectFacadeData } from '@/features/project-facade/hooks'
import { useProjectData } from '@/features/project/hooks'

export const ProjectFacadeEditPanel = () => {
  const { t } = useTranslation()

  const { project } = useProjectData({})

  const { facade } = useProjectFacadeData({})

  return (
    <>
      <ProjectFacadeDataEntryPanel isEdit project={project} facade={facade}>
        <Outlet />
      </ProjectFacadeDataEntryPanel>
    </>
  )
}
