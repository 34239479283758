import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mantine/core'
import { SimpleCard } from '@/components/Elements'
import { FallProtection } from '@/features/facade/FallProtection/types'
import useStyles from './Card.styles'
import { DescriptionBlock } from './DescriptionBlock'
import { MediaBlock } from './MediaBlock'
import { Title } from './Title'

interface IProps {
  data: FallProtection
  footer?: React.ReactNode
  component?: any
  classNames?: any
  componentProps?: any
}

export const FallProtectionCard = ({
  data,
  footer,
  component,
  classNames,
  componentProps,
  ...props
}: IProps) => {
  const { t } = useTranslation()

  const { classes } = useStyles({})

  return (
    <SimpleCard classNames={classNames} component={component} componentProps={componentProps}>
      <div className={classes.body}>
        <Box className={classes.media}>
          <MediaBlock data={data} />
        </Box>

        <Box mt={'xs'} className={classes.title}>
          <Title data={data} />
        </Box>

        <Box mt={'xs'}>
          <DescriptionBlock data={data} />
        </Box>
      </div>

      {footer && <Box mt={'xs'}>{footer}</Box>}
    </SimpleCard>
  )
}
