import { yupResolver } from '@hookform/resolvers/yup'
import React, { useState } from 'react'
import { flushSync } from 'react-dom'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Anchor, Group, Space } from '@mantine/core'
import { Alert, Button } from '@/components/Elements'
import {
  CheckboxFieldController,
  PasswordInputController,
  PasswordStrengthBar,
} from '@/components/FormElements'
import { PASSWORD_MIN_LENGTH } from '@/const/form'
import {
  CompanyFormSection,
  GeneralFormSection,
  LocationFormSection,
} from '@/features/profile/components/FormSections'
import { useFormSubmit } from '@/hooks'
import { usePasswordStrengthMeter } from '@/packages/password-strength-meter'
import { PATH, PATH_LEGAL } from '@/routes/const'
import { validationSchema } from './validation'

export type FormValues = {
  gender: string
  name: string
  last_name: string
  email: string
  phone: string
  company_name: string
  company_uid: string
  company_function?: string
  street: string
  street_number: string
  zip: string
  city: string
  password: string
  password_confirmation: string
  terms: boolean | undefined
  policy: boolean | undefined
}

interface IProps {
  onSubmit: (values: FormValues) => Promise<void>
}

export const RegisterForm: React.FC<IProps> = (props) => {
  const { t } = useTranslation()

  const [passScore, setPassScore] = useState(0)

  const methods = useForm<FormValues>({
    defaultValues: {
      name: '',
      last_name: '',
      email: '',
      phone: '',
      company_name: '',
      company_uid: '',
      company_function: '',
      street: '',
      street_number: '',
      zip: '',
      city: '',
      password: '',
      password_confirmation: '',
      terms: false,
      policy: false,
    },
    resolver: yupResolver(validationSchema as any),
    context: { passScore },
  })

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
    watch,
    setValue,
    trigger,
  } = methods

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: props.onSubmit,
    setError,
  })

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      await onFormSubmit(data)
    } catch (err) {}
  }

  const isLegalAccepted = watch('terms') && watch('policy')

  const watchPassword = watch('password')

  const { measure } = usePasswordStrengthMeter()

  const onPasswordChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const pass = event.target.value

    setValue('password', pass)

    await flushSync(async () => {
      const score = measure(pass)?.score
      setPassScore(score)
    })

    await trigger('password')
  }

  const legalControlConfig: any[] = [
    {
      name: 'terms',
      label: (
        <>
          {t('accept')}{' '}
          <Anchor
            target={'_blank'}
            component={Link}
            to={`/${PATH.LEGAL}/${PATH_LEGAL.TERMS}`}
            className={'font-bold'}
            state={{ from: `/${PATH.REGISTER}` }}
          >
            {t('terms_and_conditions')}
          </Anchor>
        </>
      ),
    },
    {
      name: 'policy',
      label: (
        <>
          {t('accept')}{' '}
          <Anchor
            target={'_blank'}
            component={Link}
            to={`/${PATH.LEGAL}/${PATH_LEGAL.LICENCE_AGREEMENT}`}
            className={'font-bold'}
            state={{ from: `/${PATH.REGISTER}` }}
          >
            {t('licence_agreement')}
          </Anchor>
          {' & '}
          <Anchor
            target={'_blank'}
            component={Link}
            to={`/${PATH.LEGAL}/${PATH_LEGAL.PRIVACY_POLICY}`}
            className={'font-bold'}
            state={{ from: `/${PATH.REGISTER}` }}
          >
            {t('privacy_policy')}
          </Anchor>
        </>
      ),
    },
  ]

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {submitError && (
          <Alert type={'error'} mb={'sm'}>
            {submitError?.data?.message || t('error')}
          </Alert>
        )}

        <GeneralFormSection />

        <CompanyFormSection />

        <LocationFormSection />

        <PasswordInputController
          control={control}
          name={'password'}
          id={'password'}
          label={t('password')}
          placeholder={t('password')}
          mb={'md'}
          translateParams={{ count: PASSWORD_MIN_LENGTH }}
          onChange={onPasswordChange}
        />

        {watchPassword && <PasswordStrengthBar mb={'md'} score={passScore} />}

        <PasswordInputController
          control={control}
          name={'password_confirmation'}
          id={'password_confirmation'}
          label={t('confirm_password')}
          placeholder={t('confirm_password')}
          mb={'md'}
          translateParams={{ count: PASSWORD_MIN_LENGTH }}
        />

        <Space h={'xl'} />

        {legalControlConfig.map(({ name, label }) => (
          <CheckboxFieldController
            key={name}
            control={control}
            name={name}
            id={name}
            label={label}
            mb={'md'}
          />
        ))}

        <Space h={'xl'} />

        <Group mt={'xl'} position={'right'}>
          <Button type={'submit'} disabled={!isLegalAccepted} loading={isSubmitting}>
            {t('register')}
          </Button>
        </Group>
      </form>
    </FormProvider>
  )
}
