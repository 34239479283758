import { SortDirection } from '@tanstack/react-table'
import { createStyles, rem } from '@mantine/core'

type StylesParams = {
  isSorted?: SortDirection | false
}

export default createStyles((theme, _params: StylesParams) => {
  return {
    root: {},
  }
})
