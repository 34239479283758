import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useUnitOfMeasure } from '@/features/measure/hooks'
import { useProjectUpdateMutation } from '@/features/project/store'
import { Project } from '@/features/project/types'
import { useNotify } from '@/hooks'
import { CommonUtils } from '@/utils'
import { ProjectForm } from '../../ProjectForm'

interface IProps {
  data: Project
  onCancel: () => void
  onSuccess?: (data: any) => void
  queryOptions?: { invalidatesTagsById: boolean }
}

export const ProjectEditContainer = ({
  data: project,
  onCancel,
  onSuccess,
  queryOptions,
}: IProps) => {
  const { t } = useTranslation()

  const [update] = useProjectUpdateMutation()

  const { showNotification } = useNotify()

  const onSubmit = async (values: any) => {
    const params = {
      ...values,
    }

    const data = await update({ id: project?.id, params, options: queryOptions }).unwrap()

    showNotification({
      type: 'success',
      message: t('saved_successfully'),
    })

    onSuccess && onSuccess(data)
  }

  const { measure } = useUnitOfMeasure()

  const initialValues = useMemo(() => {
    if (project) {
      const { length, width, height, wind_speed_zone, ...rest } = project

      const getFieldValue = (value?: number | null) => {
        return !CommonUtils.isNil(value) ? measure.length.cmToM(value as number) : ''
      }

      return {
        length: getFieldValue(length),
        width: getFieldValue(width),
        height: getFieldValue(height),
        // wind_speed_zone: !CommonUtils.isNil(wind_speed_zone) ? wind_speed_zone : '',
        ...rest,
      }
    }
    return {}
  }, [project])

  return (
    <>
      <ProjectForm
        onSubmit={onSubmit}
        onCancel={onCancel}
        initialValues={{
          ...initialValues,
        }}
      />
    </>
  )
}
