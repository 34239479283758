import React from 'react'
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params'

type SortingType = {
  orderBy: string
  sortedBy?: 'asc' | 'desc'
}

export const useGeneralProjectsQueryParams = () => {
  const [query, setQuery] = useQueryParams(
    {
      page: withDefault(NumberParam, 1),
      keywords: withDefault(StringParam, ''),
      orderBy: withDefault(StringParam, ''),
      sortedBy: withDefault(StringParam, ''),
    },
    { removeDefaultsFromUrl: true }
  )

  const { page, orderBy, sortedBy, ...restQuery } = query

  const filters = {
    ...restQuery,
  }

  const onPageChange = (page?: number) => {
    setQuery({ page })
  }

  const onFiltersChange = (data?: object) => {
    const params = {
      ...data,
    }

    setQuery({ page: undefined, ...params })
  }

  const sorting = {
    orderBy,
    sortedBy,
  }

  const onSortingChange = (data?: SortingType) => {
    const params = {
      ...data,
    }

    setQuery({ page: undefined, ...params })
  }

  return {
    query,
    setQuery,
    page,
    onPageChange,
    filters,
    onFiltersChange,
    sorting,
    onSortingChange,
  }
}
