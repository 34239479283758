import { TFunction } from 'i18next'
import * as Yup from 'yup'

export const buildYupLocale = (t: TFunction): void => {
  Yup.setLocale({
    mixed: {
      required: {
        key: 'field.error.required',
      },
    },
    string: {
      email: {
        key: 'field.error.invalid',
      },
      min: ({ min }: any) => ({ key: 'field.error.min.length', values: { count: min } }),
    },
    number: {
      min: ({ min }: any) => ({ key: 'field.error.greater_or_equal', values: { value: min } }),
      max: ({ max }: any) => ({ key: 'field.error.less_or_equal', values: { value: max } }),
    },
    array: {
      min: ({ min }: any) => ({ key: 'field.error.array.min.length', values: { value: min } }),
    },
  })
}
