import { createStyles, getStylesRef, rem } from '@mantine/core'

// eslint-disable-next-line
interface StylesParams {}

export default createStyles((theme, props: StylesParams) => {
  const variant = 'light'
  const colors = theme.fn.variant({ variant, color: 'gray' })

  const borderColor = theme.colors.gray[2]

  return {
    control: {
      ref: getStylesRef('control'),

      backgroundColor: colors.background,

      ...theme.fn.hover({
        backgroundColor: colors.hover,
      }),

      borderBottom: `${rem(1)} solid ${borderColor}`,
    },

    item: {
      ref: getStylesRef('item'),

      borderBottom: `none`,

      [`&:first-of-type .${getStylesRef('control')}`]: {
        borderTop: `${rem(1)} solid ${borderColor}`,
      },

      [`&[data-active] + .${getStylesRef('item')} .${getStylesRef('control')}`]: {
        borderTop: `${rem(1)} solid ${borderColor}`,
      },
    },
  }
})
