import { createStyles, rem } from '@mantine/core'

export interface StylesParams {
  height: number
}

export default createStyles((theme, _params: StylesParams) => {
  const { height } = _params

  const variant = 'light'
  const colors = theme.fn.variant({ variant, color: 'gray' })

  return {
    main: {
      display: 'flex',
      width: '100%',
      backgroundColor: colors.background,
      minHeight: rem(height),
      alignItems: 'center',
    },
  }
})
