import React from 'react'
import { useTranslation } from 'react-i18next'
import { Group, Text } from '@mantine/core'
import { Button, Modal } from '@/components/Elements'

interface IProps {
  visible: boolean
  children?: React.ReactNode
  message?: string | null
  confirmText?: React.ReactNode
  onConfirm: () => void
  onCancel?: () => void
  onClose: () => void
  zIndex?: number
}

// Todo: refactoring via modal manager
export const ConfirmModal = ({
  visible,
  children,
  message,
  confirmText,
  onConfirm,
  onCancel,
  onClose,
  ...props
}: IProps) => {
  const { t } = useTranslation()

  message = message || t('please_confirm')

  const renderMessage = () => <Text>{message}</Text>

  const handleCancel = () => {
    if (onCancel) {
      onCancel()
    } else {
      onClose && onClose()
    }
  }

  return (
    <>
      <Modal
        opened={visible}
        onClose={onClose}
        centered
        title={t('confirmation')}
        size={'md'}
        {...props}
      >
        <div>
          {children ? children : renderMessage()}

          <Group mt={'xl'} position={'right'}>
            <Button variant="default" onClick={handleCancel}>
              {t('cancel')}
            </Button>

            <Button onClick={onConfirm}>{confirmText ? confirmText : t('confirm')}</Button>
          </Group>
        </div>
      </Modal>
    </>
  )
}
