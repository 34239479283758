import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { ProjectFacadeDataLayout } from '@/features/project-facade/components/ProjectFacade'
import { ProjectFacadesPanel } from '@/features/project-facade/components/ProjectFacades/Layouts'
import { PATH_PROJECTS_FACADES } from '@/routes/const'
import { FacadeCreate } from '../create'
import { FacadeRoot } from '../facade'
import { Facade } from '../facade'
import { Facades } from '../facades'

const { CREATE } = PATH_PROJECTS_FACADES

export const ProjectFacadeRoot = () => {
  return (
    <Routes>
      <Route element={<ProjectFacadesPanel />}>
        <Route index element={<Facades />} />

        <Route element={<ProjectFacadeDataLayout />}>
          <Route path={':facadeId'} element={<Facade />} />
        </Route>
      </Route>

      <Route element={<ProjectFacadeDataLayout />}>
        <Route path={':facadeId/*'} element={<FacadeRoot />} />
      </Route>

      <Route path={`${CREATE}`} element={<FacadeCreate />} />

      <Route path={'*'} element={<Navigate to={'/'} replace={true} />} />
    </Routes>
  )
}
