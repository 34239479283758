import React from 'react'
import { TypographyStylesProvider, TypographyStylesProviderProps } from '@mantine/core'

interface IProps extends Omit<TypographyStylesProviderProps, 'children'> {
  data?: string
}

export const HtmlContent = ({ data = '', ...props }: IProps) => {
  return (
    <TypographyStylesProvider pl={0} {...props}>
      <div
        dangerouslySetInnerHTML={{
          __html: data,
        }}
      />
    </TypographyStylesProvider>
  )
}
