export const DE = {
  cancel: 'Abbrechen',
  canceled: 'Abgebrochen',
  expiry_date: 'Ablaufdatum',
  logout: 'Abmelden',
  license_option: 'Abo Optionen',
  subscription_plan: 'Abonnement ',
  change_subscription_plan: 'Abonnement Ändern',
  subscribed_canceled: 'Abonnement beendet',
  subscription_plan_changed: 'Abonnement geändert',
  subscription_plans: 'Abonnemente',
  subscribe: 'Abonnieren',
  billing: 'Abrechnung',
  invoices: 'Abrechnungen',
  billing_information: 'Abrechnungsinformationen',
  billing_account: 'Abrechnungskonto',
  billed_period: 'Abrechnungszeitraum',
  'fall_protection.p2.description': 'Absturzhemmung mit VSG auf der Aussenseite',
  'fall_protection.p1.description': 'Absturzhemmung mit VSG auf der Raumseite',
  'fall_protection.p4.description':
    'Absturzhemmung mit VSG auf der Raumseite oder mit zusätzlichem Bauteil bei beweglichen Verglasungen (z.B. VSG-Glasgeländer oder Stange)',
  'fall_protection.p3.description':
    'Absturzhemmung mit zusätzlichem Bauteil bzw. Geländer (Französischer Balkon)',
  address: 'Adresse',
  terms_and_conditions: "AGB's",
  legend: 'Legend DE',
  type: 'Type DE',
  active: 'Aktiv',
  refresh: 'Aktualisieren',
  current_plan: 'Aktuelles Abonnement',
  current_period_ends_at: 'Aktuelles Abonnement endet am',
  current_password: 'Aktuelles Passwort',
  accept: 'Akzeptieren',
  all_rights_reserved: 'Alle Rechte vorbehalten',
  clear_all: 'Alles Löschen',
  general: 'Allgemein  ',
  common_information: 'Allgemeine Informationen',
  general_info: 'Allgemeine Informationen',
  request_sent_successfully: 'Anfrage erfolgreich gesendet',
  send_request: 'Anfrage senden',
  'attached-file': 'Angehängte Datei',
  attachment: 'Anhang',
  attachments: 'Anhänge',
  sign_up: 'Anmelden',
  registration: 'Anmeldung',
  prefix: 'Anrede',
  apply: 'Anwenden',
  resources: 'Anwendungshilfen',
  architect: 'Architekt',
  type_of_request: 'Art der Anfrage',
  chosen_glass: 'Ausgewähltes Glas',
  expand: 'Ausklappen',
  pending: 'Ausstehend',
  edit: 'Bearbeiten',
  show_constructor_actions: 'Bearbeitungsfenster öffnen',
  note: 'Bemerkung',
  user: 'Benutzer',
  view_profile: 'Benutzerkonto ansehen',
  account_deleted_successfully: 'Benutzerkonto erfolgreich gelöscht',
  create_an_account: 'Benutzerkonto erstellen',
  delete_account: 'Benutzerkonto löschen',
  description: 'Beschreibung',
  confirm: 'Bestätigen',
  confirmation: 'Bestätigung  ',
  send_confirmation_email_to: 'Bestätigung an Mailadresse senden',
  subscribed_successfully: 'Bestellung erfolgreich',
  amount: 'Betrag',
  subject: 'Betreff',
  pay: 'Bezahlen',
  'feedback.hint.text':
    'Bitte beschreibe dein Anliegen und kontrolliere anschliessend die Kontaktangaben. Ein Administrator wird sich in den nächsten Tagen melden. ',
  please_confirm: 'Bitte bestätigen',
  please_fill_following_fields: 'Bitte fülle die folgenden Felder aus',
  'projects_facades.empty_content.title-2': 'Bitte zuerst eine Fassade erstellen',
  building_width: 'Breite des Gebäudes',
  under_pertition_height: 'Brüstungshöhe [mm]',
  cvc: 'CVC',
  subscribed_resumed: 'Das Abonnement wurde wieder aufgenommen',
  'field.error.greater_or_equal': 'Das Feld muss grösser oder gleich {{value}} sein',
  'field.error.less_or_equal': 'Das Feld muss kleiner oder gleich {{value}} sein',
  'field.error.array.min.length': 'Das Feld muss mindestens {{value}} Material enthalten',
  'field.error.company_uid.rule':
    'Das Feld muss mit 2 bis 4 Buchstaben starten, gefolgt von 8 bis 12 Nummern',
  'field.error.password.npt_strong': 'Das Passwort ist zu schwach',
  'field.error.password.length': 'Das Passwort muss mindestens {{count}} Zeichen lang sein',
  'field.error.password.no_match': 'Das Passwort stimmt nicht überein',
  'reset_password.success': 'Das Passwort wurde erfolgreich zurückgesetzt',
  'attach-files': 'Datei anhängen',
  confidential_info_settings: 'Datenschutzeinstellungen',
  privacy_policy: 'Datenschutzrichtlinie',
  date: 'Datum',
  deactivation: 'Deaktivieren',
  deflection: 'Deformation',
  your_cards: 'Deine Karten',
  details: 'Details',
  view_details: 'Details ansehen',
  'confirmation-email-sent': 'Die Bestätigung wurde an deine Mailadresse versendet',
  'field.error.file-size.max': 'Die Datei darf nicht grösser als {{text}} sein',
  'file.max_size.error': 'Die Datei darf nicht grösser als {{text}} sein',
  'field.error.date.after-or-equal': 'Die Eingabe muss ein Datumnach dem {{date}} sein',
  'field.error.min.length': 'Die Eingabe muss mindestens {{count}} Zeichen lang sein',
  'field.error.date.after': 'Die Eingabe muss nach dem {{date} sein',
  'field.error.time.after': 'Die eingabe muss nach dem {{date}} sein',
  'field.error.digits_between': 'Die Eingabe muss zwischen {{min}} und {{max}} Ziffern',
  payment_was_successful: 'Die Zahlung war erfolgreich ',
  'billing.pay.subscription.success':
    'Die Zahlung war erfolgreich. Das Abonnement wird in kürze freigeschaltet.',
  skill_already_exist: 'Diese Funktion ist bereits vorhanden',
  documentation: 'Dokumentation',
  you: 'Du',
  'projects_facades.empty_content.title-1': 'Du hast noch keine Fassaden erstellt',
  dark: 'Dunkel',
  editor: 'Editor',
  'message.error-fallback': 'Ein Fehler ist aufgetreten',
  'fall_protection.p7.description': 'Einbausituation mit hohen Verglasungen',
  burglaryType: 'Einbruchschutz (RC)',
  burglary_protection_RC2: 'Einbruchschutz (RC2)',
  collapse: 'Einklappen',
  single_pane_safety_glass: 'Einscheibensicherheitsglas (ESG)',
  settings: 'Einstellungen',
  profile_settings: 'Einstellungen Benutzerkonto',
  window_settings: 'Einstellungen Glas',
  floor_settings: 'Einstellungen Stockwerk',
  window_updated: 'Element erfolgreich bearbeitet',
  window_deleted: 'Element erfolgreich gelöscht',
  window_width: 'Elementbreite [mm]',
  floor_height: 'Elementhöhe [mm]',
  window_typeId: 'Elementtyp',
  email: 'Email',
  email_verification: 'Email Bestätigung',
  'forgot-password.hint': 'Email eingeben, um dein Passwort zurückzusetzen',
  end_date: 'Enddatum',
  ends_at: 'Endet am',
  personal_data_settings: 'Eninstellungen persönliche Daten',
  success: 'Erfolgreich',
  removed_successfully: 'Erfolgreich entfernt',
  deleted_successfully: 'Erfolgreich gelöscht',
  send_successfully: 'Erfolgreich gesendet',
  saved_successfully: 'Erfolgreich gespeichert',
  sent_successfully: 'Erfolgreich versendet',
  'projects.empty_content.title-2':
    'Facade/Lab ist einfaches Tool, um architektonische Ideen der Glasfassade einfach umzusetzen',
  faq: 'FAQ',
  facade: 'Fassade',
  edit_facade: 'Fassade bearbeiten',
  'edit_facade.common_info.title': 'Fassade bearbeiten: Allgemeine Informationen',
  'edit_facade.glazing_browser.title': 'Fassade bearbeiten: Glaskatalog',
  'edit_facade.application_pane_editor.title':
    'Fassade bearbeiten: in der Konstruktionsebene bearbeiten',
  'edit_facade.application_pane.title': 'Fassade bearbeiten: Konstruktionsebene',
  'edit_facade.product_browser.title': 'Fassade bearbeiten: Profilkatalog',
  update_facade_success: 'Fassade erfolgreich bearbeitet',
  create_facade: 'Fassade erstellen',
  add_facade: 'Fassade hinzufügen',
  'projects_facades.select_facade.title-1': 'Fassade Wählen',
  facades: 'Fassaden',
  facade_name: 'Fassadenbezeichnung',
  delete_window: 'Fassadenelement löschen',
  feedback: 'Feeback',
  error: 'Fehler',
  payment_error: 'Fehler beim bezahlen',
  company_address: 'Firmenanschrift',
  company_info: 'Firmeninformationen',
  float_glass: 'Float Glas',
  upload_photo: 'Foto hochladen',
  delete_photo: 'Foto löschen',
  question: 'Frage',
  female: 'Frau',
  mrs: 'Frau',
  frequently_asked_questions: 'Frequently Asked Questions',
  function: 'Funktion',
  feature_request: 'Funktions Anfrage',
  to: 'Für  ',
  'registration.title': 'Für Facade/Lab Anmelden',
  modified: 'Geändert',
  modified_on: 'Geändert am',
  building_address: 'Gebäudeadresse',
  enter_building_data: 'Gebäudedaten eingeben',
  tare_weight: 'Gewicht',
  choose_glass: 'Glas auswählen',
  choose_glasses: 'Glas auswählen',
  glazing_browser: 'Glaskatalog',
  free: 'Gratis',
  'fall_protection.feature.coarse-refractive-glass': 'grob brechendes Glas (Float-, Gussglas, TVG)',
  largest_support_force: 'Grösste Auflagerlast',
  biggest_momentum: 'Grösstes Moment',
  action: 'Handlung?',
  light: 'Hell',
  help_center: 'Help Center',
  mr: 'Herr',
  manufacturer: 'Hersteller',
  download: 'Herunterladen',
  today: 'Heute',
  add: 'Hinzufügen',
  building_height: 'Höhe des Gebäudes',
  iban: 'IBAN',
  already_have_account: 'Ich habe bereits ein Benutzerkonto',
  register_terms_label: 'Ich habe die {{terms of sercive and conditions}} gelesen und verstanden',
  dont_have_account_yet: 'Ich habe noch kein Benutzerkonto',
  'imprint_and_disclaimer.title': 'Impressum & Haftungsausschluss',
  inactive: 'Inaktiv',
  information: 'Information',
  yes: 'Ja',
  year: 'Jahr',
  annually: 'Jährlich',
  start_now: 'Jetzt Starten',
  card: 'Karte',
  card_number: 'Kartennummer',
  no_glasses_found_matching_conditions: 'Kein Glas gefunden, welches die Anforderungen erfüllt',
  no_options: 'Keine Auswahl',
  no_data: 'Keine Daten',
  no_info: 'Keine Informationen',
  no_messages: 'Keine Nachrichten',
  no_data_found_matching_conditions: 'Keine passenden Daten gefunden',
  no_systems_found_matching_conditions: 'Keine passenden Profile gefunden',
  constructor: 'Konstrukteur',
  application_pane: 'Konstruktionsebene',
  contact_us: 'Kontak',
  country: 'Land',
  building_length: 'Länge des Gebäudes',
  empty: 'Leer',
  latest_invoices: 'Letzte Abrechnungen',
  get_license: 'Lizenz kaufen',
  licence_agreement: 'Lizenzvereinbarung',
  login: 'Login',
  upload_logo: 'Logo hochladen',
  delete_logo: 'Logo löschen',
  delete: 'Löschen',
  male: 'Mann',
  material: 'Material',
  materials: 'Materialien',
  material_type: 'Materialtyp',
  glazCountTypes: 'Maximal verschiedene Glasstypen',
  load_more: 'Mehr laden',
  my_projects: 'Meine Projekte',
  quantity: 'Menge',
  remember_it: 'Merken',
  'min-characters.count': 'Min. {{count}} Zeichen',
  mm: 'mm',
  month: 'Monatlich',
  monthly: 'Monatlich',
  vat: 'MwSt',
  last_name: 'Nachname',
  message: 'Nachricht',
  write_a_message: 'Nachricht schreiben',
  send_message: 'Nachricht senden',
  name: 'Name',
  name_and_surname: 'Name und Vorname',
  no: 'Nein',
  add_new_facade: 'Neue Fassade hinzufügen',
  new_card: 'Neue Karte',
  new_message: 'Neue Nachricht',
  new_sepa: 'Neue SEPA',
  window_added: 'Neues Element erfolgreich hinzugefügt',
  add_new_window: 'Neues Fassadenelement hinzufügen',
  new_password: 'Neues Passwort  ',
  'reset-password.hint': 'Neues Passwort eingeben',
  new_project: 'Neues Projekt',
  floor_added: 'Neues Stockwerk erfolgreich hinzugefügt',
  add_new_floor: 'Neues Stockwerk hinzufügen',
  set_new_password: 'Neuse Passwort festlegen',
  not_filled: 'Nicht ausgefüllt',
  unpaid: 'Nicht bezahlt',
  unverified: 'Nicht verifiziert',
  incomplete: 'Nicht vollständig',
  'projects.empty_content.title-1': 'Noch keine Projekte vorhanden',
  'number-no_abbreviation': 'Nr.',
  number: 'Nummer',
  register_terms_text: 'Nutzungsbedingungen',
  or: 'oder',
  'projects_facades.select_facade.title-2': 'Oder neue Fassade einfügen',
  open: 'Öffnen',
  choose_option: 'Option wählen',
  city: 'Ort',
  location: 'Ort',
  panels: 'Paneele',
  choose_panels: 'Paneele auswählen',
  add_panels: 'Paneele hinzufügen',
  password: 'Passwort',
  change_password: 'Passwort ändern',
  confirm_new_password: 'Passwort bestätigen',
  confirm_password: 'Passwort bestätigen',
  password_changed_successfully: 'Passwort erfolgreich geändert',
  forgot_password: 'Passwort vergessen',
  reset_password: 'Passwort zurücksetzen',
  paused: 'Pausiert',
  'fall_protection.p6.description':
    'Personenschutz ohne Absturzsituation bei geschosshoher Verglasung',
  'fall_protection.p5.description': 'Personenschutz ohne Absturzsituation bei tiefer Brüstung',
  personal_data: 'Persönliche Daten',
  individual_info: 'Persönliche Informationen',
  required: 'Pflichtfeld',
  'field.error.required': 'Pflichtfeld',
  zip: 'PLZ',
  price: 'Preis ',
  pricing: 'Preise',
  profile: 'Profil',
  system: 'Profil',
  edit_profile: 'Profil bearbeiten',
  chosen_system: 'Profil wählen',
  product_browser: 'Profilkatalog',
  project: 'Projekt',
  edit_project: 'Projekt bearbeiten',
  create_a_project: 'Projekt erstellen',
  projects: 'Projekte',
  project_info: 'Projektinformationen',
  project_name: 'Projektname',
  invoice: 'Rechnung',
  billed_to: 'Rechnung an',
  reference_number: 'Referenz Nummer',
  register: 'Registrieren',
  step: 'Schritt',
  'fall_protection.p1': 'Schutz 1',
  'fall_protection.p2': 'Schutz 2',
  'fall_protection.p3': 'Schutz 3',
  'fall_protection.p4': 'Schutz 4',
  'fall_protection.p5': 'Schutz 5',
  'fall_protection.p6': 'Schutz 6',
  'fall_protection.p7': 'Schutz 7',
  'fall_protection.category.falls': 'Schutz gegen Absturz',
  protection_against_falls: 'Schutz gegen Absturz',
  'fall_protection.category.cuts': 'Schutz gegen Schnittverletzungen',
  protection_against_cuts: 'Schutz gegen Schnittverletzungen',
  protectionType: 'Schutz nach SIGAB',
  send_reset_link: 'Sende einen Link zum Zurücksetzen',
  send: 'Senden',
  sepa: 'SEPA',
  'fall_protection.feature.safety-glass': 'Sicherheitsglas (ESG, VSG)',
  save: 'Speichern',
  default: 'Standart',
  start_date: 'Startdatum',
  home: 'Startseite',
  status: 'Status',
  subscription_status: 'Status des Abonnements',
  wind_speed_zone: 'Staudruck',
  floor_updated: 'Stockwerk erfolgreich bearbeitet',
  floor_deleted: 'Stockwerk erfolgreich gelöscht',
  delete_floor: 'Stockwerk löschen',
  street: 'Strasse',
  street_no: 'Strassennummer',
  up_pertition_height: 'Sturzhöhe [mm]',
  search: 'Suche',
  support: 'Support',
  partially_tempered_glass: 'Teil vorgespanntes Glas (TVG)',
  subtotal: 'Teilbetrag',
  phone: 'Telefon',
  phone_number: 'Telofonnummer',
  trialing: 'Testen',
  on_grace_period: 'Testperiode',
  get_for_test: 'Testzugang',
  tiefe: 'Tiefe',
  total: 'Total',
  isWindow: 'Transparentes Bauteil',
  about_us: 'Über uns',
  past_due: 'Überfällig',
  dashboard: 'Übersicht',
  uid: 'UID',
  vat_code: 'UID',
  vat_id: 'UID',
  vat_id_unverified: 'UID nicht verifiziert',
  vat_id_verified: 'UID verifiziert',
  'billing.incomplete_payment.message':
    'Um das Abonnement zu aktivieren, musst du die Zahlung vervollständigen',
  unread_messages: 'Ungelesene Nachrichten',
  'field.error.invalid': 'Ungültige Eingabe',
  our_offers: 'Unsere Angebote',
  our_products: 'Unsere Produkte',
  company: 'Unternehmen',
  incomplete_expired: 'Unvolständig abgelaufen',
  update: 'Update',
  variation: 'Variation',
  'fall_protection.feature.laminated-safety-glass': 'Verbund-Sicherheitsglas (VSG)',
  laminated_safety_glass: 'Verbund-Sicherheitsglas (VSG)',
  pane_glazing: 'Verglasung',
  glazing: 'Verglasung  ',
  view_glazing: 'Verglasung ansehen',
  selected_glazing: 'Verglasung auswählen',
  use_auto_glazing: 'Verglasung automatisch generieren',
  edit_glazing: 'Verglasung beareiten',
  add_glazing: 'Verglasung hinzufügen',
  add_new_glazing: 'Verglasung hinzufügen',
  create_glazing: 'Verglasung hinzufügen',
  verified: 'Verifiziert',
  'email_verification.success': 'Verifizierung erfolgreich bestanden',
  confidential_info: 'Vertrauliche Informationen',
  from: 'Von',
  templates: 'Vorlagen',
  first_name: 'Vorname',
  choose_license_option: 'Wähle ein Abonnement',
  left_pertition_width: 'Wandscheibe links [mm]',
  right_pertition_width: 'Wandscheibe rechts [mm]',
  what_included: 'Was ist alles dabei?',
  next: 'Weiter',
  'billing_info.editable-alert':
    'WICHTIG! Du kannst das Land und die UID nicht mehr Ändern, wenn du das Abo bestellst',
  'help_center.subtitle': 'Wie können wir dir helfen?',
  resume: 'Wieder aufnehmen',
  welcome: 'Willkommen',
  welcome_back: 'Willkommen zurück',
  wind_load: 'Windlasten',
  'forgot_password.success':
    'Wir haben dir den Link zum Passwort zurücksetzten an deine Emailadresse gesendet',
  incomplete_payment: 'Zahlung nicht vollständig',
  complete_payment: 'Zahlung vervollständigen',
  payment_information: 'Zahlungsinformationen',
  payment_method: 'Zahlungsmethode ',
  add_payment_method: 'Zahlungsmethode hinzufügen',
  payment_methods: 'Zahlungsmethoden',
  payment_system: 'Zahlungssystem',
  payment: 'Zahlunng',
  go_to_login_page: 'Zum Login',
  back: 'Zurück',
  back_to: 'Zurück',
  back_to_login_page: 'Zurück zur Startseite',
  reset: 'Zurücksetzen',
  summary: 'Zusammenfassung',
  'types_of_glass.construction_from_the_inside_to_the_outside':
    'Glastypen - Aufbau von Innen nach Aussen',
}
