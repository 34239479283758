export enum BILLING_TARIFF_ALIAS {
  FREE = 'free',
  TEST = 'test',
  STATICA = 'statica',
  PREMIUM = 'premium',
}

export enum BILLING_TARIFF_PERIOD {
  INFINITE = 'infinite',
  MONTHLY = 'monthly',
  ANNUALLY = 'annually',
}
