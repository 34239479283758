import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { MetaPagination, OverlayDataShower } from '@/components/Elements'
import { ENTRIES_VIEW_MODE } from '@/const/layout'
import { GeneralPanelPage } from '@/features/panel/components/Layouts'
import { ProjectCreate, ProjectEdit } from '@/features/project/components/Project'
import { GeneralProjectsFilter } from '@/features/project/components/Projects/Filters'
import { GeneralProjectsTable } from '@/features/project/components/Projects/Tables'
import { useMyProjectsState } from '@/features/project/hooks'
import { useGeneralProjectsQueryParams, useProjectRemove } from '@/features/project/hooks'
import { useProjectMyEntriesQuery } from '@/features/project/store'
import { Project } from '@/features/project/types'
import { usePopup } from '@/hooks'
import {
  OnChangeFn,
  SortingState,
  TableOptions,
  formatSorting,
  normalizeSorting,
} from '@/hooks/UI/useTableUI'
import { PATH } from '@/routes/const'
import { EmptyContent } from './EmptyContent'
import { GridView } from './GridView'
import { SubBar, TopBar } from './Header'
import useStyles from './Style.styles'

export const MyProjectsContainer = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { classes } = useStyles()

  const { page, onPageChange, sorting, onSortingChange, filters, onFiltersChange } =
    useGeneralProjectsQueryParams()

  const queryParams = useMemo(() => {
    const { keywords } = filters
    return {
      page,
      ...sorting,
      search: keywords.trim(),
      searchJoin: 'or',
      include: ['picture'],
      searchFields: {
        name: 'like',
        reference_number: 'like',
        architect: 'like',
      },
    }
  }, [page, sorting, filters])

  const { data, isLoading, isFetching, error, isError } = useProjectMyEntriesQuery({
    params: queryParams,
  })

  const list = data?.data || []
  const meta = data?.meta

  const loading = useMemo(() => {
    return isLoading || isFetching
  }, [isLoading, isFetching])

  const {
    state: { entriesViewMode },
    onEntriesViewModeChange,
  } = useMyProjectsState()

  // --- Remove Project --- //
  const projectRemove = useProjectRemove()

  const onRemove = async (data: Project) => {
    await projectRemove.onRemove(data.id)
  }
  // --- //

  // --- Create Project --- //
  const createPopup = usePopup()

  const onProjectCreate = () => {
    createPopup.open()
  }

  const onProjectCreatePopupClose = () => {
    createPopup.close()
  }

  const onProjectCreateSuccess = (data: Project) => {
    onProjectCreatePopupClose()

    if (data) {
      const { id } = data

      navigate(`/${PATH.PROJECTS}/${id}`)
    }
  }
  // --- //

  // --- Edit Project --- //
  const editPopup = usePopup()

  const onEdit = (data: Project) => {
    editPopup.open(data)
  }
  // --- //

  const onTableSortingChange: OnChangeFn<SortingState> = (updaterFn) => {
    if (typeof updaterFn === 'function') {
      const old = normalizeSorting(sorting)
      const sortValue = updaterFn(old)
      onSortingChange(formatSorting(sortValue))
    }
  }

  const tableOptions = useMemo<Partial<TableOptions<any>>>(() => {
    return {
      manualSorting: true,
      onSortingChange: onTableSortingChange,
      state: {
        sorting: normalizeSorting(sorting),
      },
    }
  }, [sorting])

  const onFilterFormChange = (values: any) => {
    onFiltersChange(values)
  }

  return (
    <GeneralPanelPage
      header={
        <>
          <TopBar onProjectCreate={onProjectCreate} />
          <SubBar viewMode={entriesViewMode} onViewModeChange={onEntriesViewModeChange}>
            <GeneralProjectsFilter values={filters} onFiltersChange={onFilterFormChange} />
          </SubBar>
        </>
      }
    >
      <OverlayDataShower
        isLoading={loading || projectRemove.loading}
        isFailed={isError}
        error={error}
      >
        {list.length !== 0 ? (
          <>
            {entriesViewMode === ENTRIES_VIEW_MODE.TABLE ? (
              <GeneralProjectsTable
                keys={['name', 'reference_number', 'architect', 'modified', 'action']}
                items={list}
                loading={loading}
                removeProps={{ onRemove }}
                editProps={{ onEdit }}
                tableOptions={tableOptions}
              />
            ) : (
              <GridView items={list} removeProps={{ onRemove }} editProps={{ onEdit }} />
            )}

            <MetaPagination
              meta={meta.pagination}
              page={page}
              onChange={onPageChange}
              className={'mt-4 mb-4'}
            />
          </>
        ) : !loading ? (
          <EmptyContent onProjectCreate={onProjectCreate} />
        ) : null}
      </OverlayDataShower>

      <ProjectCreate
        visible={createPopup.visible}
        onClose={onProjectCreatePopupClose}
        onSuccess={onProjectCreateSuccess}
      />

      <ProjectEdit
        data={editPopup.data}
        visible={editPopup.visible}
        onClose={editPopup.close}
        onSuccess={editPopup.close}
      />
    </GeneralPanelPage>
  )
}
