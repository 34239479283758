import * as React from 'react'
import { ColorSchemeToggle } from '@/components/Elements'
import { MainHeaderProvider } from '@/components/Layouts/Elements'
import useStyles from './Header.styles'

interface IProps {
  lang?: boolean
  colorScheme?: boolean
}

export const Header = ({ lang = false, colorScheme = false }: IProps) => {
  const { classes } = useStyles()

  return <MainHeaderProvider rightSection={colorScheme && <ColorSchemeToggle />} />
}
