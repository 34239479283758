import React from 'react'
import { useTranslation } from 'react-i18next'
import { Burger, MediaQuery } from '@mantine/core'

interface IProps {
  onDrawerOpen: (data?: any) => void
}

export const HeaderBurgerMenu = ({ onDrawerOpen }: IProps) => {
  const { t } = useTranslation()

  return (
    <MediaQuery largerThan="md" styles={{ display: 'none' }}>
      <Burger opened={false} onClick={onDrawerOpen} size="sm" mr={'lg'} />
    </MediaQuery>
  )
}
