import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid, Text } from '@mantine/core'
import { TextInputController } from '@/components/FormElements'
import { MEASURE_UNIT_LENGTH } from '@/features/measure/consts/unit'
import { useUnitStaticOptions } from '@/features/measure/hooks'
import { NormalizeUtils } from '@/utils'

type FormValues = {
  length: string
  width: string
  height: string
}

export const DimensionsSection = () => {
  const { t } = useTranslation()

  const { control } = useFormContext<FormValues>()

  const { lengthUnitsMap } = useUnitStaticOptions()

  const renderRightSection = () => (
    <Text c="dimmed">{lengthUnitsMap.get(MEASURE_UNIT_LENGTH.m).label}</Text>
  )

  const fields: { name: keyof FormValues; label: string }[] = [
    {
      name: 'length',
      label: t('building_length'),
    },
    {
      name: 'width',
      label: t('building_width'),
    },
    {
      name: 'height',
      label: t('building_height'),
    },
  ]

  return (
    <>
      <Grid>
        {fields.map(({ name, label }, index) => (
          <Grid.Col md={4} key={index}>
            <TextInputController
              control={control}
              name={name}
              id={name}
              label={label}
              placeholder={label}
              rightSection={renderRightSection()}
              normalize={(value) => NormalizeUtils.onlyDecimal(value, 2)}
              mb={'md'}
            />
          </Grid.Col>
        ))}
      </Grid>
    </>
  )
}
