import React from 'react'
import { MantineNumberSize } from '@mantine/core'
import { Drawer } from '@mantine/core'
import useStyles from './NavbarDrawer.styles'

interface IProps {
  opened: boolean
  onClose: () => void
  width: MantineNumberSize
  children?: React.ReactNode
  className?: string
}

export const NavbarDrawer = ({ width, children, className, opened, onClose }: IProps) => {
  const { classes, cx } = useStyles()

  return (
    <Drawer
      classNames={classes}
      className={cx(className)}
      padding={0}
      opened={opened}
      onClose={onClose}
      withCloseButton={false}
      size={width}
    >
      {children}
    </Drawer>
  )
}
