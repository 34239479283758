import _ from 'lodash'
import { useCallback, useMemo } from 'react'
import p1 from '@/assets/images/illustrations/fall-protection/p1.png'
import p2 from '@/assets/images/illustrations/fall-protection/p2.png'
import p3 from '@/assets/images/illustrations/fall-protection/p3.png'
import p4 from '@/assets/images/illustrations/fall-protection/p4.png'
import p5 from '@/assets/images/illustrations/fall-protection/p5.png'
import p6 from '@/assets/images/illustrations/fall-protection/p6.png'
import p7 from '@/assets/images/illustrations/fall-protection/p7.png'
import {
  FALL_PROTECTION_CATEGORY as CATEGORY,
  FALL_PROTECTION_ALIAS,
} from '@/features/facade/FallProtection/consts/fall-protection'
import { FallProtection } from '@/features/facade/FallProtection/types'
import i18n from '@/i18n'

const { P1, P2, P3, P4, P5, P6, P7 } = FALL_PROTECTION_ALIAS

export const getEntries = (): FallProtection[] => {
  return [
    {
      name: i18n.t('fall_protection.p1'),
      alias: P1,
      category: CATEGORY.FALLS,
      description: i18n.t('fall_protection.p1.description'),
      picture: p1,
    },
    {
      name: i18n.t('fall_protection.p2'),
      alias: P2,
      category: CATEGORY.FALLS,
      description: i18n.t('fall_protection.p2.description'),
      picture: p2,
    },
    {
      name: i18n.t('fall_protection.p3'),
      alias: P3,
      category: CATEGORY.FALLS,
      description: i18n.t('fall_protection.p3.description'),
      picture: p3,
    },
    {
      name: i18n.t('fall_protection.p4'),
      alias: P4,
      category: CATEGORY.FALLS,
      description: i18n.t('fall_protection.p4.description'),
      picture: p4,
    },
    {
      name: i18n.t('fall_protection.p5'),
      alias: P5,
      category: CATEGORY.CUTS,
      description: i18n.t('fall_protection.p5.description'),
      picture: p5,
    },
    {
      name: i18n.t('fall_protection.p6'),
      alias: P6,
      category: CATEGORY.CUTS,
      description: i18n.t('fall_protection.p6.description'),
      picture: p6,
    },
    {
      name: i18n.t('fall_protection.p7'),
      alias: P7,
      category: CATEGORY.CUTS,
      description: i18n.t('fall_protection.p7.description'),
      picture: p7,
    },
  ]
}

export const getEntry = (alias: FALL_PROTECTION_ALIAS): FallProtection | any => {
  const options = getEntries()
  return _.find(options, { alias })
}

const getFallProtectionGroupByCategory = () => {
  return getEntries().reduce((acc, curr) => {
    const keyValue = curr?.category

    const { [keyValue]: reducedValue = [] } = acc

    return Object.assign(acc, {
      [keyValue]: reducedValue.concat(curr),
    })
  }, {} as any)
}

export const fallProtectionGroupsByCategory: FallProtection[][] =
  Object.values(getFallProtectionGroupByCategory()) || []
