import { createStyles, rem } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  root: {
    height: rem(200),
    width: '100%',
  },

  media: {
    objectFit: 'contain',
    width: '100%',
    height: '100%',
  },
}))
