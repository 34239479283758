import { createStyles, rem } from '@mantine/core'

export default createStyles((theme) => {
  return {
    root: {},

    header: {},

    content: {},
  }
})
