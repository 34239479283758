import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Menu, Stack, Text, UnstyledButton, useMantineTheme } from '@mantine/core'
import { AvatarProvider, Icon } from '@/components/Elements'
import { useLogout } from '@/features/auth/hooks'
import { useUser } from '@/features/user/hooks'
import { PATH, PATH_BILLING, PATH_SETTINGS } from '@/routes/const'
import useStyles from './ProfileMenu.styles'

export const ProfileMenu = () => {
  const { t } = useTranslation()

  const theme = useMantineTheme()

  const { classes } = useStyles()

  const { logout } = useLogout()

  const { user, getName } = useUser()

  return (
    <>
      <Menu width={230} position="bottom-end">
        <Menu.Target>
          <UnstyledButton>
            <AvatarProvider avatar={user?.avatar?.data} size={48} radius={48} />
          </UnstyledButton>
        </Menu.Target>

        <Menu.Dropdown p={'0.5rem'}>
          <Stack align={'center'} p={'0.5rem'} spacing={'xs'}>
            <AvatarProvider avatar={user?.avatar?.data} size={48} radius={48} />

            <Text fw={600}>{getName()}</Text>
          </Stack>

          <Menu.Divider />
          <Menu.Item
            color={theme.primaryColor}
            icon={<Icon name={'chevron-left'} />}
            component={Link}
            to={`/${PATH.BILLING}/${PATH_BILLING.SUBSCRIPTIONS}`}
          >
            {t('choose_license_option')}
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item
            icon={<Icon name={'gear'} />}
            component={Link}
            to={`/${PATH.SETTINGS}/${PATH_SETTINGS.PROFILE}`}
          >
            {t('settings')}
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item icon={<Icon name={'logout'} />} onClick={() => logout()}>
            {t('logout')}
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </>
  )
}
