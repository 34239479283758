import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  NavbarDrawer,
  Navbar as PanelNavbar,
} from '@/features/panel/components/Layouts/GeneralPageShell'
import { PROJECT_FACADE_SECTION_ALIAS } from '@/features/project-facade/consts/project-facade'
import { Content } from './Content'
import { Shell } from './Shell'
import useStyles from './Style.styles'

interface IProps {
  drawerVisible: boolean
  onDrawerClose: () => void
  navConfig?: { [key in PROJECT_FACADE_SECTION_ALIAS]?: boolean }
}

const NAVBAR_WIDTH = 260

export const Navbar = ({ drawerVisible, onDrawerClose, navConfig }: IProps) => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  const renderContent = () => (
    <Shell>
      <Content config={navConfig} />
    </Shell>
  )

  return (
    <>
      <PanelNavbar width={NAVBAR_WIDTH}>{renderContent()}</PanelNavbar>

      <NavbarDrawer width={NAVBAR_WIDTH} opened={drawerVisible} onClose={onDrawerClose}>
        {renderContent()}
      </NavbarDrawer>
    </>
  )
}
