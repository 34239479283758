import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Text } from '@mantine/core'
import { SelectController } from '@/components/FormElements'
import { FacadeMaterialsCheckboxGroupController } from '@/features/facade/FacadeMaterial/components/FormElements'
import { useProjectFacadeSelectsData } from '@/features/facade/ProjectFacadeSelects/hooks'
import { FacadeSystemSection } from './Sections'

type FormValues = {
  facade_materials: string[]
  facade_manufacturer_id: string
  facade_system_id: string | null
}

interface IProps {
  facadeId: string
}

export const ProductSection = ({ facadeId }: IProps) => {
  const { t } = useTranslation()

  const { control } = useFormContext<FormValues>()

  const { facadeManufacturersOptions } = useProjectFacadeSelectsData()

  return (
    <>
      <Box>
        <Text mb={'sm'} fw={600}>{`${t('material')}:`}</Text>
        <FacadeMaterialsCheckboxGroupController
          control={control}
          name={'facade_materials'}
          id={'facade_materials'}
        />
      </Box>

      <Grid mt={'xl'} mb={'xs'}>
        <Grid.Col md={4}>
          <SelectController
            control={control}
            name={'facade_manufacturer_id'}
            id={'facade_manufacturer_id'}
            label={t('manufacturer')}
            placeholder={t('manufacturer')}
            mb={'md'}
            data={facadeManufacturersOptions}
          />
        </Grid.Col>
      </Grid>

      <Box>
        <FacadeSystemSection facadeId={facadeId} />
      </Box>
    </>
  )
}
