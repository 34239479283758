import React from 'react'
import { useTranslation } from 'react-i18next'
import logo from '@/assets/images/placeholders/logo.svg'
import { AvatarManagerControl } from '@/components/Controls'
import { useCompanyLogoManager } from '@/features/profile/hooks'
import { useUser } from '@/features/user/hooks'

export const LogoManager = () => {
  const { t } = useTranslation()

  const { user } = useUser()

  const { uploading, onUpload, removing, onRemove } = useCompanyLogoManager()

  return (
    <AvatarManagerControl
      image={user?.companyLogo?.data?.full}
      onUpload={onUpload}
      onRemove={onRemove}
      uploading={uploading}
      removing={removing}
      uploadLabel={t('upload_logo')}
      deleteLabel={t('delete_logo')}
      placeholder={logo}
    />
  )
}
