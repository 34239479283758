import React from 'react'
import { useTranslation } from 'react-i18next'
import { Group } from '@mantine/core'
import { GeneralActionMenu } from '@/features/project/components/ProjectActionControls'
import { Project } from '@/features/project/types'

interface IProps {
  config?: {
    menu: boolean
  }
  project: Project
  editProps?: any
  removeProps?: any
}

const defaultConfig = {
  menu: true,
}

export const Action = ({ project, config, removeProps, editProps }: IProps) => {
  const { t } = useTranslation()

  config = {
    ...defaultConfig,
    ...config,
  }

  return (
    <Group>
      {config.menu && (
        <GeneralActionMenu
          data={project}
          editProps={editProps}
          removeProps={removeProps}
          menuProps={{ withinPortal: true }}
        />
      )}
    </Group>
  )
}
