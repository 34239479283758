import React from 'react'
import { useTranslation } from 'react-i18next'
import { Textarea as MantineTextarea, TextareaProps as MantineTextareaProps } from '@mantine/core'
import { FormUtils } from '@/utils'

export interface TextAreaProps extends MantineTextareaProps {
  fieldRef?: any
  normalize?: (value: string) => string
  translateParams?: object
  meta?: any
}

export const TextArea = ({
  onChange,
  normalize,
  meta,
  fieldRef,
  translateParams,
  ...props
}: TextAreaProps) => {
  const { t } = useTranslation()

  const { error } = meta

  const showError = Boolean(error)

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    event.target.value = normalize ? normalize(event.target.value) : event.target.value

    if (onChange) {
      onChange(event)
    }
  }

  const getErrorMessage = () => {
    return FormUtils.getErrorMessage(error, {
      t,
      translateParams: translateParams,
    })
  }

  return (
    <MantineTextarea {...props} onChange={onChange} error={showError ? getErrorMessage() : null} />
  )
}
