import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Stack, Text, Title } from '@mantine/core'
import { useAuth } from '@/features/auth/hooks'
import { FeedbackForm } from '@/features/feedback/components/Forms'
import { useFeedbackCreateMutation } from '@/features/feedback/store'
import { useUser } from '@/features/user/hooks'
import { useNotify } from '@/hooks'
import { FormatUtils } from '@/utils'

export const FeedbackContainer = () => {
  const { t } = useTranslation()

  const [createFeedback] = useFeedbackCreateMutation()

  const { showNotification } = useNotify()

  const { isLoggedIn } = useAuth()
  const { user, getName } = useUser()

  const onSubmit = async (values: any) => {
    await createFeedback({ params: values }).unwrap()

    showNotification({
      type: 'success',
      message: t('request_sent_successfully'),
    })
  }

  const initialValues = useMemo(() => {
    if (isLoggedIn && user) {
      return {
        name: getName(),
        email: user?.email,
        phone: FormatUtils.formatPhone(user?.phone) || '',
      }
    }

    return {}
  }, [isLoggedIn, user])

  return (
    <>
      <Stack spacing="xs" mb={'xl'}>
        <Title order={3}>{t('contact_us')}</Title>

        <Box maw={'75%'}>
          <Text>{t('feedback.hint.text')}</Text>
        </Box>
      </Stack>

      <FeedbackForm onSubmit={onSubmit} initialValues={initialValues} />
    </>
  )
}
