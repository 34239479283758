import React from 'react'
import { Container } from '@mantine/core'
import useStyles from './Footer.styles'

interface IProps {
  height?: number
  children?: React.ReactNode
  className?: string
}

export const Footer = ({ height = 90, children, className }: IProps) => {
  const { classes, cx } = useStyles({
    height,
  })

  return (
    <div className={cx(classes.main, className)}>
      <div className={classes.content}>{children && <>{children}</>}</div>
    </div>
  )
}
