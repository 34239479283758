import { createStyles, rem } from '@mantine/core'

export default createStyles((theme, _params, getRef) => {
  const height = rem(100)

  return {
    figure: {
      height,
    },

    imageWrapper: {
      height: '100%',
    },

    image: {
      height: `${height} !important`,
    },
  }
})
