import React from 'react'
import { Accordion, AccordionControlProps, AccordionProps } from '@mantine/core'
import { Icon } from '@/components/Elements'
import useStyles from './Component.styles'

type ItemType = {
  control: React.ReactNode | string
  controlProps?: AccordionControlProps
  panel: React.ReactElement | string
  value: string
}

interface IProps extends Omit<AccordionProps<boolean>, 'children'> {
  items: ItemType[]
  classNames?: any
}

export const GeneralAccordion: React.FC<IProps> = ({ items, classNames, ...props }) => {
  const { classes } = useStyles({}, { name: 'GeneralAccordion', classNames })

  return (
    <Accordion classNames={classes} {...props} chevron={<Icon name={'chevron-down'} />}>
      {items.map(({ panel, control, controlProps, value }, index) => (
        <Accordion.Item key={index} value={value}>
          <Accordion.Control {...controlProps}>{control}</Accordion.Control>
          <Accordion.Panel>{panel}</Accordion.Panel>
        </Accordion.Item>
      ))}
    </Accordion>
  )
}
