import { FacadeSystem } from '@/features/facade/FacadeSystem/types'
import { ProjectFacadeTag, projectFacadesApi } from '@/features/project-facade/store'
import { CollectionDTO } from '@/types'
import { RTKQueryUtils, RequestUtils } from '@/utils'

const { cacher } = RTKQueryUtils

export const ProjectFacadeFacadeSystemTag = 'ProjectFacadeFacadeSystem'

const enhanceApi = projectFacadesApi.enhanceEndpoints({
  addTagTypes: [ProjectFacadeFacadeSystemTag],
})

export const projectFacadeFacadeSystemsApi = enhanceApi.injectEndpoints({
  endpoints: (builder) => ({
    projectFacadeFacadeSystemEntries: builder.query<
      CollectionDTO<FacadeSystem>,
      {
        id: string
        params: any
      }
    >({
      query: ({ id, params }) => ({
        url: `project-facades/${id}/facade-systems`,
        params: RequestUtils.getMapRequestParams({
          include: ['picture'],
          ...params,
        }),
      }),
      providesTags: (result, error, args) => {
        const { id } = args

        const currentFacadeTag = `${ProjectFacadeTag}-${id}`
        return [
          { type: ProjectFacadeFacadeSystemTag, id: currentFacadeTag },
          ...cacher.providesNestedList(ProjectFacadeFacadeSystemTag)(result, error),
        ]
      },
    }),
  }),
  overrideExisting: false,
})

export const { useProjectFacadeFacadeSystemEntriesQuery } = projectFacadeFacadeSystemsApi
