import { createStyles, rem } from '@mantine/core'

export default createStyles((theme, _params, getRef) => {
  const width = rem(52)

  return {
    leftSectionBlock: {
      width,
      display: 'flex',
    },
  }
})
