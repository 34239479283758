import { User } from '@/features/user'
import { policyHelper } from '@/features/user/helpers'
import { PATH, PATH_BILLING } from '@/routes/const'
import { GUARD } from './types'

export type GuardType = {
  type: GUARD
  condition: (arg: any) => boolean
  redirectPath: string
}

export const hasSubscriptionGuard: GuardType = {
  type: GUARD.SUBSCRIPTION,
  condition: (user: User) => policyHelper.hasSubscription(user),
  redirectPath: `/${PATH.BILLING}/${PATH_BILLING.SUBSCRIPTIONS}`,
}
