import React from 'react'
import { QueryDataShell } from '@/components/Elements'
import { useBillingSubscriptionDataQuery } from '@/features/billing/store'
import useStyles from './Style.styles'

interface IProps {
  children: React.ReactNode
}

export const BillingSubscriptionDataQuery = ({ children }: IProps) => {
  const { classes } = useStyles()

  const { isLoading, isFetching, error, isError, isSuccess } = useBillingSubscriptionDataQuery()

  return (
    <QueryDataShell
      isLoading={isLoading}
      isFetching={isFetching}
      error={error}
      isError={isError}
      isSuccess={isSuccess}
      loaderProps={{
        className: classes.loaderBlock,
      }}
      overlayLoadingProps={{
        wrapClassName: classes.overlayBlock,
      }}
    >
      {children}
    </QueryDataShell>
  )
}
