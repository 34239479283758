import React from 'react'
import { useTranslation } from 'react-i18next'
import { PROJECT_FACADE_SECTION_ALIAS } from '@/features/project-facade/consts/project-facade'
import { ProjectFacade } from '@/features/project-facade/types'
import { Project } from '@/features/project/types'
import { SectionCard } from '../SectionCard'
import { Features } from './Features'

const { COMMON } = PROJECT_FACADE_SECTION_ALIAS

interface IProps {
  project: Project
  facade: ProjectFacade
}

export const CommonInfo = ({ project, facade }: IProps) => {
  const { t } = useTranslation()

  return (
    <>
      <SectionCard sectionAlias={COMMON} project={project} facade={facade}>
        <Features data={facade} />
      </SectionCard>
    </>
  )
}
