import * as Yup from 'yup'
import { PASSWORD_MIN_LENGTH } from '@/const/form'
import { SCORE_STRONG } from '@/packages/password-strength-meter'

export const validationSchema = Yup.object({
  current_password: Yup.string().required().min(PASSWORD_MIN_LENGTH),

  new_password: Yup.string()
    .required()
    .min(PASSWORD_MIN_LENGTH)
    .test('score', { key: 'field.error.password.npt_strong' }, (value, context) => {
      return context?.options?.context?.passScore >= SCORE_STRONG
    }),

  new_password_confirmation: Yup.string()
    .required()
    .oneOf([Yup.ref('new_password')], { key: 'field.error.password.no_match' })
    .min(PASSWORD_MIN_LENGTH),
})
