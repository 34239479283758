import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { FAQContainer } from '@/features/help-center/components/FAQ'

export const FAQ = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'faq'} />

      <FAQContainer />
    </>
  )
}
