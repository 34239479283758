const mToCm = (value: number | string) => {
  return +value * 100
}

const cmToM = (value: number | string) => {
  return +value / 100
}

export const length = {
  mToCm,
  cmToM,
}
