import React from 'react'
import { Group } from '@mantine/core'
import { GeneralActionMenu } from '@/features/project/components/ProjectActionControls'
import { Project } from '@/features/project/types'

interface IProps {
  config: {
    menu?: boolean
  }
  menu: {
    config?: {
      edit?: boolean
      remove?: boolean
    }
  }
  data: Project
  editProps?: any
  removeProps?: any
}

const defaultConfig = {
  menu: true,
}

export const ActionBar = ({ config, menu, data, editProps, removeProps }: IProps) => {
  config = {
    ...defaultConfig,
    ...config,
  }

  return (
    <Group>
      {config?.menu && (
        <GeneralActionMenu {...menu} data={data} editProps={editProps} removeProps={removeProps} />
      )}
    </Group>
  )
}
