import { PATH, PATH_PROJECTS, PATH_PROJECTS_FACADES } from '@/routes/const'

export const getProjectFacadesPath = (projectId: string) => {
  return `/${PATH.PROJECTS}/${projectId}/${PATH_PROJECTS.FACADES}`
}

export const getProjectFacadePathConfig = (projectId: string, facadeId: string) => {
  const basePath = getProjectFacadesPath(projectId)

  const view = `${basePath}/${facadeId}`
  const edit = `${view}/${PATH_PROJECTS_FACADES.EDIT}`
  const create = `${basePath}/${PATH_PROJECTS_FACADES.CREATE}`

  return {
    view,
    edit,
    create,
  }
}
