import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Stack, Text, rem } from '@mantine/core'
import {
  MEASURE_UNIT_FACADE_DEFLECTION,
  MEASURE_UNIT_FACADE_MOMENTUM,
  MEASURE_UNIT_WIND_LOAD,
} from '@/features/measure/consts/unit'
import { useUnitStaticOptions } from '@/features/measure/hooks'
import { ProjectFacade } from '@/features/project-facade/types'
import { CommonUtils } from '@/utils'

const { isNil } = CommonUtils

interface IProps {
  data: ProjectFacade
}

export const Features = ({ data }: IProps) => {
  const { t } = useTranslation()

  const { windLoadUnitsMap, facadeMomentumUnitsMap, facadeDeflectionUnitsMap } =
    useUnitStaticOptions()

  const { wind_load, momentum, deflection } = data

  const items = [
    {
      caption: t('wind_load'),
      value: wind_load,
      formatted: `${wind_load} ${windLoadUnitsMap.get(MEASURE_UNIT_WIND_LOAD.kn_m2).label}`,
    },
    // {
    //   caption: t('tare_weight'),
    //   value: null,
    // },
    // {
    //   caption: t('largest_support_force'),
    //   value: null,
    // },
    {
      caption: t('biggest_momentum'),
      value: momentum,
      formatted: `${momentum} ${
        facadeMomentumUnitsMap.get(MEASURE_UNIT_FACADE_MOMENTUM.knm).label
      }`,
    },
    {
      caption: t('deflection'),
      value: deflection,
      formatted: `${deflection} ${
        facadeDeflectionUnitsMap.get(MEASURE_UNIT_FACADE_DEFLECTION.mm).label
      }`,
    },
  ]

  return (
    <>
      <Stack>
        {items.map(({ caption, value, formatted }, index) => (
          <Box key={index}>
            <Text inline size={rem(24)}>
              {!isNil(value) ? formatted : '-'}
            </Text>
            <Text fz={'xs'} c={'dimmed'}>
              {caption}
            </Text>
          </Box>
        ))}
      </Stack>
    </>
  )
}
