import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mantine/core'
import { useProjectFacadeData } from '@/features/project-facade/hooks'
import { useProjectData } from '@/features/project/hooks'
import { CommonInfo, Legend, Pane, Product } from './Sections'

export const ProjectFacadeViewContainer = () => {
  const { t } = useTranslation()

  const { project } = useProjectData({})
  const { facade } = useProjectFacadeData({})

  return (
    <>
      <Grid>
        <Grid.Col md={3}>
          <CommonInfo project={project} facade={facade} />
        </Grid.Col>

        <Grid.Col md={9}>
          <Pane project={project} facade={facade} />
        </Grid.Col>

        <Grid.Col md={6}>
          <Product project={project} facade={facade} />
        </Grid.Col>

        <Grid.Col md={6}>
          <Legend project={project} facade={facade} />
        </Grid.Col>
      </Grid>
    </>
  )
}
