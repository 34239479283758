import React, { useMemo } from 'react'
import { DataShower } from '@/components/Elements'
import { GeneralPageShell } from '@/features/panel/components/Layouts'
import { useProjectPanelContext } from '@/features/project/contexts'
import { useProjectData } from '@/features/project/hooks'
import { Header } from './Header'

interface IProps {
  children: React.ReactNode
  title?: React.ReactNode | string
  navbar?: React.ReactElement
  headerLeftSection?: React.ReactElement
}

export const GeneralProjectPanel = ({ children, title, navbar, headerLeftSection }: IProps) => {
  const { editProps } = useProjectPanelContext()

  const {
    state: { isLoading, isFetching, error, isError, isSuccess },
  } = useProjectData({})

  const loading = useMemo(() => {
    return isLoading || isFetching
  }, [isLoading, isFetching])

  return (
    <GeneralPageShell
      navbar={navbar}
      header={
        <Header title={title} leftSection={headerLeftSection} onProjectEdit={editProps.onEdit} />
      }
    >
      <DataShower isFetched={isSuccess} isLoading={loading} isFailed={isError} error={error}>
        {children}
      </DataShower>
    </GeneralPageShell>
  )
}
