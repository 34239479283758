import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { OffersContainer } from '../../components/Offers'

export const Offers = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'our_offers'} />

      <OffersContainer />
    </>
  )
}
