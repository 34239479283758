import React from 'react'
import { useTranslation } from 'react-i18next'
import { Divider, Stack, Text, UnstyledButton } from '@mantine/core'
import useStyles from './Component.styles'

type Tab = {
  label: string
  icon: React.ReactElement
  value: string
}

interface IProps {
  value: string
  onChange: (value: string) => void
  tabs: Tab[]
}

export const NavTabs = ({ value, onChange, tabs }: IProps) => {
  const { t } = useTranslation()

  const { classes, cx } = useStyles()

  const onHandleChange = (tabValue: string) => {
    if (tabValue !== value) onChange(tabValue)
  }

  return (
    <div className={classes.root}>
      {tabs.map(({ icon, label, value: tabValue }, index) => (
        <div className={classes.item} key={index}>
          <UnstyledButton
            onClick={() => onHandleChange(tabValue)}
            className={cx(classes.tab, {
              [classes.activeTab]: value === tabValue,
            })}
          >
            <Stack align={'center'} spacing={'xs'}>
              {icon}

              <Text fw={600}>{label}</Text>
            </Stack>
          </UnstyledButton>

          {index !== tabs.length - 1 && <Divider orientation="vertical" />}
        </div>
      ))}
    </div>
  )
}
