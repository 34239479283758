import React from 'react'
import { MantineNumberSize } from '@mantine/core'
import useStyles from './GeneralPageShell.styles'

interface IProps {
  children: React.ReactNode
  header?: React.ReactElement
  navbar?: React.ReactElement
  aside?: React.ReactElement
  navbarOffsetBreakpoint?: MantineNumberSize
  asideOffsetBreakpoint?: MantineNumberSize
  padding?: MantineNumberSize
}

export const GeneralPageShell = ({
  children,
  header,
  navbar,
  aside,
  navbarOffsetBreakpoint,
  asideOffsetBreakpoint,
  padding,
}: IProps) => {
  const { classes, cx } = useStyles({
    padding,
    navbarOffsetBreakpoint,
    asideOffsetBreakpoint,
  })

  return (
    <div className={classes.wrap}>
      {header && <>{header}</>}

      <div className={classes.body}>
        {navbar && <>{navbar}</>}

        <div className={classes.main}>
          <div className={classes.content}>{children}</div>
        </div>
        {aside && <>{aside}</>}
      </div>
    </div>
  )
}
