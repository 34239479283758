import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  TextInput as MantineTextInput,
  TextInputProps as MantineTextInputProps,
} from '@mantine/core'
import { FormUtils } from '@/utils'

export interface TextInputProps extends MantineTextInputProps {
  fieldRef?: any
  normalize?: (value: string) => string
  translateParams?: object
  meta?: any
}

export const TextInput = ({
  onChange,
  normalize,
  meta,
  fieldRef,
  translateParams,
  ...props
}: TextInputProps) => {
  const { t } = useTranslation()

  const { error } = meta ?? {}

  const showError = Boolean(error)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.value = normalize ? normalize(event.target.value) : event.target.value

    if (onChange) {
      onChange(event)
    }
  }

  const getErrorMessage = () => {
    return FormUtils.getErrorMessage(error, {
      t,
      translateParams: translateParams,
    })
  }

  return (
    <MantineTextInput
      {...props}
      ref={fieldRef}
      onChange={handleChange}
      error={showError ? getErrorMessage() : null}
    />
  )
}
