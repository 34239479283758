import { ModalsProviderProps } from '@mantine/modals'
import { UseModalsReturn } from './modals'

export enum GlazingAction {
  CREATE = 'create',
  DELETE = 'delete',
  UPDATE = 'update',
}

export interface GlazingCallbackProps {
  action: GlazingAction
  payload?: any
}

export enum FacadeAction {
  UPDATE = 'update',
}

export interface FacadeCallbackProps {
  action: FacadeAction
  payload?: any
}

export interface UseFacadeGlazingModuleProps {
  projectId?: string
  facadeId: string
  glazingCallback?: (data?: GlazingCallbackProps) => void
  facadeCallback?: (data: FacadeCallbackProps) => void
  modalsProviderProps: Omit<ModalsProviderProps, 'children'>
}

// eslint-disable-next-line
export interface UseFacadeGlazingModuleReturn<TContext = any> {
  modals: UseModalsReturn
}
