import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import {
  Box,
  Burger,
  Container,
  Divider,
  Group,
  Header as MantineHeader,
  MediaQuery,
  Stack,
} from '@mantine/core'
import { useToggle } from '@mantine/hooks'
import { Anchor, LogoIcon } from '@/components/Elements'
import { NavMenu, NavbarDrawer } from '@/components/Layouts/Elements'
import { NavLinkType } from '@/components/Layouts/Elements'
import { HEADER_HEIGHT } from '@/const/layout'
import useStyles from './MainHeader.styles'
import { GeneralLinks } from './Navbar'

export interface MainHeaderProps {
  menu?: NavLinkType[]
  rightSection?: any
  fixed?: boolean
  className?: string
}

export const MainHeader = ({
  menu,
  rightSection,
  fixed = true,
  className,
  ...props
}: MainHeaderProps) => {
  const { t } = useTranslation()

  const { classes, cx } = useStyles()

  const [isNavbarDrawerOpened, toggleNavbarDrawerOpened] = useToggle([false, true])

  return (
    <>
      <MantineHeader
        className={cx(classes.root, className)}
        height={HEADER_HEIGHT}
        fixed={fixed}
        zIndex={1}
      >
        <Container className={classes.header}>
          <Group className={'w-full flex-nowrap'}>
            <div>
              <Group className={'flex-nowrap'}>
                <MediaQuery largerThan="md" styles={{ display: 'none', height: 0 }}>
                  <Burger
                    opened={false}
                    onClick={() => toggleNavbarDrawerOpened()}
                    size="sm"
                    mr={'lg'}
                  />
                </MediaQuery>

                <Anchor component={Link} to={'/'}>
                  <LogoIcon size={'sm'} />
                </Anchor>
              </Group>
            </div>

            {menu && (
              <MediaQuery smallerThan="md" styles={{ display: 'none' }}>
                <Box>
                  <Box className={'flex'}>
                    <Divider mx={'xl'} orientation="vertical" />
                    <NavMenu>
                      <GeneralLinks menu={menu} onLinkClick={() => {}} />
                    </NavMenu>
                  </Box>
                </Box>
              </MediaQuery>
            )}

            {rightSection && <div className={'flex grow justify-end'}>{rightSection}</div>}
          </Group>
        </Container>
      </MantineHeader>

      <NavbarDrawer opened={isNavbarDrawerOpened} onClose={toggleNavbarDrawerOpened}>
        {menu && (
          <Stack>
            <GeneralLinks menu={menu} onLinkClick={toggleNavbarDrawerOpened} />
          </Stack>
        )}
      </NavbarDrawer>
    </>
  )
}
