import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  GeneralBottomBar,
  GeneralTopBar,
  ProjectFacadeDataEntryPage,
} from '@/features/project-facade/components/ProjectFacadeDataEntry/Layouts'
import { PROJECT_FACADE_SECTION_ALIAS } from '@/features/project-facade/consts/project-facade'
import { projectFacadeHelper } from '@/features/project-facade/helpers'
import {
  useProjectFacadeData,
  useProjectFacadeDataEntryForm,
} from '@/features/project-facade/hooks'
import { useProjectFacadeStaticOptions } from '@/features/project-facade/hooks'
import { useProjectFacadeUpdateMutation } from '@/features/project-facade/store'
import { useProjectData } from '@/features/project/hooks'
import { CommonUtils } from '@/utils'
import { CommonInfoEditForm } from './CommonInfoEditForm'
import { validationSchema } from './validation'

const { isNil } = CommonUtils
const { COMMON } = PROJECT_FACADE_SECTION_ALIAS
const { getProjectFacadePathConfig } = projectFacadeHelper.routes

type FormValues = {
  name: string
  wind_load: string
}

export const CommonInfoEditContainer = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { project } = useProjectData({})

  const { facade, onSetFacadeData, projectFacadeFacadeSystemsInvalidateTags } =
    useProjectFacadeData({})

  const [update] = useProjectFacadeUpdateMutation()

  const onSubmit = async (values: FormValues) => {
    const params = {
      ...values,
    }

    const data = await update({ id: facade?.id, params }).unwrap()

    if (data) {
      await onSetFacadeData(data)
    }

    projectFacadeFacadeSystemsInvalidateTags()
  }

  // --- Form --- //
  const { methods, onHandleSubmit, isSubmitting, error } =
    useProjectFacadeDataEntryForm<FormValues>({
      formConfig: {
        defaultValues: {
          name: facade?.name || '',
          wind_load: !isNil(facade?.wind_load) ? facade?.wind_load + '' : '',
        },
        resolver: yupResolver(validationSchema as any),
      },
      onSubmit,
    })

  const { handleSubmit } = methods
  // --- //

  const { sectionsMap, getAdjacentSections } = useProjectFacadeStaticOptions()

  const onNavigate = () => {
    const pathConfig = getProjectFacadePathConfig(project?.id, facade?.id)

    const { next } = getAdjacentSections(COMMON)

    navigate({
      pathname: `${pathConfig.edit}/${next?.path}`,
    })
  }

  const onSave = async () => {
    await handleSubmit(onHandleSubmit)()
  }

  const onNext = async () => {
    await handleSubmit(async (data) => {
      await onHandleSubmit(data)

      onNavigate()
    })()
  }

  const facadeSection = sectionsMap.get(COMMON)

  return (
    <>
      <ProjectFacadeDataEntryPage
        footer={
          <GeneralBottomBar
            config={{ save: true, next: true }}
            saveProps={{ btnProps: { disabled: isSubmitting } }}
            nextProps={{ btnProps: { disabled: isSubmitting } }}
            onSave={onSave}
            onNext={onNext}
          />
        }
      >
        <GeneralTopBar
          title={`${t('step')} ${facadeSection.order + 1} - ${facadeSection.label}`}
          subtitle={t('enter_building_data')}
        />

        <div>
          <CommonInfoEditForm onSubmit={onSave} methods={methods} error={error} />
        </div>
      </ProjectFacadeDataEntryPage>
    </>
  )
}
