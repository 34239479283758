import { createStyles, rem } from '@mantine/core'

export default createStyles((theme, _params) => {
  return {
    overlay: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      height: 0,
    },
  }
})
