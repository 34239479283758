import _ from 'lodash'
import de from '@/assets/images/langs/de.png'
import uk from '@/assets/images/langs/uk.jpg'

export const getLanguages = () => {
  return [
    {
      code: 'en',
      title: 'English',
      flag: uk,
    },
    {
      code: 'de',
      title: 'Deutsch',
      flag: de,
    },
  ]
}

export const getLang = (code: string) => {
  const options = getLanguages()
  return _.find(options, { code })
}
