import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Text } from '@mantine/core'
import { FallProtection } from '@/features/facade/FallProtection/types'
import useStyles from './Title.styles'

interface IProps {
  data: FallProtection
}

export const Title = ({ data }: IProps) => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  const { name } = data

  return (
    <Box className={classes.root}>
      <Text title={name} truncate weight={600}>
        {name}
      </Text>
    </Box>
  )
}
