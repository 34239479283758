import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { PATH_PROJECTS_FACADES } from '@/routes/const'
import { FacadeEditRoot } from '../edit'

const { EDIT } = PATH_PROJECTS_FACADES

export const FacadeRoot = () => {
  return (
    <Routes>
      <Route path={`${EDIT}/*`} element={<FacadeEditRoot />} />

      <Route path={'*'} element={<Navigate to={'..'} replace={true} />} />
    </Routes>
  )
}
