import { rootApi } from '@/store/api'
import { CreateFeedbackDTO } from '@/features/feedback/types'

const enhanceApi = rootApi.enhanceEndpoints({
  addTagTypes: [],
})

export const feedbackApi = enhanceApi.injectEndpoints({
  endpoints: (builder) => ({
    feedbackCreate: builder.mutation<any, { params: CreateFeedbackDTO }>({
      query: ({ params }) => ({
        url: `feedback`,
        method: 'POST',
        body: params,
      }),
      transformResponse: (response: any) => {
        return response?.data
      },
    }),
  }),
  overrideExisting: false,
})

export const { useFeedbackCreateMutation } = feedbackApi
