import React from 'react'
import { FieldValues, SubmitHandler, UseFormProps, UseFormReturn, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useFormSubmit, useNotify } from '@/hooks'

interface UseProjectFacadeDataEntryFormProps<
  TFieldValues extends FieldValues = FieldValues,
  TContext = any
> {
  formConfig: UseFormProps<TFieldValues, TContext>
  onSubmit: (data: any) => Promise<void>
}

interface UseProjectFacadeDataEntryFormReturn<
  TFieldValues extends FieldValues = FieldValues,
  TContext = any
> {
  methods: UseFormReturn<TFieldValues, TContext>
  isSubmitting: boolean
  error: string
  onHandleSubmit: SubmitHandler<FieldValues>
}

export const useProjectFacadeDataEntryForm = <
  TFieldValues extends FieldValues = FieldValues,
  TContext = any
>({
  formConfig,
  onSubmit,
}: UseProjectFacadeDataEntryFormProps<TFieldValues, TContext>): UseProjectFacadeDataEntryFormReturn<
  TFieldValues,
  TContext
> => {
  const { t } = useTranslation()

  const { showNotification } = useNotify()

  const methods = useForm({
    ...formConfig,
  })

  const {
    setError,
    formState: { isSubmitting },
  } = methods

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: onSubmit,
    setError,
  })

  const onHandleSubmit: SubmitHandler<FieldValues> = async (data) => {
    try {
      await onFormSubmit(data)

      showNotification({
        type: 'success',
        message: t('saved_successfully'),
      })
    } catch (error) {}
  }

  return {
    methods,
    isSubmitting,
    error: submitError?.data?.message,
    onHandleSubmit,
  }
}
