import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { ResourcesContainer } from '@/features/help-center/components/Resources'

export const Resources = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'resources'} />

      <ResourcesContainer />
    </>
  )
}
