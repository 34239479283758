import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { ProjectFacadeCreateContainer } from '@/features/project-facade/components/ProjectFacadeDataEntry/ProjectFacadeCreate'

export const FacadeCreate = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'add_new_facade'} />

      <ProjectFacadeCreateContainer />
    </>
  )
}
