import {
  TableOptions,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { TableProps } from '@mantine/core'
import { Group } from '@mantine/core'
import { GeneralTable } from '@/components/Elements'
import { SortButton } from './SortButton'

interface IProps {
  columns: any[]
  data: any[]
  loading: boolean
  tableProps?: TableProps
  tableOptions?: Partial<TableOptions<any>>
}

export const TableView = ({ columns, data, loading, tableProps, tableOptions }: IProps) => {
  const { t } = useTranslation()

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    ...tableOptions,
  })

  return (
    <div className={'relative overflow-x-auto'}>
      <GeneralTable {...tableProps}>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const __headerProps = (header.column.columnDef as any)?.__headerProps

                return (
                  <th key={header.id} {...__headerProps}>
                    {header.isPlaceholder ? null : (
                      <Group
                        {...{
                          className: header.column.getCanSort() ? 'cursor-pointer select-none' : '',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}

                        {header.column.getCanSort() && (
                          <SortButton
                            isSorted={header.column.getIsSorted()}
                            onClick={header.column.getToggleSortingHandler()}
                          />
                        )}
                      </Group>
                    )}
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.length > 0 ? (
            table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={columns.length}>
                {!loading ? t('empty') : <span dangerouslySetInnerHTML={{ __html: '&nbsp;' }} />}
              </td>
            </tr>
          )}
        </tbody>
      </GeneralTable>
    </div>
  )
}
