import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Box, Group, Input, Select, Slider, Switch } from '@mantine/core'
import { Button } from '@/components/Elements'
import {
  GeneralBottomBar,
  GeneralTopBar,
  ProjectFacadeDataEntryPage,
} from '@/features/project-facade/components/ProjectFacadeDataEntry/Layouts'
import { PROJECT_FACADE_SECTION_ALIAS } from '@/features/project-facade/consts/project-facade'
import { projectFacadeHelper } from '@/features/project-facade/helpers'
import { useProjectFacadeData } from '@/features/project-facade/hooks'
import { useProjectFacadeStaticOptions } from '@/features/project-facade/hooks'
import { useProjectFacadeUpdateMutation } from '@/features/project-facade/store'
import { useProjectData } from '@/features/project/hooks'
import { useNotify } from '@/hooks'
import { DEFAULT_CONFIG, DEFAULT_NEW_FLOOR, DEFAULT_NEW_WINDOW } from './DefaultConfigs'
import { Floor } from './Floor'
import useStyles from './Style.styles'
import { IConstructorSaveRequestBody } from './types'

const { PANE } = PROJECT_FACADE_SECTION_ALIAS
const { getProjectFacadePathConfig } = projectFacadeHelper.routes

export const ConstructorContainer = () => {
  const { showNotification } = useNotify()

  const [settings, setSettings] = useState(DEFAULT_CONFIG(null, null))
  const [showActions, setShowActions] = useState(true)
  const [constructorScale, setConstructorSacel] = useState(0.6)
  const [glazCountTypes, setGlazCountTypes] = useState(5)
  const [useAutoGlazing, setUseAutoGlazing] = useState(false)

  // FIXME
  const consoleError = console.error
  const SUPPRESSED_WARNINGS = ['Functions that are interpolated in css calls will be stringified.']
  console.error = function filterWarnings(msg, ...args) {
    if (!SUPPRESSED_WARNINGS.some((entry) => msg.includes(entry))) {
      consoleError(msg, ...args)
    }
  }

  const [update] = useProjectFacadeUpdateMutation()

  const { t } = useTranslation()

  const navigate = useNavigate()

  const { sectionsMap, getAdjacentSections } = useProjectFacadeStaticOptions()

  const { classes } = useStyles()

  const { project } = useProjectData({})

  const { facade, onSetFacadeData, projectFacadeFacadeSystemsInvalidateTags } =
    useProjectFacadeData({})

  const facadeSection = sectionsMap.get(PANE)

  const onNavigate = () => {
    const pathConfig = getProjectFacadePathConfig(project?.id, facade?.id)

    const { next } = getAdjacentSections(PANE)

    navigate({
      pathname: `${pathConfig.edit}/${next?.path}`,
    })
  }

  useEffect(() => {
    if (facade.application_panes) {
      setSettings(JSON.parse(facade.application_panes))
    } else {
      setSettings(DEFAULT_CONFIG(null, null))
    }
  }, [facade])

  const onSave = async () => {
    const params: IConstructorSaveRequestBody = {
      application_panes: JSON.stringify(settings),
    }

    if (useAutoGlazing) {
      params.use_auto_glazzing = useAutoGlazing
      params.generate_glazzing_count = +glazCountTypes
    }

    try {
      const data = await update({ id: facade?.id, params }).unwrap()
      showNotification({ type: 'success', message: t('update_facade_success') })

      if (data) {
        await onSetFacadeData(data)
      }

      projectFacadeFacadeSystemsInvalidateTags()
    } catch (e) {
      console.log('ERROR: update facade ', e)
    }
  }

  const onNext = async () => {
    await onSave()
    onNavigate()
  }

  const WINDOW_TYPES = {
    WINDOW: 'window',
    PANEL: 'panel',
  }

  const onAddNewFloor = () => {
    setSettings({
      ...settings,
      floors: [DEFAULT_NEW_FLOOR(), ...settings.floors],
    })
    showNotification({ type: 'success', message: t('floor_added') })
  }

  const calculateFullFloorWindowSpace = () => {
    const currentFloors = settings.floors
    const currentFloor = currentFloors[currentFloors.length - 1]

    let totalWindowWidth = 0

    currentFloor?.windows.forEach((window: any) => {
      totalWindowWidth += window.width
      totalWindowWidth += window.leftPertition.width ?? 1
      totalWindowWidth += window.rightPertition.width ?? 1
    })

    return totalWindowWidth || 10000
  }

  const canAddNewWindow = (floorId: number) => {
    const currentFloors = settings.floors
    const currentFloor = currentFloors.find((floor) => floor.id === floorId)
    const rootFloor = currentFloors[currentFloors.length - 1]

    if (currentFloor?.id === rootFloor.id) {
      return true
    }

    let totalWindowWidth = 0

    currentFloor?.windows.forEach((window: any) => {
      totalWindowWidth += window.width
      totalWindowWidth += window.leftPertition.width ?? 1
      totalWindowWidth += window.rightPertition.width ?? 1
    })

    return calculateFullFloorWindowSpace() - totalWindowWidth >= 1500 // Initial width
  }

  const canDeleteFloor = (floorId: number) => {
    const currentFloors = settings.floors
    const currentFloor = currentFloors.find((floor) => floor.id === floorId)
    const rootFloor = currentFloors[currentFloors.length - 1]

    if (currentFloors?.length < 2) return true

    if (currentFloor?.id === rootFloor.id) return false

    return true
  }

  const onAddNewWindow = (floorId: any) => {
    const currentFloors = settings.floors
    const currentFloor = currentFloors.find((floor) => floor.id === floorId)

    currentFloors.find((floor) => floor.id === floorId)?.windows.push(DEFAULT_NEW_WINDOW())

    setSettings({
      ...settings,
      floors: currentFloors,
    })

    showNotification({ type: 'success', message: t('window_added') })
  }

  const onDeleteFloor = (floorId: any) => {
    const floors = settings.floors.filter((floor) => floor.id !== floorId)

    setSettings({
      ...settings,
      floors,
    })

    showNotification({ type: 'success', message: t('floor_deleted') })
  }

  const onUpdateFloor = (floorId: any, updatedFloor: any) => {
    const currentFloors = settings.floors

    currentFloors.forEach((floor) => {
      if (floor.id === floorId) {
        floor.height = +updatedFloor.height
        floor.upPertition = { height: +updatedFloor.upPertitionHeight }
        floor.underPertition = { height: +updatedFloor.underPertitionHeight }
      }
    })

    setSettings({
      ...settings,
      floors: currentFloors,
    })

    showNotification({ type: 'success', message: t('floor_updated') })
  }

  const onDeleteWindow = (floorId: any, windowId: any) => {
    const floors = settings.floors.map((floor) => {
      if (floorId !== floor.id) return floor

      return { ...floor, windows: floor.windows.filter((window) => window.id !== windowId) }
    })

    setSettings({
      ...settings,
      floors,
    })

    showNotification({ type: 'success', message: t('window_deleted') })
  }

  const onUpdateWindow = (floorId: any, windowId: any, updatedWindow: any) => {
    const currentFloors = settings.floors

    currentFloors.forEach((floor: any) => {
      if (floorId !== floor.id) return

      floor.windows.forEach((window: any) => {
        if (windowId !== window.id) return

        window.width = updatedWindow.width !== '' ? +updatedWindow.width : null
        window.leftPertition = {
          width: +updatedWindow.leftPertitionWidth || 0,
        }
        window.rightPertition = { width: +updatedWindow.rightPertitionWidth || 0 }

        window.burglaryType = updatedWindow.burglaryType
        window.protectionType = updatedWindow.protectionType
        window.isWindow = updatedWindow.isWindow
      })
    })

    setSettings({
      ...settings,
      floors: currentFloors,
    })

    showNotification({ type: 'success', message: t('window_updated') })
  }

  const MARKS = [
    { value: 0.2, label: '20%' },
    { value: 0.4, label: '40%' },
    { value: 0.6, label: '60%' },
    { value: 0.8, label: '80%' },
    { value: 1, label: '100%' },
    { value: 1.2, label: '120%' },
    { value: 1.4, label: '140%' },
    { value: 1.6, label: '160%' },
    { value: 1.8, label: '180%' },
    { value: 2, label: '200%' },
  ]

  const onBack = async () => {
    const pathConfig = getProjectFacadePathConfig(project?.id, facade?.id)

    const { prev } = getAdjacentSections(PANE)

    navigate({
      pathname: `${pathConfig.edit}/${prev?.path}`,
    })
  }

  return (
    <>
      <ProjectFacadeDataEntryPage
        classNames={{ content: 'h-full' }}
        footer={
          <GeneralBottomBar
            config={{ save: true, next: true, back: true }}
            saveProps={{ btnProps: { disabled: false } }}
            nextProps={{ btnProps: { disabled: false } }}
            onSave={onSave}
            onNext={onNext}
            onBack={onBack}
          />
        }
      >
        <GeneralTopBar
          title={`${t('step')} ${facadeSection.order + 1} - ${facadeSection.label}`}
          subtitle={t('enter_building_data')}
        />

        <Box mb={'lg'} maw={300}>
          <Input.Wrapper label={t('glazCountTypes')}>
            <Select
              data={Array.from({ length: 15 }, (_, i) => {
                return { value: `${i + 1}`, label: `${i + 1}` }
              })}
              value={`${glazCountTypes}`}
              onChange={(e) => setGlazCountTypes(e ? +e : 1)}
            />
          </Input.Wrapper>
        </Box>

        <Box mb={'lg'}>
          <Group spacing={'xs'} position="left">
            <Button variant="outline" onClick={onAddNewFloor}>
              {t('add_new_floor')}
            </Button>

            <Switch
              checked={showActions}
              onChange={(event) => setShowActions(event.currentTarget.checked)}
              label={t('show_constructor_actions')}
            />

            <Switch
              checked={useAutoGlazing}
              onChange={(event) => setUseAutoGlazing(event.currentTarget.checked)}
              label={t('use_auto_glazing')}
            />

            <Slider
              label={(val) => `${val.toFixed(1)}%`}
              defaultValue={constructorScale}
              onChange={(val) => setConstructorSacel(+val.toFixed(1))}
              step={0.2}
              max={2}
              min={0.2}
              marks={MARKS}
              styles={{ track: { width: 350 } }}
            />
          </Group>
        </Box>

        {project && settings && (
          <div
            className={classes.constructor}
            style={{
              height: '100%',
              width: `${100 * constructorScale}%`,
              minWidth: '100%',
            }}
          >
            {settings.floors.map((floor) => (
              <Floor
                scale={+constructorScale}
                floorWidth={calculateFullFloorWindowSpace()}
                canAddNewWindow={canAddNewWindow}
                canDeleteFloor={canDeleteFloor}
                key={floor.id}
                data={floor}
                showActions={showActions}
                onAddNewWindow={onAddNewWindow}
                onDeleteFloor={onDeleteFloor}
                onUpdateFloor={onUpdateFloor}
                onDeleteWindow={onDeleteWindow}
                onUpdateWindow={onUpdateWindow}
              />
            ))}
          </div>
        )}
      </ProjectFacadeDataEntryPage>
    </>
  )
}
