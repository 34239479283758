import _ from 'lodash'
import { FACADE_GLASS_TYPE } from '@/features/facade/FacadeGlass/consts/facade-glass'
import i18n from '@/i18n'

type OptionType = {
  label: string
  value: FACADE_GLASS_TYPE
}

const { FLOAT, TEMPERED, LAMINATED } = FACADE_GLASS_TYPE

export const getTypes = (): OptionType[] => {
  return [
    {
      label: i18n.t('float_glass'),
      value: FLOAT,
    },
    {
      label: `${i18n.t('partially_tempered_glass')} / ${i18n.t('single_pane_safety_glass')}`,
      value: TEMPERED,
    },
    {
      label: i18n.t('laminated_safety_glass'),
      value: LAMINATED,
    },
  ]
}

export const getType = (value: FACADE_GLASS_TYPE): OptionType | any => {
  const options = getTypes()
  return _.find(options, { value })
}
