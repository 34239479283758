import React from 'react'
import { useTranslation } from 'react-i18next'
import useStyles from './HomeContent.styles'

export const HomeContent = () => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  return <div />
}
