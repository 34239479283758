import React from 'react'
import { useTranslation } from 'react-i18next'
import { Select as MantineSelect, SelectProps as MantineSelectProps } from '@mantine/core'
import { FormUtils } from '@/utils'

export interface SelectProps extends MantineSelectProps {
  fieldRef?: any
  normalize?: (value: string) => string
  translateParams?: object
  meta?: any
}

export const Select = ({
  onChange,
  normalize,
  meta,
  fieldRef,
  translateParams,
  value,
  ...props
}: SelectProps) => {
  const { t } = useTranslation()

  const { error } = meta

  const showError = Boolean(error)

  const getErrorMessage = () => {
    return FormUtils.getErrorMessage(error, {
      t,
      translateParams: translateParams,
    })
  }

  return (
    <MantineSelect
      nothingFound={t('no_options')}
      value={value ? value.toString() : value}
      {...props}
      onChange={onChange}
      error={showError ? getErrorMessage() : null}
    />
  )
}
