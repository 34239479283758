import React, { useMemo } from 'react'
import { billingSubscriptionApi } from '@/features/billing/store'
import { Subscription } from '@/features/billing/types'
import { useAppSelector } from '@/store'

export const useSubscriptionData = () => {
  const selectSubscriptionData: any = useMemo(
    () => billingSubscriptionApi.endpoints['billingSubscriptionData'].select(),
    []
  )

  const state: { data: Subscription; [x: string]: any } = useAppSelector(selectSubscriptionData)

  const { data: subscription } = state

  return {
    subscription,
  }
}
