import { yupResolver } from '@hookform/resolvers/yup'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  GeneralBottomBar,
  GeneralTopBar,
  ProjectFacadeDataEntryPage,
} from '@/features/project-facade/components/ProjectFacadeDataEntry/Layouts'
import { PROJECT_FACADE_SECTION_ALIAS } from '@/features/project-facade/consts/project-facade'
import { projectFacadeHelper } from '@/features/project-facade/helpers'
import {
  useProjectFacadeData,
  useProjectFacadeDataEntryForm,
} from '@/features/project-facade/hooks'
import { useProjectFacadeStaticOptions } from '@/features/project-facade/hooks'
import { useProjectFacadeUpdateMutation } from '@/features/project-facade/store'
import { useProjectData } from '@/features/project/hooks'
import { ProductEditForm } from './ProductEditForm'
import { validationSchema } from './validation'

const sectionAlias = PROJECT_FACADE_SECTION_ALIAS.PRODUCT
const { getProjectFacadePathConfig } = projectFacadeHelper.routes

type FormValues = {
  facade_materials: string[]
  facade_manufacturer_id: string
  facade_system_id: string
}

export const ProductEditContainer = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { project } = useProjectData({})

  const { facade, onSetFacadeData } = useProjectFacadeData({})

  const [update] = useProjectFacadeUpdateMutation()

  const onSubmit = async (values: FormValues) => {
    const params = {
      ...values,
    }

    const data = await update({ id: facade?.id, params }).unwrap()

    if (data) {
      await onSetFacadeData(data)
    }
  }

  const initialValues = useMemo(() => {
    let result = {}

    if (facade) {
      const { facadeMaterials, facadeManufacturer, facadeSystem } = facade

      result = {
        facade_materials: facadeMaterials?.data ? facadeMaterials?.data.map(({ id }) => id) : [],
        facade_manufacturer_id: facadeManufacturer?.data?.id || '',
        facade_system_id: facadeSystem?.data?.id || '',
      }
    }

    return result
  }, [facade])

  // --- Form --- //
  const { methods, onHandleSubmit, isSubmitting, error } =
    useProjectFacadeDataEntryForm<FormValues>({
      formConfig: {
        defaultValues: {
          ...initialValues,
        },
        resolver: yupResolver(validationSchema as any),
      },
      onSubmit,
    })

  const { handleSubmit } = methods
  // --- //

  const { sectionsMap, getAdjacentSections } = useProjectFacadeStaticOptions()

  const onNavigate = (pathname: string) => {
    navigate({
      pathname,
    })
  }

  const onSave = async () => {
    await handleSubmit(onHandleSubmit)()
  }

  const adjacentSectionsRoutes = useMemo(() => {
    const pathConfig = getProjectFacadePathConfig(project?.id, facade?.id)
    const { next, prev } = getAdjacentSections(sectionAlias)

    const basePath = pathConfig.edit

    return {
      prev: `${basePath}/${prev?.path}`,
      next: next ? `${basePath}/${next?.path}` : pathConfig.view,
    }
  }, [project?.id, facade?.id])

  const onNext = async () => {
    await handleSubmit(async (data) => {
      await onHandleSubmit(data)

      onNavigate(adjacentSectionsRoutes.next)
    })()
  }

  const onBack = async () => {
    onNavigate(adjacentSectionsRoutes.prev)
  }

  const facadeSection = sectionsMap.get(sectionAlias)

  return (
    <>
      <ProjectFacadeDataEntryPage
        footer={
          <GeneralBottomBar
            config={{ save: true, next: true, back: true }}
            saveProps={{ btnProps: { disabled: isSubmitting } }}
            nextProps={{ btnProps: { disabled: isSubmitting } }}
            onSave={onSave}
            onNext={onNext}
            onBack={onBack}
          />
        }
      >
        <GeneralTopBar
          title={`${t('step')} ${facadeSection.order + 1} - ${facadeSection.label}`}
          subtitle={t('please_fill_following_fields')}
        />

        <div>
          <ProductEditForm
            onSubmit={onSave}
            methods={methods}
            error={error}
            facadeId={facade?.id}
          />
        </div>
      </ProjectFacadeDataEntryPage>
    </>
  )
}
