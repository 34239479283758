import React from 'react'
import { Outlet } from 'react-router-dom'
import { BasicLayoutPage } from '@/components/Layouts'

export const LegalShell = () => {
  return (
    <BasicLayoutPage>
      <Outlet />
    </BasicLayoutPage>
  )
}
