import React from 'react'
import { unitHelper } from '@/features/measure/helpers'

export const useUnitStaticOptions = () => {
  const lengthUnitsMap = unitHelper.length.lengthUnitsMap

  const windLoadUnitsMap = unitHelper.windLoad.windLoadUnitsMap

  const windSpeedZoneUnitsMap = unitHelper.windSpeedZone.windSpeedZoneUnitsMap

  const facadeMomentumUnitsMap = unitHelper.facade.facadeMomentumUnitsMap

  const facadeDeflectionUnitsMap = unitHelper.facade.facadeDeflectionUnitsMap

  const facadeSystemVariationUnitsMap = unitHelper.facadeSystem.facadeSystemVariationUnitsMap

  const facadeSystemTiefeUnitsMap = unitHelper.facadeSystem.facadeSystemTiefeUnitsMap

  return {
    lengthUnitsMap,
    windLoadUnitsMap,
    windSpeedZoneUnitsMap,
    facadeMomentumUnitsMap,
    facadeDeflectionUnitsMap,
    facadeSystemVariationUnitsMap,
    facadeSystemTiefeUnitsMap,
  }
}
