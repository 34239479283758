import React from 'react'
import { useTranslation } from 'react-i18next'
import { Flex, Radio } from '@mantine/core'

interface IProps {
  checked: boolean
}

export const Footer = ({ checked, ...props }: IProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Flex justify="flex-end">
        <Radio checked={checked} readOnly />
      </Flex>
    </>
  )
}
