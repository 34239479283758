import React from 'react'
import { ScrollArea, Tabs, TabsProps } from '@mantine/core'
import useStyles from './GeneralTabs.styles'

export interface GeneralTabProps {
  label: React.ReactNode
  value: string
  icon?: React.ReactNode
  render?: () => void
  tabProps?: any
  panelProps?: any
}

export interface GeneralTabsProps extends Omit<TabsProps, 'children'> {
  keepMounted?: boolean
  value: string
  onChange: (value: any) => void
  tabs: Array<GeneralTabProps>
  navbarOnly?: boolean
  scrollable?: boolean
}

export const GeneralTabs = ({
  value,
  tabs,
  onChange,
  navbarOnly = false,
  scrollable = true,
  ...props
}: GeneralTabsProps) => {
  const { classes } = useStyles()

  const onTabChange = (tab: string) => {
    if (tab !== value) {
      onChange(tab)
    }
  }

  const renderTabList = () => (
    <Tabs.List>
      {tabs.map(({ label, value, icon, tabProps }) => (
        <Tabs.Tab key={value} value={value} icon={icon} {...tabProps}>
          {label}
        </Tabs.Tab>
      ))}
    </Tabs.List>
  )

  return (
    <>
      <Tabs
        value={value}
        onTabChange={onTabChange}
        classNames={classes}
        styles={(theme, params) => {
          const { variant } = props
          const { color } = params
          const outline = variant === 'outline'
          const dark = theme.colorScheme === 'dark'
          const defaultVariant = variant === 'default' || variant === undefined

          return {
            tab: {
              '&[data-active]': {
                backgroundColor: outline ? (dark ? theme.colors.dark[6] : theme.white) : '',
                color: defaultVariant
                  ? theme.colors[color || theme.primaryColor][theme.fn.primaryShade()]
                  : '',
              },
            },
          }
        }}
        {...props}
      >
        {scrollable ? <ScrollArea type={'never'}>{renderTabList()}</ScrollArea> : renderTabList()}

        {!navbarOnly &&
          tabs.map(({ value, render, panelProps }) => (
            <Tabs.Panel key={value} value={value} {...panelProps}>
              {render && render()}
            </Tabs.Panel>
          ))}
      </Tabs>
    </>
  )
}
