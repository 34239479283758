import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Flex, Text, Title } from '@mantine/core'
import { ButtonLink } from '@/components/Elements'
import { PATH, PATH_PROJECTS, PATH_PROJECTS_FACADES } from '@/routes/const'
import useStyles from './Style.styles'

interface IProps {
  projectId?: string
  isEmpty?: boolean
}

export const Content: React.FC<IProps> = ({ projectId, isEmpty }) => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  const title = isEmpty
    ? t('projects_facades.empty_content.title-1')
    : t('projects_facades.select_facade.title-1')
  const subtitle = isEmpty
    ? t('projects_facades.empty_content.title-2')
    : t('projects_facades.select_facade.title-2')

  return (
    <div className={classes.root}>
      <Flex direction={'column'} align={'center'} my={'2rem'}>
        <Title order={4} mb={'xs'} ta="center">
          {title}
        </Title>

        <Text ta="center">{subtitle}</Text>
      </Flex>

      <Box w={270}>
        <ButtonLink
          fullWidth
          to={`/${PATH.PROJECTS}/${projectId}/${PATH_PROJECTS.FACADES}/${PATH_PROJECTS_FACADES.CREATE}`}
        >
          {t('add_facade')}
        </ButtonLink>
      </Box>
    </div>
  )
}
