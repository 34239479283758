import { createStyles, rem } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  root: {
    border: `${rem(1)} solid ${theme.colors.gray[2]}`,
    borderTop: `${rem(2)} solid ${theme.colors['quince'][theme.fn.primaryShade()]}`,
    borderRadius: theme.radius.sm,
    backgroundColor: theme.white,
    display: 'flex',
    flexDirection: 'column',
  },

  header: {
    display: 'flex',
    flex: 1,
    gap: theme.spacing.xs,
  },

  topBar: {
    display: 'flex',
    flexGrow: 1,
    minWidth: 0,
  },

  actionBar: {},
}))
