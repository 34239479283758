import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { HelpCenterShell } from '@/features/help-center/components/Layouts'
import { PATH_HELP_CENTER } from '@/routes/const'
import { FAQ, Resources, Support } from '../panel'

export const PanelRoot = () => {
  return (
    <Routes>
      <Route element={<HelpCenterShell />}>
        <Route index element={<Navigate to={PATH_HELP_CENTER.FAQ} replace={true} />} />

        <Route path={PATH_HELP_CENTER.FAQ} element={<FAQ />} />
        <Route path={PATH_HELP_CENTER.RESOURCES} element={<Resources />} />
        <Route path={PATH_HELP_CENTER.SUPPORT} element={<Support />} />
      </Route>

      <Route path={'*'} element={<Navigate to={PATH_HELP_CENTER.FAQ} replace={true} />} />
    </Routes>
  )
}
