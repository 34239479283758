export const content = `
  <h1>Impressum</h1>
  
  <h2>Kontaktadresse</h2>
  <div>Egli Software GmbH</div>
  <div>Spitalstrasse 29</div>
  <div>CH-6004 Luzern</div>
  
  <h2>Handelsregistereintrag</h2>
  <div>UID: CHE-202.214.369</div>
  <div>CH-ID: CH-100-4815857-2</div>
  <div>Handelsregisteramt: Luzern</div>
  
  <h1>Disclaimer</h1>

  <h2>Haftungsausschluss</h2>
  <p>Der Autor übernimmt keinerlei Gewähr hinsichtlich der inhaltlichen Richtigkeit, Genauigkeit, Aktualität, Zuverlässigkeit und Vollständigkeit der Informationen.</p>
  <p>Haftungsansprüche gegen den Autor wegen Schäden materieller oder immaterieller Art, welche aus dem Zugriff oder der Nutzung bzw. Nichtnutzung der veröffentlichten Informationen, durch Missbrauch der Verbindung oder durch technische Störungen entstanden sind, werden ausgeschlossen.</p>
  <p>Alle Angebote sind unverbindlich. Der Autor behält es sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen.</p>
  
  <h2>Haftung für Links</h2>
  <p>Verweise und Links auf Webseiten Dritter liegen ausserhalb unseres Verantwortungsbereichs Es wird jegliche Verantwortung für solche Webseiten abgelehnt. Der Zugriff und die Nutzung solcher Webseiten erfolgen auf eigene Gefahr des Nutzers oder der Nutzerin.</p>
  
  <h2>Urheber- und Kennzeichenrecht</h2>
  <p>Der Autor achtet darauf, dass in allen Veröffentlichungen die Urheberrechte der verwendeten Bilder, Sounds, Videoszenen und Texte beachtet werden, selbst erstellte Bilder, Sounds, Videoszenen und Texte genutzt werden oder dass auf lizenzfreie Bilder, Sounds, Videoszenen und Texte zurückgegriffen wird. Werden innerhalb des Internetangebotes geschützten Marken- und Warenzeichen genutzt oder ggf. durch Dritte genannt, unterliegen diese ohne Ausnahme den Bestimmungen des jeweils gültigen Kennzeichenrechts und den Besitzrechten der jeweiligen eingetragenen Eigentümer. Aufgrund der alleinigen Nennung kann nicht der Schluss gezogen werden, dass diese Markenzeichen nicht durch die Rechte Dritter geschützt werden! Das Urheberrecht für veröffentlichte und selbst erarbeitete Objekte bleibt allein beim Autor der Seite. Die Vervielfältigung und die Verwendung dieser Bilder, Sounds, Videoszenen und Texte in anderen elektronischen oder gedruckten Publikationen ist ohne die ausdrückliche Zustimmung des Autors nicht gestattet.</p>
  
  <h2>Datenschutz</h2>
  <p>Gestützt auf Artikel 13 der schweizerischen Bundesverfassung und die datenschutzrechtlichen Bestimmungen des Bundes und der Kantone (Datenschutzgesetze, DSG) hat jede Person Anspruch auf Schutz ihrer Privatsphäre sowie auf Schutz vor Missbrauch ihrer persönlichen Daten. Wir halten diese Bestimmungen ein. Persönliche Daten werden streng vertraulich behandelt und weder an Dritte verkauft noch weitergegeben. In enger Zusammenarbeit mit unseren Hosting-Providern bemühen wir uns, die Datenbanken so gut wie möglich vor fremden Zugriffen, Verlusten, Missbrauch oder vor Fälschung zu schützen.</p>
  <p>Beim Zugriff auf unsere Webseiten werden folgende Daten in Logfiles gespeichert: IP-Adresse, Datum, Uhrzeit, Browser-Anfrage und allg. übertragene Informationen zum Betriebssystem resp. Browser. Diese Nutzungsdaten bilden die Basis für statistische, anonyme Auswertungen, so dass Trends erkennbar sind, anhand derer wir unsere Angebote entsprechend verbessern können.</p>
  
  <h2>Rechtswirksamkeit dieses Haftungsausschlusses</h2>
  <p>Es gilt, diesen Haftungsausschluss als einen Teil des Onlineangebotes zu betrachten, von dem aus auf diese Seiten hingewiesen wurde. Sollten einzelne Abschnitte oder Formulierungen dieses Textes nicht mehr der geltenden Rechtslage entsprechen oder nicht vollständig sein, bleiben alle anderen Teile des Dokumentes hinsichtlich ihres Inhalts und dessen Gültigkeit unberührt.</p>
`
