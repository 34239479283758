import React from 'react'
import { useTranslation } from 'react-i18next'
import { Burger, MediaQuery } from '@mantine/core'
import { GeneralTopBar } from '@/features/panel/components/Layouts'
import useStyles from './Style.styles'

interface IProps {
  title?: any
  onDrawerOpen: (data?: any) => void
  onBack: () => void
}

export const Header = ({ title, onDrawerOpen, onBack }: IProps) => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  return (
    <GeneralTopBar
      back
      backProps={{ onBack }}
      title={title}
      leftSection={
        <MediaQuery largerThan="md" styles={{ display: 'none' }}>
          <Burger opened={false} onClick={onDrawerOpen} size="sm" mr={'lg'} />
        </MediaQuery>
      }
    />
  )
}
