import React from 'react'
import { BillingRoot } from '@/features/billing/routes'
import { ProjectsRoot } from '@/features/project/routes'
import { SettingsRoot } from '@/features/settings/routes'
import { ROLE } from '@/features/users/types'
import { getHelpRoutes as getPublicHelpRoutes } from '@/routes/configs/public/routes'
import { PATH } from '@/routes/const'
import { GUARD } from '../../guards'

type PrivateRouteType = {
  index?: boolean
  path?: string
  element: React.ReactNode
  roles?: Array<string>
  guards: Array<GUARD>
}

const generalRoutes: PrivateRouteType[] = [
  {
    path: `${PATH.SETTINGS}/*`,
    element: <SettingsRoot />,
    roles: [ROLE.CUSTOMER],
    guards: [],
  },
  {
    path: `${PATH.PROJECTS}/*`,
    element: <ProjectsRoot />,
    roles: [ROLE.CUSTOMER],
    guards: [GUARD.SUBSCRIPTION],
  },
  {
    path: `${PATH.BILLING}/*`,
    element: <BillingRoot />,
    roles: [ROLE.CUSTOMER],
    guards: [],
  },
]

export const getHelpRoutes = () => {
  return getPublicHelpRoutes().map((route) => ({
    ...route,
    roles: [ROLE.CUSTOMER],
    guards: [],
  }))
}

export const getGeneralRoutes = () => {
  const helpRoutes = getHelpRoutes()
  return [...generalRoutes, ...helpRoutes]
}
