import _ from 'lodash'
import { FALL_PROTECTION_CATEGORY } from '@/features/facade/FallProtection/consts/fall-protection'
import i18n from '@/i18n'

type OptionType = {
  label: string
  value: FALL_PROTECTION_CATEGORY
}

const { FALLS, CUTS } = FALL_PROTECTION_CATEGORY

export const getCategories = (): OptionType[] => {
  return [
    {
      label: i18n.t('fall_protection.category.falls'),
      value: FALLS,
    },
    {
      label: i18n.t('fall_protection.category.cuts'),
      value: CUTS,
    },
  ]
}

export const getCategory = (value: FALL_PROTECTION_CATEGORY): OptionType | any => {
  const options = getCategories()
  return _.find(options, { value })
}
