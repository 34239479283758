import React from 'react'
import { fallProtectionHelper } from '@/features/facade/FallProtection/helpers/fall-protection'

export const useFallProtectionStaticOptions = () => {
  const getFeatures = fallProtectionHelper.feature.getFeatures

  const getEntries = fallProtectionHelper.general.getEntries

  const fallProtectionGroupsByCategory = fallProtectionHelper.general.fallProtectionGroupsByCategory

  const getCategories = fallProtectionHelper.category.getCategories

  const getCategory = fallProtectionHelper.category.getCategory

  return {
    getFeatures,
    getEntries,
    fallProtectionGroupsByCategory,
    getCategories,
    getCategory,
  }
}
