import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Card } from '@/components/Elements'
import useStyles from './SimpleCard.styles'

interface IProps {
  children?: React.ReactNode
  component?: any
  classNames?: any
  componentProps?: any

  [x: string]: any
}

export const SimpleCard = forwardRef(
  ({ children, component, classNames, componentProps, ...props }: IProps, ref) => {
    const { t } = useTranslation()

    const { classes } = useStyles({}, { name: 'SimpleCard', classNames })

    return (
      <Card ref={ref} px={'xs'} className={classes.root} component={component} {...componentProps}>
        {children}
      </Card>
    )
  }
)
