import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { NavMenuBar } from '@/components/Elements'
import { PATH, PATH_SETTINGS } from '@/routes/const'

const TABS_KEY = {
  PROFILE: 'profile',
  SECURITY: 'security',
}

const { PROFILE, SECURITY } = TABS_KEY

export const Tabs = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const location = useLocation()

  const basePath = `/${PATH.SETTINGS}`

  const TABS_CONFIG = {
    [PROFILE]: {
      label: t('personal_data'),
      value: PROFILE,
      path: `${basePath}/${PATH_SETTINGS.PROFILE}`,
    },
    [SECURITY]: {
      label: t('confidential_info'),
      value: SECURITY,
      path: `${basePath}/${PATH_SETTINGS.SECURITY}`,
    },
  }

  const tabsConfigValues = Object.values(TABS_CONFIG)

  const tabs = useMemo(() => {
    return tabsConfigValues.map(({ label, value }) => ({
      label,
      value,
    }))
  }, [])

  const onTabChange = (value: string) => {
    const tabConfig = TABS_CONFIG[value]

    navigate(`${tabConfig?.path}`)
  }

  const tabsValue = useMemo(() => {
    const pathName = location.pathname

    for (let i = 0; i < tabsConfigValues.length; i++) {
      const item = tabsConfigValues[i]

      if (pathName === item.path) {
        return item.value
      }
    }

    return ''
  }, [location])

  return (
    <div className={'flex self-end min-w-0'}>
      <NavMenuBar value={tabsValue} onChange={onTabChange} tabs={tabs} />
    </div>
  )
}
