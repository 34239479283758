import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Card, Flex, Image, Text } from '@mantine/core'
import { PROJECT_FACADE_SECTION_ALIAS } from '@/features/project-facade/consts/project-facade'
import { ProjectFacade } from '@/features/project-facade/types'
import { Project } from '@/features/project/types'
import { SectionCard } from '../SectionCard'

const { LEGEND } = PROJECT_FACADE_SECTION_ALIAS

interface IProps {
  project: Project
  facade: ProjectFacade
}

export const Legend = ({ project, facade }: IProps) => {
  const { t } = useTranslation()

  const items = facade?.facadeGlasses?.data

  if (!items) return null

  return (
    <>
      <SectionCard
        title={t('types_of_glass.construction_from_the_inside_to_the_outside')}
        sectionAlias={LEGEND}
        project={project}
        facade={facade}
      >
        <Flex align={'flex-start'} direction={'column'} gap={'sm'}>
          {items.map((item) => (
            <Flex align={'center'} gap={'sm'}>
              <Card style={{ backgroundColor: item.color }}>
                <></>
              </Card>
              <Text size={'md'}>{item.name}</Text>
            </Flex>
          ))}
        </Flex>
      </SectionCard>
    </>
  )
}
