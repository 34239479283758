import React from 'react'
import { useTranslation } from 'react-i18next'
import { LegalCard } from '@/features/legal/components/Elements'
import { TermsContent } from '@/features/legal/components/Terms'

export const TermsContainer = () => {
  const { t } = useTranslation()

  return (
    <LegalCard title={t('terms_and_conditions')}>
      <TermsContent />
    </LegalCard>
  )
}
