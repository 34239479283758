import { useTranslation } from 'react-i18next'
import useStyles from './Style.styles'
import { Window } from './Window'

export const Floor = (params: any) => {
  const { classes } = useStyles()
  const { t } = useTranslation()

  const { data, onUpdateWindow, showActions, scale, floorWidth } = params

  return (
    <>
      {data.upPertition && (
        <div
          className={classes.upPertition}
          style={{
            height: (data.upPertition.height / 10) * scale,
            width: (floorWidth / 10) * scale,
          }}
        ></div>
      )}
      <div
        className={`${classes.floor}
          ${!data.upPertition || +data.upPertition.height === 0 ? classes.upPertitionEmpty : ''}
          ${
            !data.underPertition || +data.underPertition.height === 0
              ? classes.underPertitionEmpty
              : ''
          }`}
        style={{ height: (data.height / 10) * scale, width: (floorWidth / 10) * scale }}
      >
        {data.windows.map((window: any) => (
          <Window
            key={window.id}
            data={window}
            onUpdateWindow={onUpdateWindow}
            floorId={data.id}
            showActions={showActions}
            scale={scale}
          />
        ))}
      </div>
      {data.underPertition && (
        <div
          className={classes.underPertition}
          style={{
            height: (data.underPertition.height / 10) * scale,
            width: (floorWidth / 10) * scale,
          }}
        ></div>
      )}
    </>
  )
}
