import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { Alert, LoadingScreen } from '@/components/Elements'
import { useAppWindowFocus } from '@/features/app/hooks'
import { useProjectFacadeSelectsDataQuery } from '@/features/facade/ProjectFacadeSelects/store'
import { useUserDataQuery } from '@/features/user/store'

export const PrivateLayout = () => {
  const { t } = useTranslation()

  // todo: move to App component
  useAppWindowFocus()

  const userDataQuery = useUserDataQuery()
  const projectFacadeSelectsDataQuery = useProjectFacadeSelectsDataQuery()

  const loading = useMemo(() => {
    return userDataQuery.isLoading || projectFacadeSelectsDataQuery.isLoading
  }, [userDataQuery.isLoading, projectFacadeSelectsDataQuery.isLoading])

  const prepared = useMemo(() => {
    return userDataQuery.isSuccess || projectFacadeSelectsDataQuery.isSuccess
  }, [userDataQuery.isSuccess, projectFacadeSelectsDataQuery.isSuccess])

  const error = useMemo(() => {
    return userDataQuery.error || projectFacadeSelectsDataQuery.error
  }, [userDataQuery.error, projectFacadeSelectsDataQuery.error])

  return loading ? (
    <LoadingScreen />
  ) : prepared ? (
    <Outlet />
  ) : error ? (
    <Alert type={'error'} mt={'lg'} mx={'lg'}>
      {(error as any)?.message || ((error as any)?.data as any)?.message || t('error')}
    </Alert>
  ) : null
}
