import { useEffect, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CiSettings } from 'react-icons/ci'
import { Box, Card, Grid, Group, Modal, ScrollArea, Stack, Text } from '@mantine/core'
import { Button } from '@/components/Elements'
import { SelectController } from '@/components/FormElements'
import { useFacadeGlassStaticOptions } from '@/features/facade/FacadeGlass/hooks'
import useStyles from './Style.styles'

export const WindowSettingsModal = ({ opened, open, close, data, submit, glazingData }: any) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const [selectedGlazing, setSelectedGlazing] = useState<any>(null)

  const onSubmit = () => {
    if (!selectedGlazing?.id) return

    submit({
      ...data,
      typeId: selectedGlazing.id,
    })
  }

  const { getType } = useFacadeGlassStaticOptions()

  const availableGlazingTypes = () => {
    if (data?.burglaryType && data?.protectionType) {
      return (
        glazingData?.data
          ?.filter((item: any) => item?.rc2 && item?.[data?.protectionType])
          .map((item: any) => ({
            id: item.id,
            name: item.name,
            type: item.type,
            color: item.color,
          })) || []
      )
    }

    if (data?.burglaryType) {
      return (
        glazingData?.data
          ?.filter((item: any) => item?.rc2)
          .map((item: any) => ({
            id: item.id,
            name: item.name,
            type: item.type,
            color: item.color,
          })) || []
      )
    }

    if (data?.protectionType) {
      return (
        glazingData?.data
          ?.filter((item: any) => item?.[data?.protectionType])
          .map((item: any) => ({
            id: item.id,
            name: item.name,
            type: item.type,
            color: item.color,
          })) || []
      )
    }

    return (
      glazingData?.data?.map((item: any) => ({
        id: item.id,
        name: item.name,
        type: item.type,
        color: item.color,
      })) || []
    )
  }

  const glazingGroups = () => {
    return availableGlazingTypes().reduce((acc: any, currentValue: any) => {
      const groupKey = currentValue['type']
      if (!acc[groupKey]) {
        acc[groupKey] = []
      }
      acc[groupKey].push(currentValue)
      return acc
    }, {})
  }

  useEffect(() => {
    const currentGlazingType = glazingData?.data?.find((item: any) => item?.id === data.typeId)

    if (currentGlazingType) {
      setSelectedGlazing(currentGlazingType)
    }
  }, [glazingData, data])

  const GlazingCard = ({ glazing }: any) => (
    <Card shadow="sm" padding="lg" radius="md" withBorder>
      <Card.Section>
        <div className={classes.glazingCardColor} style={{ backgroundColor: glazing.color }}></div>
      </Card.Section>

      <Text mt={15} fw={500}>
        {glazing.name}
      </Text>

      <Button
        onClick={() => setSelectedGlazing(glazing)}
        disabled={selectedGlazing?.id === glazing.id}
        variant="light"
        color="blue"
        fullWidth
        mt="md"
        radius="md"
      >
        {selectedGlazing?.id === glazing.id ? t('active') : t('update')}
      </Button>
    </Card>
  )

  return (
    <>
      <Modal size={'lg'} opened={opened} onClose={close} title={t('window_settings')}>
        <Stack spacing="xl">
          <Text mt={15} fw={500}>
            {t('selected_glazing')}
          </Text>
          {selectedGlazing && (
            <Card shadow="sm" padding="lg" radius="md" withBorder>
              <Card.Section>
                <div
                  className={classes.glazingCardColor}
                  style={{ backgroundColor: selectedGlazing?.color }}
                ></div>
              </Card.Section>

              <Text mt={15} fw={500}>
                {selectedGlazing?.name}
              </Text>

              <Button disabled variant="light" color="blue" fullWidth mt="md" radius="md">
                {t('selected_glazing')}
              </Button>
            </Card>
          )}
        </Stack>
        <Box>
          <ScrollArea h={400} w={588}>
            <Box w={570} pt={20} pb={20}>
              <Stack spacing="xl">
                {Object.keys(glazingGroups()).map((type: string, index) => {
                  return (
                    <Box key={index}>
                      <Text fw={'600'} mb={'md'} fz={'sm'}>
                        {getType(type as any)?.label}
                      </Text>

                      <Grid>
                        {glazingGroups()[type].map((item: any) => (
                          <Grid.Col key={item.value} xs={6} sm={4}>
                            <GlazingCard glazing={item} />
                          </Grid.Col>
                        ))}
                      </Grid>
                    </Box>
                  )
                })}
              </Stack>
            </Box>
          </ScrollArea>
          <Box mt={'lg'} mb={'lg'}>
            <Group spacing={'xs'} noWrap>
              <Button type={'button'} disabled={!selectedGlazing?.id} onClick={onSubmit}>
                {t('update')}
              </Button>
            </Group>
          </Box>
        </Box>
      </Modal>

      <Group position="left">
        <CiSettings onClick={open} size={24} />
      </Group>
    </>
  )
}
