import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { ProductEditContainer } from '@/features/project-facade/components/ProjectFacadeDataEntry/ProjectFacadeEdit'

export const Product = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'edit_facade.product_browser.title'} />

      <ProductEditContainer />
    </>
  )
}
