import * as React from 'react'
import { Outlet } from 'react-router-dom'
import useStyles from './BasicLayout.styles'
import { Header } from './Header'

interface IProps {
  children?: any
  header?: boolean
  colorScheme?: boolean
}

export const BasicLayout = ({ children, header = true, colorScheme = false }: IProps) => {
  const { classes } = useStyles()

  return (
    <div className={classes.root}>
      {header && <Header colorScheme={colorScheme} />}

      <div className={classes.shell}>
        <div className={classes.main}>
          <div className={classes.content}>{children ? children : <Outlet />}</div>
        </div>
      </div>
    </div>
  )
}
