import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Space, Stack, Text } from '@mantine/core'
import { Alert, Anchor } from '@/components/Elements'
import { AuthCard } from '@/features/auth/components/Elements'
import { useResetPasswordMutation } from '@/features/auth/store'
import { useQuery } from '@/hooks'
import { PATH } from '@/routes/const'
import { FormValues, ResetPasswordForm } from './ResetPasswordForm'

type SubmitParams = FormValues & {
  token: string
  email: string
}

export const ResetPasswordContainer = () => {
  const { t } = useTranslation()

  const query = useQuery()

  const [resetPassword] = useResetPasswordMutation()

  const [success, setSuccess] = useState<string | null>(null)

  const handleSubmit = async (values: FormValues) => {
    setSuccess(null)

    const params: SubmitParams = {
      ...values,
      token: query.get('token') || '',
      email: query.get('email') || '',
    }

    await resetPassword(params).unwrap()

    setSuccess(t('reset_password.success'))
  }

  return (
    <AuthCard title={t('reset_password')}>
      <Stack spacing="xs" align={'flex-start'}>
        <Text>{t('reset-password.hint')}</Text>
      </Stack>

      <Space h={'xl'} />

      {success && (
        <Alert type={'success'} mb={'sm'}>
          {success}
        </Alert>
      )}

      <ResetPasswordForm onSubmit={handleSubmit} />

      <Space h={'xl'} />

      <Stack spacing="xs" align={'flex-start'}>
        <Anchor component={Link} to={`/${PATH.LOGIN}`}>
          <Text tt="capitalize" fw={'600'}>
            {t('back_to_login_page')}
          </Text>
        </Anchor>
      </Stack>
    </AuthCard>
  )
}
