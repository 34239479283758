import * as Yup from 'yup'
import { COMPANY_UID_LENGTH, PASSWORD_MIN_LENGTH, PHONE_DIGITS_LENGTH } from '@/const/form'
import { SCORE_STRONG } from '@/packages/password-strength-meter'
import { ValidationsUtils } from '@/utils'

export const validationSchema = Yup.object({
  gender: Yup.string().nullable().required(),
  name: Yup.string().required(),
  last_name: Yup.string().required(),
  email: Yup.string().email().required(),
  phone: Yup.string()
    .test('phone', { key: 'field.error.invalid' }, ValidationsUtils.phoneFormat)
    .test(
      'phone-length',
      { key: 'field.error.digits_between', values: { ...PHONE_DIGITS_LENGTH } },
      (value) => ValidationsUtils.digitsBetween(value, PHONE_DIGITS_LENGTH)
    )
    .required(),
  company_name: Yup.string().nullable().required(),
  company_uid: Yup.string()
    .required()
    .nullable()
    .test('companyUidRule', { key: 'field.error.company_uid.rule' }, ValidationsUtils.companyUID)
    .min(COMPANY_UID_LENGTH.min),
  company_function: Yup.string().nullable(),
  street: Yup.string().required(),
  street_number: Yup.string().required(),
  zip: Yup.string().required(),
  city: Yup.string().required(),
  password: Yup.string()
    .required()
    .min(PASSWORD_MIN_LENGTH)
    .test('score', { key: 'field.error.password.npt_strong' }, (value, context) => {
      return context?.options?.context?.passScore >= SCORE_STRONG
    }),
  password_confirmation: Yup.string()
    .required()
    .oneOf([Yup.ref('password')], { key: 'field.error.password.no_match' })
    .min(PASSWORD_MIN_LENGTH),
  terms: Yup.bool().oneOf([true], 'required'),
  policy: Yup.bool().oneOf([true], 'required'),
})
