import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@mantine/core'
import { TextInputController } from '@/components/FormElements'
import { MEASURE_UNIT_WIND_LOAD } from '@/features/measure/consts/unit'
import { useUnitStaticOptions } from '@/features/measure/hooks'
import { NormalizeUtils } from '@/utils'

interface IProps {
  control: any
  name: string
  id?: string
  label?: string
  placeholder?: string
}

export const WindLoadTextInputController = ({
  control,
  name,
  id,
  label,
  placeholder,
  ...props
}: IProps) => {
  const { t } = useTranslation()

  const { windLoadUnitsMap } = useUnitStaticOptions()

  const renderRightSection = () => (
    <Text c="dimmed">{windLoadUnitsMap.get(MEASURE_UNIT_WIND_LOAD.kn_m2).label}</Text>
  )

  return (
    <TextInputController
      control={control}
      name={name}
      id={id}
      label={label}
      placeholder={placeholder}
      rightSection={renderRightSection()}
      normalize={(value) => NormalizeUtils.onlyDecimal(value, 3)}
      mb={'md'}
      rightSectionWidth={'4rem'}
      {...props}
    />
  )
}
