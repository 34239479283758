import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { Group, Space } from '@mantine/core'
import { Alert, Button } from '@/components/Elements'
import { TextInputController } from '@/components/FormElements'
import { useFormSubmit } from '@/hooks'

export type FormValues = {
  email: string
}

interface IProps {
  onSubmit: (values: FormValues) => Promise<void>
}

export const ForgotPasswordForm: React.FC<IProps> = (props) => {
  const { t } = useTranslation()

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(
      Yup.object().shape({
        email: Yup.string().email().required(),
      })
    ),
  })

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: props.onSubmit,
    setError,
  })

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      await onFormSubmit(data)
    } catch (err) {}
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {submitError && (
          <Alert type={'error'} mb={'sm'}>
            {submitError?.data?.message || t('error')}
          </Alert>
        )}

        <TextInputController
          control={control}
          name={'email'}
          id={'email'}
          label={t('email')}
          placeholder={t('email')}
          mb={'md'}
        />

        <Space h={'xl'} />

        <Group mt={'xl'} position={'right'}>
          <Button type={'submit'} loading={isSubmitting}>
            {t('send_reset_link')}
          </Button>
        </Group>
      </form>
    </>
  )
}
