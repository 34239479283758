import React, { useCallback, useMemo } from 'react'
import { FacadeManufacturer } from '@/features/facade/FacadeManufacturer/types'
import { FacadePanel } from '@/features/facade/FacadePanel/types'
import { projectFacadeSelectsApi } from '@/features/facade/ProjectFacadeSelects/store'
import { ProjectFacadeSelects } from '@/features/facade/ProjectFacadeSelects/types'
import { useAppDispatch, useAppSelector } from '@/store'

export const useProjectFacadeSelectsData = () => {
  const dispatch = useAppDispatch()

  const selectProjectData: any = useMemo(
    () => projectFacadeSelectsApi.endpoints.projectFacadeSelectsData.select(),
    []
  )

  const state: { data: ProjectFacadeSelects; [x: string]: any } = useAppSelector(selectProjectData)

  const { data: selects } = state

  const facadeMaterialsOptions = useMemo(() => {
    if (selects?.facade_materials) {
      return selects.facade_materials.map(({ id, name }) => ({
        label: name,
        value: id,
      }))
    }
    return []
  }, [selects])

  const facadeManufacturersOptions = useMemo(() => {
    if (selects?.facade_manufacturers) {
      return selects.facade_manufacturers.map(({ id, name }) => ({
        label: name,
        value: id,
      }))
    }
    return []
  }, [selects])

  const getFacadePanelGroupByManufacturer = useCallback(
    (key: keyof FacadeManufacturer = 'id') => {
      if (selects?.facade_panels) {
        return selects?.facade_panels.reduce((acc, curr) => {
          if (curr?.facadeManufacturer) {
            const keyValue = curr?.facadeManufacturer[key]

            const { [keyValue]: reducedValue = [] } = acc

            return Object.assign(acc, {
              [keyValue]: reducedValue.concat(curr),
            })
          }

          return {}
        }, {} as any)
      }

      return {}
    },
    [selects]
  )

  const facadePanelsGroupsByManufacturer: FacadePanel[][] = useMemo(() => {
    return Object.values(getFacadePanelGroupByManufacturer()) || []
  }, [getFacadePanelGroupByManufacturer])

  return {
    selects,
    state,
    facadeMaterialsOptions,
    facadeManufacturersOptions,
    facadePanelsGroupsByManufacturer,
    facadePanels: selects?.facade_panels || [],
  }
}
