import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Space, Stack, Text } from '@mantine/core'
import { Anchor } from '@/components/Elements'
import { AuthCard } from '@/features/auth/components/Elements'
import { useLoginMutation, useSendVerificationEmailMutation } from '@/features/auth/store'
import { PATH } from '@/routes/const'
import { FormValues, LoginForm } from './LoginForm'

export const LoginContainer = () => {
  const { t } = useTranslation()

  const [login] = useLoginMutation()

  const [verifyEmail] = useSendVerificationEmailMutation()

  const handleSubmit = async (values: FormValues) => {
    await login(values).unwrap()
  }

  const onVerifyEmail = async (email: string) => {
    await verifyEmail({ email })
  }

  return (
    <AuthCard title={`${t('welcome')}!`}>
      <Stack spacing="xs" align={'flex-start'}>
        <Text>{t('dont_have_account_yet')}?</Text>

        <Anchor component={Link} to={`/${PATH.REGISTER}`} weight={600}>
          {t('create_an_account')}
        </Anchor>
      </Stack>

      <Space h={'xl'} />

      <LoginForm onSubmit={handleSubmit} onVerifyEmail={onVerifyEmail} />
    </AuthCard>
  )
}
