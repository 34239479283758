import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Divider, Title } from '@mantine/core'
import { CompanyFormSection } from '@/features/profile/components/FormSections'
import { LogoManager } from '@/features/profile/components/Profile/Company'

export const CompanySection = () => {
  const { t } = useTranslation()

  return (
    <>
      <Box mb={'md'}>
        <Title mb={'md'} order={4}>
          {t('company_info')}
        </Title>

        <Box mb={'md'}>
          <LogoManager />
        </Box>

        <CompanyFormSection />
        <Divider />
      </Box>
    </>
  )
}
