import { getAuthRoutes } from '@/routes/configs/auth'
import { getPrivateRoutes } from '@/routes/configs/private'
import { getPublicRoutes } from '@/routes/configs/public'

export const getRootRoutes = (isLoggedIn: boolean, user: object | null): any => {
  return [
    ...getPublicRoutes(isLoggedIn),
    ...getPrivateRoutes(isLoggedIn, user), // order is necessary (for index route go to private route)
    ...getAuthRoutes(isLoggedIn),
  ]
}
