import React from 'react'
import { useTranslation } from 'react-i18next'
import { Title } from '@mantine/core'
import { useProfileUpdatePasswordMutation } from '@/features/profile/store'
import { useNotify } from '@/hooks'
import { PasswordForm } from './PasswordForm'

export const PasswordContainer = () => {
  const { t } = useTranslation()

  const { showNotification } = useNotify()

  const [updatePassword] = useProfileUpdatePasswordMutation()

  const onSubmit = async (values: any) => {
    await updatePassword(values).unwrap()

    showNotification({
      type: 'success',
      message: t('password_changed_successfully'),
    })
  }

  return (
    <>
      <Title mb={'xs'} order={4}>
        {t('change_password')}
      </Title>

      <PasswordForm onSubmit={onSubmit} />
    </>
  )
}
