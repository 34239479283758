import React from 'react'
import { NavLink as MantineNavLink, NavLinkProps } from '@mantine/core'
import { Variants } from '@mantine/styles'
import useStyles from './NavLink.styles'

interface IProps extends NavLinkProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  variant?: Variants<'filled' | 'light' | 'subtle' | 'default'>
  component?: any
  elementRef?: any

  [x: string]: any
}

export const NavLink = ({ children, className, variant, elementRef, ...props }: IProps) => {
  const { classes, cx } = useStyles({
    variant,
  })

  return (
    <MantineNavLink classNames={classes} className={cx(className)} ref={elementRef} {...props}>
      {children}
    </MantineNavLink>
  )
}
