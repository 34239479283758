import { MantineThemeOverride } from '@mantine/core'
import { overrideColors } from './colors'

export const globalTheme: MantineThemeOverride = {
  //Setup global theme for util, components
  //https://github.com/mantinedev/mantine/blob/master/src/mantine-styles/src/theme/default-theme.ts
  colors: overrideColors,
  primaryShade: { light: 5, dark: 5 },
  primaryColor: 'felix',
  fontFamily: 'Open Sans',
  fontFamilyMonospace: 'Menlo',
  headings: {
    fontFamily: 'Open Sans',
  },
  cursorType: 'pointer',
  components: {
    Container: {
      defaultProps: {
        size: 'xl',
        sizes: {
          xs: 576,
          sm: 768,
          md: 992,
          lg: 1200,
          xl: 1400,
        },
      },
    },
    Modal: {
      defaultProps: {
        size: 'lg',
      },
    },
    Avatar: {
      defaultProps: {
        size: 48,
        radius: 'xl',
      },
    },
    Button: {
      defaultProps: {
        size: 'md',
      },
    },
    InputWrapper: {
      defaultProps: {
        size: 'md',
      },
    },
    TextInput: {
      defaultProps: {
        size: 'md',
      },
    },
    PasswordInput: {
      defaultProps: {
        size: 'md',
      },
    },
    Checkbox: {
      defaultProps: {},
    },
    Select: {
      defaultProps: {
        size: 'md',
      },
    },
    Textarea: {
      defaultProps: {
        size: 'md',
      },
    },
    DatePicker: {
      defaultProps: {
        size: 'md',
      },
    },
    Calendar: {
      defaultProps: {
        size: 'md',
      },
    },
    SegmentedControl: {
      defaultProps: {
        size: 'md',
      },
    },
    TimeInput: {
      defaultProps: {
        size: 'md',
      },
    },
    Divider: {
      defaultProps: {
        color: 'gray.2',
      },
    },
  },
}
