import _ from 'lodash'
import { BILLING_TARIFF_PERIOD } from '@/features/billing/consts/tariff'
import i18n from '@/i18n'

const { MONTHLY, ANNUALLY } = BILLING_TARIFF_PERIOD

type OptionType = {
  label: string
  value: BILLING_TARIFF_PERIOD
}

export const getPeriods = () => {
  return [
    {
      label: i18n.t('monthly'),
      value: MONTHLY,
    },
    {
      label: i18n.t('annually'),
      value: ANNUALLY,
    },
  ]
}

export const getPeriod = (value: OptionType | string): OptionType | any => {
  const options = getPeriods()
  return _.find(options, { value })
}

export const isAnnual = (value: BILLING_TARIFF_PERIOD): boolean => {
  return value === ANNUALLY
}

export const isMonthly = (value: BILLING_TARIFF_PERIOD): boolean => {
  return value === MONTHLY
}
