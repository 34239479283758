import { createSelector } from '@reduxjs/toolkit'
import { rootApi } from '@/store/api'
import {
  CreateProjectFacadeDTO,
  ProjectFacade,
  ProjectFacadeDTO,
  UpdateProjectFacadeDTO,
} from '@/features/project-facade/types'
import { CollectionDTO } from '@/types'
import { RTKQueryUtils, RequestUtils } from '@/utils'

const { cacher } = RTKQueryUtils

export const ProjectFacadeTag = 'ProjectFacade'

const enhanceApi = rootApi.enhanceEndpoints({
  addTagTypes: [ProjectFacadeTag],
})

export const projectFacadesApi = enhanceApi.injectEndpoints({
  endpoints: (builder) => ({
    projectFacadeEntries: builder.query<
      CollectionDTO<ProjectFacade>,
      { projectId: string; params?: any }
    >({
      query: ({ projectId, params }) => ({
        url: `projects/${projectId}/project-facades`,
        params: RequestUtils.getMapRequestParams(params),
      }),
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName
      },
      merge: (currentCache, result) => {
        if (result?.meta.pagination.current_page > 1) {
          currentCache.data.push(...result.data)
          currentCache.meta = result.meta
          return currentCache
        }
        return result
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.params?.page !== previousArg?.params?.page
      },
      // without tags because of infinite scroll
      // providesTags: cacher.providesNestedList(ProjectFacadeTag),
      keepUnusedDataFor: 0,
    }),
    projectFacadePreviewsEntries: builder.query<
      CollectionDTO<ProjectFacade>,
      { projectId: string }
    >({
      query: ({ projectId }) => ({
        url: `project-facades/${projectId}`,
      }),
      transformResponse: (response: any) => {
        return response
      },
    }),
    projectFacadeCreate: builder.mutation<ProjectFacadeDTO, { params: CreateProjectFacadeDTO }>({
      query: ({ params }) => ({
        url: `project-facades`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: cacher.invalidatesList(ProjectFacadeTag),
      transformResponse: (response: any) => {
        return response?.data
      },
    }),
    projectFacadeData: builder.query<ProjectFacadeDTO, { id: string; params?: any }>({
      query: ({ id, params }) => ({
        url: `project-facades/${id}`,
        params: RequestUtils.getMapRequestParams(params),
      }),
      transformResponse: (response: any) => {
        return response?.data
      },
      providesTags: cacher.cacheByIdArgProperty(ProjectFacadeTag),
    }),
    projectFacadeUpdate: builder.mutation<
      ProjectFacadeDTO,
      { id: string; params: UpdateProjectFacadeDTO; options?: { invalidatesTagsById: boolean } }
    >({
      query: (arg) => {
        const { id, params } = arg

        return {
          url: `project-facades/${id}`,
          method: 'PATCH',
          body: params,
        }
      },
      invalidatesTags: (result, error, arg) => {
        if (result) {
          const facadesList = cacher.invalidatesList(ProjectFacadeTag)()
          const { options } = arg

          const { invalidatesTagsById } = options ?? { invalidatesTagsById: false }

          const facadeData = invalidatesTagsById
            ? cacher.cacheByIdArg(ProjectFacadeTag)(result, error, arg.id)
            : []
          return [...facadesList, ...facadeData]
        }
        return []
      },
      transformResponse: (response: any) => {
        return response?.data
      },
    }),
    projectFacadeUpdatePreview: builder.mutation<ProjectFacadeDTO, { id: string; params: any }>({
      query: (arg) => {
        const { id, params } = arg
        const formData = new FormData()
        formData.append('picture', params?.picture)

        return {
          url: `project-facades/${id}/picture`,
          method: 'POST',
          body: formData,
        }
      },
      transformResponse: (response: any) => {
        return response?.data
      },
    }),
    projectFacadeDelete: builder.mutation<
      null,
      {
        id: string
        params?: any
        options?: { invalidatesList: boolean }
      }
    >({
      query: ({ id, params, options }) => {
        return {
          url: `project-facades/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result, error, arg) => {
        const { options } = arg
        const { invalidatesList } = options ?? { invalidatesList: true }

        const facadeList = invalidatesList ? cacher.invalidatesList(ProjectFacadeTag)() : []
        const facadeData = cacher.cacheByIdArgProperty(ProjectFacadeTag)(result, error, arg)
        return [...facadeList, ...facadeData]
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useProjectFacadeEntriesQuery,
  useProjectFacadeDataQuery,
  useProjectFacadePreviewsEntriesQuery,
  useProjectFacadeCreateMutation,
  useProjectFacadeUpdateMutation,
  useProjectFacadeUpdatePreviewMutation,
  useProjectFacadeDeleteMutation,
} = projectFacadesApi

export const selectInvalidatedProjectFacadeData = createSelector([(state) => state], (state) =>
  projectFacadesApi.util.selectInvalidatedBy(state, [ProjectFacadeTag])
)
