import { FacadePanel } from '@/features/facade/FacadePanel/types'
import { GlazingTemplate } from '@/features/glazing/GlazingTemplate/types'

export enum ItemType {
  glazingTemplate = 'glazingTemplate',
  panel = 'panel',
}

export type ItemData = GlazingTemplate | FacadePanel

export type ItemDataType<T extends ItemType> = T extends 'glazingTemplate'
  ? GlazingTemplate
  : FacadePanel

export type Item = {
  data: ItemData
  type: ItemType
}
