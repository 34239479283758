import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet, useNavigate } from 'react-router-dom'
import { GeneralPanelPage, GeneralTopBar } from '@/features/panel/components/Layouts'
import { useNotify, useQuery } from '@/hooks'
import { BillingSubscriptionDataQuery } from '../../BillingSubscriptionDataQuery'
import { Tabs } from './Tabs'

export const BillingPanelShell = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const query = useQuery()

  const { showNotification } = useNotify()

  const historyReplace = () => {
    navigate(
      {
        search: '',
      },
      { replace: true }
    )
  }

  useEffect(() => {
    if (query.get('success')) {
      const success = query.get('success') === 'true'
      let message = ''

      if (success) {
        message = query.get('message') || t('payment_was_successful')
      } else {
        message = query.get('message') || t('payment_error')
      }

      showNotification({
        type: success ? 'success' : 'error',
        message,
      })

      historyReplace()
    }
  }, [])

  return (
    <GeneralPanelPage
      header={
        <GeneralTopBar title={t('license_option')}>
          <Tabs />
        </GeneralTopBar>
      }
    >
      <BillingSubscriptionDataQuery>
        <Outlet />
      </BillingSubscriptionDataQuery>
    </GeneralPanelPage>
  )
}
