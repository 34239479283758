import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { LicenceAgreementContainer } from '@/features/legal/components/LicenceAgreement'

export const LicenceAgreement = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'licence_agreement'} />

      <LicenceAgreementContainer />
    </>
  )
}
