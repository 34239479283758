import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { GlazingContainer } from '@/features/constructor/components/Glazing'

export const PaneGlazing = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'edit_facade.application_pane.title'} />

      <GlazingContainer />
    </>
  )
}
