import { createStyles, rem } from '@mantine/core'

export default createStyles((theme) => {
  return {
    root: {
      display: 'flex',
      flexGrow: 1,
    },

    item: {
      display: 'flex',
      flex: 1,
      height: rem(112),

      [`@media (max-width: ${theme.breakpoints.md})`]: {
        height: rem(80),
      },
    },

    tab: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      padding: theme.spacing.sm,
      color: theme.colors['dark'][2],

      ...theme.fn.hover({
        color: theme.colors[theme.primaryColor][theme.fn.primaryShade()],
      }),
    },

    activeTab: {
      color: theme.colors[theme.primaryColor][theme.fn.primaryShade()],
    },
  }
})
