import { useTranslation } from 'react-i18next'
import { Image } from '@mantine/core'
import { Project } from '@/features/project/types'
import useStyles from './MediaBlock.styles'

interface IProps {
  data: Project
}

export const MediaBlock = ({ data }: IProps) => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  return (
    <div className={classes.root}>
      <Image
        alt={'pane'}
        src={data?.picture?.data?.thumb || null}
        className={classes.media}
        styles={{
          figure: {
            height: '100%',
          },
          imageWrapper: {
            height: '100%',
          },
        }}
        withPlaceholder
        fit={'contain'}
      />
    </div>
  )
}
