import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NoDataTextMessage, QueryDataShell } from '@/components/Elements'
import { PROJECT_FACADE_SECTION_ALIAS } from '@/features/project-facade/consts/project-facade'
import { useProjectFacadeGlazingTemplateEntriesQuery } from '@/features/project-facade/store'
import { ProjectFacade } from '@/features/project-facade/types'
import { Project } from '@/features/project/types'
import { SectionCard } from '../SectionCard'
import { GlazingView, Item, ItemType } from './GlazingView'

const defaultTabValue = '0'

const { GLAZING } = PROJECT_FACADE_SECTION_ALIAS

interface IProps {
  project: Project
  facade: ProjectFacade
}

export const Glazing = ({ project, facade }: IProps) => {
  const { t } = useTranslation()

  const facadePanels = facade?.facadePanels?.data || []

  const { isLoading, isFetching, error, isError, isSuccess, data } =
    useProjectFacadeGlazingTemplateEntriesQuery({
      id: facade?.id,
      params: {
        limit: 0,
        search: {
          active: 1,
        },
      },
    })

  const glazingTemplates = data?.data || []

  const items: Item[] = useMemo(() => {
    return [
      ...glazingTemplates.map((item) => ({
        data: item,
        type: ItemType.glazingTemplate,
      })),
      ...facadePanels.map((item) => ({
        data: item,
        type: ItemType.panel,
      })),
    ]
  }, [glazingTemplates, facadePanels])

  const [tab, setTab] = useState(defaultTabValue)

  const onTabChange = (value: string) => {
    setTab(value)
  }

  useEffect(() => {
    onTabChange(defaultTabValue)
  }, [facade.id])

  return (
    <>
      <SectionCard sectionAlias={GLAZING} project={project} facade={facade}>
        <QueryDataShell
          isLoading={isLoading}
          isFetching={isFetching}
          error={error}
          isError={isError}
          isSuccess={isSuccess}
        >
          {items.length !== 0 ? (
            <>
              <GlazingView items={items} tab={tab} onTabChange={onTabChange} />
            </>
          ) : (
            <NoDataTextMessage />
          )}
        </QueryDataShell>
      </SectionCard>
    </>
  )
}
