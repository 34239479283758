import React from 'react'
import { useDispatch } from 'react-redux'
import { projectProjectsMyState } from '@/features/project/store'
import { MyProjectsState } from '@/features/project/types'
import { useAppSelector } from '@/store'

export const useMyProjectsState = () => {
  const dispatch = useDispatch()

  const state = useAppSelector((state) => state.project.projects.my.state) as MyProjectsState

  const onEntriesViewModeChange = (mode: any) => {
    dispatch(projectProjectsMyState.setEntriesViewMode(mode))
  }

  return {
    state,
    onEntriesViewModeChange,
  }
}
