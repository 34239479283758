import { rootApi } from '@/store/api'
import { CreateProjectDTO, Project, ProjectDTO, UpdateProjectDTO } from '@/features/project/types'
import { CollectionDTO } from '@/types'
import { RTKQueryUtils, RequestUtils } from '@/utils'

const { cacher } = RTKQueryUtils

export const MyProjectTag = 'MyProject'

const enhanceApi = rootApi.enhanceEndpoints({ addTagTypes: [MyProjectTag] })

export const projectsApi = enhanceApi.injectEndpoints({
  endpoints: (builder) => ({
    projectMyEntries: builder.query<CollectionDTO<Project>, { params: any }>({
      query: (arg) => ({
        url: `my-projects`,
        params: RequestUtils.getMapRequestParams(arg.params),
      }),
      providesTags: cacher.providesNestedList(MyProjectTag),
    }),
    projectCreate: builder.mutation<ProjectDTO, CreateProjectDTO>({
      query: (body) => ({
        url: 'projects',
        method: 'POST',
        body,
      }),
      invalidatesTags: cacher.invalidatesList(MyProjectTag),
      transformResponse: (response: any) => {
        return response?.data
      },
    }),
    projectData: builder.query<ProjectDTO, string>({
      query: (id) => ({
        url: `projects/${id}`,
      }),
      transformResponse: (response: any) => {
        return response?.data
      },
      providesTags: cacher.cacheByIdArg(MyProjectTag),
    }),
    projectUpdate: builder.mutation<
      ProjectDTO,
      { id: string; params: UpdateProjectDTO; options?: { invalidatesTagsById: boolean } }
    >({
      query: (arg) => {
        const { id, params } = arg

        return {
          url: `projects/${id}`,
          method: 'PATCH',
          body: params,
        }
      },
      invalidatesTags: (result, error, arg) => {
        const projectList = cacher.invalidatesList(MyProjectTag)()
        const { options } = arg

        const { invalidatesTagsById } = options ?? { invalidatesTagsById: true }

        const projectData = invalidatesTagsById
          ? cacher.cacheByIdArg(MyProjectTag)(result, error, arg.id)
          : []
        return [...projectList, ...projectData]
      },
      transformResponse: (response: any) => {
        return response?.data
      },
    }),
    projectDelete: builder.mutation<null, string>({
      query: (id) => {
        return {
          url: `projects/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result, error, arg) => {
        const projectList = cacher.invalidatesList(MyProjectTag)()
        const projectData = cacher.cacheByIdArg(MyProjectTag)(result, error, arg)
        return [...projectList, ...projectData]
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useProjectMyEntriesQuery,
  useProjectDataQuery,
  useProjectCreateMutation,
  useProjectUpdateMutation,
  useProjectDeleteMutation,
} = projectsApi
