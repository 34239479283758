import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { PATH, PATH_PROJECTS } from '@/routes/const'
import { MyRoot } from '../my'
import { ProjectRoot } from '../project'

export const ProjectsRoot = () => {
  return (
    <Routes>
      <Route
        index
        element={<Navigate to={`/${PATH.PROJECTS}/${PATH_PROJECTS.MY}`} replace={true} />}
      />

      <Route path={`${PATH_PROJECTS.MY}/*`} element={<MyRoot />} />

      <Route path={':id/*'} element={<ProjectRoot />} />

      <Route path={'*'} element={<Navigate to={'/'} replace={true} />} />
    </Routes>
  )
}
