import { rootApi } from '@/store/api'
import { ProjectFacadeSelects } from '@/features/facade/ProjectFacadeSelects/types'
import { ItemDTO } from '@/types'

export const ProjectFacadeSelectsTag = 'ProjectFacadeSelects'

const enhanceApi = rootApi.enhanceEndpoints({
  addTagTypes: [ProjectFacadeSelectsTag],
})

export const projectFacadeSelectsApi = enhanceApi.injectEndpoints({
  endpoints: (builder) => ({
    projectFacadeSelectsData: builder.query<ItemDTO<ProjectFacadeSelects>, void>({
      query: () => ({
        url: `/project-facade-selects`,
      }),
      providesTags: [ProjectFacadeSelectsTag],
      transformResponse: (response: any) => {
        return response?.data
      },
    }),
  }),
  overrideExisting: false,
})

export const { useProjectFacadeSelectsDataQuery } = projectFacadeSelectsApi
