import React from 'react'
import { useTranslation } from 'react-i18next'
import { BILLING_TARIFF_ALIAS, BILLING_TARIFF_PERIOD } from '@/features/billing/consts/tariff'
import { tariffHelper } from '@/features/billing/helpers'

export const useTariffStaticOptions = () => {
  const { t } = useTranslation()

  const getPeriods = () => {
    return tariffHelper.period.getPeriods()
  }

  const getPeriod = (value: BILLING_TARIFF_PERIOD) => {
    return tariffHelper.period.getPeriod(value)
  }

  const isAnnual = (value: BILLING_TARIFF_PERIOD) => {
    return tariffHelper.period.isAnnual(value)
  }

  const isMonthly = (value: BILLING_TARIFF_PERIOD) => {
    return tariffHelper.period.isMonthly(value)
  }

  const isStatica = (alias: BILLING_TARIFF_ALIAS) => {
    return tariffHelper.general.isStatica(alias)
  }

  const isTest = (alias: BILLING_TARIFF_ALIAS) => {
    return tariffHelper.general.isTest(alias)
  }

  const isFree = (alias: BILLING_TARIFF_ALIAS) => {
    return tariffHelper.general.isFree(alias)
  }

  const isPremium = (alias: BILLING_TARIFF_ALIAS) => {
    return tariffHelper.general.isPremium(alias)
  }

  return {
    getPeriods,
    getPeriod,
    isAnnual,
    isMonthly,
    isStatica,
    isTest,
    isFree,
    isPremium,
  }
}
