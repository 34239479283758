import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mantine/core'
import { NoDataTextMessage } from '@/components/Elements'
import { GeneralFacadeSystemCard } from '@/features/facade/FacadeSystem/components/FacadeSystems/Cards'
import { FacadeSystem } from '@/features/facade/FacadeSystem/types'

interface IProps {
  data?: FacadeSystem
}

export const System = ({ data }: IProps) => {
  const { t } = useTranslation()

  return (
    <>
      {data ? (
        <Grid>
          <Grid.Col xs={6} sm={4} md={6} lg={4} xl={4}>
            <GeneralFacadeSystemCard data={data} />
          </Grid.Col>
        </Grid>
      ) : (
        <NoDataTextMessage variant={'data'} />
      )}
    </>
  )
}
