import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { AiOutlineAppstoreAdd } from 'react-icons/ai'
import { Box, Group, Menu, Modal, Text } from '@mantine/core'
import { Button } from '@/components/Elements'
import { TextInputController } from '@/components/FormElements'

export const FloorSettingsModal = ({
  opened,
  open,
  close,
  handleAddNewWindow,
  handleDeleteFloor,
  canDeleteFloor,
  data,
  submit,
}: any) => {
  const { t } = useTranslation()

  const methods = useForm({
    defaultValues: {
      height: `${data?.height || 0}`,
      upPertitionHeight: `${data?.upPertition?.height || 0}`,
      underPertitionHeight: `${data?.underPertition?.height || 0}`,
    },
  })

  const { control, handleSubmit } = methods

  const onSubmit = (data: any) => {
    submit(data)
  }

  return (
    <>
      <Modal opened={opened} onClose={close} title={t('floor_settings')}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Group spacing={'xs'} noWrap>
              <TextInputController
                control={control}
                name={'height'}
                id={'height'}
                label={t('floor_height')}
                placeholder={t('floor_height')}
                mb={'md'}
              />
              <TextInputController
                control={control}
                name={'underPertitionHeight'}
                id={'underPertitionHeight'}
                label={t('under_pertition_height')}
                placeholder={t('under_pertition_height')}
                mb={'md'}
              />
            </Group>
            <Button type={'submit'}>{t('update')}</Button>
          </form>
        </FormProvider>
        <Box mt={'lg'}>
          <Group spacing={'xs'} noWrap>
            <Button variant="outline" onClick={handleAddNewWindow}>
              {t('add_new_window')}
            </Button>
            <Button
              disabled={!canDeleteFloor(data?.id)}
              onClick={handleDeleteFloor}
              className="ml-2"
            >
              {t('delete_floor')}
            </Button>
          </Group>
        </Box>
      </Modal>

      <Group position="center">
        <AiOutlineAppstoreAdd onClick={open} size={24} />
      </Group>
    </>
  )
}
