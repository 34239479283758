export const content = `
  <p style='text-align: center'><b>Allgemeine Lizenzvereinbarung der EGLI SOFTWARE GmbH</b></p>
  
  <ol>
    <li>
      <b>
        Allgemein
      </b>
      <ol>
        <li>Diese Lizenzvereinbarung (nachfolgend: "Vereinbarung") wird zwischen Ihnen (nachfolgend: "Benutzer") und der EGLI SOFTWARE GmbH (nachfolgend: "Lizenzgeberin"), einer Gesellschaft mit Sitz an der Spitalstrasse 29, 6004 Luzern, geschlossen.</li>
        <li>Die Lizenzgeberin gewährt dem Benutzer das Recht zur Nutzung der EFA-Soft Facade//Labs (nachfolgend: "Webanwendung") gemäß den in dieser Lizenzvereinbarung festgelegten Bedingungen. Diese Lizenz gestattet dem Benutzer keine Nutzung der Webanwendung über die in dieser Vereinbarung angegebenen Verfahren hinaus oder im Widerspruch zu den geltenden gesetzlichen Bestimmungen der Schweiz.</li>
        <li>Es wird ausdrücklich darauf hingewiesen, dass trotz sorgfältiger Prüfung der Programme mögliche Programmfehler nicht vollständig ausgeschlossen werden können. Die Ergebnisse der Programme sind daher stets zu überprüfen. Der Benutzer trägt die alleinige Verantwortung für die Nutzung der Webanwendung, die Ergebnisse und Exportdaten, Eingabefehler sowie die Sicherung der Daten und die Weitergabe der Ausgaben der Webanwendung an Dritte. Diese Aufzählung ist nicht abschließend. Der Benutzer bestätigt zudem, dass er ausreichend qualifiziert und geschult ist, um eine derartige Webanwendung zu bedienen. Darüber hinaus erklärt sich der Benutzer einverstanden, bei der Planung von Fassaden mit Hilfe der Webanwendung von einem Experten unterstützt zu werden oder selbst ein Experte in diesem Gebiet zu sein.</li>
        <li>Diese Lizenzvereinbarung tritt in Kraft ab dem Tag des Vertragsabschlusses und dauert entweder ein Jahr oder einen Monat, je nachdem welche Subscription der Benutzer gewählt hat. Wenn die Monats- beziehungsweise Jahresfrist erreicht ist, kann diese durch erneute Zahlung verlängert werden. Wenn die Verlängerung nicht bezahlt wurde, wird dem Benutzer den Zugang zu der Webapplikation gesperrt.</li>
        <li>Der Benutzer verpflichtet sich, seine firmen- und personenbezogenen Daten, wie beispielsweise Name und Bauvorhaben, korrekt und vollständig in der dafür vorgesehenen Registrierungsmaske auf dieser Webseite anzugeben. Bei Änderungen ist der Benutzer verpflichtet, diese unverzüglich in dieser Webanwendung nachzutragen.</li>
        <li>Der Benutzer ist dafür verantwortlich, einen Internetzugang am Arbeitsplatz sicherzustellen, an dem ein Webbrowser installiert ist, indem die Webanwendung verwendet wird.</li>
        <li>Die in der Webanwendung verwendeten Berechnungen und Vorschriften basieren auf internationale geltender Bemessungsnormen (SIA-260, SIA-261, SIA-263, SIA-265, SIA-296, EUROCODE 9). Der Benutzer ist selbst dafür verantwortlich zu prüfen, welche Bau- und Rechtsvorschriften in seinem Land Anwendung finden. Die Lizenzgeberin übernimmt keine Haftung für die Einhaltung landesspezifischer Vorschriften und haftet nicht für die Interpretation der Konstruktionsnormen, Zertifikate, Berechnungen und Datei-Exporte. Diese Aufzählung ist nicht abschließend.</li>
      </ol>
    </li>
    
    <li>
      <b>
        Vertragsgegenstand
      </b>
      
      <ol>
        <li>Die Webanwendung mit dem Namen " Facade//Labs " umfasst die Webanwendung selbst, mit all ihren einzelnen Lab Komponenten, die Dokumentation sowie möglicherweise Datenträger und andere Unterlagen in gedruckter oder elektronischer Form.</li>
        <li>Die Webanwendung beinhaltet alle verbesserten und geänderten Versionen sowie Aktualisierungen (generell als "Update" bezeichnet). Ein Update ersetzt oder ergänzt eine ältere Version der Webanwendung. Die Nutzung der Webanwendung unterliegt den zum Zeitpunkt der Veröffentlichung der aktuellen Version geltenden Bestimmungen der Lizenzvereinbarung. Diese können auf der Webseite https://efa-soft.ch eingesehen werden.</li>
        <li> Serviceleistungen, Konfigurationen, Wartung oder ähnliche Dienstleistungen sind nicht Gegenstand dieses Vertrages.</li>
      </ol>
    </li>
    
    <li>
      <b>
        Nutzungsrecht
      </b>
      
      <ol>
        <li>Die Lizenzgeberin gewährt dem Benutzer ein einfaches, zeitlich auf die bezahlte Subscription (Siehe Punkt 1.4) begrenztes und nicht ausschließliches Nutzungsrecht der Webanwendung gemäß dem Urheberrechtsgesetz der Schweiz. Der Quelltext oder der kompilierte Objektcode ist nicht Teil der Lieferung.</li>
        <li>Die Lizenz berechtigt den Benutzer zur Nutzung der Webanwendung im Rahmen ihres bestimmungsgemäßen Gebrauchs. Der Benutzer darf insbesondere keinerlei Änderungen, Übersetzungen oder andere Vervielfältigungen der Webanwendung vornehmen, auch nicht teilweise oder vorübergehend. Eine unzulässige Vervielfältigung stellt auch der Ausdruck des Programmcodes dar.</li>
        <li>Die Webanwendung darf ausschließlich für die in der Webanwendung von der Lizenzgeberin vorgegebenen Produkte verwendet werden.</li>
        <li>Der Benutzer darf die Webanwendung weder vermieten noch verleihen. Eine Übertragung der Lizenz an der Webanwendung auf einen Dritten ist nur nach vorheriger Absprache mit der Lizenzgeberin und nur dann zulässig, wenn sich der Dritte mit diesen Bedingungen schriftlich einverstanden erklärt. Der Benutzer darf die Allgemeine Lizenzvereinbarung der Egli Software GmbH weder ganz noch teilweise übertragen, abtreten oder anderweitig übertragen, es sei denn, dies erfolgt im Rahmen einer Gesamtübertragung des Unternehmens, bei der sämtliche Rechte und Pflichten aus dieser Lizenzvereinbarung auf den Erwerber übergehen. Tritt dieser Fall ein, muss dies der Lizenzgeberin auf schriftlichem Wege mitgeteilt werden.</li>
        <li>Alle Rechte, die dem Benutzer durch diese Lizenzvereinbarung nicht ausdrücklich gewährt werden, sind der Lizenzgeberin vorbehalten.</li>
        <li>Der Gebrauch von nicht-lizenzierter, nicht registrierter oder gekündigten Lizenzen ist nicht gestattet.</li>
      </ol>
    </li>
    
    <li>
      <b>
        Lizenzmodelle und beschränkte Gewährleistung
      </b>
      
      <ol>
        <li>Monatliche Lizenz:
          <br/><br/>
          Der Benutzer kann die Webanwendung gegen monatliche Zahlung nutzen. Die Lizenzgebühr und die Zahlungsmodalitäten richten sich nach den Angaben auf der Webseite.
        </li>
        
        <li>Jährliche Lizenz:
          <br/><br/>
          Der Benutzer kann die Webanwendung gegen jährliche Zahlung nutzen. Die Lizenzgebühr und die Zahlungsmodalitäten richten sich nach den Angaben auf der Webseite.
        </li>
        
        <li>Auschluss der Gewährleistung (Rechts- und Sachgewährleistung):
          <br/>
          <ol>
            <li>Die Lizenzgeberin gewährt keine Gewährleistung für die Webanwendungen und ist für keine Defekte oder Schäden verantwortlich. Der Benutzer hat keine Ansprüche bei Mängeln, Ausfällen der Webanwendung oder sonstigen Fehlern.
              <br/><br/>
              Aufgrund der komplexen Natur von Webanwendungen gewährleistet die Lizenzgeberin nicht, dass die Webanwendung und die Dokumentation vollständig fehlerfrei sind, dass sie unterbrechungsfrei läuft und mit allen Geräten und Webanwendungskonfigurationen kompatibel ist. Sämtliche Eigenschaftsdaten, Webanwendungs- sowie Produktbeschreibungen stellen keine Zusicherung irgendwelcher Eigenschaften dar, auch wenn sie auf geltende Normen Bezug nehmen.
              <br/><br/>
              Die Lizenzgeberin ist nicht verpflichtet, die Webanwendung zu korrigieren, zu überprüfen, zu aktualisieren oder zu vervollständigen.
            </li>
            <li>Die Webanwendungen und die Dokumentation stehen im vorhanden Zustand online zur Verfügung und der Benutzer trägt sämtliche Risiken, die mit der Nutzung verbunden sind. Die Lizenzgeberin übernimmt weder ausdrückliche, stillschweigende noch implizite Gewährleistungen in Bezug auf die Webanwendung oder die Dokumentation, einschließlich, aber nicht beschränkt auf ihre Tauglichkeit und Handelsüblichkeit im Allgemeinen, ihre Eignung für einen bestimmten Zweck, die zukünftige Verfügbarkeit oder die Kompatibilität mit Hardware. Diese Aufzählung ist nicht abschließend.</li>
            <li>Die Lizenzgeberin übernimmt insbesondere keine Haftung oder Gewährleistung in Bezug auf die Beseitigung von Schäden, die durch das Vorhandensein von Viren, Trojanern oder ähnlichen Elementen in der Webanwendung entstehen können.</li>
            <li>Die Lizenzgeberin übernimmt zudem keine Verantwortung für Defekte in älteren Versionen der Webanwendung sowie für mögliche Inkompatibilitäten mit neueren Versionen. Es besteht keine Verpflichtung seitens der Lizenzgeberin zur Bereitstellung von technischer Unterstützung, Weiterentwicklung oder Wartung für ältere Versionen der Webanwendung.</li>
          </ol>
        </li>
      </ol>
    </li>
    
    <li>
      <b>
        Urheberrecht
      </b>
      
      <ol>
        <li>Das Eigentum und das Urheberrecht an der Webanwendung (einschließlich, aber nicht beschränkt auf den Quelltext, kompilierten Objektcode, sämtliche Bilder, Fotografien, Abbildungen, Animationen, Videos und Texte, die in der Webanwendung enthalten sind), sowie an gedrucktem oder elektronischem Begleitmaterial und sämtlichen Kopien der Webanwendung, liegen bei der Lizenzgeberin. Die Webanwendung ist durch das Urheberrecht geschützt.
          <br/><br/>
          Hinweise auf Urheberrechte oder sonstige gewerbliche Schutzrechte, die sich auf oder in der Webanwendung befinden, dürfen weder verändert, entfernt noch anderweitig unkenntlich gemacht werden.
        </li>
      </ol>
    </li>
    
    <li>
      <b>
        Haftungsbegrenzung, Schadensersatz
      </b>
      
      <ol>
        <li>Schadensersatzansprüche des Benutzers, gleich aus welchem Rechtsgrund, insbesondere wegen Verletzung von Pflichten aus dem Schuldverhältnis, Produkthaftung und unerlaubter Handlung, sind ausgeschlossen, soweit im Folgenden nicht anderweitig geregelt. Der Benutzer erklärt sich damit einverstanden, die Lizenzgeberin sowie mit ihr verbundene Unternehmen, deren Geschäftsführer oder Beauftragte, von Ansprüchen Dritter im Zusammenhang mit der Webanwendung schadlos zu halten. Im Falle eines Abwehrversuchs seitens der Lizenzgeberin gegenüber Ansprüchen Dritter, wird der Benutzer die Lizenzgeberin bei der Anspruchsabwehr bestmöglich unterstützen.</li>
        <li>Die Lizenzgeberin schließt jegliche Haftung für unmittelbare, mittelbare oder Folgeschäden aus, einschließlich, aber nicht beschränkt auf entgangenen Gewinn, entgangene Gebrauchsvorteile, Verlust von Informationen und Daten, einschließlich deren Wiederbeschaffung, Betriebsunterbrechungen oder jeden anderen Vermögensschaden, der sich aus der Nutzung oder der Unmöglichkeit der Nutzung der Webanwendung ergibt, insbesondere auch durch Schäden, die auf Fehler in der Webanwendung und ihrer Datenbank zurückzuführen sind. Diese Haftungsausschlüsse gelten selbst dann, wenn ein Vertreter der Lizenzgeberin über die Möglichkeit solcher Schäden in Kenntnis gesetzt wurde. Dies erstreckt sich auch auf Fälle, in denen die Lizenzgeberin als mögliche Urheberin solcher Schäden bezeichnet wurde oder diese Möglichkeit vorhersehbar war. Darüber hinaus übernimmt die Lizenzgeberin keine Haftung für Verlust, Wegnahme oder Beschädigung der Lizenz.</li>
        <li>Dieser Ausschluss greift nur dann nicht, wenn zum Beispiel wegen Vorsatz oder grober Fahrlässigkeit, wegen Verletzung des Lebens, des Körpers und der Gesundheit eine gesetzlich zwingende Haftung besteht.</li>
        <li>Dem Benutzer ist bewusst, dass die Lizenzgeberin die Webanwendung nicht zur Verfügung stellen würde, wenn die genannten Haftungsausschlüsse zu seinen Gunsten geändert oder nicht wirksam wären.Dem Benutzer ist bewusst, dass die Lizenzgeberin die Webanwendung nicht zur Verfügung stellen würde, wenn die genannten Haftungsausschlüsse zu seinen Gunsten geändert oder nicht wirksam wären.</li>
      </ol>
    </li>
    
    <li>
      <b>
        Informations- und Warnpflichten
      </b>
      <br/><br/>
      Die Lizenzgeberin erfüllt ihre Informations- und Warnpflichten ausschließlich durch Veröffentlichungen im Internet unter www.efa-soft.ch. Notwendige Mitteilungen, wie etwa Hinweise zu Programmfehlern und deren Auswirkungen, werden auf www.efa-soft.ch veröffentlicht. Im Rahmen seiner Mitwirkungspflicht ist der Benutzer verpflichtet, diesen Bereich regelmäßig zur Kenntnis zu nehmen. Ansprüche gegen die Lizenzgeberin aufgrund von Schäden, die durch rechtzeitige Kenntnisnahme von Veröffentlichungen hätten vermieden werden können, sind ausgeschlossen. Diese Regelungen zur Haftung bleiben davon unberührt.
    </li>
    
    <li>
      <b>
        Lizenzabschluss und Kündigung
      </b>
      
      <ol>
        <li>Diese Lizenzvereinbarung tritt in Kraft ab dem Tag des Vertragsabschlusses. Die Lizenz wird je nach dem gewählten Lizenzmodell gewährt und endet entweder einen Monat nach Freischaltung der Lizenz im Falle einer monatlichen Subscription oder ein Jahr nach Freischaltung im Falle einer jährlichen Subscription. Während der Laufzeit ist eine Kündigung der Lizenz ausgeschlossen.</li>
        <li>Mit dem Erlöschen des Lizenzvertrags enden sämtliche dem Benutzer gewährten Nutzungsrechte zur Verwendung der Webanwendung.</li>
      </ol>
    </li>
    
    <li>
      <b>
        Erfüllungsort – Gerichtsstand – Recht
      </b>
      
      <ol>
        <li><b>Erfüllungsort und Gerichtsstand für Rechtsstreitigkeiten zwischen den Parteien im Zusammenhang mit diesem Vertrag ist der Sitz der Lizenzgeberin (Luzern). Die Lizenzgeberin ist jedoch berechtigt, den Benutzer auch an seinem Sitz/Wohnsitz zu verklagen.</b></li>
        <li><b>Die Beziehungen zwischen den Vertragsparteien regeln sich ausschließlich nach dem in der Schweiz geltenden Recht unter Ausschluss des UN-Kaufrechts.</b></li>
      </ol>
    </li>
  </ol>
`
