import { useDisclosure } from '@mantine/hooks'
import useStyles from './Style.styles'
import { WindowSettingsModal } from './WindowSettingsModal'

export const Window = (params: any) => {
  const { classes } = useStyles()

  const [opened, { open, close }] = useDisclosure(false)

  const { data, onDeleteWindow, onUpdateWindow, floorId, showActions, scale } = params

  const handleDeleteWindow = () => {
    onDeleteWindow(floorId, data.id)
  }

  const handleUpdateWindow = (updatedWindow: any) => {
    onUpdateWindow(floorId, data.id, updatedWindow)
  }

  const windowStyles = () => {
    if (!data.width) return {}

    return { width: data.width, flex: `0 0 ${(data.width / 10) * scale}px` }
  }

  return (
    <>
      {data.leftPertition && (
        <div
          className={classes.windowPertition}
          style={{ width: (data.leftPertition.width / 10) * scale }}
        ></div>
      )}
      <div
        className={`${classes.window} ${classes.leftPertition}
          ${!data.leftPertition || data.leftPertition.width === 0 ? classes.leftPertition : ''}
          ${!data.rightPertition || data.rightPertition.width === 0 ? classes.rightPertition : ''}`}
        style={windowStyles()}
      >
        {showActions && (
          <WindowSettingsModal
            data={data}
            opened={opened}
            open={open}
            close={close}
            handleDeleteWindow={handleDeleteWindow}
            submit={handleUpdateWindow}
          />
        )}
      </div>
      {data.rightPertition && (
        <div
          className={classes.windowPertition}
          style={{ width: (data.rightPertition.width / 10) * scale }}
        ></div>
      )}
    </>
  )
}
