import { createStyles, getStylesRef } from '@mantine/core'

// eslint-disable-next-line
interface StylesParams {}

export default createStyles((theme, props: StylesParams) => {
  return {
    control: {
      ref: getStylesRef('control'),
    },

    item: {},
  }
})
