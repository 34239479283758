import React from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { Box, Container } from '@mantine/core'
import { HeroSection } from './Sections'
import { Tabs } from './Sections/Tabs'

export const HelpCenterShell = () => {
  const { t } = useTranslation()

  return (
    <>
      <HeroSection />

      <Tabs />

      <Container size={'md'} w={'100%'}>
        <Box py={'xl'}>
          <Outlet />
        </Box>
      </Container>
    </>
  )
}
