import React from 'react'
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form'
import { FacadeSystemPicker, FacadeSystemPickerProps } from '../../FacadeSystemPicker'

interface FacadeSystemPickerControllerProps<T extends FieldValues>
  extends Omit<FacadeSystemPickerProps, 'onChange' | 'value'> {
  control: Control<T, any>
  name: FieldPath<T>
  shouldUnregister?: boolean
  defaultValue?: any
  meta?: any
}

export const FacadeSystemPickerController = <T extends FieldValues>({
  name,
  control,
  shouldUnregister,
  defaultValue,
  ...rest
}: FacadeSystemPickerControllerProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue}
      render={({ field: { ref, ...field }, fieldState, formState }) => {
        const meta = {
          error: fieldState.error,
        }

        return (
          <FacadeSystemPicker
            {...field}
            onChange={(value) => field.onChange(value as any)}
            fieldRef={ref}
            {...rest}
            meta={meta}
          />
        )
      }}
    />
  )
}
