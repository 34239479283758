import { createSlice } from '@reduxjs/toolkit'
import { profileApi } from '@/features/profile/store/api/slice'
import { User } from '@/features/user'
import { userApi } from '@/features/user/store/api/slice'
import { RootState } from '@/store'

interface UserDataState {
  data: User | null
}

const initialState: UserDataState = {
  data: null,
}

const userDataSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    setData(state, action) {
      state.data = action.payload
    },
    updateData(state, action) {
      state.data = {
        ...state.data,
        ...action.payload,
      }
    },
    updateUnreadMessages(state, action) {
      const data = state.data as User
      state.data = {
        ...data,
        unread_messages: action.payload >= 0 ? action.payload : 0,
      }
    },
    cleanData() {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(userApi.endpoints.userData.matchFulfilled, (state, action) => {
      state.data = action.payload
    })

    builder.addMatcher(
      profileApi.endpoints.profileUpdateCustomerProfile.matchFulfilled,
      (state, action) => {
        state.data = action.payload
      }
    )
  },
})

export const userData = userDataSlice.actions

export const selectUserData = (state: RootState) => state.user.data.data

export default userDataSlice.reducer
