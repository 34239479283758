import { createStyles, rem } from '@mantine/core'

export default createStyles((theme) => {
  const variant = 'light'
  const colors = theme.fn.variant({ variant, color: 'gray' })

  return {
    root: {
      backgroundColor: colors.background,
    },
  }
})
