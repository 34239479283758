import { rootApi } from '@/store/api'
import { CheckoutSubscriptionDTO, Subscription } from '@/features/billing/types'

const enhanceApi = rootApi.enhanceEndpoints({ addTagTypes: ['BillingSubscription'] })

export const billingSubscriptionApi = enhanceApi.injectEndpoints({
  endpoints: (builder) => ({
    billingSubscriptionCheckout: builder.mutation<any, { params: CheckoutSubscriptionDTO }>({
      query: (arg) => {
        const { params } = arg

        return {
          url: `billing/subscription`,
          method: 'POST',
          body: params,
        }
      },
      transformResponse: (response: any) => {
        return response?.data
      },
    }),
    billingSubscriptionData: builder.query<Subscription, void>({
      query: (id) => ({
        url: `billing/subscription`,
      }),
      transformResponse: (response: any) => {
        return response?.data
      },
      keepUnusedDataFor: 0,
    }),
  }),
  overrideExisting: false,
})

export const { useBillingSubscriptionCheckoutMutation, useBillingSubscriptionDataQuery } =
  billingSubscriptionApi
