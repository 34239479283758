import { createStyles, rem } from '@mantine/core'

export default createStyles((theme) => {
  return {
    section: {
      background: theme.white,
    },

    container: {},
  }
})
