import { debounce } from 'lodash'
import React, { useCallback, useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Icon } from '@/components/Elements'
import { TextInputController, TextInputProps } from '@/components/FormElements'
import { KEYWORDS_MIN_LENGTH } from '@/const/form'

interface IProps {
  inputProps?: TextInputProps
  keywordsMinLength?: number
}

export const SearchFormSection = ({
  inputProps,
  keywordsMinLength = KEYWORDS_MIN_LENGTH,
}: IProps) => {
  const { t } = useTranslation()

  const { control, watch, getValues } = useFormContext()

  const [keywordsHint, setKeywordsHint] = useState<boolean>(false)

  const onKeywordHintVisible = (value: string) => {
    if (value && value.length < keywordsMinLength) {
      setKeywordsHint(true)
    } else {
      setKeywordsHint(false)
    }
  }

  const onDebouncedChange = useCallback(debounce(onKeywordHintVisible, 700), [onKeywordHintVisible])

  useEffect(() => {
    onKeywordHintVisible(getValues('keywords'))

    const subscription = watch((values: any, { name }) => {
      if (!name || name === 'keywords') {
        onDebouncedChange(values?.keywords)
      }
    })
    return () => subscription.unsubscribe()
  }, [watch])

  return (
    <TextInputController
      control={control}
      name={'keywords'}
      id={'keywords'}
      placeholder={t('search')}
      icon={<Icon name={'search'} />}
      description={keywordsHint ? t('min-characters.count', { count: keywordsMinLength }) : null}
      inputWrapperOrder={['label', 'input', 'description', 'error']}
      {...inputProps}
    />
  )
}
