import { createStyles } from '@mantine/core'
import { MantineNumberSize, em, getBreakpointValue, getSize, rem } from '@mantine/styles'

export interface NavBarStylesParams {
  width: MantineNumberSize
  hiddenBreakpoint: MantineNumberSize
}

export default createStyles((theme, props: NavBarStylesParams) => {
  const dark = theme.colorScheme === 'dark'

  const width = getSize({ size: props.width, sizes: theme.breakpoints })

  return {
    root: {
      borderRight: `${rem(1)} solid ${dark ? theme.colors.dark[5] : theme.colors.gray[2]}`,
      width: width,
      display: 'flex',
      flexDirection: 'column',

      [`@media (max-width: ${em(
        getBreakpointValue(
          getSize({
            size: props.hiddenBreakpoint,
            sizes: theme.breakpoints,
          })
        ) - 1
      )})`]: {
        display: 'none',
      },
    },
  }
})
