import React from 'react'
import { useTranslation } from 'react-i18next'
import { PROJECT_FACADE_SECTION_ALIAS } from '@/features/project-facade/consts/project-facade'
import { ProjectFacade } from '@/features/project-facade/types'
import { Project } from '@/features/project/types'
import { SectionCard } from '../SectionCard'
import { System } from './System'

const { PRODUCT } = PROJECT_FACADE_SECTION_ALIAS

interface IProps {
  project: Project
  facade: ProjectFacade
}

export const Product = ({ project, facade }: IProps) => {
  const { t } = useTranslation()

  return (
    <>
      <SectionCard sectionAlias={PRODUCT} project={project} facade={facade}>
        <System data={facade?.facadeSystem?.data} />
      </SectionCard>
    </>
  )
}
