import React from 'react'
import { Box, CardProps, Group, Text, TextProps } from '@mantine/core'
import { Card, CardSection } from '@/components/Elements'

interface IProps extends CardProps {
  children: React.ReactNode
  title?: React.ReactElement | string
  subtitle?: React.ReactElement | string
  rightSection?: React.ReactElement
  titleProps?: TextProps
  subtitleProps?: TextProps
}

export const InfoCard: React.FC<IProps> = ({
  children,
  title,
  subtitle,
  rightSection,
  titleProps,
  subtitleProps,
  ...props
}) => {
  const renderTitle = () => {
    if (typeof title === 'string') {
      return (
        <Text fz={'sm'} fw={700} {...titleProps}>
          {title}
        </Text>
      )
    }

    return title
  }

  const renderSubtitle = () => {
    if (typeof subtitle === 'string') {
      return <Text fz={'xs'}>{subtitle}</Text>
    }

    return subtitle
  }

  return (
    <Card shadow={'xs'} p={'lg'} {...props}>
      {(title || rightSection) && (
        <CardSection inheritPadding py="lg">
          <Group position="apart">
            <Box>
              {title && renderTitle()}

              {subtitle && renderSubtitle()}
            </Box>

            {rightSection}
          </Group>
        </CardSection>
      )}

      {children}
    </Card>
  )
}
