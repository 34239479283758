import React from 'react'
import { ScrollArea } from '@mantine/core'
import { NavTabs } from '@/components/Elements'
import { GeneralTabProps } from '@/components/Elements/Tabs/GeneralTabs'
import useStyles from './NavMenuBar.styles'

interface IProps {
  value: string
  onChange: (value: any) => void
  tabs: GeneralTabProps[]
}

export const NavMenuBar = ({ value, onChange, tabs }: IProps) => {
  const { classes } = useStyles()

  return (
    <div className={classes.root}>
      <NavTabs value={value} onChange={onChange} tabs={tabs} />
    </div>
  )
}
