import React from 'react'
import { AnchorProps, Anchor as MantineAnchor, useMantineTheme } from '@mantine/core'

interface IProps extends AnchorProps {
  children: React.ReactNode

  [x: string]: any
}

export const Anchor: React.FC<IProps> = ({ children, ...props }) => {
  const theme = useMantineTheme()

  const { color } = props

  const getAnchorColor = () => {
    if (color === 'dimmed') {
      return theme.fn.dimmed()
    }

    return theme.fn.themeColor(
      color || theme.primaryColor,
      theme.colorScheme === 'dark' ? 4 : theme.fn.primaryShade(),
      false,
      true
    )
  }

  return (
    <MantineAnchor color={getAnchorColor()} {...props}>
      {children}
    </MantineAnchor>
  )
}
