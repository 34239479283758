import { MantineThemeColorsOverride } from '@mantine/core'

const colors = {
  felix: [
    '#EBF8FF',
    '#CCEEFF',
    '#94DBFF',
    '#42C0FF',
    '#0099E6',
    '#00608F',
    '#005580',
    '#004B70',
    '#003D5C',
    '#00293D',
  ],
  quince: [
    '#FCFBF3',
    '#F9F7E6',
    '#F1EDC6',
    '#EAE3A9',
    '#E0D680',
    '#D7CB5C',
    '#C5B630',
    '#B0A32B',
    '#8F8523',
    '#6A621A',
  ],
}

export const overrideColors: MantineThemeColorsOverride = {
  felix: colors.felix,
  quince: colors.quince,
}
