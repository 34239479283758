import * as Yup from 'yup'
import { COMPANY_UID_LENGTH, PHONE_DIGITS_LENGTH } from '@/const/form'
import { ValidationsUtils } from '@/utils'

export const validationSchema = Yup.object({
  gender: Yup.string().nullable().required(),
  name: Yup.string().required(),
  last_name: Yup.string().required(),
  email: Yup.string().email().required(),
  phone: Yup.string()
    .test('phone', { key: 'field.error.invalid' }, ValidationsUtils.phoneFormat)
    .test(
      'phone-length',
      { key: 'field.error.digits_between', values: { ...PHONE_DIGITS_LENGTH } },
      (value) => ValidationsUtils.digitsBetween(value, PHONE_DIGITS_LENGTH)
    )
    .required(),
  company_name: Yup.string().nullable().required(),
  company_uid: Yup.string()
    .required()
    .test('companyUidRule', { key: 'field.error.company_uid.rule' }, ValidationsUtils.companyUID)
    .nullable()
    .min(COMPANY_UID_LENGTH.min),
  company_function: Yup.string().nullable(),
  street: Yup.string().required(),
  street_number: Yup.string().required(),
  zip: Yup.string().required(),
  city: Yup.string().required(),
})
