import React from 'react'
import { Modal as MantineModal, ModalProps as MantineModalProps, Title } from '@mantine/core'
import useStyles from './Modal.styles'

export interface ModalProps extends MantineModalProps {
  opened: boolean
  onClose: () => void
  header?: React.ReactNode
  title?: React.ReactNode
  children: React.ReactNode
  classNames?: any
  grow?: boolean
}

export const Modal = ({
  opened,
  onClose,
  header,
  title,
  children,
  classNames,
  grow,
  ...props
}: ModalProps) => {
  const { classes } = useStyles({ grow }, { name: 'GeneralModal', classNames })

  const renderModalTitle = () =>
    header ? (
      header
    ) : title ? (
      <div className={'flex'}>
        <Title order={3}>{title}</Title>
      </div>
    ) : null

  return (
    <MantineModal
      classNames={classes}
      {...props}
      onClose={onClose}
      title={renderModalTitle()}
      opened={opened}
    >
      {children}
    </MantineModal>
  )
}
