import domtoimage from 'dom-to-image'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Box, Group, Input, Select, Slider, Switch } from '@mantine/core'
import {
  GeneralBottomBar,
  GeneralTopBar,
  ProjectFacadeDataEntryPage,
} from '@/features/project-facade/components/ProjectFacadeDataEntry/Layouts'
import { PROJECT_FACADE_SECTION_ALIAS } from '@/features/project-facade/consts/project-facade'
import { projectFacadeHelper } from '@/features/project-facade/helpers'
import { useProjectFacadeData } from '@/features/project-facade/hooks'
import { useProjectFacadeStaticOptions } from '@/features/project-facade/hooks'
import {
  useProjectFacadeUpdateMutation,
  useProjectFacadeUpdatePreviewMutation,
} from '@/features/project-facade/store'
import { useProjectData } from '@/features/project/hooks'
import { useNotify } from '@/hooks'
import { DEFAULT_CONFIG } from '../../Constructor/ConstructorContainer/DefaultConfigs'
import { Floor } from './Floor'
import useStyles from './Style.styles'

const { PANE_GLAZING } = PROJECT_FACADE_SECTION_ALIAS
const { getProjectFacadePathConfig } = projectFacadeHelper.routes

export const GlazingContainer = () => {
  const { showNotification } = useNotify()

  const [settings, setSettings] = useState(DEFAULT_CONFIG(null, null))
  const [showActions, setShowActions] = useState(true)
  const [constructorScale, setConstructorSacel] = useState(0.6)

  // FIXME
  const consoleError = console.error
  const SUPPRESSED_WARNINGS = ['Functions that are interpolated in css calls will be stringified.']
  console.error = function filterWarnings(msg, ...args) {
    if (!SUPPRESSED_WARNINGS.some((entry) => msg.includes(entry))) {
      consoleError(msg, ...args)
    }
  }

  const [update] = useProjectFacadeUpdateMutation()
  const [updatePreview] = useProjectFacadeUpdatePreviewMutation()

  const { t } = useTranslation()

  const navigate = useNavigate()

  const { sectionsMap, getAdjacentSections } = useProjectFacadeStaticOptions()

  const { classes } = useStyles()

  const { project } = useProjectData({})

  const { facade, onSetFacadeData } = useProjectFacadeData({})

  const facadeSection = sectionsMap.get(PANE_GLAZING)

  const onNavigate = () => {
    const pathConfig = getProjectFacadePathConfig(project?.id, facade?.id)

    const { next } = getAdjacentSections(PANE_GLAZING)

    navigate({
      pathname: `${pathConfig.edit}/${next?.path}`,
    })
  }

  useEffect(() => {
    if (facade.application_panes) {
      setSettings(JSON.parse(facade.application_panes))
    } else {
      setSettings(DEFAULT_CONFIG(null, null))
    }
  }, [facade])

  const onSave = async () => {
    const params = {
      application_panes: JSON.stringify(settings),
    }

    try {
      const data = await update({ id: facade?.id, params }).unwrap()
      showNotification({ type: 'success', message: t('update_facade_success') })

      const facadeBuilderNode = document.getElementById('facadeBuilder') || null

      if (facadeBuilderNode) {
        await domtoimage
          .toBlob(facadeBuilderNode)
          .then(async function (blobData: any) {
            const pictureFile = new File([blobData], `facade-${facade?.id}.png`, {
              type: 'image/png',
            })

            updatePreview({ id: facade?.id, params: { picture: pictureFile } }).unwrap()
          })
          .catch(function (error: any) {
            console.error(error)
          })
      }

      if (data) {
        await onSetFacadeData(data)
      }
    } catch (e) {
      console.log('ERROR: update facade ', e)
    }
  }

  const onNext = async () => {
    await onSave()
    onNavigate()
  }

  const onUpdateWindow = (floorId: any, windowId: any, updatedWindow: any) => {
    const currentFloors = settings.floors

    currentFloors.forEach((floor: any) => {
      if (floorId !== floor.id) return

      floor.windows.forEach((window: any) => {
        if (windowId !== window.id) return
        window.typeId = updatedWindow.typeId
      })
    })

    setSettings({
      ...settings,
      floors: currentFloors,
    })

    showNotification({ type: 'success', message: t('window_updated') })
  }

  const MARKS = [
    { value: 0.2, label: '20%' },
    { value: 0.4, label: '40%' },
    { value: 0.6, label: '60%' },
    { value: 0.8, label: '80%' },
    { value: 1, label: '100%' },
    { value: 1.2, label: '120%' },
    { value: 1.4, label: '140%' },
    { value: 1.6, label: '160%' },
    { value: 1.8, label: '180%' },
    { value: 2, label: '200%' },
  ]

  const calculateFullFloorWindowSpace = () => {
    const currentFloors = settings.floors
    const currentFloor = currentFloors[currentFloors.length - 1]

    let totalWindowWidth = 0

    currentFloor?.windows.forEach((window: any) => {
      totalWindowWidth += window.width
      totalWindowWidth += window.leftPertition.width ?? 1
      totalWindowWidth += window.rightPertition.width ?? 1
    })

    return totalWindowWidth || 10000
  }

  const onBack = async () => {
    const pathConfig = getProjectFacadePathConfig(project?.id, facade?.id)

    const { prev } = getAdjacentSections(PANE_GLAZING)

    navigate({
      pathname: `${pathConfig.edit}/${prev?.path}`,
    })
  }

  return (
    <>
      <ProjectFacadeDataEntryPage
        classNames={{ content: 'h-full' }}
        footer={
          <GeneralBottomBar
            config={{ save: true, next: true, back: true }}
            saveProps={{ btnProps: { disabled: false } }}
            nextProps={{ btnProps: { disabled: false } }}
            onSave={onSave}
            onNext={onNext}
            onBack={onBack}
          />
        }
      >
        <GeneralTopBar
          title={`${t('step')} ${facadeSection.order + 1} - ${facadeSection.label}`}
          subtitle={t('enter_building_data')}
        />

        <Box mb={'lg'}>
          <Group spacing={'xs'} position="left">
            <Switch
              checked={showActions}
              onChange={(event) => setShowActions(event.currentTarget.checked)}
              label={t('show_constructor_actions')}
            />

            <Slider
              label={(val) => `${val.toFixed(1)}%`}
              defaultValue={constructorScale}
              onChange={(val) => setConstructorSacel(+val.toFixed(1))}
              step={0.2}
              max={2}
              min={0.2}
              marks={MARKS}
              styles={{ track: { width: 350 } }}
            />
          </Group>
        </Box>

        {project && settings && (
          <div
            id="facadeBuilder"
            className={classes.constructor}
            style={{
              height: '100%',
              width: `${100 * constructorScale}%`,
              minWidth: '100%',
            }}
          >
            {settings.floors.map((floor: any) => (
              <Floor
                scale={+constructorScale}
                floorWidth={calculateFullFloorWindowSpace()}
                key={floor.id}
                data={floor}
                showActions={showActions}
                onUpdateWindow={onUpdateWindow}
              />
            ))}
          </div>
        )}
      </ProjectFacadeDataEntryPage>
    </>
  )
}
