import { createStyles, rem } from '@mantine/core'

export default createStyles((theme) => {
  const primaryColor = theme.primaryColor
  const primaryShade = theme.fn.primaryShade()

  const highlightBorderColor = theme.colors[primaryColor][primaryShade]
  return {
    root: {
      width: '100%',

      ['&:hover']: {
        borderColor: highlightBorderColor,
      },

      ['> div']: {
        width: '100%',
      },
    },

    active: {
      borderColor: highlightBorderColor,
    },
  }
})
