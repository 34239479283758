import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '@/components/Elements'
import { TermsConfirm } from './TermsConfirm'

interface IProps {
  visible: boolean
  onClose: () => void
  onConfirm: () => void
}

export const TermsConfirmModal = ({ visible, onClose, onConfirm }: IProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Modal size={'xl'} opened={visible} onClose={onClose} title={t('terms_and_conditions')} grow>
        <TermsConfirm onCancel={onClose} onConfirm={onConfirm} />
      </Modal>
    </>
  )
}
