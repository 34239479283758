import React from 'react'
import { HtmlContent } from '@/components/Elements'
import { contentHelper } from '@/features/legal/helpers'

export const TermsContent = () => {
  return (
    <HtmlContent
      data={contentHelper.terms.getContent()}
      sx={{
        ol: {
          listStyleType: 'none',
          counterReset: 'item',
          margin: 0,
          padding: 0,
        },

        ['div > ol > li:before']: {
          fontWeight: 'bold',
        },

        ['ol > li']: {
          display: 'table',
          counterIncrement: 'item',
          marginBottom: '1rem',
        },

        ['ol > li:before']: {
          content: 'counters(item, ".") ". "',
          display: 'table-cell',
          paddingRight: '0.6em',
          whiteSpace: 'nowrap',
        },

        ['li ol > li']: {
          margin: '1rem',
        },

        ['li ol > li:before']: {
          content: 'counters(item, ".") ". "',
        },
      }}
    />
  )
}
