import React from 'react'
import { useTranslation } from 'react-i18next'
import { Flex, Grid } from '@mantine/core'
import { ContactInfo } from './ContactInfo'
import { FeedbackContainer } from './FeedbackContainer'

export const SupportContainer = () => {
  const { t } = useTranslation()

  return (
    <Grid>
      <Grid.Col order={2} orderSm={1} sm={7}>
        <FeedbackContainer />
      </Grid.Col>
      <Grid.Col order={1} orderSm={2} sm={5}>
        <Flex justify={'center'}>
          <ContactInfo />
        </Flex>
      </Grid.Col>
    </Grid>
  )
}
