import React from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { ProjectEdit } from '@/features/project/components/Project'
import { ProjectPanelProvider } from '@/features/project/contexts'
import { useProjectData } from '@/features/project/hooks'
import { useProjectDataQuery } from '@/features/project/store'
import { ProjectDTO } from '@/features/project/types'
import { usePopup } from '@/hooks'

export const ProjectPanel = () => {
  const { id } = useParams()

  if (!id) {
    return null
  }

  const { data } = useProjectDataQuery(id)

  const { onSetProjectData } = useProjectData({ id })

  // --- Edit Project --- //
  const editPopup = usePopup()

  const onProjectEdit = () => {
    editPopup.open()
  }

  const onProjectEditSuccess = (data: ProjectDTO) => {
    editPopup.close()

    onSetProjectData(data)
  }
  // --- //

  return (
    <>
      <ProjectPanelProvider editProps={{ onEdit: onProjectEdit }}>
        <Outlet />
      </ProjectPanelProvider>

      {data && (
        <ProjectEdit
          data={data}
          visible={editPopup.visible}
          onClose={editPopup.close}
          onSuccess={onProjectEditSuccess}
          queryOptions={{ invalidatesTagsById: false }}
        />
      )}
    </>
  )
}
