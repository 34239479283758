import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useProfileUpdateCustomerProfileMutation } from '@/features/profile/store'
import { useUser } from '@/features/user/hooks'
import { useNotify } from '@/hooks'
import { FormatUtils } from '@/utils'
import { ProfileForm } from '../ProfileForm'

export const ProfileContainer = () => {
  const { t } = useTranslation()

  const { user } = useUser()

  const { phone, name, last_name, gender, email, customerProfile, userAddress } = user || {}

  const { showNotification } = useNotify()

  const [updateProfile] = useProfileUpdateCustomerProfileMutation()

  const onSubmit = async (values: any) => {
    await updateProfile(values).unwrap()

    showNotification({
      type: 'success',
      message: t('saved_successfully'),
    })
  }

  const initialValues = useMemo(() => {
    return {
      name: name || '',
      last_name: last_name || '',
      phone: FormatUtils.formatPhone(phone) || '',
      email,
      gender,
      ...customerProfile?.data,
      ...userAddress?.data,
    }
  }, [])

  return (
    <>
      <ProfileForm
        onSubmit={onSubmit}
        initialValues={{
          ...initialValues,
        }}
      />
    </>
  )
}
