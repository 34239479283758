import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionIcon, Menu, Text } from '@mantine/core'
import { ConfirmModal, Icon } from '@/components/Elements'
import { Project } from '@/features/project/types'
import { useConfirmPopup } from '@/hooks'

interface IProps {
  config?: {
    edit?: boolean
    remove?: boolean
    print?: boolean
  }
  data: Project
  menuProps?: any
  editProps?: any
  removeProps?: any
  printProps?: any
}

const defaultConfig = {
  edit: true,
  remove: true,
}

export const GeneralActionMenu = ({ config, data, menuProps, editProps, removeProps }: IProps) => {
  const { t } = useTranslation()

  config = { ...defaultConfig, ...config }

  const onEventPrevent = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
  }

  const onRemoveConfirm = () => {
    const { onRemove } = removeProps ?? {}

    onRemove && onRemove(data)
  }

  const removeConfirm = useConfirmPopup({ confirm: onRemoveConfirm })

  return (
    <div onClick={onEventPrevent}>
      <Menu width={150} {...menuProps}>
        <Menu.Target>
          <ActionIcon color={'gray.9'} variant={'subtle'}>
            <Icon name={'dots'} />
          </ActionIcon>
        </Menu.Target>

        <Menu.Dropdown>
          {config?.edit && (
            <Menu.Item
              icon={<Icon name={'pencil'} />}
              onClick={() => editProps.onEdit && editProps.onEdit(data)}
            >
              <Text>{t('edit')}</Text>
            </Menu.Item>
          )}
          {config?.print && (
            <Menu.Item icon={<Icon name={'printer'} />}>
              <Text>{t('print')}</Text>
            </Menu.Item>
          )}
          {config?.remove && (
            <Menu.Item icon={<Icon name={'trash'} />} onClick={removeConfirm.open}>
              <Text>{t('delete')}</Text>
            </Menu.Item>
          )}
        </Menu.Dropdown>
      </Menu>

      <ConfirmModal
        visible={removeConfirm.visible}
        onClose={removeConfirm.close}
        onConfirm={removeConfirm.onConfirm}
      />
    </div>
  )
}
