import { createStyles } from '@mantine/core'

export interface StylesParams {
  color: string
}

export default createStyles((theme, _params: StylesParams) => {
  const { color } = _params

  return {
    root: {
      width: theme.spacing.xl,
      height: theme.spacing.xl,
      backgroundColor: color,
    },
  }
})
