import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Title } from '@mantine/core'
import useStyles from './Component.styles'

export const ResourcesContainer = () => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  return (
    <>
      <Box mb={'xl'}>
        <Title order={3}>{t('resources')}</Title>
      </Box>
    </>
  )
}
