import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { TermsContainer } from '@/features/legal/components/Terms'

export const Terms = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'terms_and_conditions'} />

      <TermsContainer />
    </>
  )
}
