import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { projectsApi } from '@/features/project/store'
import { ProjectDTO } from '@/features/project/types'
import { useAppDispatch, useAppSelector } from '@/store'

interface IProps {
  id?: string
}

export const useProjectData = ({ id }: IProps) => {
  const { id: paramsId } = useParams()

  const projectId = id || (paramsId as string)

  const dispatch = useAppDispatch()

  const selectProjectData: any = useMemo(
    () => projectsApi.endpoints.projectData.select(projectId),
    [projectId]
  )

  const state: { data: ProjectDTO; [x: string]: any } = useAppSelector(selectProjectData)

  const { data: project } = state

  const onSetProjectData = (data: ProjectDTO) => {
    dispatch(projectsApi.util.updateQueryData('projectData', projectId, (draft) => data))
  }

  return {
    project,
    state,
    onSetProjectData,
  }
}
