import * as Yup from 'yup'
import { PHONE_DIGITS_LENGTH } from '@/const/form'
import { ValidationsUtils } from '@/utils'

export const validationSchema = Yup.object({
  name: Yup.string().required(),
  email: Yup.string().email().required(),
  phone: Yup.string()
    .test('phone', { key: 'field.error.invalid' }, ValidationsUtils.phoneFormat)
    .test(
      'phone-length',
      { key: 'field.error.digits_between', values: { ...PHONE_DIGITS_LENGTH } },
      (value) => ValidationsUtils.digitsBetween(value, PHONE_DIGITS_LENGTH)
    )
    .required(),
  type: Yup.string().required(),
  subject: Yup.string().required(),
  message: Yup.string().required(),
})
