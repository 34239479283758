import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink, NavLinkType } from '@/components/Layouts/Elements'

interface IProps {
  onLinkClick: () => void
  menu: Array<NavLinkType>
}

export const GeneralLinks = ({ onLinkClick, menu }: IProps) => {
  const { t } = useTranslation()

  return (
    <>
      {menu.map((item) => {
        return <NavLink key={item.path} onClick={onLinkClick} {...item} />
      })}
    </>
  )
}
