import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Container, Flex, Grid, Footer as MantineFooter, Stack } from '@mantine/core'
import { Anchor, LogoIcon } from '@/components/Elements'
import { APP_NAME } from '@/const/core'
import { PATH, PATH_HELP_CENTER, PATH_LEGAL } from '@/routes/const'
import useStyles from './Footer.styles'

export const Footer = () => {
  const { t } = useTranslation()

  const { classes, cx } = useStyles()

  const menu = [
    {
      path: `${PATH.HELP}/${PATH_HELP_CENTER.RESOURCES}`,
      label: t('documentation'),
    },
    {
      path: `${PATH.HELP}/${PATH_HELP_CENTER.FAQ}`,
      label: t('faq'),
    },
    {
      path: `${PATH.HELP}/${PATH_HELP_CENTER.SUPPORT}`,
      label: t('support'),
    },
    {
      path: `${PATH.LEGAL}/${PATH_LEGAL.IMPRINT_DISCLAIMER}`,
      label: t('imprint_and_disclaimer.title'),
      target: '_blank',
    },
  ]

  return (
    <>
      <MantineFooter className={classes.root} height={'100%'} zIndex={1}>
        <Container className={classes.wrap}>
          <Grid py={'xl'}>
            <Grid.Col md={4} order={2} orderMd={1}>
              <Flex align={'flex-start'} direction="column" gap={'md'}>
                <Anchor component={Link} to={'/'}>
                  <LogoIcon size={'sm'} />
                </Anchor>

                <div className={'flex-nowrap'}>
                  © {new Date().getFullYear()} {APP_NAME}. {t('all_rights_reserved')}
                </div>
              </Flex>
            </Grid.Col>
            <Grid.Col md={4} order={1} orderMd={2}>
              <Stack align={'flex-start'}>
                {menu.map(({ path, label, target }, index) => (
                  <Anchor
                    key={index}
                    color={'dark'}
                    className={classes.nav}
                    component={Link}
                    to={path}
                    target={target}
                  >
                    {label}
                  </Anchor>
                ))}
              </Stack>
            </Grid.Col>
          </Grid>
        </Container>
      </MantineFooter>
    </>
  )
}
