import React from 'react'
import { OverlayLoading, OverlayLoadingProps } from '../OverlayLoading'
import useStyles from './Style.styles'

// eslint-disable-next-line
interface IProps extends Omit<OverlayLoadingProps, 'visible'> {
  children: React.ReactNode
  isLoading: boolean
  wrapClassName?: any
}

export const OverlayBlockLoading = ({ isLoading, children, wrapClassName, ...props }: IProps) => {
  const { classes, cx } = useStyles()

  return (
    <div className={cx('relative h-full', { [wrapClassName]: wrapClassName })}>
      <OverlayLoading {...props} visible={isLoading} />

      {children}
    </div>
  )
}
