import React, { createContext, useContext } from 'react'

interface ProjectPanel {
  editProps: {
    onEdit: () => void
  }
}

const ProjectPanelContext = createContext<ProjectPanel | null>(null)

interface IProps extends ProjectPanel {
  children: React.ReactNode
}

const ProjectPanelProvider = ({ children, ...props }: IProps) => {
  return (
    <>
      <ProjectPanelContext.Provider value={{ ...props }}>{children}</ProjectPanelContext.Provider>
    </>
  )
}

const useProjectPanelContext = () => useContext(ProjectPanelContext) as ProjectPanel

export { ProjectPanelProvider, useProjectPanelContext }
