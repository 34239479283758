import { createStyles } from '@mantine/core'

export default createStyles((theme, _params) => {
  return {
    constructor: {
      overflow: 'auto',
      backgroundColor: '#e7e9eb',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      flexDirection: 'column',
    },
    floor: {
      backgroundColor: '#c2c2c2',
      width: '100%',
      display: 'flex',
      position: 'relative',
    },
    window: {
      backgroundColor: '#b3e6ff',
      width: '100%',
      height: '100%',
      flex: 1,
    },
    upPertition: {
      backgroundColor: '#b1b1b1',
      width: '100%',
    },
    underPertition: {
      backgroundColor: '#b1b1b1',
      width: '100%',
    },
    windowPertition: {
      backgroundColor: '#b1b1b1',
      height: '100%',
    },
    floorActions: {
      position: 'absolute',
      right: 0,
      top: 0,
    },
    constructorActions: {
      marginBottom: 25,
      display: 'flex',
      alignItems: 'center',
    },
    leftPertition: {
      borderLeft: '1px #b1b1b1 solid',
    },
    rightPertition: {
      borderRight: '1px #b1b1b1 solid',
    },
    upPertitionEmpty: {
      borderTop: '1px #b1b1b1 solid',
    },
    underPertitionEmpty: {
      borderBottom: '1px #b1b1b1 solid',
    },
  }
})
