import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mantine/core'
import { TextInputController } from '@/components/FormElements'
import { WindLoadTextInputController } from '@/features/measure/components/FormElements'

type FormValues = {
  name: string
  wind_load: string
}

export const CommonInfoSection = () => {
  const { t } = useTranslation()

  const { control } = useFormContext<FormValues>()

  return (
    <>
      <Grid>
        <Grid.Col>
          <TextInputController
            control={control}
            name={'name'}
            id={'name'}
            label={t('facade_name')}
            placeholder={t('facade_name')}
            mb={'md'}
          />
        </Grid.Col>
      </Grid>

      <Grid>
        <Grid.Col md={6}>
          <WindLoadTextInputController
            control={control}
            name={'wind_load'}
            id={'wind_load'}
            label={t('wind_load')}
            placeholder={t('wind_load')}
          />
        </Grid.Col>
      </Grid>
    </>
  )
}
