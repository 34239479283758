import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Projects } from '../projects'

export const MyRoot = () => {
  return (
    <Routes>
      <Route index element={<Projects />} />

      <Route path={'*'} element={<Navigate to={'/'} replace={true} />} />
    </Routes>
  )
}
