import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { Container } from '@mantine/core'
import { Icon } from '@/components/Elements'
import { PATH, PATH_HELP_CENTER } from '@/routes/const'
import useStyles from './Component.styles'
import { NavTabs } from './NavTabs'

const TABS_KEY = {
  FAQ: 'faq',
  RESOURCES: 'resources',
  SUPPORT: 'support',
}

const { FAQ, RESOURCES, SUPPORT } = TABS_KEY

export const Tabs = () => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  const navigate = useNavigate()
  const location = useLocation()

  const basePath = `/${PATH.HELP}`

  const TABS_CONFIG = {
    [FAQ]: {
      label: t('faq'),
      value: FAQ,
      path: `${basePath}/${PATH_HELP_CENTER.FAQ}`,
      icon: <Icon name={'question-circle'} size={24} />,
    },
    [RESOURCES]: {
      label: t('resources'),
      value: RESOURCES,
      path: `${basePath}/${PATH_HELP_CENTER.RESOURCES}`,
      icon: <Icon name={'cloud-download'} size={24} />,
    },
    [SUPPORT]: {
      label: t('contact_us'),
      value: SUPPORT,
      path: `${basePath}/${PATH_HELP_CENTER.SUPPORT}`,
      icon: <Icon name={'support-agent'} size={24} />,
    },
  }

  const tabsConfigValues = Object.values(TABS_CONFIG)

  const tabs = useMemo(() => {
    return tabsConfigValues.map(({ label, value, icon }) => ({
      label,
      value,
      icon,
    }))
  }, [])

  const onTabChange = (value: string) => {
    const tabConfig = TABS_CONFIG[value]

    navigate(`${tabConfig?.path}`)
  }

  const tabsValue = useMemo(() => {
    const pathName = location.pathname

    for (let i = 0; i < tabsConfigValues.length; i++) {
      const item = tabsConfigValues[i]

      if (pathName === item.path) {
        return item.value
      }
    }

    return ''
  }, [location])

  return (
    <div className={classes.section}>
      <Container size={'md'} className={classes.container}>
        <NavTabs value={tabsValue} onChange={onTabChange} tabs={tabs} />
      </Container>
    </div>
  )
}
