import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Space, Title } from '@mantine/core'
import { Anchor, GeneralCard } from '@/components/Elements'
import { APP_NAME } from '@/const/core'
import { useFromPath } from '@/hooks'

interface IProps {
  title: string
  children: any
}

export const LegalCard = ({ title, children }: IProps) => {
  const { t } = useTranslation()
  const from = useFromPath()

  return (
    <GeneralCard title={title} titleProps={{ order: 2 }}>
      <div className={'flex'}>
        <Title order={6}>
          {t('back_to')}{' '}
          <Anchor component={Link} to={from}>
            {APP_NAME}
          </Anchor>
        </Title>
      </div>

      {children}
    </GeneralCard>
  )
}
