import { rootApi } from '@/store/api'
import {
  CreateGlazingTemplateDTO,
  GlazingTemplate,
  GlazingTemplateDTO,
  UpdateGlazingTemplateDTO,
} from '@/features/glazing/GlazingTemplate/types'
import { RTKQueryUtils, RequestUtils } from '@/utils'

const { cacher } = RTKQueryUtils

export const GlazingTemplateTag = 'GlazingTemplate'

const enhanceApi = rootApi.enhanceEndpoints({
  addTagTypes: [GlazingTemplateTag],
})

export const glazingTemplatesApi = enhanceApi.injectEndpoints({
  endpoints: (builder) => ({
    glazingTemplateCreate: builder.mutation<
      GlazingTemplateDTO,
      { params: CreateGlazingTemplateDTO }
    >({
      query: ({ params }) => ({
        url: `glazing-templates`,
        method: 'POST',
        body: params,
      }),
      invalidatesTags: cacher.invalidatesList(GlazingTemplateTag),
      transformResponse: (response: any) => {
        return response?.data
      },
    }),
    glazingTemplateData: builder.query<GlazingTemplateDTO, { id: string; params?: any }>({
      query: ({ id, params }) => ({
        url: `glazing-templates/${id}`,
        params: RequestUtils.getMapRequestParams(params),
      }),
      transformResponse: (response: any) => {
        return response?.data
      },
      providesTags: cacher.cacheByIdArgProperty(GlazingTemplateTag),
    }),
    glazingTemplateUpdate: builder.mutation<
      GlazingTemplateDTO,
      { id: string; params: UpdateGlazingTemplateDTO; options?: { invalidatesTagsById: boolean } }
    >({
      query: (arg) => {
        const { id, params } = arg

        return {
          url: `glazing-templates/${id}`,
          method: 'PATCH',
          body: params,
        }
      },
      invalidatesTags: (result, error, arg) => {
        if (result) {
          const facadesList = cacher.invalidatesList(GlazingTemplateTag)()
          const { options } = arg

          const { invalidatesTagsById } = options ?? { invalidatesTagsById: false }

          const facadeData = invalidatesTagsById
            ? cacher.cacheByIdArg(GlazingTemplateTag)(result, error, arg.id)
            : []
          return [...facadesList, ...facadeData]
        }
        return []
      },
      transformResponse: (response: any) => {
        return response?.data
      },
    }),
    glazingTemplateDelete: builder.mutation<
      null,
      {
        id: string
        params?: any
        options?: { invalidatesList: boolean }
      }
    >({
      query: ({ id, params, options }) => {
        return {
          url: `glazing-templates/${id}`,
          method: 'DELETE',
        }
      },
      invalidatesTags: (result, error, arg) => {
        const { options } = arg
        const { invalidatesList } = options ?? { invalidatesList: true }

        const facadeList = invalidatesList ? cacher.invalidatesList(GlazingTemplateTag)() : []
        const facadeData = cacher.cacheByIdArgProperty(GlazingTemplateTag)(result, error, arg)
        return [...facadeList, ...facadeData]
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useGlazingTemplateDataQuery,
  useGlazingTemplateCreateMutation,
  useGlazingTemplateUpdateMutation,
  useGlazingTemplateDeleteMutation,
} = glazingTemplatesApi
