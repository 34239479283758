export const PATH = {
  HOME: 'home',
  LOGIN: 'login',
  REGISTER: 'register',
  EMAIL_VERIFICATION: 'email-verification',
  FORGOT_PASSWORD: 'forgot-password',
  RESET_PASSWORD: 'reset-password',
  OFFERS: 'offers',
  HELP: 'help',
  LEGAL: 'legal',
  DASHBOARD: 'dashboard',
  PROFILE: 'profile',
  BILLING: 'billing',
  SETTINGS: 'settings',
  PROJECTS: 'projects',
}

export const PATH_HELP_CENTER = {
  FAQ: 'faq',
  RESOURCES: 'resources',
  SUPPORT: 'support',
}

export const PATH_LEGAL = {
  TERMS: 'terms',
  LICENCE_AGREEMENT: 'licence-agreement',
  PRIVACY_POLICY: 'privacy-policy',
  IMPRINT_DISCLAIMER: 'imprint-disclaimer',
}

export const PATH_SETTINGS = {
  PROFILE: 'profile',
  SECURITY: 'security',
}

export const PATH_PROJECTS = {
  MY: 'my',
  FACADES: 'facades',
}

export const PATH_BILLING = {
  SUBSCRIPTIONS: 'subscriptions',
}

export const PATH_PROJECTS_FACADES = {
  CREATE: 'create',
  EDIT: 'edit',
}

export enum PATH_PROJECTS_FACADES_EDIT {
  COMMON = 'common',
  PANE = 'pane',
  PANE_GLAZING = 'pane-glazing',
  PRODUCT = 'product',
  GLAZING = 'glazing',
}

export const PATH_PROJECTS_FACADES_EDIT_PANE = {
  EDITOR: 'editor',
}
