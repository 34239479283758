import React, { useCallback, useRef } from 'react'

interface IProps {
  disabled?: boolean
  isLoading: boolean
  hasNext: boolean
  onLoad: () => void
}

export const useInfiniteScroll = ({ disabled, isLoading, hasNext, onLoad }: IProps) => {
  const intObserver: any = useRef()

  const lastRef = useCallback(
    (entry: any) => {
      if (isLoading || disabled) return

      if (intObserver.current) intObserver.current.disconnect()

      intObserver.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNext) {
          onLoad()
        }
      })

      if (entry) intObserver.current.observe(entry)
    },
    [isLoading, hasNext, onLoad]
  )

  return {
    lastRef,
  }
}
