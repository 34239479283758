import * as Yup from 'yup'
import { MEASURE_WIND_LOAD } from '@/features/measure/consts/validation'

export const validationSchema = Yup.object({
  name: Yup.string().required().min(2),

  wind_load: Yup.number()
    .min(MEASURE_WIND_LOAD.min)
    .max(MEASURE_WIND_LOAD.max)
    .nullable()
    .transform((v, o) => (o === '' ? null : v))
    .required(),
})
