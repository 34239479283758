import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Card, Text, Title } from '@mantine/core'
import { useBilling, useTariffStaticOptions } from '@/features/billing/hooks'
import { Tariff } from '@/features/billing/types'
import useStyles from './GeneralCard.styles'

interface IProps {
  data: Tariff
  actionSections?: React.ReactNode
}

export const GeneralCard = ({ data, actionSections }: IProps) => {
  const { t } = useTranslation()

  const { classes, cx } = useStyles()

  const { getCurrency } = useBilling()
  const { isAnnual, isMonthly } = useTariffStaticOptions()

  const { period, name, price } = data

  const currencySymbol = getCurrency()?.symbol
  const periodLabel = isAnnual(period) ? t('year') : isMonthly(period) ? t('month') : null

  return (
    <>
      <Card className={cx(classes.card, {})}>
        <div className={classes.header}>
          <Box>
            <Title className={classes.name} order={5}>
              {name}
            </Title>
          </Box>

          <Box>
            <Title order={1}>
              {price || 0} {currencySymbol}{' '}
              {periodLabel && (
                <Text component={'span'} transform={'lowercase'} fz="sm" fw={400} c="dimmed">
                  {`/`}
                  {periodLabel}
                </Text>
              )}
            </Title>
          </Box>

          {/*{description && (*/}
          {/*  <Box mt={'md'} className={classes.descriptionBlock}>*/}
          {/*    <Text>{description}</Text>*/}
          {/*  </Box>*/}
          {/*)}*/}
        </div>

        {actionSections && <Box mt={'lg'}>{actionSections}</Box>}

        {/*<Box mt={32}>*/}
        {/*  <Text fz={'sm'} fw={700} className={classes.listTitle} mb={'md'}>*/}
        {/*    {t('what_included')}*/}
        {/*  </Text>*/}

        {/*  <List className={classes.list} spacing={'xl'}>*/}
        {/*    {includes.map(({ value, active }, index) => (*/}
        {/*      <List.Item*/}
        {/*        key={index}*/}
        {/*        icon={*/}
        {/*          <ThemeIcon color={active ? 'green' : 'gray'} size={24} radius="xl">*/}
        {/*            <Icon name={active ? 'check' : 'x'} />*/}
        {/*          </ThemeIcon>*/}
        {/*        }*/}
        {/*      >*/}
        {/*        <Text fw={600}>{value}</Text>*/}
        {/*      </List.Item>*/}
        {/*    ))}*/}
        {/*  </List>*/}
        {/*</Box>*/}
      </Card>
    </>
  )
}
