import i18n from '@/i18n'

type OptionType = {
  label: string
  lineType: 'solid' | 'dotted' | 'dashed-dotted'
}

export const getFeatures = (): OptionType[] => {
  return [
    {
      label: i18n.t('fall_protection.feature.coarse-refractive-glass'),
      lineType: 'solid',
    },
    {
      label: i18n.t('fall_protection.feature.safety-glass'),
      lineType: 'dotted',
    },
    {
      label: i18n.t('fall_protection.feature.laminated-safety-glass'),
      lineType: 'dashed-dotted',
    },
  ]
}
