import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import stateReducer from './state/slice'

const myProjectsStatePersistConfig = {
  key: 'my_projects_state',
  storage,
  whitelist: ['entriesViewMode'],
}

const myReducer = combineReducers({
  state: persistReducer(myProjectsStatePersistConfig, stateReducer),
})

export default myReducer
