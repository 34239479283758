import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { SegmentedControl } from '@/components/FormElements'
import { BILLING_TARIFF_PERIOD } from '@/features/billing/consts/tariff'
import { useTariffStaticOptions } from '@/features/billing/hooks'
import useStyles from './TariffsPeriodSwitch.styles'

interface IProps {
  value: BILLING_TARIFF_PERIOD | string
  onChange: (value: string) => void
}

export const TariffsPeriodSwitch = ({ value, onChange }: IProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const { getPeriods } = useTariffStaticOptions()

  const periods = useMemo(() => {
    return getPeriods().reverse()
  }, [])

  return (
    <>
      <SegmentedControl
        data={periods}
        value={value}
        onChange={onChange}
        meta={{}}
        radius={'xl'}
        classNames={classes}
      />
    </>
  )
}
