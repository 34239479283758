export const projectFacadeDataQueryArgsDefaultParams = {
  include: [
    'facadeMaterials',
    'facadeGlasses',
    'facadeManufacturer',
    'facadeSystem',
    'facadeSystem.picture',
    'glazingTemplates',
    'facadePanels',
    'picture',
  ],
}
