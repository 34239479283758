export const EN = {
  cancel: 'Cancel',
  canceled: 'Canceled',
  expiry_date: 'Expiry date',
  logout: 'Logout',
  license_option: 'License option',
  subscription_plan: 'Subscription plan',
  change_subscription_plan: 'Change subscription plan',
  subscribed_canceled: 'Subscription canceled',
  subscription_plan_changed: 'Subscription plan changed',
  subscription_plans: 'Subscription plans',
  subscribe: 'Subscribe',
  billing: 'Billing',
  invoices: 'Invoices',
  billing_information: 'Billing information',
  billing_account: 'Billing account',
  billed_period: 'Billed period',
  'fall_protection.p2.description': 'Fall protection with laminated safety glass on the outside',
  'fall_protection.p1.description': 'Fall protection with laminated safety glass on the room side',
  'fall_protection.p4.description':
    'Fall protection with laminated safety glass on the room side or with additional component for movable glazing (for example laminated safety glass railing or bar)',
  'fall_protection.p3.description':
    'Fall protection with additional component or railing (French balcony)',
  address: 'Address',
  terms_and_conditions: 'Terms and conditions',
  active: 'Active',
  refresh: 'Refresh',
  current_plan: 'Current plan',
  current_period_ends_at: 'Current period ends at',
  current_password: 'Current password',
  accept: 'Accept',
  all_rights_reserved: 'All rights reserved',
  clear_all: 'Clear all',
  general: 'General',
  legend: 'Legend',
  type: 'Type',
  common_information: 'Common information',
  general_info: 'General info',
  request_sent_successfully: 'Request sent successfully',
  send_request: 'Send request',
  'attached-file': 'Attached file',
  attachment: 'Attachment',
  attachments: 'Attachments',
  sign_up: 'Sign Up',
  registration: 'Registration',
  prefix: 'Prefix',
  apply: 'Apply',
  resources: 'Resources',
  architect: 'Architect',
  type_of_request: 'Type of request',
  chosen_glass: 'Chosen glass',
  expand: 'Expand',
  pending: 'Pending',
  edit: 'Edit',
  show_constructor_actions: 'Show constructor actions',
  note: 'Note',
  user: 'User',
  view_profile: 'View profile',
  account_deleted_successfully: 'Account deleted successfully',
  create_an_account: 'Create an account',
  delete_account: 'Delete account',
  description: 'Description',
  confirm: 'Confirm',
  confirmation: 'Confirmation',
  send_confirmation_email_to: 'Send a confirmation email to',
  subscribed_successfully: 'Subscribed successfully',
  amount: 'Amount',
  subject: 'Subject',
  pay: 'Pay',
  'feedback.hint.text':
    'Please leave your issue and contact details and the administrator will contact you',
  please_confirm: 'Please confirm',
  please_fill_following_fields: 'Please fill the following fields',
  'projects_facades.empty_content.title-2': 'Please, add the first facade',
  building_width: 'Building width',
  under_pertition_height: 'Under pertition height (mm)',
  cvc: 'CVC',
  subscribed_resumed: 'Subscription resumed',
  'field.error.greater_or_equal': 'The field must be greater than or equal to {{value}}',
  'field.error.less_or_equal': 'The field must be less than or equal to {{value}}',
  'field.error.array.min.length': 'The field must contain at least {{value}} value',
  'field.error.company_uid.rule':
    'The field must starts with 2-4 letters and ends with 8-12 numbers',
  'field.error.password.npt_strong': 'The password not strong enough',
  'field.error.password.length': 'The password must be at least {{count}} characters',
  'field.error.password.no_match': 'Passwords do not match',
  'reset_password.success': 'Your password has been reset!',
  'attach-files': 'Attach files',
  confidential_info_settings: 'Confidential info settings',
  privacy_policy: 'Privacy Policy',
  date: 'Date',
  deactivation: 'Deactivation',
  deflection: 'Deflection',
  your_cards: 'Your cards',
  details: 'Details',
  view_details: 'View details',
  'confirmation-email-sent': 'A confirmation email has been sent to your email',
  'field.error.file-size.max': 'The file must not be greater than {{text}}',
  'file.max_size.error': 'The file must not be greater than {{text}}',
  'field.error.date.after-or-equal': 'The value must be a date after or equal to {{date}}.',
  'field.error.min.length': 'The field must be at least {{count}} characters',
  'field.error.date.after': 'The value must be a date after {{date}}.',
  'field.error.time.after': 'The value must be a time after {{date}}.',
  'field.error.digits_between': 'The field must be between {{min}} and {{max}} digits',
  payment_was_successful: 'The payment was successful',
  'billing.pay.subscription.success':
    'The payment was successful. Subscription will be issued shortly',
  skill_already_exist: 'Skill already exist',
  documentation: 'Documentation',
  you: 'You',
  'projects_facades.empty_content.title-1': 'You don’t have any facades here',
  dark: 'Dark',
  editor: 'Editor',
  'message.error-fallback': 'Error occurred',
  'fall_protection.p7.description': 'Installation situation with high glazing',
  burglaryType: 'Burglary Type',
  burglary_protection_RC2: 'Burglary protection (RC2)',
  collapse: 'Collapse',
  single_pane_safety_glass: 'Single Pane Safety Glass',
  settings: 'Settings',
  profile_settings: 'Profile settings',
  window_settings: 'Window settings',
  floor_settings: 'Floor settings',
  window_updated: 'Window successfully updated',
  window_deleted: 'Window successfully deleted',
  window_width: 'Window width (mm)',
  floor_height: 'Floor height (mm)',
  window_typeId: 'Window type',
  email: 'Email',
  email_verification: 'Email verification',
  'forgot-password.hint': 'Enter your email for a password reset link.',
  end_date: 'End date',
  ends_at: 'Ends at',
  personal_data_settings: 'Personal data settings',
  success: 'Success',
  removed_successfully: 'Removed successfully',
  deleted_successfully: 'Deleted successfully',
  send_successfully: 'Send successfully',
  saved_successfully: 'Saved successfully',
  sent_successfully: 'Sent successfully',
  'projects.empty_content.title-2':
    'Facade//Lab is your tool to create, manage and print architectural designs.',
  faq: 'FAQ',
  facade: 'Facade',
  edit_facade: 'Edit facade',
  'edit_facade.common_info.title': 'Edit facade: Common information',
  'edit_facade.glazing_browser.title': 'Edit facade: Glazing browser',
  'edit_facade.application_pane_editor.title': 'Edit facade: Application pane editor',
  'edit_facade.application_pane.title': 'Edit facade: Application pane',
  'edit_facade.product_browser.title': 'Edit facade: Product browser',
  update_facade_success: 'Facade successfully updated',
  create_facade: 'Create facade',
  add_facade: 'Add facade',
  'projects_facades.select_facade.title-1': 'Select a facade',
  facades: 'Facades',
  facade_name: 'Facade name',
  delete_window: 'Delete window',
  feedback: 'Feedback',
  error: 'Error',
  payment_error: 'Payment error',
  company_address: 'Company address',
  company_info: 'Company info',
  float_glass: 'Float Glass',
  upload_photo: 'Upload photo',
  delete_photo: 'Delete photo',
  question: 'Question',
  female: 'Female',
  mrs: 'Mrs',
  frequently_asked_questions: 'Frequently Asked Questions',
  function: 'Function',
  feature_request: 'Feature request',
  to: 'To',
  'registration.title': 'Facade//Lab Registration',
  modified: 'Modified',
  modified_on: 'Modified on',
  building_address: 'Building address',
  enter_building_data: 'Enter building data',
  tare_weight: 'Tare weight',
  choose_glass: 'Choose glass',
  choose_glasses: 'Choose Glasses',
  glazing_browser: 'Glazing browser',
  free: 'Free',
  'fall_protection.feature.coarse-refractive-glass':
    'Coarse refractive glass (float glass, cast glass, partially tempered glass)',
  largest_support_force: 'Largest support force',
  biggest_momentum: 'Biggest momentum',
  action: 'Action',
  light: 'Light',
  help_center: 'Help center',
  mr: 'Mr',
  manufacturer: 'Manufacturer',
  download: 'Download',
  today: 'Today',
  add: 'Add',
  building_height: 'Building height',
  iban: 'IBAN',
  already_have_account: 'Already have an account',
  register_terms_label: 'I have read and accept the',
  dont_have_account_yet: 'Don’t have an account yet',
  'imprint_and_disclaimer.title': 'Imprint & Disclaimer',
  inactive: 'Inactive',
  information: 'Information',
  yes: 'Yes',
  year: 'Year',
  annually: 'Annually',
  start_now: 'Start now',
  card: 'Card',
  card_number: 'Card number',
  no_glasses_found_matching_conditions: 'No glasses found matching the conditions',
  no_options: 'No options',
  no_data: 'No data',
  no_info: 'No info',
  no_messages: 'No messages',
  no_data_found_matching_conditions: 'No data found matching the conditions',
  no_systems_found_matching_conditions: 'No systems found matching the conditions',
  constructor: 'Constructor',
  application_pane: 'Application pane',
  contact_us: 'Contact us',
  country: 'Country',
  building_length: 'Building length',
  empty: 'Empty',
  latest_invoices: 'Latest invoices',
  get_license: 'Get license',
  licence_agreement: 'Licence Agreement',
  login: 'Login',
  upload_logo: 'Upload logo',
  delete_logo: 'Delete logo',
  delete: 'Delete',
  male: 'Male',
  material: 'Material',
  materials: 'Materials',
  material_type: 'Material type',
  glazCountTypes: 'Generate glazzing count types',
  load_more: 'Load more',
  my_projects: 'My projects',
  quantity: 'Quantity',
  remember_it: 'Remember it',
  'min-characters.count': 'Min. {{count}} characters',
  mm: 'mm',
  month: 'Month',
  monthly: 'Monthly',
  vat: 'VAT',
  last_name: 'Last name',
  message: 'Message',
  write_a_message: 'Write a message',
  send_message: 'Send message',
  name: 'Name',
  name_and_surname: 'Name and Surname',
  no: 'No',
  add_new_facade: 'Add new facade',
  new_card: 'New card',
  new_message: 'New message',
  new_sepa: 'New SEPA',
  window_added: 'New window successfully added',
  add_new_window: 'Add new window',
  new_password: 'New password',
  'reset-password.hint': 'Enter a new password',
  new_project: 'New project',
  floor_added: 'New floor successfully added',
  add_new_floor: 'Add new floor',
  set_new_password: 'Set new password',
  not_filled: 'Not filled',
  unpaid: 'Unpaid',
  unverified: 'Unverified',
  incomplete: 'Incomplete',
  'projects.empty_content.title-1': 'You don’t have any projects yet',
  'number-no_abbreviation': 'No.',
  number: 'Number',
  register_terms_text: 'terms of service and conditions',
  or: 'or',
  'projects_facades.select_facade.title-2': 'Or add a new facade',
  open: 'Open',
  choose_option: 'Choose option',
  city: 'City',
  location: 'Location',
  panels: 'Panels',
  choose_panels: 'Choose panels',
  add_panels: 'Add panels',
  password: 'Password',
  change_password: 'Change password',
  confirm_new_password: 'Confirm new password',
  confirm_password: 'Confirm password',
  password_changed_successfully: 'Password changed successfully',
  forgot_password: 'Forgot password',
  reset_password: 'Reset password',
  paused: 'Paused',
  'fall_protection.p6.description':
    'Personal protection without a fall situation with floor-to-ceiling glazing',
  'fall_protection.p5.description': 'Personal protection without fall situation with deep parapet',
  personal_data: 'Personal data',
  individual_info: 'Individual info',
  required: 'Required',
  'field.error.required': 'This field is required',
  zip: 'ZIP',
  price: 'Price',
  pricing: 'Pricing',
  profile: 'Profile',
  system: 'System',
  edit_profile: 'Edit profile',
  chosen_system: 'Chosen system',
  product_browser: 'Product browser',
  project: 'Project',
  edit_project: 'Edit project',
  create_a_project: 'Create a project',
  projects: 'Projects',
  project_info: 'Project info',
  project_name: 'Project name',
  invoice: 'Invoice',
  billed_to: 'Billed to',
  reference_number: 'Reference number',
  register: 'Register',
  step: 'Step',
  'fall_protection.p1': 'Protection 1',
  'fall_protection.p2': 'Protection 2',
  'fall_protection.p3': 'Protection 3',
  'fall_protection.p4': 'Protection 4',
  'fall_protection.p5': 'Protection 5',
  'fall_protection.p6': 'Protection 6',
  'fall_protection.p7': 'Protection 7',
  'fall_protection.category.falls': 'Protection against falls',
  protection_against_falls: 'Protection against falls',
  'fall_protection.category.cuts': 'Protection against cuts',
  protection_against_cuts: 'Protection against cuts',
  protectionType: 'Protection Type',
  send_reset_link: 'Send reset link',
  send: 'Send',
  sepa: 'SEPA',
  'fall_protection.feature.safety-glass':
    'Safety glass (Single-pane safety glass, laminated safety glass)',
  save: 'Save',
  default: 'Default',
  start_date: 'Start date',
  home: 'Home',
  status: 'Status',
  subscription_status: 'Subscription status',
  wind_speed_zone: 'Wind speed zone',
  floor_updated: 'Floor successfully updated',
  floor_deleted: 'Floor successfully deleted',
  delete_floor: 'Delete floor',
  street: 'Street',
  street_no: 'Street no',
  up_pertition_height: 'Up pertition height (mm)',
  search: 'Search',
  support: 'Support',
  partially_tempered_glass: 'Partially Tempered Glass',
  subtotal: 'Subtotal',
  phone: 'Phone',
  phone_number: 'Phone number',
  trialing: 'Trialing',
  on_grace_period: 'On grace period',
  get_for_test: 'Get for test',
  tiefe: 'Depth',
  total: 'Total',
  isWindow: 'Is Window',
  about_us: 'About us',
  past_due: 'Past due',
  dashboard: 'Dashboard',
  uid: 'UID',
  vat_code: 'VAT code',
  vat_id: 'VAT ID',
  vat_id_unverified: 'VAT ID unverified',
  vat_id_verified: 'VAT ID verified',
  'billing.incomplete_payment.message':
    'To activate the subscription, you must complete the payment',
  unread_messages: 'Unread messages',
  'field.error.invalid': 'This field is invalid',
  our_offers: 'Our offers',
  our_products: 'Our products',
  company: 'Company',
  incomplete_expired: 'Incomplete expired',
  update: 'Update',
  variation: 'Variation',
  'fall_protection.feature.laminated-safety-glass': 'Laminated safety glass',
  laminated_safety_glass: 'Laminated Safety Glass',
  pane_glazing: 'Pane glazing',
  glazing: 'Glazing',
  view_glazing: 'View glazing',
  selected_glazing: 'Selected glazing',
  use_auto_glazing: 'Use auto glazing',
  edit_glazing: 'Edit glazing',
  add_glazing: 'Add glazing',
  add_new_glazing: 'Add new glazing',
  create_glazing: 'Create glazing',
  verified: 'Verified',
  'email_verification.success': 'Verification passed successfully',
  confidential_info: 'Confidential info',
  from: 'From',
  templates: 'Templates',
  first_name: 'First name',
  choose_license_option: 'Choose a license option',
  left_pertition_width: 'Left pertition (mm)',
  right_pertition_width: 'Right pertition (mm)',
  what_included: 'What’s included',
  next: 'Next',
  'billing_info.editable-alert':
    'Important! You cannot edit "Country", "VAT ID" after subscribing.',
  'help_center.subtitle': 'How can we help you?',
  resume: 'Resume',
  welcome: 'Welcome',
  welcome_back: 'Welcome back',
  wind_load: 'Wind load',
  'forgot_password.success': 'We have emailed your password reset link!',
  incomplete_payment: 'Incomplete payment',
  complete_payment: 'Complete payment',
  payment_information: 'Payment information',
  payment_method: 'Payment method',
  add_payment_method: 'Add payment method',
  payment_methods: 'Payment methods',
  payment_system: 'Payment system',
  payment: 'Payment',
  go_to_login_page: 'Go to login page',
  back: 'Back',
  back_to: 'Back to',
  back_to_login_page: 'Back to login page',
  reset: 'Reset',
  summary: 'Summary',
  'types_of_glass.construction_from_the_inside_to_the_outside':
    'Types of glass - construction from the inside to the outside',
}
