import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Checkbox as MantineCheckbox,
  CheckboxGroupProps as MantineCheckboxGroupProps,
} from '@mantine/core'
import { FormUtils } from '@/utils'

type Option = {
  label?: React.ReactNode
  value: string
  disabled?: boolean
}

export interface CheckboxGroupProps extends Omit<MantineCheckboxGroupProps, 'children'> {
  options: Option[]
  fieldRef?: any
  translateParams?: object
  meta?: any
  shellComponent?: any
  shellComponentProps?: any
}

export const CheckboxGroup = ({
  options,
  meta = {},
  fieldRef,
  translateParams,
  shellComponent,
  shellComponentProps,
  ...props
}: CheckboxGroupProps) => {
  const { t } = useTranslation()

  const { error } = meta

  const showError = Boolean(error)

  const getErrorMessage = () => {
    return FormUtils.getErrorMessage(error, {
      t,
      translateParams: translateParams,
    })
  }

  const Shell = shellComponent || Box

  return (
    <MantineCheckbox.Group {...props} error={showError ? getErrorMessage() : null}>
      <Shell {...shellComponentProps}>
        {options.map(({ label, value, disabled = false }) => (
          <MantineCheckbox key={value} value={value} label={label} disabled={disabled} />
        ))}
      </Shell>
    </MantineCheckbox.Group>
  )
}
