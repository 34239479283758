import { PROJECT_FACADE_SECTION_ALIAS } from '@/features/project-facade/consts/project-facade'
import i18n from '@/i18n'
import { PATH_PROJECTS_FACADES_EDIT as PATH } from '@/routes/const'

type OptionType = {
  label: string
  alias: PROJECT_FACADE_SECTION_ALIAS
  order: number
  path: PATH
}

const { COMMON, PANE, PRODUCT, GLAZING, PANE_GLAZING } = PROJECT_FACADE_SECTION_ALIAS

export const getSections = (): OptionType[] => {
  return [
    {
      label: i18n.t('common_information'),
      alias: COMMON,
      order: 0,
      path: PATH.COMMON,
    },
    {
      label: i18n.t('application_pane'),
      alias: PANE,
      order: 1,
      path: PATH.PANE,
    },
    {
      label: i18n.t('pane_glazing'),
      alias: PANE_GLAZING,
      order: 2,
      path: PATH.PANE_GLAZING,
    },
    {
      label: i18n.t('product_browser'),
      alias: PRODUCT,
      order: 3,
      path: PATH.PRODUCT,
    },
    // {
    //   label: i18n.t('glazing_browser'),
    //   alias: GLAZING,
    //   order: 4,
    //   path: PATH.GLAZING,
    // },
  ]
}

export const sectionsMap = getSections().reduce((acc: any, curr) => {
  const { alias } = curr

  return acc.set(alias, curr)
}, new Map())

export const getAdjacentSections = (alias: PROJECT_FACADE_SECTION_ALIAS) => {
  const sections = getSections()
  const index = sections.findIndex((item) => item.alias === alias)

  const prev = index > 0 ? sections[index - 1] : null
  const next = index < sections.length - 1 ? sections[index + 1] : null
  const current = sections[index] || null

  return {
    prev,
    next,
    current,
  }
}
