import React from 'react'
import { HelpCenterRoot } from '@/features/help-center/routes'
import { PATH } from '@/routes/const'

type RouteType = {
  path?: string
  element: React.ReactNode
}

const helpRoutes: RouteType[] = [
  {
    path: `${PATH.HELP}/*`,
    element: <HelpCenterRoot />,
  },
]

export const getHelpRoutes = () => {
  return helpRoutes
}
