import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mantine/core'
import { TextInputController } from '@/components/FormElements'

type FormValues = {
  street: string
  street_number: string
  zip: string
  city: string
}

export const LocationFormSection = () => {
  const { t } = useTranslation()

  const { control } = useFormContext<FormValues>()

  return (
    <>
      <Grid>
        <Grid.Col md={6}>
          <TextInputController
            control={control}
            name={'street'}
            id={'street'}
            label={t('street')}
            placeholder={t('street')}
            mb={'md'}
          />
        </Grid.Col>

        <Grid.Col md={6}>
          <TextInputController
            control={control}
            name={'street_number'}
            id={'street_number'}
            label={t('street_no')}
            placeholder={t('street_no')}
            mb={'md'}
          />
        </Grid.Col>

        <Grid.Col md={6}>
          <TextInputController
            control={control}
            name={'zip'}
            id={'zip'}
            label={t('zip')}
            placeholder={t('zip')}
            mb={'md'}
          />
        </Grid.Col>

        <Grid.Col md={6}>
          <TextInputController
            control={control}
            name={'city'}
            id={'city'}
            label={t('city')}
            placeholder={t('city')}
            mb={'md'}
          />
        </Grid.Col>
      </Grid>
    </>
  )
}
