import React from 'react'
import { useTranslation } from 'react-i18next'
import { Image, Tooltip } from '@mantine/core'
import { FacadeSystem } from '@/features/facade/FacadeSystem/types'
import useStyles from './MediaBlock.styles'
import useTooltipStyles from './Tooltip.styles'

interface IProps {
  data: FacadeSystem
}

export const MediaBlock = ({ data }: IProps) => {
  const { t } = useTranslation()

  const { picture } = data

  const { classes } = useStyles()
  const { classes: tooltipClasses } = useTooltipStyles()

  const renderImage = () => (
    <Image
      alt={'pane'}
      src={picture?.data?.thumb}
      classNames={classes}
      withPlaceholder
      fit={'contain'}
    />
  )

  return (
    <>
      {picture?.data?.full ? (
        <Tooltip.Floating
          label={<Image alt={'pane'} src={picture?.data?.full} withPlaceholder />}
          color={'white'}
          classNames={tooltipClasses}
        >
          {renderImage()}
        </Tooltip.Floating>
      ) : (
        renderImage()
      )}
    </>
  )
}
