import {
  MEASURE_UNIT_FACADE_DEFLECTION as DEFLECTION,
  MEASURE_UNIT_FACADE_MOMENTUM as MOMENTUM,
} from '@/features/measure/consts/unit'
import i18n from '@/i18n'

type MomentumOptionType = {
  label: string
  value: MOMENTUM
}

const localeData: any = {
  en: {
    [MOMENTUM.knm]: 'kNm',
  },
  de: {
    [MOMENTUM.knm]: 'kNm',
  },
}

const locale = (code: string) => {
  return localeData[code]
}

export const getFacadeMomentumUnits = (): MomentumOptionType[] => {
  const code = i18n.language

  return [
    {
      label: locale(code)[MOMENTUM.knm],
      value: MOMENTUM.knm,
    },
  ]
}

export const facadeMomentumUnitsMap = getFacadeMomentumUnits().reduce((acc: any, curr) => {
  const { value } = curr

  return acc.set(value, curr)
}, new Map())

type DeflectionOptionType = {
  label: string
  value: DEFLECTION
}

const deflectionLocaleData: any = {
  en: {
    [DEFLECTION.mm]: 'mm',
  },
  de: {
    [DEFLECTION.mm]: 'mm',
  },
}

const deflectionLocale = (code: string) => {
  return deflectionLocaleData[code]
}

export const getFacadeDeflectionUnits = (): DeflectionOptionType[] => {
  const code = i18n.language

  return [
    {
      label: deflectionLocale(code)[DEFLECTION.mm],
      value: DEFLECTION.mm,
    },
  ]
}

export const facadeDeflectionUnitsMap = getFacadeDeflectionUnits().reduce((acc: any, curr) => {
  const { value } = curr

  return acc.set(value, curr)
}, new Map())
