import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { ProjectFacadesContainer } from '@/features/project-facade/components/ProjectFacades'

export const Facades = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'facades'} />

      <ProjectFacadesContainer />
    </>
  )
}
