import { createStyles } from '@mantine/core'
import { HEADER_HEIGHT } from '@/const/layout'

export default createStyles((theme, _params) => {
  return {
    wrap: {},

    body: {
      minHeight: `calc(100vh - ${HEADER_HEIGHT}px)`,
      flex: 1,
      display: 'flex',
    },

    main: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      minWidth: 0,
    },
  }
})
