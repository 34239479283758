import React from 'react'
// import { useAppWindowFocus } from '@/features/app/hooks'
import { RootNavigator } from '@/routes'

function App() {
  // fixme: issue with rtk-query
  // useAppWindowFocus()

  return <RootNavigator />
}

export default App
