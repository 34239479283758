import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Divider, SimpleGrid, Text } from '@mantine/core'
import { Anchor } from '@/components/Elements'
import {
  useSubscriptionData,
  useSubscriptionStaticOptions,
  useTariffStaticOptions,
} from '@/features/billing/hooks'
import { DateUtils } from '@/utils'

export const SubscriptionOverview = () => {
  const { t } = useTranslation()

  const { subscription } = useSubscriptionData()

  const { getPeriod, isFree } = useTariffStaticOptions()

  const { getStatus } = useSubscriptionStaticOptions()

  const items = [
    {
      label: t('subscription_plan'),
      value: subscription.tariff?.name,
    },
    {
      label: t('subscription_status'),
      value: subscription.subscription_status,
      hidden: !subscription.subscription_status,
    },
    {
      label: t('billed_period'),
      value: subscription && getPeriod(subscription.tariff.period)?.label,
      hidden: !(subscription && !isFree(subscription?.tariff.alias)),
    },
    {
      label: t('current_period_ends_at'),
      value: subscription && DateUtils.formatDate(subscription.current_period_ends_at),
      hidden: !(subscription && subscription.current_period_ends_at),
    },
    {
      label: t('billing_account'),
      value: subscription && (
        <Anchor component={'a'} href={subscription.billing_url} target={'_blank'}>
          {t('open')}
        </Anchor>
      ),
      hidden: !(subscription && subscription.billing_url),
    },
  ]

  return (
    <SimpleGrid verticalSpacing={'xl'}>
      {items
        .filter((item: any) => !item.hidden)
        .map(({ label, value }, index) => (
          <div key={index}>
            <Box pb={'sm'} className={'flex grow gap-4 flex-wrap'}>
              <div className={'flex grow flex-col justify-center'}>
                <Text fw={700}>{label}</Text>
                <Text fz={'sm'}>{value}</Text>
              </div>
            </Box>

            <Divider />
          </div>
        ))}
    </SimpleGrid>
  )
}
