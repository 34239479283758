import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActionIcon, Menu, Text } from '@mantine/core'
import { Icon } from '@/components/Elements'

interface IProps {
  config?: {
    edit?: boolean
  }
  menuProps?: any
  editProps?: any
}

const defaultConfig = {
  edit: true,
}

export const ActionMenu = ({ config, menuProps, editProps }: IProps) => {
  const { t } = useTranslation()

  config = { ...defaultConfig, ...config }

  return (
    <Menu width={150} withinPortal {...menuProps}>
      <Menu.Target>
        <ActionIcon color={'gray.9'} variant={'subtle'}>
          <Icon name={'dots'} />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>
        {config?.edit && (
          <Menu.Item icon={<Icon name={'pencil'} />} {...editProps}>
            <Text>{t('edit')}</Text>
          </Menu.Item>
        )}
      </Menu.Dropdown>
    </Menu>
  )
}
