import { useEffect, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CiSettings } from 'react-icons/ci'
import { Box, Grid, Group, Modal, ScrollArea } from '@mantine/core'
import { Button } from '@/components/Elements'
import {
  CheckboxFieldController,
  SelectController,
  TextInputController,
} from '@/components/FormElements'
import {
  FallProtectionCard,
  Footer,
} from '@/features/facade/FallProtection/components/FallProtections/Cards'
import { useFallProtectionStaticOptions } from '@/features/facade/FallProtection/hooks'
import { Button as ButtonCard } from './Button'
import useStyles from './Card.styles'

export const WindowSettingsModal = ({
  opened,
  open,
  close,
  handleDeleteWindow,
  data,
  submit,
}: any) => {
  const { t } = useTranslation()

  const { getEntries } = useFallProtectionStaticOptions()
  const [protectionType, setProtectionType] = useState<any>(null)

  const { classes, cx } = useStyles()

  const methods = useForm({
    defaultValues: {
      width: `${data?.width || ''}`,
      leftPertitionWidth: `${data?.leftPertition?.width || 0}`,
      rightPertitionWidth: `${data?.rightPertition?.width || 0}`,
      protectionType: data.protectionType || null,
      burglaryType: data.burglaryType || null,
      isWindow: data.isWindow || null,
    },
  })

  const { control, handleSubmit } = methods

  const onSubmit = (data: any) => {
    submit({
      ...data,
      protectionType: protectionType?.alias || null,
    })
  }

  const handleProtectionType = (item: any) => {
    if (protectionType && item?.alias === protectionType?.alias) {
      setProtectionType(null)
      return
    }
    setProtectionType(item)
  }

  useEffect(() => {
    if (data?.protectionType) setProtectionType({ alias: data.protectionType })
  }, [data])

  return (
    <>
      <Modal opened={opened} onClose={close} title={t('window_settings')}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box mt={'lg'}>
              <Group spacing={'xs'} noWrap>
                <TextInputController
                  control={control}
                  name={'width'}
                  id={'width'}
                  label={t('window_width')}
                  placeholder={t('window_width')}
                  mb={'md'}
                />
                <TextInputController
                  control={control}
                  name={'leftPertitionWidth'}
                  id={'leftPertitionWidth'}
                  label={t('left_pertition_width')}
                  placeholder={t('left_pertition_width')}
                  mb={'md'}
                />
                <TextInputController
                  control={control}
                  name={'rightPertitionWidth'}
                  id={'rightPertitionWidth'}
                  label={t('right_pertition_width')}
                  placeholder={t('right_pertition_width')}
                  mb={'md'}
                />
              </Group>
              <Group spacing={'xs'} noWrap>
                <CheckboxFieldController
                  control={control}
                  name={'isWindow'}
                  id={'isWindow'}
                  mb={'md'}
                  label={t('isWindow')}
                />
                <CheckboxFieldController
                  control={control}
                  name={'burglaryType'}
                  id={'burglaryType'}
                  mb={'md'}
                  label={t('burglaryType')}
                />
              </Group>

              {getEntries().length ? (
                <Grid>
                  {getEntries().map((item, index) => (
                    <Grid.Col key={index} xs={6} sm={6} lg={4} xl={4}>
                      <FallProtectionCard
                        data={item}
                        component={ButtonCard}
                        footer={<Footer checked={protectionType?.alias === item?.alias} />}
                        classNames={{
                          root: cx(classes.root, {
                            [`${classes.active}`]: protectionType?.alias === item?.alias,
                          }),
                        }}
                        componentProps={{
                          onClick: () => handleProtectionType(item),
                        }}
                      />
                    </Grid.Col>
                  ))}
                </Grid>
              ) : (
                t('no_options')
              )}
              <Box mt={'lg'} mb={'lg'}>
                <Group spacing={'xs'} noWrap>
                  <Button type={'button'} onClick={handleDeleteWindow}>
                    {t('delete_window')}
                  </Button>
                  <Button type={'submit'}>{t('update')}</Button>
                </Group>
              </Box>
            </Box>
          </form>
        </FormProvider>
      </Modal>

      <Group position="left">
        <CiSettings onClick={open} size={24} />
      </Group>
    </>
  )
}
