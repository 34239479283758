import 'dayjs/locale/de'
import moment from 'moment'
import 'moment/locale/de'
import { langHelper } from '@/helpers'

const DEFAULT_LANGUAGE_CODE = process.env.REACT_APP_DEFAULT_LANGUAGE_CODE

class DateService {
  constructor() {}

  setLocale = (code: string) => {
    let locale = DEFAULT_LANGUAGE_CODE
    const lang = langHelper.general.getLang(code)
    if (lang) {
      locale = lang?.code
    }
    moment.locale(locale)
  }
}

export const DATE = new DateService()
