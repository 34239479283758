import React from 'react'
import { useTariffStaticOptions } from '@/features/billing/hooks'
import { Tariff } from '@/features/billing/types'
import { useUser } from '@/features/user/hooks'

export const useUserSubscription = () => {
  const { user } = useUser()

  const { isFree } = useTariffStaticOptions()

  const tariff = user?.tariff

  const isSubscriptionTariffAllow = (target: Tariff) => {
    if (!tariff) {
      return true
    }

    if (isFree(target?.alias)) {
      return false
    }

    return target?.id !== tariff?.id
  }

  return {
    tariff,
    isSubscriptionTariffAllow,
  }
}
