import React from 'react'
import { facadeGlassHelper } from '@/features/facade/FacadeGlass/helpers'

export const useFacadeGlassStaticOptions = () => {
  const getTypes = facadeGlassHelper.type.getTypes

  const getType = facadeGlassHelper.type.getType

  return {
    getTypes,
    getType,
  }
}
