import { createStyles } from '@mantine/core'

export default createStyles((theme, _params) => {
  const variant = 'light'

  const colors = theme.fn.variant({ variant, color: theme.primaryColor })

  return {
    root: {
      backgroundColor: colors.background,
    },
  }
})
