import React from 'react'
import { Group } from '@mantine/core'
import { ActionMenu } from './Controls'

interface IProps {
  config: {
    menu?: boolean
  }
  menu: {
    config?: {
      edit?: boolean
    }
  }
  editProps?: any
}

const defaultConfig = {
  menu: true,
}

export const ActionBar = ({ config, menu, editProps }: IProps) => {
  config = {
    ...config,
    ...defaultConfig,
  }

  return <Group>{config?.menu && <ActionMenu {...menu} editProps={editProps} />}</Group>
}
