import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { ImprintDisclaimerContainer } from '@/features/legal/components/ImprintDisclaimer'

export const ImprintDisclaimer = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'imprint_and_disclaimer.title'} />

      <ImprintDisclaimerContainer />
    </>
  )
}
