import {
  MEASURE_UNIT_FACADE_SYSTEM_TIEFE as TIEFE,
  MEASURE_UNIT_FACADE_SYSTEM_VARIATION as VARIATION,
} from '@/features/measure/consts/unit'
import i18n from '@/i18n'

type VariationOptionType = {
  label: string
  value: VARIATION
}

const localeData: any = {
  en: {
    [VARIATION.mm]: 'mm',
  },
  de: {
    [VARIATION.mm]: 'mm',
  },
}

const locale = (code: string) => {
  return localeData[code]
}

export const getFacadeSystemVariationUnits = (): VariationOptionType[] => {
  const code = i18n.language

  return [
    {
      label: locale(code)[VARIATION.mm],
      value: VARIATION.mm,
    },
  ]
}

export const facadeSystemVariationUnitsMap = getFacadeSystemVariationUnits().reduce(
  (acc: any, curr) => {
    const { value } = curr

    return acc.set(value, curr)
  },
  new Map()
)

type TiefeOptionType = {
  label: string
  value: TIEFE
}

const tiefeLocaleData: any = {
  en: {
    [TIEFE.mm]: 'mm',
  },
  de: {
    [TIEFE.mm]: 'mm',
  },
}

const tiefeLocale = (code: string) => {
  return tiefeLocaleData[code]
}

export const getFacadeSystemTiefeUnits = (): TiefeOptionType[] => {
  const code = i18n.language

  return [
    {
      label: tiefeLocale(code)[TIEFE.mm],
      value: TIEFE.mm,
    },
  ]
}

export const facadeSystemTiefeUnitsMap = getFacadeSystemTiefeUnits().reduce((acc: any, curr) => {
  const { value } = curr

  return acc.set(value, curr)
}, new Map())
