import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Anchor, TableView } from '@/components/Elements'
import { TableOptions } from '@/hooks/UI/useTableUI'
import { PATH } from '@/routes/const'
import { DateUtils } from '@/utils'
import { Action } from './Action'

type Key = 'name' | 'reference_number' | 'architect' | 'modified' | 'action'

interface IProps {
  keys: Key[]
  items: any[]
  loading: boolean
  actionProps?: any
  editProps?: any
  removeProps?: any
  tableOptions?: Partial<TableOptions<any>>
}

export const GeneralProjectsTable = ({
  keys = [],
  items,
  loading,
  actionProps,
  tableOptions,
  ...props
}: IProps) => {
  const { t } = useTranslation()

  const [columns, setColumns] = useState<any[]>([])

  const columnConfig: any = {
    name: {
      accessorKey: 'name',
      id: 'name',
      header: t('project_name'),
      cell: (info: any) => (
        <Anchor fw={600} component={Link} to={`/${PATH.PROJECTS}/${info.row.original?.id}`}>
          {info.getValue()}
        </Anchor>
      ),
    },
    reference_number: {
      accessorKey: 'reference_number',
      id: 'reference_number',
      header: t('reference_number'),
      cell: (info: any) => info.getValue() || '-',
    },
    architect: {
      accessorKey: 'architect',
      id: 'architect',
      header: t('architect'),
      cell: (info: any) => info.getValue() || '-',
    },
    modified: {
      accessorKey: 'modified_at',
      id: 'modified_at',
      header: t('modified'),
      cell: (info: any) => (info.getValue() ? DateUtils.formatDate(info.getValue()) : '-'),
    },
    action: {
      accessorKey: 'action',
      header: null,
      isPlaceholder: true,
      enableSorting: false,
      cell: (info: any) => (
        <div className={'flex justify-end'}>
          <Action
            {...actionProps}
            project={info.row.original}
            editProps={props.editProps}
            removeProps={props.removeProps}
          />
        </div>
      ),
    },
  }

  useEffect(() => {
    const config = []

    for (let i = 0; i < keys.length; i++) {
      const key = keys[i]
      if (columnConfig[key]) {
        config.push(columnConfig[key])
      }
    }

    setColumns(config)
  }, [])

  return (
    <>
      <TableView
        data={items}
        columns={columns}
        loading={loading}
        tableProps={{ verticalSpacing: 'md' }}
        tableOptions={tableOptions}
      />
    </>
  )
}
