import React from 'react'
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mantine/core'
import { Alert } from '@/components/Elements'
import { CommonInfoSection } from '@/features/project-facade/components/ProjectFacadeDataEntry/ProjectFacadeFormSections'

interface IProps<TFieldValues extends FieldValues = FieldValues, TContext = any> {
  methods: UseFormReturn<TFieldValues, TContext>
  onSubmit: () => void
  error?: string
}

export const ProjectFacadeCreateForm = <
  TFieldValues extends FieldValues = FieldValues,
  TContext = any
>({
  methods,
  onSubmit,
  error,
  ...props
}: IProps<TFieldValues, TContext>) => {
  const { t } = useTranslation()

  return (
    <>
      <FormProvider {...methods}>
        {error && (
          <Alert type={'error'} mb={'sm'}>
            {error || t('error')}
          </Alert>
        )}

        <form
          onSubmit={(e) => {
            e.preventDefault()
            onSubmit()
          }}
        >
          <input hidden type={'submit'} />

          <Grid>
            <Grid.Col sm={6}>
              <CommonInfoSection />
            </Grid.Col>
          </Grid>
        </form>
      </FormProvider>
    </>
  )
}
