import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Icon } from '@/components/Elements'
import { GeneralTopBar } from '@/features/panel/components/Layouts'
import useStyles from './Style.styles'

interface IProps {
  onProjectCreate: () => void
}

export const TopBar = ({ onProjectCreate }: IProps) => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  return (
    <GeneralTopBar
      title={t('my_projects')}
      className={classes.root}
      rightSection={
        <Button rightIcon={<Icon name={'plus'} />} onClick={() => onProjectCreate()}>
          {t('new_project')}
        </Button>
      }
    />
  )
}
