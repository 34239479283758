import React from 'react'
import { feedbackHelper } from '@/features/feedback/helpers'

export const useFeedbackStaticOptions = () => {
  const getTypes = feedbackHelper.type.getTypes

  return {
    getTypes,
  }
}
