import { createStyles } from '@mantine/core'

interface StylesParams {
  grow?: boolean
}

const getContentStyles = (grow = false) =>
  grow
    ? {
        display: 'flex',
        flexDirection: 'column',
        // flex: '1 1 0%',
        minHeight: 0,
        height: '100%',
      }
    : {}

const getBodyStyles = (grow = false) =>
  grow
    ? {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 0%',
      }
    : {}

export default createStyles((theme, _params: StylesParams): any => {
  const { grow } = _params

  return {
    title: {
      width: '100%',
      margin: 0,
    },

    close: {
      position: 'absolute',
      right: '20px',
      top: '20px',
      color: theme.colors.dark,
    },

    content: {
      ...getContentStyles(grow),
    },

    body: {
      ...getBodyStyles(grow),
    },
  }
})
