import React from 'react'
import {
  BsBoxArrowRight,
  BsChatText,
  BsChevronDown,
  BsChevronLeft,
  BsDownload,
  BsGear,
  BsGrid3X3,
  BsListUl,
  BsPencil,
  BsPrinter,
  BsQuestionCircle,
  BsTrash,
} from 'react-icons/bs'
import { HiOutlineCloudDownload } from 'react-icons/hi'
import { MdSupportAgent } from 'react-icons/md'
import {
  TbArrowNarrowRight,
  TbArrowsSort,
  TbCheck,
  TbDots,
  TbPlus,
  TbSearch,
  TbSortAscending,
  TbSortDescending,
  TbX,
} from 'react-icons/tb'
import { useMantineTheme } from '@mantine/core'

export type IconNameType =
  | 'chat'
  | 'logout'
  | 'gear'
  | 'chevron-down'
  | 'chevron-left'
  | 'check'
  | 'x'
  | 'printer'
  | 'trash'
  | 'pencil'
  | 'search'
  | 'download'
  | 'grid-3x3'
  | 'list-ul'
  | 'dots'
  | 'plus'
  | 'arrow-right'
  | 'arrows-sort'
  | 'sort-asc'
  | 'sort-desc'
  | 'support-agent'
  | 'cloud-download'
  | 'question-circle'

interface IProps {
  name: IconNameType
  size?: number
  color?: string
  className?: string

  [x: string]: any
}

export const Icon = ({ name, ...props }: IProps) => {
  const theme = useMantineTheme()

  const color = props?.color || theme.black

  switch (name) {
    case 'logout':
      return <BsBoxArrowRight {...props} />

    case 'chat':
      return <BsChatText {...props} />

    case 'gear':
      return <BsGear {...props} />

    case 'chevron-down':
      return <BsChevronDown {...props} />

    case 'chevron-left':
      return <BsChevronLeft {...props} />

    case 'check':
      return <TbCheck {...props} />

    case 'x':
      return <TbX {...props} />

    case 'trash':
      return <BsTrash {...props} />

    case 'printer':
      return <BsPrinter {...props} />

    case 'pencil':
      return <BsPencil {...props} />

    case 'search':
      return <TbSearch {...props} />

    case 'download':
      return <BsDownload {...props} />

    case 'list-ul':
      return <BsListUl {...props} />

    case 'grid-3x3':
      return <BsGrid3X3 {...props} />

    case 'dots':
      return <TbDots {...props} />

    case 'plus':
      return <TbPlus {...props} />

    case 'arrow-right':
      return <TbArrowNarrowRight {...props} />

    case 'arrows-sort':
      return <TbArrowsSort {...props} />

    case 'sort-asc':
      return <TbSortAscending {...props} />

    case 'sort-desc':
      return <TbSortDescending {...props} />

    case 'question-circle':
      return <BsQuestionCircle {...props} />

    case 'cloud-download':
      return <HiOutlineCloudDownload {...props} />

    case 'support-agent':
      return <MdSupportAgent {...props} />

    default:
      return null
  }
}
