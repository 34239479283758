import * as Yup from 'yup'
import { MEASURE_LENGTH, MEASURE_WIND_SPEED_ZONE } from '@/features/measure/consts/validation'
import { UnitUtils } from '@/utils'

export const validationSchema = Yup.object({
  name: Yup.string().nullable().required(),
  reference_number: Yup.string().nullable().required(),
  architect: Yup.string().nullable(),
  building_address: Yup.string().nullable(),

  length: Yup.number()
    .min(UnitUtils.measure.length.cmToM(MEASURE_LENGTH.min))
    .max(UnitUtils.measure.length.cmToM(MEASURE_LENGTH.max))
    .nullable()
    .transform((v, o) => (o === '' ? null : v)),

  width: Yup.number()
    .min(UnitUtils.measure.length.cmToM(MEASURE_LENGTH.min))
    .max(UnitUtils.measure.length.cmToM(MEASURE_LENGTH.max))
    .nullable()
    .transform((v, o) => (o === '' ? null : v)),

  height: Yup.number()
    .min(UnitUtils.measure.length.cmToM(MEASURE_LENGTH.min))
    .max(UnitUtils.measure.length.cmToM(MEASURE_LENGTH.max))
    .nullable()
    .transform((v, o) => (o === '' ? null : v)),

  // wind_speed_zone: Yup.number()
  //   .min(MEASURE_WIND_SPEED_ZONE.min)
  //   .max(MEASURE_WIND_SPEED_ZONE.max)
  //   .nullable()
  //   .transform((v, o) => (o === '' ? null : v))

  info: Yup.string().nullable(),
})
