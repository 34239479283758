import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Button, Icon } from '@/components/Elements'
import { GeneralTopBar } from '@/features/panel/components/Layouts'
import { PATH, PATH_PROJECTS } from '@/routes/const'
import useStyles from './Style.styles'

interface IProps {
  title?: React.ReactNode | string
  leftSection?: React.ReactElement
  onProjectEdit: () => void
}

export const Header = ({ title, leftSection, onProjectEdit }: IProps) => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  const navigate = useNavigate()

  const goToMyProjects = () => {
    navigate(`/${PATH.PROJECTS}/${PATH_PROJECTS.MY}`)
  }

  return (
    <GeneralTopBar
      className={classes.header}
      back
      backProps={{ title: `${t('back_to')} ${t('my_projects')}`, onBack: goToMyProjects }}
      title={title}
      leftSection={leftSection}
      rightSection={
        <Button
          variant={'default'}
          onClick={() => onProjectEdit()}
          rightIcon={<Icon name={'pencil'} />}
        >
          {t('edit_project')}
        </Button>
      }
    />
  )
}
