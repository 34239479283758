import { createStyles, rem } from '@mantine/core'

// eslint-disable-next-line
interface StylesParams {}

export default createStyles((theme, props: StylesParams) => {
  return {
    root: {
      border: `${rem(1)} solid ${theme.colors.gray[2]}`,
      borderRadius: theme.radius.sm,
      backgroundColor: theme.white,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'inherit',
    },
  }
})
