import React from 'react'
import { ScrollArea, Space } from '@mantine/core'
import useStyles from './Component.styles'

interface IProps {
  children: React.ReactNode
  footer?: React.ReactElement
}

export const GrowModalContentShell = ({ children, footer }: IProps) => {
  const { classes, cx } = useStyles({})

  return (
    <div className={classes.wrap}>
      <div className={classes.body}>
        <div className={classes.main}>
          <div className={classes.content}>
            <ScrollArea>
              {children}

              <Space h={'md'} />
            </ScrollArea>
          </div>
        </div>
      </div>

      {footer && <>{footer}</>}
    </div>
  )
}
