import { createStyles, em } from '@mantine/styles'

export default createStyles((theme) => ({
  block: {
    flex: '1 1 0%',
    display: 'flex',
    flexDirection: 'column',
    minWidth: 0,
    minHeight: 0,

    [theme.fn.smallerThan('lg')]: {
      minHeight: 'auto',
    },

    [`@media (max-height: ${em(700)})`]: {
      minHeight: 'auto',
    },
  },

  gridWrapper: {
    height: '100%',
    minHeight: 0,
    display: 'flex',
  },

  gridParent: {
    flexDirection: 'row',

    [theme.fn.smallerThan('lg')]: {
      flexDirection: 'column',
    },
  },

  grid: {
    flex: '1 1 0%',
    display: 'flex',
    minWidth: 0,
    gap: '1rem',
    flexDirection: 'column',
  },

  gridItem: {
    flex: '1 1 0%',
    display: 'flex',
    flexDirection: 'column',
    minWidth: 0,
    minHeight: 0,
  },

  applicationPanelBlock: {
    [theme.fn.smallerThan('lg')]: {
      flex: 0,
      minHeight: 'auto',
    },
  },
}))
