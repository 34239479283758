import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Group, Space } from '@mantine/core'
import { Alert, Button } from '@/components/Elements'
import {
  SelectController,
  TextAreaController,
  TextInputController,
} from '@/components/FormElements'
import { useFeedbackStaticOptions } from '@/features/feedback/hooks'
import { useFormSubmit } from '@/hooks'
import { NormalizeUtils } from '@/utils'
import { validationSchema } from './validation'

export type FormValues = {
  name: string
  email: string
  phone: string
  type: string
  subject: string
  message: string
}

interface IProps {
  initialValues?: FormValues | any
  onSubmit: (values: FormValues) => Promise<void>
}

export const FeedbackForm: React.FC<IProps> = ({ initialValues, ...props }) => {
  const { t } = useTranslation()

  const methods = useForm<FormValues>({
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      type: '',
      subject: '',
      message: '',
      ...initialValues,
    },
    resolver: yupResolver(validationSchema),
  })

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
    reset,
  } = methods

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: props.onSubmit,
    setError,
  })

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      const values = {
        ...data,
        phone: data.phone.replace(/[^0-9]/g, ''),
      }
      await onFormSubmit(values)

      reset({ subject: '', message: '' })
    } catch (err) {}
  }

  const { getTypes } = useFeedbackStaticOptions()

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {submitError && (
          <Alert type={'error'} mb={'sm'}>
            {submitError?.data?.message || t('error')}
          </Alert>
        )}

        <TextInputController
          control={control}
          name={'name'}
          id={'name'}
          label={t('name_and_surname')}
          placeholder={t('name_and_surname')}
          mb={'md'}
        />

        <TextInputController
          control={control}
          name={'email'}
          id={'email'}
          label={t('email')}
          placeholder={t('email')}
          mb={'md'}
        />

        <TextInputController
          control={control}
          name={'phone'}
          id={'phone'}
          label={t('phone_number')}
          placeholder={t('phone_number')}
          mb={'md'}
          normalize={NormalizeUtils.phone}
        />

        <SelectController
          control={control}
          name={'type'}
          id={'type'}
          label={t('type_of_request')}
          placeholder={t('type_of_request')}
          mb={'md'}
          data={getTypes()}
        />

        <TextInputController
          control={control}
          name={'subject'}
          id={'subject'}
          label={t('subject')}
          placeholder={t('subject')}
          mb={'md'}
        />

        <TextAreaController
          control={control}
          name={'message'}
          id={'message'}
          label={t('message')}
          placeholder={t('message')}
          mb={'md'}
        />

        <Space h={'xl'} />

        <Group mt={'xl'} position={'right'}>
          <Button type={'submit'} loading={isSubmitting}>
            {t('send_request')}
          </Button>
        </Group>
      </form>
    </FormProvider>
  )
}
