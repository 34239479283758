import { SortingState, SortingType } from '../types'

export const formatSorting = (sortingState: SortingState): SortingType => {
  const item = sortingState[0]

  return {
    orderBy: item?.id ? item?.id : '',
    sortedBy: item?.desc ? 'desc' : undefined,
  }
}

export const normalizeSorting = (sorting: SortingType | any): SortingState => {
  if (sorting && sorting.orderBy) {
    const { orderBy, sortedBy } = sorting

    return [{ id: orderBy, desc: sortedBy === 'desc' }]
  }

  return []
}
