import { createStyles, rem } from '@mantine/core'

export default createStyles((theme, _params) => {
  return {
    // fixme
    root: {
      width: '100%',

      ['.mantine-Tabs-tabsList']: {
        borderColor: 'transparent',
      },
      ['.mantine-Tabs-tab']: {
        paddingTop: 0,
        height: rem(44),
      },
    },
  }
})
