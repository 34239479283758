import React, { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { GlazingTemplate } from '@/features/glazing/GlazingTemplate/types'
import { projectFacadeDataQueryArgsDefaultParams as queryArgsDefaultParams } from '@/features/project-facade/consts/api'
import {
  ProjectFacadeFacadeSystemTag,
  ProjectFacadeTag,
  projectFacadeFacadeSystemsApi,
  projectFacadesApi,
  selectInvalidatedProjectFacadeData,
} from '@/features/project-facade/store'
import { ProjectFacadeDTO } from '@/features/project-facade/types'
import { useAppDispatch, useAppSelector } from '@/store'

interface IProps {
  id?: string
}

const endpointName = 'projectFacadeData'

export const useProjectFacadeData = ({ id }: IProps) => {
  const { facadeId: paramsId } = useParams()

  const facadeId = id || (paramsId as string)

  const dispatch = useAppDispatch()

  const selectFacadeData: any = useMemo(
    () =>
      projectFacadesApi.endpoints[endpointName].select({
        id: facadeId,
        params: queryArgsDefaultParams,
      }),
    [facadeId]
  )

  const state: { data: ProjectFacadeDTO; [x: string]: any } = useAppSelector(selectFacadeData)

  const { data: facade } = state

  const onSetFacadeData = async (data: ProjectFacadeDTO) => {
    await dispatch(
      projectFacadesApi.util.updateQueryData(
        endpointName,
        {
          id: facadeId,
          params: queryArgsDefaultParams,
        },
        (draft) => data
      )
    )
  }

  const setGlazingTemplatesFacade = async (data: GlazingTemplate[]) => {
    await dispatch(
      projectFacadesApi.util.updateQueryData(
        endpointName,
        {
          id: facadeId,
          params: queryArgsDefaultParams,
        },
        (draft) => {
          return {
            ...draft,
            glazingTemplates: {
              data,
            },
          }
        }
      )
    )
  }

  // === Manage All Cached facade data === //

  // const invalidatedProjectFacadeDataLoop = (cb: (endpointName: any, originalArgs: any) => void) => {
  //   for (const { endpointName: endpoint, originalArgs } of invalidatedProjectFacadeData) {
  //     if (endpoint === endpointName) {
  //       cb(endpointName, originalArgs)
  //     }
  //   }
  // }

  // const invalidatedProjectFacadeData = useAppSelector(selectInvalidatedProjectFacadeData)
  // const updateAllFacadeData = (params: any) => {
  //   invalidatedProjectFacadeDataLoop((originalArgs) =>
  //     dispatch(
  //       projectFacadesApi.util.updateQueryData(endpointName, originalArgs, (draft) => ({
  //         ...draft,
  //         ...params,
  //       }))
  //     )
  //   )
  // }

  // const glazingUpdate = (glazing: any) => {
  //   invalidatedProjectFacadeDataLoop((originalArgs) =>
  //     dispatch(
  //       projectFacadesApi.util.updateQueryData(endpointName, originalArgs, (draft) => {
  //         const items = draft.glazings.data.map((item) => {
  //           if (item.id === glazing?.id) {
  //             return {
  //               ...item,
  //               ...glazing,
  //             }
  //           } else {
  //             return item
  //           }
  //         })
  //
  //         return {
  //           ...draft,
  //           glazings: {
  //             ...draft?.glazings,
  //             data: items,
  //           },
  //         }
  //       })
  //     )
  //   )
  // }

  // const glazingRemove = (glazing: any) => {
  //   invalidatedProjectFacadeDataLoop((originalArgs) =>
  //     dispatch(
  //       projectFacadesApi.util.updateQueryData(endpointName, originalArgs, (draft) => ({
  //         ...draft,
  //         glazings: {
  //           ...draft?.glazings,
  //           data: ...draft.glazings.data.filter(item => item?.id !== glazing?.id),
  //         },
  //       })),
  //     ),
  //   )
  // }

  // === //

  const invalidateTags = () => {
    dispatch(projectFacadesApi.util.invalidateTags([ProjectFacadeTag]))
  }

  const projectFacadeFacadeSystemsInvalidateTags = () => {
    dispatch(
      projectFacadeFacadeSystemsApi.util.invalidateTags([
        {
          type: ProjectFacadeFacadeSystemTag,
          id: `${ProjectFacadeTag}-${facadeId}`,
        },
      ])
    )
  }

  return {
    facade,
    state,
    onSetFacadeData,
    projectFacadeFacadeSystemsInvalidateTags,
  }
}
