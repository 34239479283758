import { createStyles, rem } from '@mantine/core'

export default createStyles((theme) => {
  const dark = theme.colorScheme === 'dark'

  const primaryColor = theme.colors[theme.primaryColor][theme.fn.primaryShade()]
  const primary4 = theme.colors[theme.primaryColor][4]
  const darkerPrimary4 = theme.fn.darken(primary4, 0.25)

  return {
    section: {
      background: theme.fn.linearGradient(135, primaryColor, darkerPrimary4),
    },

    wrapper: {
      paddingTop: rem(60),
      paddingBottom: rem(60),
    },
  }
})
