import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Title, rem } from '@mantine/core'
import useStyles from './HeroSection.styles'

export const HeroSection = () => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  return (
    <div className={classes.section}>
      <Container size={'md'} className={classes.wrapper}>
        <div>
          <Title color={'white'} fz={rem(40)}>
            {t('help_center')}
          </Title>
          <Title color={'white'} order={4} fw={400}>
            {t('help_center.subtitle')}
          </Title>
        </div>
      </Container>
    </div>
  )
}
