import _ from 'lodash'
import { BILLING_SUBSCRIPTION_STATUS } from '@/features/billing/consts/subscription'
import i18n from '@/i18n'

type OptionType = {
  label: string
  value: BILLING_SUBSCRIPTION_STATUS
}

export const getStatuses = () => {
  return [
    {
      label: i18n.t('active'),
      value: BILLING_SUBSCRIPTION_STATUS.ACTIVE,
    },
    {
      label: i18n.t('canceled'),
      value: BILLING_SUBSCRIPTION_STATUS.CANCELED,
    },
    {
      label: i18n.t('incomplete'),
      value: BILLING_SUBSCRIPTION_STATUS.INCOMPLETE,
    },
    {
      label: i18n.t('incomplete_expired'),
      value: BILLING_SUBSCRIPTION_STATUS.INCOMPLETE_EXPIRED,
    },
    {
      label: i18n.t('past_due'),
      value: BILLING_SUBSCRIPTION_STATUS.PAST_DUE,
    },
    {
      label: i18n.t('paused'),
      value: BILLING_SUBSCRIPTION_STATUS.PAUSED,
    },
    {
      label: i18n.t('trialing'),
      value: BILLING_SUBSCRIPTION_STATUS.TRIALING,
    },
    {
      label: i18n.t('unpaid'),
      value: BILLING_SUBSCRIPTION_STATUS.UNPAID,
    },
  ]
}

export const getStatus = (value: OptionType | string): OptionType | any => {
  const options = getStatuses()
  return _.find(options, { value }) || { label: value }
}

export const isActive = (value: BILLING_SUBSCRIPTION_STATUS): boolean => {
  return value === BILLING_SUBSCRIPTION_STATUS.ACTIVE
}

export const isPaused = (value: BILLING_SUBSCRIPTION_STATUS): boolean => {
  return value === BILLING_SUBSCRIPTION_STATUS.PAUSED
}

export const isIncomplete = (value: BILLING_SUBSCRIPTION_STATUS): boolean => {
  return value === BILLING_SUBSCRIPTION_STATUS.INCOMPLETE
}
