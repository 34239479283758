import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { MyProjectsContainer } from '@/features/project/components/MyProjects/MyProjectsContainer'

export const Projects = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'my_projects'} />

      <MyProjectsContainer />
    </>
  )
}
