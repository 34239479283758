import React from 'react'
import { Outlet } from 'react-router-dom'
import { Header } from './Header'
import useStyles from './PanelLayout.styles'

export const PanelLayout = () => {
  const { classes } = useStyles()

  return (
    <div className={classes.wrap}>
      <Header />

      <div className={classes.body}>
        <div className={classes.main}>
          <Outlet />
        </div>
      </div>
    </div>
  )
}
