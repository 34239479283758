import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  GeneralBottomBar,
  GeneralTopBar,
  ProjectFacadeDataEntryPage,
  ProjectFacadeDataEntryPanel,
} from '@/features/project-facade/components/ProjectFacadeDataEntry/Layouts'
import { projectFacadeDataQueryArgsDefaultParams as queryArgsDefaultParams } from '@/features/project-facade/consts/api'
import { PROJECT_FACADE_SECTION_ALIAS } from '@/features/project-facade/consts/project-facade'
import { projectFacadeHelper } from '@/features/project-facade/helpers'
import {
  useProjectFacadeDataEntryForm,
  useProjectFacadeStaticOptions,
} from '@/features/project-facade/hooks'
import { projectFacadesApi, useProjectFacadeCreateMutation } from '@/features/project-facade/store'
import { ProjectFacadeDTO } from '@/features/project-facade/types'
import { useProjectData } from '@/features/project/hooks'
import { useAppDispatch } from '@/store'
import { ProjectFacadeCreateForm } from './ProjectFacadeCreateForm'
import { validationSchema } from './validation'

const { getProjectFacadePathConfig } = projectFacadeHelper.routes
const { COMMON } = PROJECT_FACADE_SECTION_ALIAS

type FormValues = {
  name: string
  wind_load: string
}

export const ProjectFacadeCreateContainer = () => {
  const { t } = useTranslation()

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { project } = useProjectData({})

  const onSetFacadeData = async (data: ProjectFacadeDTO) => {
    const { id } = data

    await dispatch(
      projectFacadesApi.util.upsertQueryData(
        'projectFacadeData',
        {
          id,
          params: queryArgsDefaultParams,
        },
        data
      )
    )
  }

  const [create] = useProjectFacadeCreateMutation()

  const onSubmit = async ({
    data: values,
    options,
  }: {
    data: FormValues
    options?: { isNext: boolean }
  }) => {
    const params = {
      project_id: project?.id,
      ...values,
    }

    const data = await create({ params }).unwrap()

    if (data) {
      await onSetFacadeData(data)

      onNavigate(data, options)
    }
  }

  // --- Form --- //
  const { methods, onHandleSubmit, isSubmitting, error } =
    useProjectFacadeDataEntryForm<FormValues>({
      formConfig: {
        defaultValues: {
          name: '',
          wind_load: '',
        },
        resolver: yupResolver(validationSchema as any),
      },
      onSubmit,
    })

  const { handleSubmit } = methods
  // --- //

  const { getAdjacentSections, sectionsMap } = useProjectFacadeStaticOptions()

  const onNavigate = (data: ProjectFacadeDTO, options?: { isNext: boolean }) => {
    const pathConfig = getProjectFacadePathConfig(project?.id, data?.id)

    const { current, next } = getAdjacentSections(PROJECT_FACADE_SECTION_ALIAS.COMMON)

    const isNext = options?.isNext

    const route = isNext ? next?.path : current?.path

    navigate(
      {
        pathname: `${pathConfig.edit}/${route}`,
      },
      { replace: isNext }
    )
  }

  const onSave = async () => {
    await handleSubmit(async (data) => await onHandleSubmit({ data }))()
  }

  const onNext = async () => {
    await handleSubmit(async (data) => await onHandleSubmit({ data, options: { isNext: true } }))()
  }

  const facadeSection = sectionsMap.get(COMMON)

  return (
    <>
      <ProjectFacadeDataEntryPanel project={project}>
        <ProjectFacadeDataEntryPage
          footer={
            <GeneralBottomBar
              config={{ save: true, next: true }}
              saveProps={{ btnProps: { disabled: isSubmitting } }}
              nextProps={{ btnProps: { disabled: isSubmitting } }}
              onSave={onSave}
              onNext={onNext}
            />
          }
        >
          <GeneralTopBar
            title={`${t('step')} ${facadeSection.order + 1} - ${facadeSection.label}`}
            subtitle={t('enter_building_data')}
          />

          <div>
            <ProjectFacadeCreateForm onSubmit={onSave} methods={methods} error={error} />
          </div>
        </ProjectFacadeDataEntryPage>
      </ProjectFacadeDataEntryPanel>
    </>
  )
}
