import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BILLING_TARIFF_ALIAS, BILLING_TARIFF_PERIOD } from '@/features/billing/consts/tariff'
import { Tariff } from '@/features/billing/types'

interface IProps {
  tariffs: Tariff[]
  initialPeriod?: BILLING_TARIFF_PERIOD
}

export const useTariffsPeriodGrid = ({
  initialPeriod = BILLING_TARIFF_PERIOD.ANNUALLY,
  tariffs = [],
}: IProps) => {
  const { t } = useTranslation()

  const [period, setPeriod] = useState<BILLING_TARIFF_PERIOD>(initialPeriod)

  const onPeriodChange = (value: string) => {
    setPeriod(value as BILLING_TARIFF_PERIOD)
  }

  const getTariffsByPeriod = useCallback(
    (period: BILLING_TARIFF_PERIOD) => {
      return tariffs.filter(
        (item) => item.alias !== BILLING_TARIFF_ALIAS.FREE && item.period === period
      )
    },
    [tariffs]
  )

  const data = useMemo(() => {
    return getTariffsByPeriod(period)
  }, [period, tariffs])

  return {
    period,
    onPeriodChange,
    data,
  }
}
