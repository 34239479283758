import { createStyles } from '@mantine/core'

// eslint-disable-next-line
interface StylesParams {}

export default createStyles((theme, props: StylesParams) => {
  return {
    header: {
      display: 'flex',
      flex: 1,
      gap: theme.spacing.xs,
    },

    topBar: {
      display: 'flex',
      flexGrow: 1,
      minWidth: 0,
    },

    actionBar: {},
  }
})
