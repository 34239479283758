import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { CheckboxGroupProps, Group } from '@mantine/core'
import { CheckboxGroupController } from '@/components/FormElements'
import { useProjectFacadeSelectsData } from '@/features/facade/ProjectFacadeSelects/hooks'

interface IProps extends Omit<CheckboxGroupProps, 'children'> {
  name: string
  id: string
  control: any
  options?: any[]
}

export const FacadeMaterialsCheckboxGroupController = ({
  options,
  control,
  name,
  id,
  ...props
}: IProps) => {
  const { t } = useTranslation()

  const { facadeMaterialsOptions } = useProjectFacadeSelectsData()

  const controlOptions = useMemo(() => {
    if (options) {
      return options
    }

    return facadeMaterialsOptions
  }, [options, facadeMaterialsOptions])

  return (
    <>
      <CheckboxGroupController
        control={control}
        name={name}
        id={id}
        mb={'md'}
        options={controlOptions}
        shellComponent={Group}
        shellComponentProps={{
          spacing: 40,
        }}
        errorProps={{ mt: 'xs' }}
        {...props}
      />
    </>
  )
}
