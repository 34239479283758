import React, { useMemo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mantine/core'
import { SelectController, TextInputController } from '@/components/FormElements'
import { useStaticOptions } from '@/hooks'
import { NormalizeUtils } from '@/utils'

type FormValues = {
  gender?: string
  name: string
  last_name: string
  email: string
  phone: string
}

interface IProps {
  emailProps?: any
}

export const GeneralFormSection = ({ emailProps }: IProps) => {
  const { t } = useTranslation()

  const { control } = useFormContext<FormValues>()

  const { getGenders } = useStaticOptions()

  const genderPrefixOptions = useMemo(() => {
    return getGenders().map(({ value, prefix }) => ({ label: prefix, value }))
  }, [])

  return (
    <>
      <Grid>
        <Grid.Col md={6}>
          <SelectController
            control={control}
            name={'gender'}
            id={'gender'}
            label={t('prefix')}
            placeholder={t('prefix')}
            mb={'md'}
            data={genderPrefixOptions}
            clearable
          />
        </Grid.Col>
      </Grid>

      <Grid>
        <Grid.Col md={6}>
          <TextInputController
            control={control}
            name={'name'}
            id={'name'}
            label={t('first_name')}
            placeholder={t('first_name')}
            mb={'md'}
          />
        </Grid.Col>

        <Grid.Col md={6}>
          <TextInputController
            control={control}
            name={'last_name'}
            id={'last_name'}
            label={t('last_name')}
            placeholder={t('last_name')}
            mb={'md'}
          />
        </Grid.Col>
      </Grid>

      <Grid>
        <Grid.Col>
          <TextInputController
            control={control}
            name={'email'}
            id={'email'}
            label={t('email')}
            placeholder={t('email')}
            mb={'md'}
            {...emailProps}
          />
        </Grid.Col>
      </Grid>

      <Grid>
        <Grid.Col>
          <TextInputController
            control={control}
            name={'phone'}
            id={'phone'}
            label={t('phone_number')}
            placeholder={t('phone_number')}
            mb={'md'}
            normalize={NormalizeUtils.phone}
          />
        </Grid.Col>
      </Grid>
    </>
  )
}
