import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Flex } from '@mantine/core'
import { MainHeaderProvider, NavLink } from '@/components/Layouts/Elements'
import { PATH, PATH_PROJECTS } from '@/routes/const'
import useStyles from './Header.styles'
import { ProfileMenu } from './ProfileMenu'

export const Header = () => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  return (
    <MainHeaderProvider
      type={'private'}
      fixed={false}
      rightSection={
        <Flex align={'center'} gap={{ base: 'xl', md: 80 }}>
          <Box>
            <NavLink path={`/${PATH.PROJECTS}/${PATH_PROJECTS.MY}`} label={t('my_projects')} />
          </Box>

          <ProfileMenu />
        </Flex>
      }
    />
  )
}
