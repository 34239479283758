import { TypedUseQueryHookResult } from '@reduxjs/toolkit/query/react'
import React, { useMemo, useState } from 'react'
import { CollectionDTO } from '@/types'

// type QDef<QueryArg, ResultType> = QueryDefinition<QueryArg, any, string, ResultType>

type BaseQueryArg = { params?: any }

interface IProps<ResultType, QueryArg> {
  useQuery: any
  queryArg?: BaseQueryArg & QueryArg
  queryOptions?: any
}

export const useInfiniteQuery = <ResultType, QueryArg>({
  useQuery,
  queryArg,
  queryOptions,
}: IProps<ResultType, QueryArg>) => {
  const [page, setPage] = useState(1)

  const arg = {
    ...queryArg,
    params: {
      ...queryArg?.params,
      page,
    },
  }

  const query: TypedUseQueryHookResult<CollectionDTO<ResultType>, QueryArg, any> = useQuery(
    { ...arg },
    { ...queryOptions }
  )

  const meta = query.data?.meta || {}

  const onPageChange = (value: number) => {
    setPage(value)
  }

  const queryLoading = useMemo(() => {
    return query.isLoading || query.isFetching
  }, [query.isLoading, query.isFetching])

  const isLoading = useMemo(() => {
    return query.isLoading || (query.isFetching && page === 1)
  }, [query.isLoading, query.isFetching, page])

  const isFetchingNextPage = useMemo(() => {
    return query.isFetching && page !== 1
  }, [query.isFetching, page])

  const hasNextPage = useMemo(() => {
    if (meta?.pagination?.total_pages) {
      return page < meta?.pagination?.total_pages
    }
    return false
  }, [page, meta?.pagination?.total_pages])

  const fetchNextPage = () => {
    setPage((prevState) => (prevState ? prevState + 1 : 1))
  }

  return {
    query,
    page,
    onPageChange,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    queryLoading,
  }
}
