import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { PrivacyPolicyContainer } from '@/features/legal/components/PrivacyPolicy'

export const PrivacyPolicy = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'privacy_policy'} />

      <PrivacyPolicyContainer />
    </>
  )
}
