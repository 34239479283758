import { yupResolver } from '@hookform/resolvers/yup'
import React, { useState } from 'react'
import { flushSync } from 'react-dom'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { Group, Space } from '@mantine/core'
import { Alert, Button } from '@/components/Elements'
import { PasswordInputController, PasswordStrengthBar } from '@/components/FormElements'
import { PASSWORD_MIN_LENGTH } from '@/const/form'
import { useFormSubmit } from '@/hooks'
import { SCORE_STRONG, usePasswordStrengthMeter } from '@/packages/password-strength-meter'

export type FormValues = {
  password: string
  password_confirmation: string
}

interface IProps {
  onSubmit: (values: FormValues) => Promise<void>
}

export const ResetPasswordForm: React.FC<IProps> = (props) => {
  const { t } = useTranslation()

  const [passScore, setPassScore] = useState(0)

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting },
    setValue,
    watch,
    trigger,
  } = useForm<FormValues>({
    defaultValues: {
      password: '',
      password_confirmation: '',
    },
    resolver: yupResolver(
      Yup.object({
        password: Yup.string()
          .required()
          .min(PASSWORD_MIN_LENGTH)
          .test('score', { key: 'field.error.password.npt_strong' }, (value, context) => {
            return context?.options?.context?.passScore >= SCORE_STRONG
          }),
        password_confirmation: Yup.string()
          .required()
          .oneOf([Yup.ref('password')], { key: 'field.error.password.no_match' })
          .min(PASSWORD_MIN_LENGTH),
      })
    ),
    context: { passScore },
  })

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: props.onSubmit,
    setError,
  })

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      await onFormSubmit(data)
    } catch (err) {}
  }

  const watchPassword = watch('password')

  const { measure } = usePasswordStrengthMeter()

  const onPasswordChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const pass = event.target.value

    setValue('password', pass)

    await flushSync(async () => {
      const score = measure(pass)?.score
      setPassScore(score)
    })

    await trigger('password')
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {submitError && (
          <Alert type={'error'} mb={'sm'}>
            {submitError?.data?.message || t('error')}
          </Alert>
        )}

        <PasswordInputController
          control={control}
          name={'password'}
          id={'password'}
          label={t('password')}
          placeholder={t('password')}
          mb={'md'}
          translateParams={{ count: PASSWORD_MIN_LENGTH }}
          onChange={onPasswordChange}
        />

        {watchPassword && <PasswordStrengthBar mb={'md'} score={passScore} />}

        <PasswordInputController
          control={control}
          name={'password_confirmation'}
          id={'password_confirmation'}
          label={t('confirm_password')}
          placeholder={t('confirm_password')}
          mb={'md'}
          translateParams={{ count: PASSWORD_MIN_LENGTH }}
        />

        <Space h={'xl'} />

        <Group mt={'xl'} position={'right'}>
          <Button type={'submit'} loading={isSubmitting}>
            {t('set_new_password')}
          </Button>
        </Group>
      </form>
    </>
  )
}
