import React from 'react'
import { Outlet, useParams } from 'react-router-dom'
import { ProjectFacadeDataQuery } from '@/features/project-facade/components/ProjectFacade/ProjectFacadeDataQuery'

export const ProjectFacadeDataLayout = () => {
  const { facadeId: paramsFacadeId } = useParams()

  const facadeId = paramsFacadeId as string

  return (
    <>
      <ProjectFacadeDataQuery id={facadeId}>
        <Outlet />
      </ProjectFacadeDataQuery>
    </>
  )
}
