import React from 'react'
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Alert } from '@/components/Elements'
import { ProductSection } from '@/features/project-facade/components/ProjectFacadeDataEntry/ProjectFacadeFormSections'

interface IProps<TFieldValues extends FieldValues = FieldValues, TContext = any> {
  methods: UseFormReturn<TFieldValues, TContext>
  onSubmit: () => void
  error?: string
  facadeId: string
}

export const ProductEditForm = <TFieldValues extends FieldValues = FieldValues, TContext = any>({
  methods,
  onSubmit,
  error,
  facadeId,
  ...props
}: IProps<TFieldValues, TContext>) => {
  const { t } = useTranslation()

  return (
    <>
      <FormProvider {...methods}>
        {error && (
          <Alert type={'error'} mb={'sm'}>
            {error || t('error')}
          </Alert>
        )}

        <form
          onSubmit={(e) => {
            e.preventDefault()
            onSubmit()
          }}
        >
          <input hidden type={'submit'} />

          <ProductSection facadeId={facadeId} />
        </form>
      </FormProvider>
    </>
  )
}
