import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mantine/core'
import { SimpleCard } from '@/components/Elements'
import { FacadeSystem } from '@/features/facade/FacadeSystem/types'
import useStyles from './Card.styles'
import { FeaturesBlock } from './FeaturesBlock'
import { MediaBlock } from './MediaBlock'
import { TopBar } from './TopBar'

interface IProps {
  data: FacadeSystem
  footer?: React.ReactNode
  component?: any
  classNames?: any
  componentProps?: any
}

export const GeneralFacadeSystemCard = ({
  data,
  footer,
  component,
  classNames,
  componentProps,
  ...props
}: IProps) => {
  const { t } = useTranslation()

  const { classes } = useStyles({})

  return (
    <SimpleCard classNames={classNames} component={component} componentProps={componentProps}>
      <div className={classes.header}>
        <div className={classes.topBar}>
          <TopBar data={data} />
        </div>
      </div>

      <Box mt={'xs'}>
        <MediaBlock data={data} />
      </Box>

      <Box mt={'xs'}>
        <FeaturesBlock data={data} />
      </Box>

      {footer && <Box mt={'xs'}>{footer}</Box>}
    </SimpleCard>
  )
}
