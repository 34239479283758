import { createStyles } from '@mantine/core'
import { HEADER_HEIGHT_SM } from '@/const/layout'

export default createStyles((theme, _params) => {
  const dark = theme.colorScheme === 'dark'

  return {
    root: {
      backgroundColor: dark ? theme.colors.dark[7] : theme.colors.gray[0],
      boxShadow: theme.shadows.xs,
      height: HEADER_HEIGHT_SM,
      zIndex: 1,
    },

    container: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    },
  }
})
