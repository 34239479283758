import React from 'react'
import { Avatar as MantineAvatar, AvatarProps as MantineAvatarProps } from '@mantine/core'
import placeholderPath from '@/assets/images/placeholders/avatar.svg'

export interface AvatarProps extends MantineAvatarProps {
  placeholder?: string
  src?: string
  children?: React.ReactNode
  className?: string
}

export const Avatar: React.FC<AvatarProps> = ({
  src,
  placeholder,
  children,
  className,
  ...props
}) => {
  const noImage = !src && !children

  placeholder = placeholder || placeholderPath

  return (
    <MantineAvatar src={noImage ? placeholder : src} {...props}>
      {children}
    </MantineAvatar>
  )
}
