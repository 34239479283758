import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import { NavMenuBar } from '@/components/Elements'

const TABS_KEY = {
  SUBSCRIPTIONS: 'subscriptions',
  GENERAL: 'general',
}

const { GENERAL, SUBSCRIPTIONS } = TABS_KEY

export const Tabs = () => {
  const { t } = useTranslation()

  const navigate = useNavigate()
  const location = useLocation()

  const basePath = `/billing`

  const TABS_CONFIG = {
    [SUBSCRIPTIONS]: {
      label: t('subscription_plans'),
      value: SUBSCRIPTIONS,
      path: `${basePath}/subscriptions`,
    },
    [GENERAL]: {
      label: t('general'),
      value: GENERAL,
      path: `${basePath}`,
      isActive: (path: string) => path === `${basePath}`,
    },
  }

  const tabsConfigValues = Object.values(TABS_CONFIG)

  const tabs = useMemo(() => {
    return tabsConfigValues.map(({ label, value }) => ({
      label,
      value,
    }))
  }, [])

  const onTabChange = (value: string) => {
    const tabConfig = TABS_CONFIG[value]

    navigate(`${tabConfig?.path}`)
  }

  const tabsValue = useMemo(() => {
    const pathName = location.pathname

    for (let i = 0; i < tabsConfigValues.length; i++) {
      const item = tabsConfigValues[i]

      if (item.isActive) {
        if (item.isActive(pathName)) {
          return item.value
        }
      } else {
        const resultIndex = pathName.indexOf(item.path)
        if (resultIndex > -1) {
          return item.value
        }
      }
    }

    return ''
  }, [location])

  return (
    <>
      <div className={'flex self-end min-w-0'}>
        <NavMenuBar value={tabsValue} onChange={onTabChange} tabs={tabs} />
      </div>
    </>
  )
}
