import React, { ChangeEvent } from 'react'
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form'
import { Select, SelectProps } from '../../Select'

interface TextInputControllerProps<T extends FieldValues> extends SelectProps {
  control: Control<T, any>
  name: FieldPath<T>
  shouldUnregister?: boolean
  defaultValue?: any
  meta?: any
}

export const SelectController = <T extends FieldValues>({
  name,
  control,
  shouldUnregister,
  defaultValue,
  ...rest
}: TextInputControllerProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue}
      render={({ field: { ref, ...field }, fieldState, formState }) => {
        const meta = {
          error: fieldState.error,
        }

        return (
          <Select
            {...field}
            onChange={(value) => field.onChange(value as any)}
            fieldRef={ref}
            {...rest}
            meta={meta}
          />
        )
      }}
    />
  )
}
