import { createStyles, rem } from '@mantine/core'

export interface StylesParams {
  height: number
}

export default createStyles((theme, _params: StylesParams) => {
  const dark = theme.colorScheme === 'dark'

  const { height } = _params

  const variant = 'light'

  const colors = theme.fn.variant({ variant, color: theme.primaryColor })

  return {
    main: {
      display: 'flex',
      width: '100%',
      backgroundColor: colors.background,
      minHeight: rem(height),
      alignItems: 'center',

      ['&:hover']: {
        backgroundColor: colors.hover,
      },
    },

    content: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      width: '100%',
      padding: '0.25rem',
      color: colors.color,
    },
  }
})
