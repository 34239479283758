import React from 'react'
import { useTranslation } from 'react-i18next'
import { Group } from '@mantine/core'
import { Button } from '@/components/Elements'
import { Footer } from '@/features/project-facade/components/ProjectFacadeDataEntry/Layouts/ProjectFacadeDataEntryPage'

type ActionProps = {
  title?: any
  btnProps?: any
}

interface GeneralBottomBarProps {
  height?: number
  config?: {
    save?: boolean
    back?: boolean
    next?: boolean
  }
  onSave?: () => void
  onBack?: () => void
  onNext?: () => void
  saveProps?: ActionProps
  backProps?: ActionProps
  nextProps?: ActionProps
}

const defaultConfig = {
  save: false,
  back: false,
  next: false,
}

export const GeneralBottomBar = ({
  height,
  config,
  onSave,
  onBack,
  onNext,
  saveProps,
  backProps,
  nextProps,
}: GeneralBottomBarProps) => {
  const { t } = useTranslation()

  config = {
    ...defaultConfig,
    ...config,
  }

  const { save, back, next } = config

  return (
    <Footer height={height}>
      <Group spacing={'xl'} className={'w-full h-full'}>
        {save && (
          <Button variant={'default'} onClick={onSave} {...saveProps?.btnProps}>
            {saveProps?.title || t('save')}
          </Button>
        )}

        {(back || next) && (
          <div className={'flex grow justify-end'}>
            <Group>
              {back && (
                <Button variant={'default'} onClick={onBack} {...backProps}>
                  {backProps?.title || t('back')}
                </Button>
              )}

              {next && (
                <Button onClick={onNext} {...nextProps?.btnProps}>
                  {nextProps?.title || t('next')}
                </Button>
              )}
            </Group>
          </div>
        )}
      </Group>
    </Footer>
  )
}
