import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  root: {},

  wrap: {},

  nav: {
    ['&:hover']: {
      textDecoration: 'none',
    },
    fontSize: theme.fontSizes.xs,
    textTransform: 'uppercase',
    fontWeight: 700,
  },
}))
