import { UploadCompanyLogoDTO } from '@/features/profile/types'
import { userData } from '@/features/user/store'
import { profileApi } from '../../api/slice'

export const profileCompanyLogoApi = profileApi.injectEndpoints({
  endpoints: (builder) => ({
    profileCompanyLogoUpload: builder.mutation<any, UploadCompanyLogoDTO>({
      query: (arg) => {
        const formData = new FormData()
        formData.append('logo', arg.logo)

        return {
          url: 'profile/company-logo',
          method: 'POST',
          body: formData,
        }
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const data = await queryFulfilled
        dispatch(userData.updateData({ companyLogo: { data: data.data } }))
      },
      transformResponse: (response: any) => {
        return response?.data
      },
    }),
    profileCompanyLogoDelete: builder.mutation<void, void>({
      query: () => ({
        url: 'profile/company-logo',
        method: 'DELETE',
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const data = await queryFulfilled
        dispatch(userData.updateData({ companyLogo: null }))
      },
    }),
  }),
  overrideExisting: false,
})

export const { useProfileCompanyLogoUploadMutation, useProfileCompanyLogoDeleteMutation } =
  profileCompanyLogoApi
