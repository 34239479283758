import React from 'react'
import { projectFacadeHelper } from '@/features/project-facade/helpers'

export const useProjectFacadeStaticOptions = () => {
  const sectionsMap = projectFacadeHelper.general.sectionsMap

  const getSections = projectFacadeHelper.general.getSections

  const getAdjacentSections = projectFacadeHelper.general.getAdjacentSections

  return {
    sectionsMap,
    getAdjacentSections,
    getSections,
  }
}
