import { createStyles } from '@mantine/styles'

export default createStyles((theme, props: any) => ({
  wrap: {
    flex: '1 1 0%',
    display: 'flex',
    flexDirection: 'column',
  },

  body: {
    flex: '1 1 0%',
    display: 'flex',
    minWidth: 0,
  },

  main: {
    flex: '1 1 0%',
    display: 'flex',
    flexDirection: 'column',
    minWidth: 0,
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: 0,
    overflow: 'auto',
    padding: '1.25rem 1rem',
  },
}))
