import { createStyles, rem } from '@mantine/core'

export default createStyles((theme, _params) => {
  return {
    body: {
      flex: '1 1 0%',
      display: 'flex',
      flexDirection: 'column',
    },

    main: {
      flex: '1 1 0%',
      display: 'flex',
      flexDirection: 'column',
      minWidth: 0,
      flexGrow: 1,
      // height: 0,
    },

    content: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      height: 0,
    },
  }
})
