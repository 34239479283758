import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { ButtonProps, Group, Text } from '@mantine/core'
import { Anchor, Icon } from '@/components/Elements'

interface IProps extends ButtonProps {
  children?: React.ReactNode
  onBack?: (event: React.MouseEvent<HTMLElement>) => void
  title?: string
}

export const BackButton: React.FC<IProps> = ({ children, onBack, title, ...props }) => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const handleBack = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()

    if (onBack) {
      onBack(event)
    } else {
      navigate(-1)
    }
  }

  title = title || t('back')

  return (
    <Anchor onClick={handleBack} truncate className={'whitespace-nowrap'}>
      {children || (
        <Group spacing={'xs'} noWrap>
          <Icon name={'chevron-left'} />

          <Text weight={600}>{title}</Text>
        </Group>
      )}
    </Anchor>
  )
}
