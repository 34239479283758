import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Group, MediaQuery } from '@mantine/core'
import { ButtonLink } from '@/components/Elements'
import { MainHeaderProvider } from '@/components/Layouts/Elements'
import { PATH } from '@/routes/const'

export const Header = () => {
  const { t } = useTranslation()

  return (
    <>
      <MainHeaderProvider
        rightSection={
          <Group spacing={'xl'}>
            <ButtonLink to={`/${PATH.LOGIN}`} variant={'default'}>
              {t('login')}
            </ButtonLink>

            <MediaQuery smallerThan="xs" styles={{ display: 'none' }}>
              <Box>
                <ButtonLink to={`/${PATH.REGISTER}`}>{t('register')}</ButtonLink>
              </Box>
            </MediaQuery>
          </Group>
        }
      />
    </>
  )
}
