import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { ProjectFacadeViewContainer } from '@/features/project-facade/components/ProjectFacade/ProjectFacadeDetails'

export const Facade = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'facade'} />

      <ProjectFacadeViewContainer />
    </>
  )
}
