import { MantineNumberSize, createStyles } from '@mantine/styles'
import { CommonUtils } from '@/utils'

export interface AppShellStylesParams {
  padding?: MantineNumberSize
  navbarOffsetBreakpoint?: MantineNumberSize
  asideOffsetBreakpoint?: MantineNumberSize
}

export default createStyles((theme, props: AppShellStylesParams) => ({
  wrap: {
    flex: '1 1 0%',
    display: 'flex',
    flexDirection: 'column',
  },

  body: {
    flex: '1 1 0%',
    display: 'flex',
    minWidth: 0,
  },

  main: {
    flex: '1 1 0%',
    display: 'flex',
    flexDirection: 'column',
    minWidth: 0,
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    height: 0,
    overflow: 'auto',
    padding: !CommonUtils.isNil(props.padding) ? props.padding : '1.25rem 1rem',
  },
}))
