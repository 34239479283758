import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { CommonInfoEditContainer } from '@/features/project-facade/components/ProjectFacadeDataEntry/ProjectFacadeEdit'

export const Common = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'edit_facade.common_info.title'} />

      <CommonInfoEditContainer />
    </>
  )
}
