import React from 'react'
import { Container } from '@mantine/core'
import useStyles from './BasicLayoutPage.styles'

interface IProps {
  children?: React.ReactNode
}

export const BasicLayoutPage = ({ children }: IProps) => {
  const { classes } = useStyles()

  return (
    <div className={classes.wrap}>
      <Container className={classes.container}>
        <div className={classes.main}>{children}</div>
      </Container>
    </div>
  )
}
