import React from 'react'
import { Navigate } from 'react-router-dom'
import { PrivateLayout } from '@/components/Layouts'
import { PanelLayout } from '@/features/panel/components/Layouts'
import { PanelSwitch, RouteGuard } from '@/routes'
import { getGeneralRoutes } from './routes'

const mapChildrenRoutes = (routs: Array<any>, user: object | null) => [
  ...routs.map(({ path, index, element, roles, guards }, i) => {
    return {
      key: `${i}-${path}`,
      path,
      index,
      element: (
        <RouteGuard roles={roles} guards={guards} user={user}>
          {element}
        </RouteGuard>
      ),
    }
  }),
]

export const getPrivateRoutes = (isLoggedIn: boolean, user: object | null) => {
  return [
    {
      path: '/*',
      element: isLoggedIn ? <PrivateLayout /> : <Navigate to={`/`} replace />,
      children: [
        {
          index: isLoggedIn,
          element: <PanelSwitch user={user} />,
        },
        {
          element: <PanelLayout />,
          children: [...mapChildrenRoutes(getGeneralRoutes(), user)],
        },
        {
          path: '*',
          element: <Navigate to={`/`} replace />,
        },
      ],
    },
  ]
}
