import React from 'react'
import { useTranslation } from 'react-i18next'
import { Image } from '@mantine/core'
import { PROJECT_FACADE_SECTION_ALIAS } from '@/features/project-facade/consts/project-facade'
import { ProjectFacade } from '@/features/project-facade/types'
import { Project } from '@/features/project/types'
import { SectionCard } from '../SectionCard'

const { PANE } = PROJECT_FACADE_SECTION_ALIAS

interface IProps {
  project: Project
  facade: ProjectFacade
}

export const Pane = ({ project, facade }: IProps) => {
  const { t } = useTranslation()

  const preview = (item: ProjectFacade) => item?.picture?.data?.full

  return (
    <>
      <SectionCard sectionAlias={PANE} project={project} facade={facade}>
        <Image
          alt={'facade-panel'}
          src={preview(facade) || null}
          withPlaceholder
          fit={'cover'}
          height={preview(facade) ? 'auto' : '300px'}
        />
      </SectionCard>
    </>
  )
}
