import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TextInputController } from '@/components/FormElements'

type FormValues = {
  name: string
  reference_number: string
  architect: string
  building_address: string
}

export const GeneralSection = () => {
  const { t } = useTranslation()

  const { control } = useFormContext<FormValues>()

  return (
    <>
      <TextInputController
        control={control}
        name={'name'}
        id={'name'}
        label={t('project_name')}
        placeholder={t('project_name')}
        mb={'md'}
      />

      <TextInputController
        control={control}
        name={'reference_number'}
        id={'reference_number'}
        label={t('reference_number')}
        placeholder={t('reference_number')}
        mb={'md'}
      />

      <TextInputController
        control={control}
        name={'architect'}
        id={'architect'}
        label={t('architect')}
        placeholder={t('architect')}
        mb={'md'}
      />

      <TextInputController
        control={control}
        name={'building_address'}
        id={'building_address'}
        label={t('building_address')}
        placeholder={t('building_address')}
        mb={'md'}
      />
    </>
  )
}
