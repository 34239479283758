import { yupResolver } from '@hookform/resolvers/yup'
import React, { useState } from 'react'
import { flushSync } from 'react-dom'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Divider, Group, Space } from '@mantine/core'
import { Alert, Button } from '@/components/Elements'
import { PasswordInputController, PasswordStrengthBar } from '@/components/FormElements'
import { PASSWORD_MIN_LENGTH } from '@/const/form'
import { useFormSubmit } from '@/hooks'
import { usePasswordStrengthMeter } from '@/packages/password-strength-meter'
import { validationSchema } from './validation'

interface IProps {
  onSubmit: (values: object) => Promise<void>
}

export const PasswordForm = ({ ...props }: IProps) => {
  const { t } = useTranslation()

  const [passScore, setPassScore] = useState(0)

  const methods = useForm({
    defaultValues: {
      current_password: '',
      new_password: '',
      new_password_confirmation: '',
    },
    resolver: yupResolver(validationSchema),
    context: { passScore },
  })

  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: { isSubmitting },
    watch,
    setValue,
    trigger,
  } = methods

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: props.onSubmit,
    setError,
  })

  const onSubmit: SubmitHandler<any> = async (data) => {
    try {
      await onFormSubmit(data)
      reset()
    } catch (error) {}
  }

  const watchPassword = watch('new_password')

  const { measure } = usePasswordStrengthMeter()

  const onPasswordChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const pass = event.target.value

    setValue('new_password', pass)

    await flushSync(async () => {
      const score = measure(pass)?.score
      setPassScore(score)
    })

    await trigger('new_password')
  }

  return (
    <>
      <FormProvider {...methods}>
        {submitError && (
          <Alert type={'error'} mb={'sm'}>
            {submitError?.data?.message || t('error')}
          </Alert>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <PasswordInputController
            control={control}
            name={'current_password'}
            id={'current_password'}
            label={t('current_password')}
            placeholder={t('current_password')}
            mb={'md'}
            translateParams={{ count: PASSWORD_MIN_LENGTH }}
          />

          <PasswordInputController
            control={control}
            name={'new_password'}
            id={'new_password'}
            label={t('new_password')}
            placeholder={t('new_password')}
            mb={'md'}
            translateParams={{ count: PASSWORD_MIN_LENGTH }}
            onChange={onPasswordChange}
          />

          {watchPassword && <PasswordStrengthBar mb={'md'} score={passScore} />}

          <PasswordInputController
            control={control}
            name={'new_password_confirmation'}
            id={'new_password_confirmation'}
            label={t('confirm_new_password')}
            placeholder={t('confirm_new_password')}
            mb={'md'}
            translateParams={{ count: PASSWORD_MIN_LENGTH }}
          />

          <Divider mb={'md'} />

          <Space h={'xl'} />

          <Group mt={'xl'} position={'right'}>
            <Button type={'submit'} loading={isSubmitting}>
              {t('save')}
            </Button>
          </Group>
        </form>
      </FormProvider>
    </>
  )
}
