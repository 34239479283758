import React from 'react'
import { useTranslation } from 'react-i18next'
import { useProjectDeleteMutation } from '@/features/project/store'
import { useNotify } from '@/hooks'

export const useProjectRemove = () => {
  const { t } = useTranslation()

  const { showNotification } = useNotify()

  const [remove, { isLoading }] = useProjectDeleteMutation()

  const onRemove = async (id: string) => {
    try {
      await remove(id).unwrap()

      showNotification({
        type: 'success',
        message: t('removed_successfully'),
      })
    } catch (error: any) {
      const message = error?.message || t('error')
      showNotification({ type: 'error', message: message })
    }
  }

  return {
    onRemove,
    loading: isLoading,
  }
}
