import { TypedUseQueryHookResult } from '@reduxjs/toolkit/query/react'
import React, { useMemo } from 'react'
import { projectFacadesApi } from '@/features/project-facade/store'
import { ProjectFacade } from '@/features/project-facade/types'
import { useAppDispatch, useAppSelector } from '@/store'
import { CollectionDTO } from '@/types'

interface IProps {
  projectId: string
}

export const useProjectFacadesQueryState = ({ projectId }: IProps) => {
  const dispatch = useAppDispatch()

  const selectProjectFacadeEntries: any = useMemo(
    () => projectFacadesApi.endpoints.projectFacadeEntries.select({ projectId }),
    [projectId]
  )

  const state: TypedUseQueryHookResult<CollectionDTO<ProjectFacade>, any, any> = useAppSelector(
    selectProjectFacadeEntries
  )

  return {
    ...state,
  }
}
