import { createStyles, rem } from '@mantine/core'

export default createStyles((theme, _params, getRef) => {
  const height = rem(80)

  return {
    imageWrapper: {
      height: '100%',
      minHeight: rem(60),
    },

    placeholder: {
      minHeight: height,

      ['svg']: {
        width: rem(24),
        height: rem(24),
        verticalAlign: 'top',
      },
    },
  }
})
