import React from 'react'
import useStyles from './ProjectFacadeDataEntryPage.styles'

interface IProps {
  children: React.ReactNode
  footer?: React.ReactElement
  classNames?: any
}

export const ProjectFacadeDataEntryPage = ({ children, footer, classNames }: IProps) => {
  const { classes } = useStyles({}, { name: 'ProjectFacadeDataEntryPage', classNames })

  return (
    <div className={classes.wrap}>
      <div className={classes.body}>
        <div className={classes.main}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>

      {footer && <>{footer}</>}
    </div>
  )
}
