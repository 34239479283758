import { skipToken } from '@reduxjs/toolkit/query/react'
import { debounce } from 'lodash'
import React, { useCallback, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDeepCompareEffect, useFirstMountState } from 'react-use'
import { Text } from '@mantine/core'
import { Alert, QueryDataShell } from '@/components/Elements'
import { FacadeSystemPickerController } from '@/features/facade/FacadeSystem/components/FormElements'
import { useProjectFacadeFacadeSystemEntriesQuery } from '@/features/project-facade/store'

type FormValues = {
  facade_materials: string[]
  facade_manufacturer_id: string
  facade_system_id: string | null
}

interface IProps {
  facadeId: string
}

export const FacadeSystemSection = ({ facadeId }: IProps) => {
  const { t } = useTranslation()

  const { control, getValues, setValue, watch } = useFormContext<FormValues>()

  const getFormValues = useCallback(() => {
    return getValues(['facade_materials', 'facade_manufacturer_id'])
  }, [getValues])

  const initialFilters = useMemo(() => {
    const [facade_materials, facade_manufacturer_id] = getFormValues()

    return {
      facade_materials: facade_materials || [],
      facade_manufacturer_id: facade_manufacturer_id || '',
    }
  }, [])

  const [filters, setFilters] = useState<{
    facade_materials: string[]
    facade_manufacturer_id: string
  }>(initialFilters)

  const queryParams = useMemo(() => {
    const { facade_materials, facade_manufacturer_id } = filters

    if (facade_materials.length !== 0 && facade_manufacturer_id) {
      return {
        id: facadeId,
        params: {
          search: {
            facade_material_id: facade_materials,
            facade_manufacturer_id: facade_manufacturer_id,
          },
          searchJoin: 'and',
          searchFields: { facade_material_id: 'in' },
          limit: 0,
        },
      }
    }

    return skipToken
  }, [filters])

  const { data, isLoading, isFetching, error, isError, isSuccess } =
    useProjectFacadeFacadeSystemEntriesQuery(queryParams)

  const handleSystemChange = () => {
    setValue('facade_system_id', null)
  }

  const watchMaterials = watch('facade_materials')
  const watchManufacturer = watch('facade_manufacturer_id')
  const watchSystem = watch('facade_system_id')

  const onFiltersChange = (value: {
    facade_materials: string[]
    facade_manufacturer_id: string
  }) => {
    setFilters((prevState) => ({
      ...prevState,
      ...value,
    }))
  }

  const onDebouncedChanged = useCallback(debounce(onFiltersChange, 700), [onFiltersChange])

  const isFirstMount = useFirstMountState()

  useDeepCompareEffect(() => {
    if (!isFirstMount) {
      onDebouncedChanged({
        facade_materials: watchMaterials,
        facade_manufacturer_id: watchManufacturer,
      })

      handleSystemChange()
    }
  }, [watchMaterials, watchManufacturer])

  const entries = data?.data || []

  const pickedSystem = useMemo(() => {
    return entries.find((item) => item.id === watchSystem)
  }, [watchSystem, isSuccess])

  const renderLabel = () => {
    let label = ''

    if (pickedSystem?.name) {
      label += pickedSystem?.name
    }

    if (pickedSystem?.article) {
      if (label) {
        label += ' - '
      }

      label += pickedSystem?.article
    }

    return (
      <Text mb={'sm'} fw={600}>
        {`${t('system')}:`}
        <Text ml={'sm'} c={'dimmed'} component={'span'}>
          {label ? `${label}` : null}
        </Text>
      </Text>
    )
  }

  return (
    <>
      <QueryDataShell
        isLoading={isLoading}
        isFetching={isFetching}
        error={error}
        isError={isError}
        isSuccess={isSuccess}
      >
        <div>
          {entries.length ? (
            <FacadeSystemPickerController
              control={control}
              label={renderLabel()}
              options={entries}
              name={'facade_system_id'}
              errorProps={{ mb: 'xs' }}
            />
          ) : (
            <Alert type={'info'}>{t('no_systems_found_matching_conditions')}</Alert>
          )}
        </div>
      </QueryDataShell>
    </>
  )
}
