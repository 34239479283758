import React from 'react'
import { useTranslation } from 'react-i18next'
import useStyles from './Style.styles'

interface IProps {
  header: React.ReactElement
  children: React.ReactNode
}

export const Shell = ({ header, children }: IProps) => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  return (
    <>
      <div className={classes.body}>
        {header}

        <div className={classes.main}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </>
  )
}
