import { createStyles } from '@mantine/core'

export default createStyles((theme) => {
  const dark = theme.colorScheme === 'dark'

  return {
    root: {},

    content: {
      backgroundColor: dark ? theme.colors.dark[7] : theme.colors.gray[0],
    },

    body: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
  }
})
