import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Divider, Title } from '@mantine/core'
import { GeneralFormSection } from '@/features/profile/components/FormSections'
import { AvatarManager } from '@/features/profile/components/Profile/Avatar'

export const GeneralSection = () => {
  const { t } = useTranslation()

  return (
    <>
      <Box mb={'md'}>
        <Title mb={'md'} order={4}>
          {t('individual_info')}
        </Title>

        <Box mb={'md'}>
          <AvatarManager />
        </Box>

        <GeneralFormSection emailProps={{ readOnly: true }} />

        <Divider />
      </Box>
    </>
  )
}
