import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Group } from '@mantine/core'
import useStyles from './NavMenu.styles'

interface IProps {
  children: React.ReactNode
}

export const NavMenu = ({ children }: IProps) => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  return (
    <>
      <Group spacing={'xl'}>{children}</Group>
    </>
  )
}
