import { createSlice } from '@reduxjs/toolkit'
import { ENTRIES_VIEW_MODE } from '@/const/layout'

interface IState {
  entriesViewMode: ENTRIES_VIEW_MODE
}

const initialState: IState = {
  entriesViewMode: ENTRIES_VIEW_MODE.TABLE,
}

const projectProjectsMyStateSlice = createSlice({
  name: 'projectProjectsMyState',
  initialState,
  reducers: {
    setEntriesViewMode(state, action) {
      state.entriesViewMode = action.payload
    },
  },
})

/**
 * @namespace
 * @property {function} setEntriesViewMode
 */

export const projectProjectsMyState = projectProjectsMyStateSlice.actions

export default projectProjectsMyStateSlice.reducer
