import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Box, Text } from '@mantine/core'
import { GeneralPageShell } from '@/features/panel/components/Layouts'
import { PROJECT_FACADE_SECTION_ALIAS } from '@/features/project-facade/consts/project-facade'
import { projectFacadeHelper } from '@/features/project-facade/helpers'
import { usePopup } from '@/hooks'
import { Header } from './Header'
import { Navbar } from './Navbar'

const { getProjectFacadesPath, getProjectFacadePathConfig } = projectFacadeHelper.routes

const { PRODUCT, GLAZING, PANE, PANE_GLAZING } = PROJECT_FACADE_SECTION_ALIAS

interface IProps {
  children: React.ReactNode
  isEdit?: boolean
  project: {
    id: string
    name: string
  }
  facade?: {
    id: string
    name: string
  }
}

export const ProjectFacadeDataEntryPanel = ({ children, project, facade, isEdit }: IProps) => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const navbarDrawer = usePopup()

  const renderTitle = () => (
    <Box className={'min-w-0'}>
      <Text size={'lg'} fw={'700'} className={'whitespace-nowrap'} truncate>
        {project?.name} {` - `}
        <Text span c="dimmed">
          {!isEdit ? t('add_new_facade') : facade?.name ? facade?.name : ''}
        </Text>
      </Text>
    </Box>
  )

  const onBack = () => {
    let path = ''
    if (isEdit && facade?.id) {
      path = getProjectFacadePathConfig(project?.id, facade?.id).view
    } else {
      path = getProjectFacadesPath(project?.id)
    }
    navigate(path)
  }

  const navConfig = useMemo(() => {
    return !isEdit
      ? { [PRODUCT]: false, [GLAZING]: false, [PANE]: false, [PANE_GLAZING]: false }
      : {}
  }, [isEdit])

  return (
    <GeneralPageShell
      navbar={
        <Navbar
          drawerVisible={navbarDrawer.visible}
          onDrawerClose={navbarDrawer.close}
          navConfig={navConfig}
        />
      }
      header={<Header onDrawerOpen={navbarDrawer.open} title={renderTitle()} onBack={onBack} />}
      padding={0}
    >
      {children}
    </GeneralPageShell>
  )
}
