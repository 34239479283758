import { SortDirection } from '@tanstack/react-table'
import React from 'react'
import { ActionIcon, ActionIconProps, useMantineTheme } from '@mantine/core'
import { Icon } from '@/components/Elements'
import useStyles from './Component.styles'

interface IProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  isSorted?: SortDirection | false
  classNames?: any
  btnProps?: ActionIconProps
}

export const SortButton = ({ isSorted, onClick, btnProps, classNames, ...props }: IProps) => {
  const { classes } = useStyles({ isSorted }, { name: 'TableCellSortButton', classNames })

  const theme = useMantineTheme()

  return (
    <ActionIcon
      color={isSorted ? theme.primaryColor : undefined}
      size="xs"
      onClick={onClick}
      {...btnProps}
    >
      {isSorted === 'desc' ? (
        <Icon name={'sort-desc'} />
      ) : isSorted === 'asc' ? (
        <Icon name={'sort-asc'} />
      ) : (
        <Icon name={'arrows-sort'} />
      )}
    </ActionIcon>
  )
}
