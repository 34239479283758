import React from 'react'
import { useTranslation } from 'react-i18next'
import { HtmlContent } from '@/components/Elements'
import { LegalCard } from '@/features/legal/components/Elements'
import { contentHelper } from '@/features/legal/helpers'

export const ImprintDisclaimerContainer = () => {
  const { t } = useTranslation()

  return (
    <LegalCard title={t('imprint_and_disclaimer.title')}>
      <HtmlContent data={contentHelper.imprintDisclaimer.getContent()} />
    </LegalCard>
  )
}
