import React from 'react'
import { Navigate } from 'react-router-dom'
import { BasicLayout } from '@/components/Layouts'
import { LegalRoot } from '@/features/legal/routes'
import { PATH } from '@/routes/const'
import { getHelpRoutes } from './routes'

//config
export const getPublicRoutes = (isLoggedIn: boolean) => {
  const helpRoutes = !isLoggedIn
    ? getHelpRoutes().map(({ path, element }) => ({
        path,
        element,
      }))
    : []

  return [
    {
      path: '/*',
      element: <BasicLayout />,
      children: [
        ...helpRoutes,
        {
          path: `${PATH.LEGAL}/*`,
          element: <LegalRoot />,
        },
      ],
    },
  ]
}
