import { GlazingTemplate } from '@/features/glazing/GlazingTemplate/types'
import { projectFacadesApi } from '@/features/project-facade/store'
import { SyncGlazingTemplateDTO } from '@/features/project-facade/types'
import { CollectionDTO } from '@/types'
import { RTKQueryUtils, RequestUtils } from '@/utils'

const { cacher } = RTKQueryUtils

export const ProjectFacadeGlazingTemplateTag = 'ProjectFacadeGlazingTemplate'

const enhanceApi = projectFacadesApi.enhanceEndpoints({
  addTagTypes: [ProjectFacadeGlazingTemplateTag],
})

export const projectFacadeGlazingTemplatesApi = enhanceApi.injectEndpoints({
  endpoints: (builder) => ({
    projectFacadeGlazingTemplateEntries: builder.query<
      CollectionDTO<GlazingTemplate>,
      {
        id: string
        params: any
      }
    >({
      query: ({ id, params }) => ({
        url: `project-facades/${id}/glazing-templates`,
        params: RequestUtils.getMapRequestParams(params),
      }),
      providesTags: cacher.providesNestedList(ProjectFacadeGlazingTemplateTag),
    }),
    projectFacadeGlazingTemplatesSync: builder.mutation<
      any,
      { id: string; params: SyncGlazingTemplateDTO }
    >({
      query: (arg) => {
        const { id, params } = arg

        return {
          url: `project-facades/${id}/sync-glazing-templates`,
          method: 'POST',
          body: params,
        }
      },
      invalidatesTags: (result, error, arg) => {
        if (!error) {
          const projectFacadeGlazingTemplateList = cacher.invalidatesList(
            ProjectFacadeGlazingTemplateTag
          )()

          return [...projectFacadeGlazingTemplateList]
        }
        return []
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useProjectFacadeGlazingTemplateEntriesQuery,
  useProjectFacadeGlazingTemplatesSyncMutation,
} = projectFacadeGlazingTemplatesApi
