import { rootApi } from '@/store/api'
import { FacadeGlass } from '@/features/facade/FacadeGlass/types'
import { CollectionDTO } from '@/types'
import { RTKQueryUtils, RequestUtils } from '@/utils'

const { cacher } = RTKQueryUtils

export const FacadeGlassTag = 'FacadeGlass'

const enhanceApi = rootApi.enhanceEndpoints({ addTagTypes: [FacadeGlassTag] })

export const facadeGlassesApi = enhanceApi.injectEndpoints({
  endpoints: (builder) => ({
    facadeGlassesEntries: builder.query<CollectionDTO<FacadeGlass>, { params: any }>({
      query: (arg) => ({
        url: `facade-glasses`,
        params: RequestUtils.getMapRequestParams(arg.params),
      }),
      providesTags: cacher.providesNestedList(FacadeGlassTag),
    }),
  }),
  overrideExisting: false,
})

export const { useFacadeGlassesEntriesQuery } = facadeGlassesApi
