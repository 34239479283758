import React from 'react'
import { Container } from '@mantine/core'
import useStyles from './GeneralPanelPage.styles'

interface IProps {
  children: React.ReactNode
  header?: React.ReactNode
}

export const GeneralPanelPage = ({ children, header }: IProps) => {
  const { classes } = useStyles()

  return (
    <div className={classes.wrap}>
      {header && <>{header}</>}

      <div className={classes.main}>
        <div className={classes.content}>
          <Container className={classes.container}>{children}</Container>
        </div>
      </div>
    </div>
  )
}
