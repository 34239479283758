import React from 'react'
import { UnitUtils } from '@/utils'

export const useUnitOfMeasure = () => {
  const measure = UnitUtils.measure

  return {
    measure,
  }
}
