import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '@/components/Elements'
import { Project } from '@/features/project/types'
import { ProjectCreateContainer } from './ProjectCreateContainer'

interface IProps {
  visible: boolean
  onClose: () => void
  onSuccess?: (data: Project) => void
}

export const ProjectCreate = ({ visible, onClose, onSuccess }: IProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Modal opened={visible} onClose={onClose} title={t('new_project')}>
        <ProjectCreateContainer onCancel={onClose} onSuccess={onSuccess} />
      </Modal>
    </>
  )
}
