export const MEASURE_LENGTH = {
  min: 0,
  max: 999999,
}

export const MEASURE_WIND_LOAD = {
  min: 0,
  max: 999999,
}

export const MEASURE_WIND_SPEED_ZONE = {
  min: 0,
  max: 999999,
}
