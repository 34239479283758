import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { MainHeader, MainHeaderProps, NavLinkType } from '@/components/Layouts/Elements'
import { PATH, PATH_BILLING } from '@/routes/const'

interface IProps extends MainHeaderProps {
  type?: 'public' | 'private'
}

export const MainHeaderProvider = ({ type = 'public', ...props }: IProps) => {
  const { t } = useTranslation()

  const isPrivate = type === 'private'

  const menu: Array<NavLinkType> = [
    {
      path: isPrivate ? `/${PATH.BILLING}/${PATH_BILLING.SUBSCRIPTIONS}` : `/${PATH.OFFERS}`,
      label: t('our_offers'),
    },
    {
      path: `/${PATH.HELP}`,
      label: t('help'),
    },
  ]

  return <MainHeader menu={menu} {...props} />
}
