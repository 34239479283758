import React from 'react'
import { useTranslation } from 'react-i18next'
import { useProjectCreateMutation } from '@/features/project/store'
import { Project } from '@/features/project/types'
import { useNotify } from '@/hooks'
import { ProjectForm } from '../../ProjectForm'

interface IProps {
  onCancel: () => void
  onSuccess?: (data: Project) => void
}

export const ProjectCreateContainer = ({ onCancel, onSuccess }: IProps) => {
  const { t } = useTranslation()

  const [create] = useProjectCreateMutation()

  const { showNotification } = useNotify()

  const onSubmit = async (values: any) => {
    const params = {
      ...values,
    }

    const data = await create(params).unwrap()

    showNotification({
      type: 'success',
      message: t('saved_successfully'),
    })

    onSuccess && onSuccess(data)
  }

  return (
    <>
      <ProjectForm onSubmit={onSubmit} onCancel={onCancel} />
    </>
  )
}
