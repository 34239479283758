import React from 'react'
import { useTranslation } from 'react-i18next'
import { MegaTagTitle } from '@/components/Elements'
import { SupportContainer } from '@/features/help-center/components/Support'

export const Support = () => {
  const { t } = useTranslation()

  return (
    <>
      <MegaTagTitle title={'contact_us'} />

      <SupportContainer />
    </>
  )
}
