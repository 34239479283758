import { createStyles } from '@mantine/core'
import { HEADER_HEIGHT } from '@/const/layout'

export default createStyles((theme) => ({
  root: {},

  shell: {
    display: 'flex',
  },

  main: {
    flex: 1,
    minWidth: 0,
    minHeight: '100vh',
    display: 'flex',
    paddingTop: HEADER_HEIGHT,
  },

  content: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}))
