import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Group, Text, Title } from '@mantine/core'

interface GeneralTopBarProps {
  title: string
  subtitle: string
  rightSection?: React.ReactElement
}

export const GeneralTopBar = ({ title, subtitle, rightSection }: GeneralTopBarProps) => {
  const { t } = useTranslation()

  return (
    <Group mb={'xl'} align={'flex-end'}>
      <Box>
        <Title order={4}>{title}</Title>
        <Text size={'sm'}>{subtitle}</Text>
      </Box>

      {rightSection && <Box className={'flex grow justify-end'}>{rightSection}</Box>}
    </Group>
  )
}
