import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Group, Stack } from '@mantine/core'
import { Button } from '@/components/Elements'
import { GrowModalContentShell } from '@/components/Elements/Modals/ModalContents'
import { CheckboxField } from '@/components/FormElements'
import { TermsContent } from '@/features/legal/components/Terms'

interface IProps {
  onCancel: () => void
  onConfirm: () => void
}

export const TermsConfirm = ({ onCancel, onConfirm }: IProps) => {
  const { t } = useTranslation()

  const [accept, setAccept] = useState(false)

  return (
    <>
      <GrowModalContentShell
        footer={
          <Stack>
            <CheckboxField
              value={accept as any}
              onChange={(value) => setAccept(value as any)}
              id={'terms'}
              label={`${t('accept')} ${t('terms_and_conditions')}`}
              mt={'md'}
            />

            <Group mt={'xl'} position={'right'}>
              <Button variant="default" onClick={onCancel}>
                {t('cancel')}
              </Button>

              <Button disabled={!accept} onClick={onConfirm}>
                {t('confirm')}
              </Button>
            </Group>
          </Stack>
        }
      >
        <TermsContent />
      </GrowModalContentShell>
    </>
  )
}
