import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { ProjectFacadeInfoCard } from '@/features/project-facade/components/ProjectFacade/ProjectFacadeDetails'
import { PROJECT_FACADE_SECTION_ALIAS } from '@/features/project-facade/consts/project-facade'
import { projectFacadeHelper } from '@/features/project-facade/helpers'
import { useProjectFacadeStaticOptions } from '@/features/project-facade/hooks'
import { ProjectFacade } from '@/features/project-facade/types'
import { Project } from '@/features/project/types'

interface IProps {
  children: React.ReactNode
  sectionAlias: PROJECT_FACADE_SECTION_ALIAS
  project: Project
  facade: ProjectFacade
  title?: string
}

export const SectionCard = ({ children, sectionAlias, project, facade, title }: IProps) => {
  const { t } = useTranslation()

  const { sectionsMap } = useProjectFacadeStaticOptions()

  const facadeSection = sectionsMap.get(sectionAlias)

  const editPath = useMemo(() => {
    const route = projectFacadeHelper.routes.getProjectFacadePathConfig(project?.id, facade?.id)

    return `${route.edit}/${facadeSection?.path}`
  }, [project?.id, facade?.id])

  return (
    <>
      <ProjectFacadeInfoCard
        title={facadeSection?.label || title}
        editProps={{
          component: Link,
          to: editPath,
        }}
        mih={400}
        h={'100%'}
      >
        {children}
      </ProjectFacadeInfoCard>
    </>
  )
}
