export const FILE_MAX_SIZE = 2097152

export const PASSWORD_MIN_LENGTH = 8

export const KEYWORDS_MIN_LENGTH = 1

export const CHAT_FILE_MAX_SIZE = 15728640

export const PHONE_DIGITS_LENGTH = {
  min: 9,
  max: 15,
}

export const COMPANY_UID_LENGTH = {
  min: 10,
}

export const COMPANY_UID_REGEXP = /^([a-zA-Z]{2,4})([a-zA-Z0-9]+)?([0-9]{8,12})$/
