import { createStyles } from '@mantine/core'

export default createStyles((theme, _params) => {
  return {
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100%',
    },
  }
})
