import { createStyles, rem } from '@mantine/core'

export interface StylesParams {
  height: number
}

export default createStyles((theme, _params: StylesParams) => {
  const dark = theme.colorScheme === 'dark'

  const { height } = _params

  return {
    main: {
      display: 'flex',
      borderTop: `${rem(1)} solid ${dark ? theme.colors.dark[5] : theme.colors.gray[2]}`,
      minHeight: rem(height),
      alignItems: 'center',
    },

    content: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      width: '100%',
      padding: '0.5rem 1rem',
    },
  }
})
