import React from 'react'
import { useTranslation } from 'react-i18next'
import { List, Text } from '@mantine/core'
import { FacadeSystem } from '@/features/facade/FacadeSystem/types'
import { MEASURE_UNIT_FACADE_SYSTEM_TIEFE } from '@/features/measure/consts/unit'
import { useUnitStaticOptions } from '@/features/measure/hooks'
import useStyles from './FeaturesBlock.styles'

interface IProps {
  data: FacadeSystem
}

export const FeaturesBlock = ({ data }: IProps) => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  const { material_type, tiefe, variation } = data

  const { facadeSystemTiefeUnitsMap } = useUnitStaticOptions()

  const items = [
    {
      label: t('material_type'),
      value: material_type,
    },
    {
      label: t('variation'),
      value: variation,
    },
    {
      label: t('tiefe'),
      value: `${tiefe} ${facadeSystemTiefeUnitsMap.get(MEASURE_UNIT_FACADE_SYSTEM_TIEFE.mm).label}`,
    },
  ]

  return (
    <div className={classes.root}>
      <List listStyleType={'none'} spacing={'xs'} fz={'sm'}>
        {items.map(({ label, value }, index) => (
          <List.Item key={index}>
            <Text fw={'600'}>{label}:</Text>
            {value}
          </List.Item>
        ))}
      </List>
    </div>
  )
}
