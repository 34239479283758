import { createStyles } from '@mantine/core'
import { CSSObject, MantineTheme, Variants } from '@mantine/styles'

interface NavLinkStylesParams {
  variant?: Variants<'filled' | 'light' | 'subtle' | 'default'>
}

interface GetVariantStylesInput {
  theme: MantineTheme
  variant?: Variants<'filled' | 'light' | 'subtle' | 'default'>
}

function getVariantStyles({ theme, variant }: GetVariantStylesInput): CSSObject {
  if (variant === 'default') {
    const color = theme.fn.variant({ variant }).color
    const backgroundColor = theme.white

    return {
      borderRadius: theme.radius.xs,

      '&[data-active]': {
        color,
        backgroundColor,
        ...theme.fn.hover({
          color,
          backgroundColor,
        }),
      },

      ...theme.fn.hover({
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[1],
      }),
    }
  }

  return {}
}

export default createStyles((theme, props: NavLinkStylesParams) => ({
  root: {
    ...getVariantStyles({ theme, variant: props.variant }),
  },
}))
