import React from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '@mantine/core'
import { FallProtection } from '@/features/facade/FallProtection/types'
import useStyles from './DescriptionBlock.styles'

interface IProps {
  data: FallProtection
}

export const DescriptionBlock = ({ data }: IProps) => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  const { description } = data

  return (
    <div className={classes.root}>
      <Text fz={'xs'}>{description}</Text>
    </div>
  )
}
