import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { ProjectFacadeEditPanel } from '@/features/project-facade/components/ProjectFacadeDataEntry/ProjectFacadeEdit'
import { PATH_PROJECTS_FACADES_EDIT, PATH_PROJECTS_FACADES_EDIT_PANE } from '@/routes/const'
import { Common } from '../common'
// import { Glazing } from '../glazing'
import { Pane } from '../pane'
import { PaneEditor } from '../pane-editor'
import { PaneGlazing } from '../pane-glazing'
import { Product } from '../product'

const { COMMON, PANE, PRODUCT, GLAZING, PANE_GLAZING } = PATH_PROJECTS_FACADES_EDIT
const { EDITOR } = PATH_PROJECTS_FACADES_EDIT_PANE

export const FacadeEditRoot = () => {
  return (
    <Routes>
      <Route index element={<Navigate to={COMMON} replace={true} />} />

      <Route element={<ProjectFacadeEditPanel />}>
        <Route path={`${COMMON}`} element={<Common />} />
        <Route path={`${PANE}`} element={<Pane />} />
        <Route path={`${PANE_GLAZING}`} element={<PaneGlazing />} />
        <Route path={`${PRODUCT}`} element={<Product />} />
        {/*<Route path={`${GLAZING}`} element={<Glazing />} />*/}
      </Route>

      <Route path={`${PANE}/${EDITOR}`} element={<PaneEditor />} />

      <Route path={'*'} element={<Navigate to={'../..'} replace={true} />} />
    </Routes>
  )
}
