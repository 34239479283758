import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Group, Space } from '@mantine/core'
import { Alert, Button } from '@/components/Elements'
import { useUnitOfMeasure } from '@/features/measure/hooks'
import { useFormSubmit } from '@/hooks'
import { DimensionsSection, GeneralSection, InfoSection, WindSpeedZoneSection } from './Sections'
import { validationSchema } from './validation'

type FormValues = {
  name: string
  reference_number: string
  architect: string
  building_address: string
  length: string
  width: string
  height: string
  // wind_speed_zone: string
  info: string
}

interface IProps {
  onCancel: () => void
  onSubmit: (values: object) => Promise<void>
  initialValues?: object
}

export const ProjectForm = ({ initialValues, onCancel, ...props }: IProps) => {
  const { t } = useTranslation()

  const methods = useForm<FormValues>({
    defaultValues: {
      name: '',
      reference_number: '',
      architect: '',
      building_address: '',
      length: '',
      width: '',
      height: '',
      // wind_speed_zone: '',
      info: '',
      ...initialValues,
    },
    resolver: yupResolver(validationSchema as any),
  })

  const {
    handleSubmit,
    setError,
    formState: { isSubmitting },
  } = methods

  const { error: submitError, onSubmit: onFormSubmit } = useFormSubmit({
    submit: props.onSubmit,
    setError,
  })

  const { measure } = useUnitOfMeasure()

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      const { length, width, height, ...resetValues } = data

      const values = {
        length: length !== null ? measure.length.mToCm(length) : null,
        width: width !== null ? measure.length.mToCm(width) : null,
        height: height !== null ? measure.length.mToCm(height) : null,
        ...resetValues,
      }

      await onFormSubmit(values)
    } catch (error) {}
  }

  return (
    <>
      <FormProvider {...methods}>
        {submitError && (
          <Alert type={'error'} mb={'sm'}>
            {submitError?.data?.message || t('error')}
          </Alert>
        )}

        <form onSubmit={handleSubmit(onSubmit)}>
          <GeneralSection />

          <DimensionsSection />

          {/*<WindSpeedZoneSection />*/}

          <InfoSection />

          <Space h={'xl'} />

          <Group mt={'xl'} position={'right'}>
            <Button variant="default" onClick={onCancel}>
              {t('cancel')}
            </Button>

            <Button type={'submit'} loading={isSubmitting}>
              {t('save')}
            </Button>
          </Group>
        </form>
      </FormProvider>
    </>
  )
}
