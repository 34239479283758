import React from 'react'
import { useTranslation } from 'react-i18next'
import { UnstyledButton, UnstyledButtonProps } from '@mantine/core'

interface IProps extends UnstyledButtonProps {
  children: React.ReactNode
}

export const Button = ({ children, ...props }: IProps) => {
  const { t } = useTranslation()

  return (
    <>
      <UnstyledButton {...props}>
        <div>{children}</div>
      </UnstyledButton>
    </>
  )
}
