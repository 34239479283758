export const DEFAULT_CONFIG = (width: any, height: any) => ({
  floors: [DEFAULT_NEW_FLOOR()],
})

export const DEFAULT_NEW_FLOOR = () => ({
  id: Date.now(),
  order: 0,
  height: 3000,
  upPertition: {
    height: 0,
  },
  underPertition: {
    height: 300,
  },
  windows: [DEFAULT_NEW_WINDOW()],
})

export const DEFAULT_NEW_WINDOW = () => ({
  id: Date.now(),
  width: 1500,
  burglaryType: false, // Boolean
  protectionType: null, // p1 - p7 || null => string or null
  isWindow: true,
  typeId: '', // facadePanels => id
  leftPertition: {
    width: 0,
  },
  rightPertition: {
    width: 0,
  },
})
