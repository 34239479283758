import React from 'react'
import { CardProps } from '@mantine/core'
import { InfoCard } from '@/components/Elements'
import { ActionBar } from './ActionBar'

interface IProps extends CardProps {
  children: React.ReactNode
  title: string
  subtitle?: string
  actionBarProps?: any
  editProps?: any
}

export const ProjectFacadeInfoCard = ({
  children,
  title,
  subtitle,
  actionBarProps,
  editProps,
  ...props
}: IProps) => {
  return (
    <>
      <InfoCard
        title={title}
        subtitle={subtitle}
        rightSection={<ActionBar {...actionBarProps} editProps={editProps} />}
        {...props}
      >
        {children}
      </InfoCard>
    </>
  )
}
