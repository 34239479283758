import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { ProjectFacadeRoot } from '@/features/project-facade/routes'
import { ProjectPanel } from '@/features/project/components/Project'
import { PATH_PROJECTS } from '@/routes/const'

export const ProjectRoot = () => {
  return (
    <Routes>
      <Route element={<ProjectPanel />}>
        <Route index element={<Navigate to={PATH_PROJECTS.FACADES} replace={true} />} />

        <Route path={`${PATH_PROJECTS.FACADES}/*`} element={<ProjectFacadeRoot />} />
      </Route>

      <Route path={'*'} element={<Navigate to={'..'} replace={true} />} />
    </Routes>
  )
}
