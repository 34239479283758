import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TextAreaController } from '@/components/FormElements'

type FormValues = {
  info: string
}

export const InfoSection = () => {
  const { t } = useTranslation()

  const { control } = useFormContext<FormValues>()

  return (
    <>
      <TextAreaController
        control={control}
        name={'info'}
        id={'info'}
        label={t('project_info')}
        placeholder={t('project_info')}
        mb={'md'}
      />
    </>
  )
}
