import React from 'react'
import { useTranslation } from 'react-i18next'
import { useBillingSubscriptionCheckoutMutation } from '@/features/billing/store'
import { useNotify } from '@/hooks'

export const useSubscriptionCheckout = () => {
  const { t } = useTranslation()

  const { showNotification } = useNotify()

  const [checkout, { isLoading }] = useBillingSubscriptionCheckoutMutation()

  const onSubmit = async (id: string, onSuccess?: (data: object) => void) => {
    try {
      const data = await checkout({
        params: { tariff_id: id },
      }).unwrap()

      const { success, data: url, message } = data

      if (success) {
        window.open(url, '_blank')
      } else {
        showNotification({ type: 'error', message: message })
      }

      onSuccess && onSuccess({ ...data })
    } catch (error: any) {
      const message = error?.message || t('error')
      showNotification({ type: 'error', message: message })
    }
  }

  return {
    onSubmit,
    loading: isLoading,
  }
}
