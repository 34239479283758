import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mantine/core'
import { Card } from '@/components/Elements'
import { Project } from '@/features/project/types'
import { ActionBar } from './ActionBar'
import useStyles from './Card.styles'
import { MediaBlock } from './MediaBlock'
import { TopBar } from './TopBar'

interface IProps {
  data: Project
  actionBar?: boolean
  actionBarSection?: React.ReactNode
  actionBarProps?: any
  editProps?: any
  removeProps?: any
}

export const GeneralProjectCard = ({
  data,
  actionBar = true,
  actionBarSection,
  actionBarProps,
  editProps,
  removeProps,
}: IProps) => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  return (
    <Card px={'xs'} className={classes.root}>
      <div className={classes.header}>
        <div className={classes.topBar}>
          <TopBar data={data} />
        </div>

        {actionBar && (
          <div className={classes.actionBar}>
            {actionBarSection ? (
              actionBarSection
            ) : (
              <ActionBar
                data={data}
                {...actionBarProps}
                editProps={editProps}
                removeProps={removeProps}
              />
            )}
          </div>
        )}
      </div>

      <Box mt={'xs'}>
        <MediaBlock data={data} />
      </Box>
    </Card>
  )
}
