import { MEASURE_UNIT_WIND_LOAD } from '@/features/measure/consts/unit'
import i18n from '@/i18n'

const { kn_m2 } = MEASURE_UNIT_WIND_LOAD

type OptionType = {
  label: string
  value: MEASURE_UNIT_WIND_LOAD
}

const localeData: any = {
  en: {
    [kn_m2]: 'kN/m²',
  },
  de: {
    [kn_m2]: 'kN/m²',
  },
}

const locale = (code: string) => {
  return localeData[code]
}

export const getWindLoadUnits = (): OptionType[] => {
  const code = i18n.language

  return [
    {
      label: locale(code).kn_m2,
      value: kn_m2,
    },
  ]
}

export const windLoadUnitsMap = getWindLoadUnits().reduce((acc: any, curr) => {
  const { value } = curr

  return acc.set(value, curr)
}, new Map())
