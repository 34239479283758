import { createStyles } from '@mantine/core'

export default createStyles((theme, _params) => {
  return {
    wrap: {
      flex: '1 1 0%',
      display: 'flex',
      flexDirection: 'column',
    },

    main: {
      flex: '1 1 0%',
      display: 'flex',
      flexDirection: 'column',
      minWidth: 0,
    },

    content: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      height: 0,
      overflow: 'auto',
      padding: '1.25rem 0',
    },

    container: {
      flex: 1,
      display: 'flex',
      minWidth: 0,
      width: '100%',
      flexDirection: 'column',
    },
  }
})
