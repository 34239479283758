import { combineReducers } from 'redux'
import { rootApi } from '@/store/api'
import app from '@/features/app/store/reducers'
import auth from '@/features/auth/store/reducers'
import project from '@/features/project/store/reducers'
import user from '@/features/user/store/reducers'

const rootReducer = combineReducers({
  [rootApi.reducerPath]: rootApi.reducer,
  auth,
  app,
  user,
  project,
})

export default rootReducer
