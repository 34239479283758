import React from 'react'
import { useTranslation } from 'react-i18next'
import { Modal } from '@/components/Elements'
import { Project } from '@/features/project/types'
import { ProjectEditContainer } from './ProjectEditContainer'

interface IProps {
  data: Project
  visible: boolean
  onClose: () => void
  onSuccess?: (data: any) => void
  queryOptions?: { invalidatesTagsById: boolean }
}

export const ProjectEdit = ({ data, visible, onClose, onSuccess, queryOptions }: IProps) => {
  const { t } = useTranslation()

  return (
    <>
      <Modal opened={visible} onClose={onClose} title={t('edit_project')}>
        <ProjectEditContainer
          data={data}
          onCancel={onClose}
          onSuccess={onSuccess}
          queryOptions={queryOptions}
        />
      </Modal>
    </>
  )
}
