import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Text } from '@mantine/core'
import { Project } from '@/features/project/types'
import { DateUtils } from '@/utils'
import useStyles from './TopBar.styles'

interface IProps {
  data: Project
}

export const TopBar = ({ data }: IProps) => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  const { name, modified_at } = data

  return (
    <Box className={classes.root}>
      <Text truncate weight={700} fz={'sm'}>
        {name}
      </Text>
      {modified_at && (
        <Text truncate fz={'xs'} color={'dimmed'}>
          {t('modified_on')} {DateUtils.formatDate(modified_at)}
        </Text>
      )}
    </Box>
  )
}
