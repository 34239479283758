import React from 'react'
import { MantineNumberSize } from '@mantine/core'
import useStyles from './Navbar.styles'

interface IProps {
  width: MantineNumberSize
  hiddenBreakpoint?: MantineNumberSize
  children?: React.ReactNode
  className?: string
}

export const Navbar = ({ width, hiddenBreakpoint = 'md', children, className }: IProps) => {
  const { classes, cx } = useStyles({
    width,
    hiddenBreakpoint,
  })

  return <div className={cx(classes.root, className)}>{children}</div>
}
