import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { Box, ScrollArea, Space } from '@mantine/core'
import { NavLink } from '@/features/panel/components/Layouts'
import { PROJECT_FACADE_SECTION_ALIAS } from '@/features/project-facade/consts/project-facade'
import { useProjectFacadeStaticOptions } from '@/features/project-facade/hooks'

const { COMMON, PRODUCT, GLAZING, PANE, PANE_GLAZING } = PROJECT_FACADE_SECTION_ALIAS

interface IProps {
  config?: { [key in PROJECT_FACADE_SECTION_ALIAS]?: boolean }
}

const defaultConfig = {
  [COMMON]: true,
  [PRODUCT]: true,
  [GLAZING]: true,
  [PANE]: true,
  [PANE_GLAZING]: true,
}

export const Content = ({ config = {} }: IProps) => {
  const { t } = useTranslation()

  config = {
    ...defaultConfig,
    ...config,
  }

  const location = useLocation()

  const locationPathname = location.pathname

  const currentRoutePath = useMemo(() => {
    let result = ''
    if (location) {
      result = locationPathname.substring(locationPathname.lastIndexOf('/') + 1)
    }

    return result
  }, [location])

  const { getSections } = useProjectFacadeStaticOptions()

  const menu = useMemo(() => {
    const sections = getSections()
    return sections.map(({ label, path, alias, order }) => ({
      label: `${t('step')} ${order + 1} - ${label}`,
      to: `${path}`,
      disabled: !config[alias],
    }))
  }, [config])

  return (
    <>
      <ScrollArea>
        <Space h={'xs'} />

        <Box px={'xs'}>
          {menu.map(({ label, to, disabled }, index) => (
            <NavLink
              active={currentRoutePath === to}
              key={index}
              label={label}
              variant={'default'}
              disabled={disabled}
              component={Link}
              to={to}
            />
          ))}
        </Box>

        <Space h={'xs'} />
      </ScrollArea>
    </>
  )
}
