import { createSlice } from '@reduxjs/toolkit'
import { authApi } from '@/features/auth/store'
import { OAuthModel } from '@/features/auth/types'
import { RootState } from '@/store'

interface AuthState {
  isLoggedIn: boolean
  oauth: OAuthModel | null
}

const initialState: AuthState = {
  isLoggedIn: false,
  oauth: null,
}

const authDataSlice = createSlice({
  name: 'authData',
  initialState,
  reducers: {
    setData(state, action) {
      state.isLoggedIn = action.payload.isLoggedIn
    },
    setOAuthData(state, action) {
      state.oauth = action.payload
    },
    cleanData() {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(authApi.endpoints.login.matchFulfilled, (state, action) => {
      state.isLoggedIn = true
      state.oauth = action.payload
    })

    builder.addMatcher(authApi.endpoints.refreshToken.matchFulfilled, (state, action) => {
      state.oauth = action.payload
    })

    builder.addMatcher(authApi.endpoints.logout.matchFulfilled, (state, action) => {
      return initialState
    })
  },
})

export const authData = authDataSlice.actions

export const selectAuthData = (state: RootState) => state.auth.data

export default authDataSlice.reducer
