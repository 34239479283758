import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Box, Group, Title, rem } from '@mantine/core'
import { Anchor, Icon } from '@/components/Elements'
import { Project } from '@/features/project/types'
import { PATH, PATH_PROJECTS, PATH_PROJECTS_FACADES } from '@/routes/const'
import useStyles from './Style.styles'

interface IProps {
  project?: Project
}

export const Header = ({ project }: IProps) => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  return (
    <div className={classes.root}>
      <Box px={'lg'} py={'md'}>
        <Group>
          <div className={'flex grow'}>
            <Title order={4}>{t('facades')}</Title>
          </div>

          {project && (
            <Anchor
              component={Link}
              to={`/${PATH.PROJECTS}/${project?.id}/${PATH_PROJECTS.FACADES}/${PATH_PROJECTS_FACADES.CREATE}`}
              fw={'600'}
            >
              <Group spacing={rem(8)} noWrap>
                <Icon name={'plus'} />
                {t('add')}
              </Group>
            </Anchor>
          )}
        </Group>
      </Box>
    </div>
  )
}
