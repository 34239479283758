import { createStyles } from '@mantine/core'

export default createStyles((theme) => {
  const dark = theme.colorScheme === 'dark'

  const primaryColor = theme.primaryColor
  const primaryShade = theme.fn.primaryShade()

  return {
    root: {
      backgroundColor: theme.colors[primaryColor][primaryShade],
      padding: 1,
    },

    label: {
      color: theme.white,
      padding: '0.25rem 2rem',

      '&:hover': {
        color: theme.white,
      },

      '&[data-active]': {
        '&, &:hover': {
          color: theme.colors[primaryColor][primaryShade],
        },
      },
    },

    indicator: {
      top: 4,
      left: 4.4,
    },
  }
})
