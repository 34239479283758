import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Box } from '@mantine/core'
import {
  GeneralBottomBar,
  GeneralTopBar,
  ProjectFacadeDataEntryPage,
} from '@/features/project-facade/components/ProjectFacadeDataEntry/Layouts'
import { PROJECT_FACADE_SECTION_ALIAS } from '@/features/project-facade/consts/project-facade'
import { projectFacadeHelper } from '@/features/project-facade/helpers'
import { useProjectFacadeData } from '@/features/project-facade/hooks'
import { useProjectFacadeStaticOptions } from '@/features/project-facade/hooks'
import { useProjectFacadeUpdateMutation } from '@/features/project-facade/store'
import { useProjectData } from '@/features/project/hooks'
import useStyles from './GlazingBrowser.styles'
import { ApplicationPane, FacadeSystem } from './Sections'

const sectionAlias = PROJECT_FACADE_SECTION_ALIAS.GLAZING
const { getProjectFacadePathConfig } = projectFacadeHelper.routes

export const GlazingBrowser = () => {
  const { t } = useTranslation()

  const { classes, cx } = useStyles()

  const navigate = useNavigate()

  const { project } = useProjectData({})

  const { facade } = useProjectFacadeData({})

  const [update] = useProjectFacadeUpdateMutation()

  // Submit
  const onSubmit = async (values: any) => {
    const params = {
      ...values,
    }

    // const data = await update({ id: facade?.id, params }).unwrap()
    //
    // if (data) {
    //   await onSetFacadeData(data)
    // }
  }
  // --- //

  const { sectionsMap, getAdjacentSections } = useProjectFacadeStaticOptions()

  const onNavigate = (pathname: string) => {
    navigate({
      pathname,
    })
  }

  const onSave = async () => {
    await onSubmit({})
  }

  const adjacentSectionsRoutes = useMemo(() => {
    const pathConfig = getProjectFacadePathConfig(project?.id, facade?.id)
    const { next, prev } = getAdjacentSections(sectionAlias)

    const basePath = pathConfig.edit

    return {
      prev: `${basePath}/${prev?.path}`,
      next: next ? `${basePath}/${next?.path}` : pathConfig.view,
    }
  }, [project?.id, facade?.id])

  const onNext = async () => {
    await onSubmit({})

    onNavigate(adjacentSectionsRoutes.next)
  }

  const onBack = async () => {
    onNavigate(adjacentSectionsRoutes.prev)
  }

  const facadeSection = sectionsMap.get(sectionAlias)

  return (
    <>
      <ProjectFacadeDataEntryPage
        footer={
          <GeneralBottomBar
            config={{ save: true, next: true, back: true }}
            saveProps={{ btnProps: { disabled: false } }}
            nextProps={{ btnProps: { disabled: false } }}
            onSave={onSave}
            onNext={onNext}
            onBack={onBack}
          />
        }
      >
        <div className={classes.block}>
          <GeneralTopBar
            title={`${t('step')} ${facadeSection.order + 1} - ${facadeSection.label}`}
            subtitle={t('please_fill_following_fields')}
          />

          <Box mb={'xl'}>
            <FacadeSystem />
          </Box>

          <div className={classes.block}>
            <div className={classes.gridWrapper}>
              <div className={cx(`${classes.grid} ${classes.gridParent}`)}>
                <div className={cx(`${classes.gridItem} ${classes.applicationPanelBlock}`)}>
                  <ApplicationPane />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ProjectFacadeDataEntryPage>
    </>
  )
}
