import React from 'react'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'
import { ContentContainer } from '@/components/Layouts/Elements'
import { GeneralPanelPage, GeneralTopBar } from '@/features/panel/components/Layouts'
import { Tabs } from './Tabs'

export const SettingsShell = () => {
  const { t } = useTranslation()

  return (
    <GeneralPanelPage
      header={
        <GeneralTopBar title={t('settings')}>
          <Tabs />
        </GeneralTopBar>
      }
    >
      <div>
        <ContentContainer>
          <Outlet />
        </ContentContainer>
      </div>
    </GeneralPanelPage>
  )
}
