import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mantine/core'
import { TextInputController } from '@/components/FormElements'

type FormValues = {
  company_name: string
  company_uid: string
  company_function: string
}

export const CompanyFormSection = () => {
  const { t } = useTranslation()

  const { control } = useFormContext<FormValues>()

  return (
    <>
      <Grid>
        <Grid.Col md={6}>
          <TextInputController
            control={control}
            name={'company_name'}
            id={'company_name'}
            label={t('company')}
            placeholder={t('company')}
            mb={'md'}
          />
        </Grid.Col>

        <Grid.Col md={6}>
          <TextInputController
            control={control}
            name={'company_uid'}
            id={'company_uid'}
            label={t('uid')}
            placeholder={t('uid')}
            mb={'md'}
          />
        </Grid.Col>
      </Grid>

      <Grid>
        <Grid.Col>
          <TextInputController
            control={control}
            name={'company_function'}
            id={'company_function'}
            label={t('function')}
            placeholder={t('function')}
            mb={'md'}
          />
        </Grid.Col>
      </Grid>
    </>
  )
}
