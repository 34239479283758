import { createStyles } from '@mantine/core'

export default createStyles((theme, _params, getRef) => ({
  root: {
    // borderSpacing: 0,
    // borderCollapse: 'separate',
    // overflow: 'hidden',

    ['thead tr th']: {
      borderBottom: `1px solid ${theme.fn.rgba(theme.colors.gray[4], 0.3)}`,
    },

    ['tbody tr td']: {
      borderBottom: `1px solid ${theme.fn.rgba(theme.colors.gray[4], 0.3)}`,
    },
  },
}))
