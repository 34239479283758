import { Global } from '@mantine/core'
import menloRegular from '@/assets/fonts/Menlo/Menlo-Regular.ttf'
import bold from '@/assets/fonts/OpenSans/OpenSans-Bold.ttf'
import light from '@/assets/fonts/OpenSans/OpenSans-Light.ttf'
import medium from '@/assets/fonts/OpenSans/OpenSans-Medium.ttf'
import regular from '@/assets/fonts/OpenSans/OpenSans-Regular.ttf'
import semiBold from '@/assets/fonts/OpenSans/OpenSans-SemiBold.ttf'

export function Fonts() {
  return (
    <Global
      styles={[
        {
          '@font-face': {
            fontFamily: 'Open Sans',
            src: `url('${light}')`,
            fontWeight: 300,
            fontStyle: 'normal',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Open Sans',
            src: `url('${regular}')`,
            fontWeight: 400,
            fontStyle: 'normal',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Open Sans',
            src: `url('${medium}')`,
            fontWeight: 500,
            fontStyle: 'normal',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Open Sans',
            src: `url('${semiBold}')`,
            fontWeight: 600,
            fontStyle: 'normal',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Open Sans',
            src: `url('${bold}')`,
            fontWeight: 700,
            fontStyle: 'normal',
          },
        },
        {
          '@font-face': {
            fontFamily: 'Menlo',
            src: `url('${menloRegular}')`,
            fontWeight: 400,
            fontStyle: 'normal',
          },
        },
      ]}
    />
  )
}
