import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Group } from '@mantine/core'
import { EntriesViewModeControl } from '@/components/Controls'
import { ENTRIES_VIEW_MODE } from '@/const/layout'
import useStyles from './Style.styles'

interface IProps {
  children?: React.ReactNode
  className?: string
  viewMode: ENTRIES_VIEW_MODE
  onViewModeChange: (value: ENTRIES_VIEW_MODE) => void
}

export const SubBar = ({ children, className, viewMode, onViewModeChange }: IProps) => {
  const { t } = useTranslation()

  const { classes, cx } = useStyles()

  return (
    <div className={cx(classes.root, className)}>
      <Container className={classes.container}>
        <Group spacing={'xl'} className={'w-full h-full flex-nowrap'}>
          {children && <>{children}</>}

          <div className={'flex grow justify-end'}>
            <EntriesViewModeControl value={viewMode} onChange={onViewModeChange} />
          </div>
        </Group>
      </Container>
    </div>
  )
}
