import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNotify } from '@/hooks'

interface IProps {
  useMutation: any
}

export const useRemoveQueryMutation = ({ useMutation }: IProps) => {
  const { t } = useTranslation()

  const { showNotification } = useNotify()

  const [remove, { isLoading }] = useMutation()

  const onRemove = async ({ id, params, options }: { id: string; params?: any; options?: any }) => {
    try {
      await remove({ id, params, options }).unwrap()

      showNotification({
        type: 'success',
        message: t('removed_successfully'),
      })
    } catch (error: any) {
      const message = error?.message || t('error')
      showNotification({ type: 'error', message: message })
    }
  }

  return {
    onRemove,
    loading: isLoading,
  }
}
