import React, { useMemo } from 'react'
import { FormProvider } from 'react-hook-form'
import { useDeepCompareEffect, useFirstMountState } from 'react-use'
import { useFilterForm } from '@/features/filter/hooks'
import { GENERAL_PROJECTS_FILTERS_INITIAL_VALUES } from '@/features/project/consts/projects'
import { GeneralFilterForm } from '../FilterForms/GeneralFilterForm'

type FormValues = {
  keywords: string
}

interface IProps {
  values: {
    keywords?: string
  }
  onFiltersChange: (data: any) => void
}

export const GeneralProjectsFilter = ({ values = {}, onFiltersChange }: IProps) => {
  const isFirstMount = useFirstMountState()

  const defaultValues = useMemo(() => {
    return {
      keywords: values?.keywords || '',
    }
  }, [values])

  const { methods, isApplied, onReset } = useFilterForm({
    config: {
      defaultValues: {
        ...defaultValues,
      },
    },
    onFiltersChange: (values: FormValues) => {
      const { keywords } = values
      onFiltersChange({
        ...values,
        keywords: keywords.trim(),
      })
    },
    initialValues: {
      ...GENERAL_PROJECTS_FILTERS_INITIAL_VALUES,
    },
  })

  const { reset } = methods

  useDeepCompareEffect(() => {
    if (!isFirstMount) {
      reset(defaultValues)
    }
  }, [defaultValues])

  return (
    <FormProvider {...methods}>
      <GeneralFilterForm />
    </FormProvider>
  )
}
