import { createStyles, rem } from '@mantine/core'

export default createStyles((theme, _params) => {
  return {
    header: {
      // borderBottom: 'none',
      // boxShadow: theme.shadows.xs,
    },
  }
})
