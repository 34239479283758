import { yupResolver } from '@hookform/resolvers/yup'
import React, { useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'
import { Group, Space, Text, Title } from '@mantine/core'
import { Alert, Anchor, Button } from '@/components/Elements'
import { PasswordInputController, TextInputController } from '@/components/FormElements'
import { PASSWORD_MIN_LENGTH } from '@/const/form'
import { useNotify } from '@/hooks'
import { useFormSubmit } from '@/hooks'
import { FormTypes } from '@/types'

export type FormValues = {
  email: string
  password: string
}

interface IProps {
  onSubmit: (values: FormValues) => Promise<void>
  onVerifyEmail: (email: string) => Promise<void>
}

export const LoginForm: React.FC<IProps> = (props) => {
  const { t } = useTranslation()

  const { showNotification } = useNotify()

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      email: '',
      password: '',
    },
    resolver: yupResolver(
      Yup.object().shape({
        email: Yup.string().email().required(),
        password: Yup.string().required().min(PASSWORD_MIN_LENGTH),
      })
    ),
  })

  const [submittedEmail, setSubmittedEmail] = useState<string | null>(null)

  const {
    error: submitError,
    onSubmit: onFormSubmit,
    resetState: submitResetState,
  } = useFormSubmit({
    submit: props.onSubmit,
    setError,
  })

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setSubmittedEmail(data.email)
    try {
      await onFormSubmit(data)
    } catch (error) {}
  }

  const onSendVerifyEmail = async (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()

    submitResetState()
    clearErrors()

    if (submittedEmail) {
      try {
        await props.onVerifyEmail(submittedEmail)
        showNotification({
          type: 'success',
          message: t('confirmation-email-sent'),
        })
      } catch (err) {
        const serverError = err as FormTypes.ValidationErrors
        const message = serverError?.message || t('error')
        showNotification({
          type: 'error',
          message,
        })
      }
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {submitError && (
          <Alert type={'error'} mb={'sm'}>
            {submitError?.data?.message || t('error')}
          </Alert>
        )}

        {submitError && submitError?.data?.errors?.unverified_email && (
          <Title order={6} my={'md'}>
            <Anchor
              component={'a'}
              href={'#'}
              onClick={(e: React.MouseEvent<HTMLElement>) => onSendVerifyEmail(e)}
            >
              {t('send_confirmation_email_to')}
            </Anchor>
            {` ${submittedEmail}`}
          </Title>
        )}

        <TextInputController
          control={control}
          name={'email'}
          id={'email'}
          label={t('email')}
          placeholder={t('email')}
          mb={'md'}
        />

        <PasswordInputController
          control={control}
          name={'password'}
          id={'password'}
          label={t('password')}
          placeholder={t('password')}
          mb={'md'}
          translateParams={{ count: PASSWORD_MIN_LENGTH }}
        />

        <Text fw={600} align={'right'}>
          <Anchor component={Link} to={'/forgot-password'}>
            {t('forgot_password')}?
          </Anchor>
        </Text>

        <Space h={'xl'} />

        <Group mt={'xl'} position={'right'}>
          <Button type={'submit'} loading={isSubmitting}>
            {t('login')}
          </Button>
        </Group>
      </form>
    </>
  )
}
