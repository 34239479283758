import React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { DataShower } from '@/components/Elements'
import { useProjectFacadesQueryState } from '@/features/project-facade/hooks'
import { Content } from './Content'

export const ProjectFacadesContainer = () => {
  const { t } = useTranslation()

  const { id } = useParams()

  if (!id) {
    return null
  }

  const { data, isLoading, error, isError, isSuccess } = useProjectFacadesQueryState({
    projectId: id,
  })

  const list = data?.data || []

  const entriesLength = list.length

  return (
    <div className={'flex flex-col justify-center items-center min-h-full'}>
      <DataShower
        isFetched={isSuccess || entriesLength !== 0}
        isLoading={isLoading && entriesLength === 0}
        isFailed={isError}
        error={error}
      >
        <Content projectId={id} isEmpty={entriesLength === 0} />
      </DataShower>
    </div>
  )
}
