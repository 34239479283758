import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { OverlayDataShower } from '@/components/Elements'
import {
  NavbarDrawer,
  Navbar as PanelNavbar,
} from '@/features/panel/components/Layouts/GeneralPageShell'
import { projectFacadeHelper } from '@/features/project-facade/helpers'
import {
  useProjectFacadeDeleteMutation,
  useProjectFacadeEntriesQuery,
} from '@/features/project-facade/store'
import { ProjectFacade } from '@/features/project-facade/types'
import { Project } from '@/features/project/types'
import { useInfiniteQuery, useInfiniteScroll, useRemoveQueryMutation } from '@/hooks'
import { Content } from './Content'
import { Header } from './Header'
import { Shell } from './Shell'
import useStyles from './Style.styles'

const { getProjectFacadesPath } = projectFacadeHelper.routes

interface IProps {
  project: Project
  drawerVisible: boolean
  onDrawerClose: () => void
}

const NAVBAR_WIDTH = 260

export const Navbar = ({ project, drawerVisible, onDrawerClose }: IProps) => {
  const { t } = useTranslation()

  const navigate = useNavigate()

  const { facadeId } = useParams()

  const { classes } = useStyles()

  const projectId = project?.id

  const {
    query,
    onPageChange,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    queryLoading,
  } = useInfiniteQuery<ProjectFacade, { projectId: string }>({
    useQuery: useProjectFacadeEntriesQuery,
    queryArg: {
      projectId,
      params: {
        limit: 20,
      },
    },
  })

  const { data, isError, error } = query

  const facades = data?.data || []

  // --- Remove --- //
  const projectFacadeRemove = useRemoveQueryMutation({
    useMutation: useProjectFacadeDeleteMutation,
  })

  const onRemove = async (data: ProjectFacade) => {
    await projectFacadeRemove.onRemove({ id: data.id, options: { invalidatesList: false } })

    onPageChange(1)

    if (data.id === facadeId) {
      navigate({ pathname: getProjectFacadesPath(projectId) }, { replace: true })
    }
  }
  // --- //

  const { lastRef } = useInfiniteScroll({
    isLoading: queryLoading,
    hasNext: hasNextPage,
    onLoad: fetchNextPage,
  })

  const renderContent = () => (
    <Shell header={<Header project={project} />}>
      <OverlayDataShower
        wrapClassName={classes.overlay}
        isLoading={isLoading || projectFacadeRemove.loading}
        isFailed={isError}
        error={error}
      >
        <Content
          lastRef={lastRef}
          projectId={projectId}
          facades={facades}
          onRemove={onRemove}
          isFetchingNextPage={isFetchingNextPage}
        />
      </OverlayDataShower>
    </Shell>
  )

  return (
    <>
      <PanelNavbar width={NAVBAR_WIDTH}>{renderContent()}</PanelNavbar>
      <NavbarDrawer width={NAVBAR_WIDTH} opened={drawerVisible} onClose={onDrawerClose}>
        {renderContent()}
      </NavbarDrawer>
    </>
  )
}
