import { createStyles, getStylesRef } from '@mantine/core'

// eslint-disable-next-line
interface StylesParams {}

export default createStyles((theme, props: StylesParams) => {
  return {
    media: {
      ref: getStylesRef('media'),
      maxHeight: 0,
      overflow: 'hidden',
      transition: '0.3s',
    },

    body: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,

      [`&:hover .${getStylesRef('media')}`]: {
        height: 'auto',
        maxHeight: '100%',
      },
    },

    title: {
      display: 'flex',
      minWidth: 0,
    },
  }
})
