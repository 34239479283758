import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Container, Flex, SimpleGrid } from '@mantine/core'
import { Button, DataShower, NoDataTextMessage } from '@/components/Elements'
import { TariffsPeriodSwitch } from '@/features/billing/components/Elements'
import { GeneralCard } from '@/features/billing/components/Tariff/Tariffs/Cards'
import {
  useSubscriptionCheckout,
  useSubscriptionData,
  useTariffStaticOptions,
  useTariffsList,
  useTariffsPeriodGrid,
} from '@/features/billing/hooks'
import { Tariff } from '@/features/billing/types'
import { TermsConfirmModal } from '@/features/legal/components/Terms'
import { useUserSubscription } from '@/features/user/hooks'
import { usePopup } from '@/hooks'
import useStyles from './SubscriptionPlans.styles'

export const SubscriptionPlans = () => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  const { list, fetched, loading, error } = useTariffsList({
    options: { skip: false },
  })

  const { isPremium, isFree } = useTariffStaticOptions()
  const { subscription } = useSubscriptionData()

  const { tariff: subscriptionTariff, isSubscriptionTariffAllow } = useUserSubscription()

  const { period, onPeriodChange, data: tariffs } = useTariffsPeriodGrid({ tariffs: list })

  const { loading: checkoutLoading, onSubmit } = useSubscriptionCheckout()

  const onSubscribe = async (tariff: Tariff) => {
    if (!isFree(subscription?.tariff.alias)) {
      onSwap()
    } else {
      const { id } = tariff
      await onSubmit(id)
    }
  }

  const onSwap = () => {
    if (subscription?.billing_url) {
      window.open(subscription?.billing_url, '_blank')
    }
  }

  const renderTariffAction = (tariff: Tariff) => {
    const current = tariff?.id === subscriptionTariff?.id

    return (
      <Button
        fullWidth
        disabled={!isSubscriptionTariffAllow(tariff) || checkoutLoading}
        onClick={() => onTermsPopupOpen(tariff)}
      >
        {current ? t('current_plan') : t('get_license')}
      </Button>
    )
  }

  // --- Terms --- //
  const termsPopup = usePopup()

  const onTermsPopupOpen = (data: Tariff) => {
    termsPopup.open({ tariff: data })
  }

  const onTermsPopupClose = () => {
    termsPopup.close()
  }

  const onTermsConfirm = () => {
    onSubscribe(termsPopup.data.tariff)

    onTermsPopupClose()
  }
  // === //

  return (
    <>
      <div>
        <Container className={classes.wrapper} size={1400}>
          <div className={classes.inner}>
            <Container py={20} size={400}>
              <DataShower isLoading={loading} isFetched={fetched} isFailed={!!error} error={error}>
                <Flex direction="column" align="center">
                  <Box mb={'xl'}>
                    <TariffsPeriodSwitch value={period} onChange={onPeriodChange} />
                  </Box>

                  {tariffs.length > 0 ? (
                    <SimpleGrid
                      className={'w-full'}
                      cols={tariffs.length}
                      spacing={120}
                      breakpoints={[
                        { maxWidth: 900, cols: tariffs.length, spacing: 40 },
                        { maxWidth: 800, cols: 1, verticalSpacing: 10 },
                      ]}
                    >
                      {tariffs.map((item) => (
                        <React.Fragment key={item.id}>
                          <GeneralCard data={item} actionSections={renderTariffAction(item)} />
                        </React.Fragment>
                      ))}
                    </SimpleGrid>
                  ) : (
                    <NoDataTextMessage variant={'data'} />
                  )}
                </Flex>
              </DataShower>
            </Container>
          </div>
        </Container>

        <TermsConfirmModal
          visible={termsPopup.visible}
          onClose={onTermsPopupClose}
          onConfirm={onTermsConfirm}
        />
      </div>
    </>
  )
}
