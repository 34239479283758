import React from 'react'
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form'
import { CheckboxGroup, CheckboxGroupProps } from '../../CheckboxGroup'

interface CheckboxGroupControllerProps<T extends FieldValues> extends CheckboxGroupProps {
  control: Control<T, any>
  name: FieldPath<T>
  shouldUnregister?: boolean
  defaultValue?: any
  meta?: any
  shellComponent?: any
  shellComponentProps?: any
}

export const CheckboxGroupController = <T extends FieldValues>({
  name,
  control,
  shouldUnregister,
  defaultValue,
  ...rest
}: CheckboxGroupControllerProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      shouldUnregister={shouldUnregister}
      defaultValue={defaultValue}
      render={({ field: { ref, ...field }, fieldState, formState }) => {
        const meta = {
          error: fieldState.error,
        }

        return (
          <CheckboxGroup
            {...field}
            onChange={(value) => field.onChange(value as any)}
            fieldRef={ref}
            meta={meta}
            {...rest}
          />
        )
      }}
    />
  )
}
