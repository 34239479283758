import { createStyles, rem } from '@mantine/core'

export default createStyles((theme) => {
  return {
    card: {
      backgroundColor: 'transparent',
    },

    header: {},

    name: {
      color: theme.colors[theme.primaryColor][4],
      textTransform: 'uppercase',
      lineHeight: rem(32),
    },

    descriptionBlock: {
      minHeight: rem(84),

      [theme.fn.smallerThan(800)]: {
        minHeight: 'auto',
      },
    },

    listTitle: {
      color: theme.colors[theme.primaryColor][4],
    },

    list: {},
  }
})
