import React, { useState } from 'react'

export interface UsePopupReturn {
  data: any
  visible: boolean
  open: (data?: any) => void
  close: () => void
  resetData: () => void
}

export const usePopup = (): UsePopupReturn => {
  const [visible, setVisible] = useState(false)
  const [data, setData] = useState<any>(null)

  const open = (data?: any) => {
    data && setData(data)
    setVisible(true)
  }

  const close = () => {
    setVisible(false)

    resetData()
  }

  const resetData = () => {
    setData(null)
  }
  return { data, visible, open, close, resetData }
}
