import React, { useEffect } from 'react'

export const useDocumentBodyClassNameEffect = (className: string) => {
  useEffect(() => {
    document.body.classList.add(className)

    return () => {
      document.body.classList.remove(className)
    }
  }, [])
}
