import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Stack, Text, Title, rem } from '@mantine/core'
import { CONTACT_COMPANY_ADDRESS_INFO } from '@/const/info'

export const ContactInfo = () => {
  const { t } = useTranslation()

  return (
    <Box maw={rem(280)}>
      <Stack spacing="xs" mb={'xl'}>
        <Title order={3}>{t('company_address')}:</Title>

        <Text className={'whitespace-pre'}>{CONTACT_COMPANY_ADDRESS_INFO}</Text>
      </Stack>
    </Box>
  )
}
