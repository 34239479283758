import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Flex, Image, Text, Title } from '@mantine/core'
import image from '@/assets/images/illustrations/man-building-relax.svg'
import { Button } from '@/components/Elements'
import useStyles from './Style.styles'

interface IProps {
  onProjectCreate: () => void
}

export const EmptyContent: React.FC<IProps> = ({ onProjectCreate, ...props }) => {
  const { t } = useTranslation()

  const { classes } = useStyles()

  return (
    <div className={classes.root}>
      <Image width={260} src={image} alt={'media'} />

      <Flex direction={'column'} align={'center'} my={'2rem'}>
        <Title order={4} mb={'xs'} ta="center">
          {t('projects.empty_content.title-1')}
        </Title>

        <Text ta="center">{t('projects.empty_content.title-2')}</Text>
      </Flex>

      <Box w={270}>
        <Button fullWidth onClick={() => onProjectCreate()}>
          {t('create_a_project')}
        </Button>
      </Box>
    </div>
  )
}
