import { useTranslation } from 'react-i18next'
import { useDisclosure } from '@mantine/hooks'
import { FloorSettingsModal } from './FloorSettingsModal'
import useStyles from './Style.styles'
import { Window } from './Window'

export const Floor = (params: any) => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const [opened, { open, close }] = useDisclosure(false)

  const {
    data,
    onAddNewWindow,
    onDeleteFloor,
    onUpdateFloor,
    onDeleteWindow,
    onUpdateWindow,
    showActions,
    scale,
    floorWidth,
    canAddNewWindow,
    canDeleteFloor,
  } = params

  const handleAddNewWindow = () => {
    if (canAddNewWindow(data.id)) {
      onAddNewWindow(data.id)
    }
  }

  const handleDeleteFloor = () => {
    onDeleteFloor(data.id)
  }

  const handleUpdateFloor = (updatedFloor: any) => {
    onUpdateFloor(data.id, updatedFloor)
  }

  return (
    <>
      {data.upPertition && (
        <div
          className={classes.upPertition}
          style={{
            height: (data.upPertition.height / 10) * scale,
            width: (floorWidth / 10) * scale,
          }}
        ></div>
      )}
      <div
        className={`${classes.floor}
          ${!data.upPertition || +data.upPertition.height === 0 ? classes.upPertitionEmpty : ''}
          ${
            !data.underPertition || +data.underPertition.height === 0
              ? classes.underPertitionEmpty
              : ''
          }`}
        style={{ height: (data.height / 10) * scale, width: (floorWidth / 10) * scale }}
      >
        {data.windows.map((window: any) => (
          <Window
            key={window.id}
            data={window}
            onDeleteWindow={onDeleteWindow}
            onUpdateWindow={onUpdateWindow}
            floorId={data.id}
            showActions={showActions}
            scale={scale}
          />
        ))}
        {showActions && (
          <div className={classes.floorActions}>
            <FloorSettingsModal
              data={data}
              opened={opened}
              open={open}
              close={close}
              handleAddNewWindow={handleAddNewWindow}
              handleDeleteFloor={handleDeleteFloor}
              canDeleteFloor={canDeleteFloor}
              submit={handleUpdateFloor}
            />
          </div>
        )}
      </div>
      {data.underPertition && (
        <div
          className={classes.underPertition}
          style={{
            height: (data.underPertition.height / 10) * scale,
            width: (floorWidth / 10) * scale,
          }}
        ></div>
      )}
    </>
  )
}
